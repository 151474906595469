import { Explore } from "@mui/icons-material";
import { SpaceDashboardOutlined } from "@mui/icons-material";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import CallMadeIcon from "@mui/icons-material/CallMade";
import DownloadIcon from "@mui/icons-material/Download";
import EditIcon from "@mui/icons-material/Edit";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import HistoryIcon from "@mui/icons-material/History";
import ReceiptIcon from "@mui/icons-material/Receipt";
import { UserRole, UserTypes } from "src/entity/models/FrontendActor";

import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  ListItemIcon,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import { Buffer } from "buffer";
import dayjs from "dayjs";
import saveAs from "file-saver";
import { MRT_ColumnFiltersState } from "material-react-table";
import React, { createContext, useContext, useEffect, useMemo, useRef, useState } from "react";
import { useLocation } from "react-router";
import { toast } from "react-toastify";
import LoadingIcon from "src/Components/Common/LoadingIcon";
import { LoggedInSkeleton } from "src/Components/Common/LoggedInSkeleton";
import { getReconTopBarButtons } from "src/Components/Common/TopNavBar";
import useFetch from "src/Components/Common/useFetch";
import { userContext } from "src/Components/Contexts/userContext";
import CustomToast from "src/Components/CustomToast";
import ReactTable, { ColDef } from "src/Components/ReactTable/ReactTable";
import { allTaskCategories, ListOwnTallyCompanies } from "src/entity/recon-entity/ReconInterfaces";
import { API_ENDPOINTS } from "src/Utils/ApiConstants/ApiUrlConstants";
import { isOverFlowing } from "src/Utils/Common/CheckTextOverflow";
import { filterDateBetweenStartAndEndDate, formatDate } from "src/Utils/DateUtils";
import { BalanceConfirmationBetaColorMap, BalanceConfirmationBetaStatuses } from "src/Utils/PartnerCommunication";
import { uiLoggerNamesTaskBoard } from "src/Utils/Recon/UiLogger/Constants";
import uiLogger from "src/Utils/UiLogger";
import DiscardTaskModal from "../DisputeResolution/ClosureTracker/DiscardTaskModal";
import SaveTaskModal from "../DisputeResolution/ClosureTracker/SaveTaskModal";
import {
  BranchInfo,
  CompanyInfo,
  DEFAULT_BRANCH_CODE,
  DEFAULT_COMPANY_ID,
} from "../PartnerCommunication/CommonLegacy/CommonComponents";
import { NdButton } from "../PartnerCommunication/MsmePartnerPortal/CommonComponents";
import BulkTasksModal from "../Tickets/BulkTasksModal";
import EditTaskModal from "../Tickets/EditTaskModal";
import TaskAttachmentsModal from "../Tickets/TaskAttachmentsModal";
import TasksHistoryModal, { NAKAD_SERVICE } from "../Tickets/TasksHistoryModal";
import { renderGroupedOptions, TicketMetaStatuses } from "../Tickets/ViewTickets";
import { IntegratedDropDownIcons } from "./IntegratedDropdownIcons";
import {
  dateRangeColumns,
  formatCurrencyCommaSeparated,
  mappingForTaskBoardStatuses,
  mappingForTaskBoardViews,
  numberRangeColumns,
  TaskDetails,
  TaskTrackerFilters,
  TASK_BOARD_TABLE_VIEWS,
  TASK_DETAILS_COLUMNS,
  TASK_PHASES,
} from "./Interfaces";
import InvoicingSingleTaskModal from "./Invoicing/InvoicingSingleTaskModal";
import styles from "./TaskTracker.module.scss";
import TaskTrackerFilter from "./TaskTrackerFilter";
import TaskTrackerFiltersBars from "./TaskTrackerFiltersBars";

type StateDispatch<T = any> = React.Dispatch<React.SetStateAction<T>>;
interface ITaskTrackerContext {
  setColumnFilters: StateDispatch<MRT_ColumnFiltersState>;
  appliedFilters: TaskTrackerFilters;
  setAppliedFilters: StateDispatch<TaskTrackerFilters>;
  listAllTasks: () => void;
  appliedFiltersRef: React.MutableRefObject<TaskTrackerFilters>;
}
export const TaskTrackerContext = createContext<ITaskTrackerContext>(null);

export default function TaskTracker() {
  const location = useLocation<any>();
  const { actor } = useContext(userContext);

  const [lastCompany, setLastCompany] = useState<CompanyInfo>(null);
  const companyId = useRef<string>(DEFAULT_COMPANY_ID);

  const [lastBranch, setLastBranch] = useState<BranchInfo>(null);
  const branchCode = useRef<string>(DEFAULT_BRANCH_CODE);

  const [allTasks, setAllTasks] = useState<TaskDetails[]>([]);
  const [isLoadingAllTasks, setIsLoadingAllTasks] = useState<boolean>(true);
  const [selectedTableView, setSelectedTableView] = useState(TASK_BOARD_TABLE_VIEWS.defaultView);
  const selectedTableViewRef = useRef(TASK_BOARD_TABLE_VIEWS.defaultView);
  const [openTaskTrackerFilter, setOpenTaskTrackerFilter] = useState<boolean>(false);
  const [appliedFilters, setAppliedFilters] = useState<TaskTrackerFilters>({});
  const appliedFiltersRef = useRef<TaskTrackerFilters>({});
  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>([
    { id: "metaStatus", value: ["In Progress"] },
  ]);
  const [grouping, setGrouping] = useState<string[]>([]);
  const [allUsers, setAllUsers] = useState<{ id: number; name: string }[]>([]);
  const [taskCategories, setTaskCategories] = useState(allTaskCategories);
  const [allTaskLabels, setAllTaskLabels] = useState([]);
  const [allStatuses, setAllStatuses] = useState([]);
  const [partnerOptions, setPartnerOptions] = useState<{ partnerName: string }[]>([]);
  const [vendorCodeOptions, setVendorCodeOptions] = useState<{ vendorCode: string }[]>([]);
  const [taskIdsOptions, setTaskIdsOptions] = useState<{ taskId: string }[]>([]);
  const [showSaveTaskModal, setShowSaveTaskModal] = useState<boolean>(false);
  const [showDiscardTaskModal, setShowDiscardTaskModal] = useState<boolean>(false);
  const changeStatusTaskId = useRef(null);
  const [showEditTaskModal, setShowEditTaskModal] = useState<boolean>(false);
  const [selectedRowForEdit, setSelectedRowForEdit] = useState(null);
  const [showTaskAttachmentsModal, setShowTaskAttachmentsModal] = useState<boolean>(false);
  const currentSelectedTaskDetails = useRef<{ businessPartnerName: string; taskId: number }>(null);
  const [showTasksHistoryModal, setShowTasksHistoryModal] = useState<boolean>(false);
  const [isEditing, setIsEditing] = useState(false);
  const updatingStatusBpId = useRef(null);

  const [showBulkTasksModal, setShowBulkTasksModal] = useState<boolean>(false);
  const [selectedRow, setSelectedRow] = useState([]);
  const [clearRows, setClearRows] = useState(false);

  const [isDownloadingReport, setIsDownloadingReport] = useState<boolean>(false);
  const taskInvoicingDetails = useRef(null);
  const [showInvoicingTaskModal, setShowInvoicingTaskModal] = useState<boolean>(false);

  const setTableGroupingAndFiltersBasedOnTableView = (tableView: string) => {
    if (tableView === TASK_BOARD_TABLE_VIEWS.defaultView) {
      setGrouping([]);
      setAppliedFilters({});
      appliedFiltersRef.current = {};
      setColumnFilters([{ id: "metaStatus", value: ["In Progress"] }]);
    } else if (tableView === TASK_BOARD_TABLE_VIEWS.reconTasks) {
      setGrouping([]);
      setAppliedFilters({});
      appliedFiltersRef.current = {};
      setColumnFilters([{ id: "metaStatus", value: ["In Progress"] }]);
    } else if (tableView === TASK_BOARD_TABLE_VIEWS.assignedToMe) {
      setGrouping([]);
      setAppliedFilters({});
      appliedFiltersRef.current = {};
      setColumnFilters([
        { id: "maker", value: [actor.userName] },
        { id: "metaStatus", value: ["In Progress"] },
      ]);
    } else if (tableView === TASK_BOARD_TABLE_VIEWS.statusSummary) {
      setGrouping(["status"]);
      setAppliedFilters({});
      appliedFiltersRef.current = {};
      setColumnFilters([{ id: "metaStatus", value: ["In Progress"] }]);
    }
  };
  const handleTableViewChange = async (event) => {
    uiLogger(mappingForTaskBoardViews[event.target.value].functionName, companyId.current, branchCode.current, {
      message: mappingForTaskBoardViews[event.target.value].message,
    });
    setSelectedTableView(event.target.value);
    selectedTableViewRef.current = event.target.value;
    setTableGroupingAndFiltersBasedOnTableView(event.target.value);
    setClearRows(true);
    await listAllTasks();
  };

  useEffect(() => {
    if (actor.integration === false) {
      listAllTasks();
      getAllTaskLabels();
      listMainPageData();
    }
  }, []);

  const companyNameSelect = (e: Record<string, any>, option: ListOwnTallyCompanies | CompanyInfo) => {
    if (option !== null) {
      if (e?.persist) e.persist();
      companyId.current = option.companyId;
      setLastCompany(option);
      if (!actor.branchLevelReconcilation) {
        listMainPageData();
        setTableGroupingAndFiltersBasedOnTableView(TASK_BOARD_TABLE_VIEWS.defaultView);
        selectedTableViewRef.current = TASK_BOARD_TABLE_VIEWS.defaultView;
        listAllTasks();
        getAllTaskLabels();
      }
    } else if (option === null) {
      companyId.current = null;
    }
  };

  const branchNameSelect = (e: Record<string, any>, option: BranchInfo) => {
    if (option !== null) {
      if (e?.persist) e.persist();
      branchCode.current = option.branchCode;
      setLastBranch(option);
      if (actor.branchLevelReconcilation) {
        listMainPageData();
        setTableGroupingAndFiltersBasedOnTableView(TASK_BOARD_TABLE_VIEWS.defaultView);
        selectedTableViewRef.current = TASK_BOARD_TABLE_VIEWS.defaultView;
        listAllTasks();
        getAllTaskLabels();
      }
    } else if (option === null) {
      ResetOnCompanyChange();
    }
  };
  const ResetOnCompanyChange = () => {
    branchCode.current = null;
    setLastBranch(null);
  };

  const listAllTasks = async () => {
    setIsLoadingAllTasks(true);
    await useFetch(API_ENDPOINTS.GET_ALL_TASKS_DATA.url, "POST", {
      failureMessage: API_ENDPOINTS.GET_ALL_TASKS_DATA.failureMessage,
      data: {
        companyId: companyId.current,
        branchCode: branchCode.current,
        tableView: selectedTableViewRef.current,
        mainFilters: appliedFiltersRef.current,
      },
      thenCallBack: (response) => {
        setAllTasks(response.data.allFinalTaskList || []);
        setIsLoadingAllTasks(false);
      },
      catchCallBack: () => {
        setIsLoadingAllTasks(false);
      },
    });
  };
  const getAllTaskLabels = () => {
    useFetch(API_ENDPOINTS.GET_TASK_LABELS.url, "GET", {
      failureMessage: API_ENDPOINTS.GET_TASK_LABELS.failureMessage,
      thenCallBack: (res) => {
        setAllTaskLabels(res.data.allLabels?.map((taskLabel) => ({ title: taskLabel })));
      },
    });
  };

  const listMainPageData = () => {
    useFetch(API_ENDPOINTS.GET_ALL_MAIN_PAGE_DATA.url, "GET", {
      config: {
        params: {
          companyId: companyId.current,
          branchCode: branchCode.current,
        },
      },
      failureMessage: API_ENDPOINTS.GET_ALL_MAIN_PAGE_DATA.failureMessage,
      thenCallBack: (response) => {
        setAllUsers(response.data.users || []);
        setTaskCategories(response.data.ticketCategories || allTaskCategories);
        setAllStatuses(response.data.statuses || []);
        setPartnerOptions(
          response.data.mainFilter.businessPartnerName?.map((partnerName) => ({ partnerName: partnerName })) || []
        );
        setVendorCodeOptions(
          response.data.mainFilter.vendorCode?.map((vendorCode) => ({ vendorCode: vendorCode })) || []
        );
        setTaskIdsOptions(response.data.mainFilter.taskId?.map((id) => ({ taskId: id.toString() })) || []);
      },
    });
  };

  const updateTaskMetaStatus = async (status: string, ticketId: number) => {
    uiLogger(mappingForTaskBoardStatuses[status].functionName, companyId.current, branchCode.current, {
      message: mappingForTaskBoardStatuses[status].functionName,
      businessPartnerId: updatingStatusBpId.current,
      ticketStatus: status,
      idOfTicket: ticketId,
    });
    await useFetch("/api/tickets/UpdateTicketMetaStatus", "POST", {
      showSuccessToast: true,
      data: {
        ticketId,
        metaStatus: status,
      },
      thenCallBack: () => {
        listAllTasks();
        setShowSaveTaskModal(false);
        setShowDiscardTaskModal(false);
      },
      catchCallBack: () => {
        listAllTasks();
        setShowSaveTaskModal(false);
        setShowDiscardTaskModal(false);
      },
    });
  };

  const updateTicketStatus = (statusId: number, ticketId: number, statusName: string) => {
    const uiLogFunctionName =
      mappingForTaskBoardStatuses[statusName]?.functionName ||
      uiLoggerNamesTaskBoard.UI_WF_TB_RECONSTATUS_CUSTOMSTATUS_CLICK.functionName;
    const uiLogMessage =
      mappingForTaskBoardStatuses[statusName]?.message ||
      uiLoggerNamesTaskBoard.UI_WF_TB_RECONSTATUS_CUSTOMSTATUS_CLICK.message;
    uiLogger(uiLogFunctionName, companyId.current, branchCode.current, {
      message: uiLogMessage,
      ticketStatus: statusName,
      idOfTicket: ticketId,
      businessPartnerId: updatingStatusBpId.current,
      statusId: statusId,
    });
    useFetch(API_ENDPOINTS.UPDATE_TICKET_STATUS.url, "POST", {
      failureMessage: API_ENDPOINTS.UPDATE_TICKET_STATUS.failureMessage,
      data: {
        ticketId: ticketId,
        statusId,
      },
      thenCallBack: () => {
        listAllTasks();

        setShowSaveTaskModal(false);
        setShowDiscardTaskModal(false);
        toast.success(<CustomToast message="Successfully changed ticket status" />);
      },
      catchCallBack: (error) => {
        const message = error?.response?.data?.message || "Error in changing ticket status";
        listAllTasks();
        setShowSaveTaskModal(false);
        setShowDiscardTaskModal(false);
        toast.error(<CustomToast message={message} />);
      },
    });
  };

  const handleDownloadReport = () => {
    uiLogger(
      uiLoggerNamesTaskBoard.UI_WF_TB_DOWNLOADTASKREPOSITORY_CLICK.functionName,
      companyId.current,
      branchCode.current,
      {
        message: uiLoggerNamesTaskBoard.UI_WF_TB_DOWNLOADTASKREPOSITORY_CLICK.message,
      }
    );
    const mergedFilters = {};

    // Iterate over columnFilters and merge values
    columnFilters.forEach((filter) => {
      const { id, value } = filter;
      const isDateRangeFilter = dateRangeColumns.includes(id as TASK_DETAILS_COLUMNS);
      const isNumberRangeFilter = numberRangeColumns.includes(id as TASK_DETAILS_COLUMNS);

      if (isDateRangeFilter) {
        const startDate = value[0] ? formatDate(dayjs(value[0]).toDate()) : null;
        const endDate = value[1] ? formatDate(dayjs(value[1]).toDate()) : null;
        if (startDate === null && endDate === null) return;
        mergedFilters[id] = [startDate, endDate];
      } else if (
        id === "remarks" ||
        id === "additionalRemark" ||
        id === "personPhoneNumber" ||
        (value as any).length === 0
      ) {
        return;
      } else if (isNumberRangeFilter) {
        const min = value[0] ? value[0] : null;
        const max = value[1] ? value[1] : null;
        if (min === null && max === null) return;
        mergedFilters[id] = [min, max];
      } else {
        mergedFilters[id] = value;
      }
    });
    setIsDownloadingReport(true);
    useFetch(API_ENDPOINTS.GET_DOWNLOAD_TASK_REPOSITORY_REPORT.url, "POST", {
      failureMessage: API_ENDPOINTS.GET_DOWNLOAD_TASK_REPOSITORY_REPORT.failureMessage,
      showSuccessToast: true,
      data: {
        companyId: companyId.current,
        branchCode: branchCode.current,
        tableView: selectedTableViewRef.current,
        tableFilters: mergedFilters,
        mainFilters: appliedFiltersRef.current,
      },
      thenCallBack: (response) => {
        const { workBookBase64, filename } = response.data;
        const excelData = Buffer.from(workBookBase64, "base64");
        const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
        const blob = new Blob([excelData], { type: fileType });
        saveAs(blob, filename);
        setIsDownloadingReport(false);
      },
      catchCallBack: () => {
        setIsDownloadingReport(false);
      },
    });
  };

  const columnDefinitionTasks = useMemo((): ColDef<TaskDetails>[] => {
    const columns = [
      {
        id: "taskId",
        header: "Task ID",
        size: 180,
        minSize: 180,
        filterVariant: "multi-select",
        filterFn: (row, _id, filterValue) => {
          if (filterValue.length === 0) return true;
          return filterValue.includes(row.original.taskId?.toString());
        },
        accessorFn: (row) => row.taskId?.toString() || "",
        Cell: ({ row }) => (
          <div style={{ textAlign: "center" }}>
            {row.original.metaStatus === TicketMetaStatuses.InProgress &&
            row.original.service === NAKAD_SERVICE.Recon ? (
              <a
                onClick={() => {
                  const { partnerId, businessPartnerName, taskId, status } = row.original;
                  uiLogger(
                    uiLoggerNamesTaskBoard.UI_WF_TB_PARTNERSELECTFROMTABLE_CLICK.functionName,
                    companyId.current,
                    branchCode.current,
                    {
                      message: uiLoggerNamesTaskBoard.UI_WF_TB_PARTNERSELECTFROMTABLE_CLICK.message,
                      businessPartnerId: partnerId,
                      idOfTicket: Number(taskId),
                      ticketStatus: status,
                    }
                  );
                  const params = `bpId=${partnerId}&bpName=${btoa(encodeURIComponent(businessPartnerName))}`;
                  const uri = `/${actor.name}/recon360/Summary/Ledger?${params}`;
                  window.open(uri, "_blank")?.focus();
                }}
                className="textOverflow_hidden_anchor"
              >
                {row.original.taskId}
              </a>
            ) : (
              <span>{row.original.taskId}</span>
            )}
          </div>
        ),
      },
      {
        id: "createdAt",
        header: "Created at",
        size: 350,
        minSize: 350,
        filterVariant: "date-range",
        accessorFn: (row) => new Date(row.createdAt),
        Cell: ({ row }) => (
          <div className="textOverflow_hidden">{row.original.createdAt ? formatDate(row.original.createdAt) : "-"}</div>
        ),
        filterFn: (row, _, filterValue) => {
          const startDate = filterValue[0] && filterValue[0].$d;
          const endDate = filterValue[1] && filterValue[1].$d;
          return filterDateBetweenStartAndEndDate(startDate, endDate, row.original.createdAt);
        },
      },
      {
        id: "companyName",
        header: "Company Name",
        size: 240,
        minSize: 240,
        filterVariant: "multi-select",
        filterFn: (row, _id, filterValue) => {
          if (filterValue.length === 0) return true;
          return filterValue.includes(row.original.companyName ? row.original.companyName : "-");
        },
        accessorFn: (row) => row.companyName || "-",
        Cell: ({ row }) => (
          <div
            style={{ width: "inherit" }}
            className="textOverflow_hidden"
            ref={(th) => {
              if (th && isOverFlowing(th)) th.title = th.innerText;
            }}
          >
            {row.original.companyName ? row.original.companyName : "-"}
          </div>
        ),
      },
      {
        id: "branchCode",
        header: "Branch Code",
        size: 220,
        minSize: 220,
        filterVariant: "multi-select",
        filterFn: (row, _id, filterValue) => {
          if (filterValue.length === 0) return true;
          return filterValue.includes(row.original.branchCode ? row.original.branchCode : "-");
        },
        accessorFn: (row) => row.branchCode || "-",
        Cell: ({ row }) => (
          <div
            style={{ width: "inherit" }}
            className="textOverflow_hidden"
            ref={(th) => {
              if (th && isOverFlowing(th)) th.title = th.innerText;
            }}
          >
            {row.original.branchCode ? row.original.branchCode : "-"}
          </div>
        ),
      },
      {
        id: "partnerId",
        header: "Partner ID",
        size: 200,
        minSize: 200,
        filterVariant: "multi-select",
        filterFn: (row, _id, filterValue) => {
          if (filterValue.length === 0) return true;
          return filterValue.includes(row.original.partnerId?.toString());
        },
        accessorFn: (row) => row.partnerId?.toString() || "",
      },
      {
        id: "businessPartnerName",
        header: "Business Partner Name",
        size: 290,
        minSize: 290,
        filterVariant: "multi-select",
        filterFn: (row, _id, filterValue) => {
          if (filterValue.length === 0) return true;
          return filterValue.includes(row.original.businessPartnerName ? row.original.businessPartnerName : "-");
        },
        accessorFn: (row) => row.businessPartnerName || "-",
        Cell: ({ row }) => (
          <div
            style={{ width: "inherit" }}
            className="textOverflow_hidden"
            ref={(th) => {
              if (th && isOverFlowing(th)) th.title = th.innerText;
            }}
          >
            {row.original.metaStatus === TicketMetaStatuses.InProgress &&
            row.original.service === NAKAD_SERVICE.Recon ? (
              <a
                onClick={() => {
                  const { partnerId, businessPartnerName, taskId, status } = row.original;
                  uiLogger(
                    uiLoggerNamesTaskBoard.UI_WF_TB_PARTNERSELECTFROMTABLE_CLICK.functionName,
                    companyId.current,
                    branchCode.current,
                    {
                      message: uiLoggerNamesTaskBoard.UI_WF_TB_PARTNERSELECTFROMTABLE_CLICK.message,
                      businessPartnerId: partnerId,
                      idOfTicket: Number(taskId),
                      ticketStatus: status,
                    }
                  );
                  const params = `bpId=${partnerId}&bpName=${btoa(encodeURIComponent(businessPartnerName))}`;
                  const uri = `/${actor.name}/recon360/Summary/Ledger?${params}`;
                  window.open(uri, "_blank")?.focus();
                }}
                className="textOverflow_hidden_anchor"
              >
                {row.original.businessPartnerName}
              </a>
            ) : (
              <span>{row.original.businessPartnerName}</span>
            )}
          </div>
        ),
      },
      {
        id: "vendorCode",
        header: "Vendor Code",
        size: 220,
        minSize: 220,
        filterVariant: "multi-select",
        filterFn: (row, _id, filterValue) => {
          if (filterValue.length === 0) return true;
          return filterValue.includes(row.original.vendorCode ? row.original.vendorCode : "-");
        },
        accessorFn: (row) => row.vendorCode || "-",
        Cell: ({ row }) => (
          <div
            className="textOverflow_hidden"
            ref={(th) => {
              if (th && isOverFlowing(th)) th.title = th.innerText;
            }}
            style={{ width: "inherit" }}
          >
            {row.original.vendorCode ? row.original.vendorCode : "-"}
          </div>
        ),
      },
      {
        id: "location",
        header: "Location",
        size: 200,
        minSize: 200,
        filterVariant: "multi-select",
        filterFn: (row, _id, filterValue) => {
          if (filterValue.length === 0) return true;
          return filterValue.includes(row.original.location ? row.original.location : "-");
        },
        accessorFn: (row) => row.location || "-",
        Cell: ({ row }) => (
          <div
            className="textOverflow_hidden"
            ref={(th) => {
              if (th && isOverFlowing(th)) th.title = th.innerText;
            }}
            style={{ width: "inherit" }}
          >
            {row.original.location ? row.original.location : "-"}
          </div>
        ),
      },
      {
        id: "relationship",
        header: "Relationship",
        size: 220,
        minSize: 220,
        filterVariant: "multi-select",
        filterFn: (row, _id, filterValue) => {
          if (filterValue.length === 0) return true;
          return filterValue.includes(row.original.relationship ? row.original.relationship : "-");
        },
        accessorFn: (row) => row.relationship || "-",
        Cell: ({ row }) => (
          <div
            className="textOverflow_hidden"
            ref={(th) => {
              if (th && isOverFlowing(th)) th.title = th.innerText;
            }}
            style={{ width: "inherit" }}
          >
            {row.original.relationship ? row.original.relationship : "-"}
          </div>
        ),
      },
      {
        id: "category",
        header: "Category",
        accessorFn: (row) => row.category?.join(", ") || "-",
        size: 200,
        minSize: 200,
        filterVariant: "multi-select",
        filterFn: (row, _id, filterValue) => {
          if (filterValue.length === 0) return true;
          return filterValue.includes(row.original.category?.join(", ") || "-");
        },
        Cell: ({ row }) => (
          <div
            className="textOverflow_hidden"
            ref={(th) => {
              if (th && isOverFlowing(th)) th.title = th.innerText;
            }}
            style={{ width: "inherit" }}
          >
            {row.original.category?.length > 0 ? row.original.category?.join(", ") : "-"}
          </div>
        ),
      },

      {
        header: "Label",
        id: "taskLabel",
        size: 180,
        minSize: 180,
        filterVariant: "multi-select",
        filterFn: (row, _id, filterValue) => {
          if (filterValue.length === 0) return true;
          return filterValue.includes(row.original.taskLabel ? row.original.taskLabel : "-");
        },
        accessorFn: (row) => row.taskLabel || "-",
        Cell: ({ row }) => {
          return (
            <div
              className="textOverflow_hidden"
              ref={(th) => {
                if (th && isOverFlowing(th)) th.title = th.innerText;
              }}
              style={{ width: "inherit" }}
            >
              {row.original.taskLabel ? row.original.taskLabel : "-"}
            </div>
          );
        },
      },
      {
        header: "Task Status",
        id: "status",
        size: 250,
        minSize: 250,
        filterVariant: "multi-select",
        filterFn: (row, _id, filterValue) => {
          if (filterValue.length === 0) return true;
          return filterValue.includes(row.original.status);
        },
        accessorFn: (row) => row.status || "",
        Cell: ({ row, column }) => {
          const [anchorElDispute, setAnchorElDispute] = React.useState(null);
          const openDispute = Boolean(anchorElDispute);

          function handleClickDispute(event) {
            setAnchorElDispute(event.currentTarget);
          }
          function handleCloseDispute() {
            setAnchorElDispute(null);
          }
          const onChangeHandler = (e) => {
            const newValue = e.target.value;
            row.original.statusId = newValue;
            const statusName = allStatuses.find((status) => status.id === newValue).status;
            updateTicketStatus(newValue, row.original.taskId, statusName);
          };
          return (
            <Select
              open={openDispute}
              onOpen={handleClickDispute}
              onClose={handleCloseDispute}
              disableUnderline={true}
              renderValue={() => row.original.status}
              displayEmpty={true}
              sx={{
                width: "inherit",
              }}
              onFocus={() => {
                updatingStatusBpId.current = row.original.partnerId;
              }}
              disabled={
                column.getIsGrouped() ||
                row.original.metaStatus !== TicketMetaStatuses.InProgress ||
                selectedRow.length > 0 ||
                row.original.service === NAKAD_SERVICE.PartnerCommunication
              }
              variant="standard"
              value={row.original.statusId}
              onChange={onChangeHandler}
              MenuProps={{
                PaperProps: {
                  sx: {
                    "& .MuiMenu-list": {
                      paddingBottom: 0,
                      paddingTop: 0,
                      maxHeight: 450,
                    },
                  },
                },
              }}
            >
              {renderGroupedOptions(taskCategories, allStatuses)}

              <div
                style={{
                  position: "sticky",
                  bottom: "0",
                  backgroundColor: "#fff",
                  zIndex: 100,
                  display: "flex",
                  flexDirection: "column",
                  padding: "16px",
                  gap: "16px",
                  borderTop: "2px solid #e7e7e7",
                  marginTop: "8px",
                }}
              >
                <Button
                  variant="contained"
                  className="theme_btn fs_14 fw_500"
                  sx={{ borderRadius: "4px !important", padding: "7px 16px" }}
                  onClick={() => {
                    changeStatusTaskId.current = row.original.taskId;
                    setAnchorElDispute(null);
                    setShowSaveTaskModal(true);
                  }}
                >
                  SAVE/SIGN OFF TASK
                </Button>
                <Button
                  className="fs_14 fw_500"
                  variant="outlined"
                  color="error"
                  sx={{ borderRadius: "4px !important", padding: "6px 16px" }}
                  onClick={() => {
                    changeStatusTaskId.current = row.original.taskId;
                    setAnchorElDispute(null);
                    setShowDiscardTaskModal(true);
                  }}
                >
                  DISCARD TASK
                </Button>
              </div>
            </Select>
          );
        },
      },
      {
        header: "Bucket",
        id: "metaStatus",
        size: 180,
        minSize: 180,
        filterFn: (row, _id, filterValue) => {
          if (filterValue.length === 0) return true;
          return filterValue.includes(row.original.metaStatus ? row.original.metaStatus : "-");
        },
        accessorFn: (row) => row.metaStatus || "-",
        filterVariant: "multi-select",
        Cell: ({ row }) => (
          <div className="textOverflow_hidden">{row.original.metaStatus ? row.original.metaStatus : "-"}</div>
        ),
      },
      {
        header: "Last Active User",
        id: "lastActiveUser",
        size: 250,
        minSize: 250,
        filterVariant: "multi-select",
        filterFn: (row, _id, filterValue) => {
          if (filterValue.length === 0) return true;
          return filterValue.includes(row.original.lastActiveUser ? row.original.lastActiveUser : "-");
        },
        accessorFn: (row) => row.lastActiveUser || "-",
        Cell: ({ row }) => (
          <div
            className="textOverflow_hidden"
            ref={(th) => {
              if (th && isOverFlowing(th)) th.title = th.innerText;
            }}
            style={{ width: "inherit" }}
          >
            {row.original.lastActiveUser ? row.original.lastActiveUser : "-"}
          </div>
        ),
      },
      {
        header: "Maker",
        id: "maker",
        size: 180,
        minSize: 180,
        filterVariant: "multi-select",
        filterFn: (row, _id, filterValue) => {
          if (filterValue.length === 0) return true;
          return filterValue.includes(row.original.maker ? row.original.maker : "-");
        },
        accessorFn: (row) => row.maker || "-",
        Cell: ({ row }) => {
          return (
            <div
              className="textOverflow_hidden"
              ref={(th) => {
                if (th && isOverFlowing(th)) th.title = th.innerText;
              }}
              style={{ width: "inherit" }}
            >
              {row.original.maker ? row.original.maker : "-"}
            </div>
          );
        },
      },
      {
        header: "Checker",
        id: "checker",
        size: 180,
        minSize: 180,
        filterVariant: "multi-select",
        filterFn: (row, _id, filterValue) => {
          if (filterValue.length === 0) return true;
          return filterValue.includes(row.original.checker ? row.original.checker : "-");
        },
        accessorFn: (row) => row.checker || "-",
        Cell: ({ row }) => (
          <div
            className="textOverflow_hidden"
            ref={(th) => {
              if (th && isOverFlowing(th)) th.title = th.innerText;
            }}
            style={{ width: "inherit" }}
          >
            {row.original.checker ? row.original.checker : "-"}
          </div>
        ),
      },
      {
        header: "Approver",
        id: "approver",
        size: 190,
        minSize: 190,
        filterVariant: "multi-select",
        filterFn: (row, _id, filterValue) => {
          if (filterValue.length === 0) return true;
          return filterValue.includes(row.original.approver ? row.original.approver : "-");
        },
        accessorFn: (row) => row.approver || "-",
        Cell: ({ row }) => (
          <div
            className="textOverflow_hidden"
            ref={(th) => {
              if (th && isOverFlowing(th)) th.title = th.innerText;
            }}
            style={{ width: "inherit" }}
          >
            {row.original.approver ? row.original.approver : "-"}
          </div>
        ),
      },
      {
        header: "Priority",
        id: "priority",
        size: 180,
        minSize: 180,
        filterVariant: "multi-select",
        filterFn: (row, _id, filterValue) => {
          if (filterValue.length === 0) return true;
          return filterValue.includes(row.original.priority ? row.original.priority : "-");
        },
        accessorFn: (row) => row.priority || "-",
        Cell: ({ row }) => (
          <div className="textOverflow_hidden">{row.original.priority ? row.original.priority : "-"}</div>
        ),
      },
      {
        header: "Due Date",
        id: "dueDate",
        size: 350,
        minSize: 350,
        filterVariant: "date-range",
        accessorFn: (row) => new Date(row.dueDate),
        Cell: ({ row }) => (
          <div className="textOverflow_hidden">{row.original.dueDate ? formatDate(row.original.dueDate) : "-"}</div>
        ),
        filterFn: (row, _, filterValue) => {
          const startDate = filterValue[0] && filterValue[0].$d;
          const endDate = filterValue[1] && filterValue[1].$d;
          return filterDateBetweenStartAndEndDate(startDate, endDate, row.original.dueDate);
        },
      },
      {
        header: "Remarks",
        id: "remarks",
        size: 250,
        minSize: 250,
        filterFn: "contains",
        accessorFn: (row) => row.remarks || "-",
        Cell: ({ row }) => (
          <div
            className="textOverflow_hidden"
            ref={(th) => {
              if (th && isOverFlowing(th)) th.title = th.innerText;
            }}
            style={{ width: "inherit" }}
          >
            {row.original.remarks ? row.original.remarks : "-"}
          </div>
        ),
      },
      {
        header: "Invoicing Status",
        id: "invoiceStatus",
        size: 240,
        minSize: 240,
        filterVariant: "multi-select",
        filterFn: (row, _id, filterValue) => {
          if (filterValue.length === 0) return true;
          return filterValue.includes(row.original.invoiceStatus ? row.original.invoiceStatus : "-");
        },
        accessorFn: (row) => row.invoiceStatus || "-",
        Cell: ({ row }) => (
          <div className="textOverflow_hidden">{row.original.invoiceStatus ? row.original.invoiceStatus : "-"}</div>
        ),
      },
      {
        header: "PC Invoice Date",
        id: "pcInvoiceDate",
        size: 350,
        minSize: 350,
        filterVariant: "date-range",
        accessorFn: (row) => new Date(row.pcInvoiceDate),
        Cell: ({ row }) => (
          <div className="textOverflow_hidden">
            {row.original.pcInvoiceDate ? formatDate(row.original.pcInvoiceDate) : "-"}
          </div>
        ),
        filterFn: (row, _, filterValue) => {
          const startDate = filterValue[0] && filterValue[0].$d;
          const endDate = filterValue[1] && filterValue[1].$d;
          return filterDateBetweenStartAndEndDate(startDate, endDate, row.original.pcInvoiceDate);
        },
      },
      {
        header: "Recon Invoice Date",
        id: "reconInvoiceDate",
        size: 350,
        minSize: 350,
        filterVariant: "date-range",
        accessorFn: (row) => new Date(row.reconInvoiceDate),
        Cell: ({ row }) => (
          <div className="textOverflow_hidden">
            {row.original.reconInvoiceDate ? formatDate(row.original.reconInvoiceDate) : "-"}
          </div>
        ),
        filterFn: (row, _, filterValue) => {
          const startDate = filterValue[0] && filterValue[0].$d;
          const endDate = filterValue[1] && filterValue[1].$d;
          return filterDateBetweenStartAndEndDate(startDate, endDate, row.original.reconInvoiceDate);
        },
      },
      {
        header: "Recon period - (From)",
        id: "reconPeriodFrom",
        size: 350,
        minSize: 350,
        filterVariant: "date-range",
        accessorFn: (row) => new Date(row.reconPeriodFrom),
        Cell: ({ row }) => (
          <div className="textOverflow_hidden">
            {row.original.reconPeriodFrom ? formatDate(row.original.reconPeriodFrom) : "-"}
          </div>
        ),
        filterFn: (row, _, filterValue) => {
          const startDate = filterValue[0] && filterValue[0].$d;
          const endDate = filterValue[1] && filterValue[1].$d;
          return filterDateBetweenStartAndEndDate(startDate, endDate, row.original.reconPeriodFrom);
        },
      },

      {
        header: "Recon period - (To)",
        id: "reconPeriodTo",
        size: 350,
        minSize: 350,
        filterVariant: "date-range",
        Cell: ({ row }) => (
          <div className="textOverflow_hidden">
            {row.original.reconPeriodTo ? formatDate(row.original.reconPeriodTo) : "-"}
          </div>
        ),
        accessorFn: (row) => new Date(row.reconPeriodTo),
        filterFn: (row, _, filterValue) => {
          const startDate = filterValue[0] && filterValue[0].$d;
          const endDate = filterValue[1] && filterValue[1].$d;
          return filterDateBetweenStartAndEndDate(startDate, endDate, row.original.reconPeriodTo);
        },
      },
      {
        header: "Own OB",
        accessorFn: (row) => Number(row.ownOB) || "",
        id: "ownOB",
        size: 200,
        minSize: 200,
        filterVariant: "range",
        filterFn: "betweenInclusive",
        Cell: ({ row }) => {
          return row.original.ownOB || row.original.ownOB === 0
            ? formatCurrencyCommaSeparated(row.original.ownOB)
            : null;
        },
      },
      {
        header: "Partner OB",
        accessorFn: (row) => Number(row.partnerOB) || "",
        id: "partnerOB",
        size: 200,
        minSize: 200,
        filterVariant: "range",
        filterFn: "betweenInclusive",
        Cell: ({ row }) => {
          return row.original.partnerOB || row.original.partnerOB === 0
            ? formatCurrencyCommaSeparated(row.original.partnerOB)
            : null;
        },
      },
      {
        header: "OB Difference",
        accessorFn: (row) => Number(row.obDifference) || "",
        id: "obDifference",
        size: 220,
        minSize: 220,
        filterVariant: "range",
        filterFn: "betweenInclusive",
        Cell: ({ row }) => {
          return row.original.obDifference || row.original.obDifference === 0
            ? formatCurrencyCommaSeparated(row.original.obDifference)
            : null;
        },
      },
      {
        header: "Own CB",
        accessorFn: (row) => Number(row.ownCB) || "",
        id: "ownCB",
        size: 200,
        minSize: 200,
        filterVariant: "range",
        filterFn: "betweenInclusive",
        Cell: ({ row }) => {
          return row.original.ownCB || row.original.ownCB === 0
            ? formatCurrencyCommaSeparated(row.original.ownCB)
            : null;
        },
      },
      {
        header: "Partner CB",
        accessorFn: (row) => Number(row.partnerCB) || "",
        id: "partnerCB",
        size: 200,
        minSize: 200,
        filterVariant: "range",
        filterFn: "betweenInclusive",
        Cell: ({ row }) => {
          return row.original.partnerCB || row.original.partnerCB === 0
            ? formatCurrencyCommaSeparated(row.original.partnerCB)
            : null;
        },
      },
      {
        header: "CB Diff",
        accessorFn: (row) => Number(row.cbDifference) || "",
        id: "cbDifference",
        size: 200,
        minSize: 200,
        filterVariant: "range",
        filterFn: "betweenInclusive",
        Cell: ({ row }) => {
          return row.original.cbDifference || row.original.cbDifference === 0
            ? formatCurrencyCommaSeparated(row.original.cbDifference)
            : null;
        },
      },
      {
        header: "Email Initiation Date",
        id: "emailInitiationDate",
        accessorFn: (row) => new Date(row.emailInitiationDate || null),
        size: 350,
        minSize: 350,
        filterVariant: "date-range",
        filterFn: (row, _id, filterValue, _meta) => {
          const minDate = filterValue[0] ? dayjs(filterValue[0]).toDate() : null;
          const maxDate = filterValue[1] ? dayjs(filterValue[1]).toDate() : null;
          if (minDate || maxDate) {
            return filterDateBetweenStartAndEndDate(minDate, maxDate, row.original.emailInitiationDate);
          }
        },
        Cell: ({ row }) => (
          <div className="textOverflow_hidden">
            {row.original.emailInitiationDate ? formatDate(row.original.emailInitiationDate) : "-"}
          </div>
        ),
      },
      {
        header: "Email Status",
        id: "emailStatus",
        accessorFn: (row) => row.emailStatus || "-",
        size: 280,
        minSize: 280,
        filterVariant: "multi-select",
        filterFn: (row, _id, filterValue) => {
          if (filterValue.length === 0) return true;
          return filterValue.includes(row.original.emailStatus ? row.original.emailStatus : "-");
        },
        Cell: ({ row }) => (
          <>
            {row.original.emailStatus ? (
              <div className="vertical_center_align gap_10" style={{ width: "100%" }}>
                <Button
                  style={{
                    width: "100%",
                    overflowWrap: "break-word",
                    color: BalanceConfirmationBetaColorMap[row.original.emailStatus]?.color || "#000",
                    backgroundColor: BalanceConfirmationBetaColorMap[row.original.emailStatus]?.bgColor || "#D7D7D7",
                  }}
                  className="status_theme_btn"
                  disabled={true}
                >
                  {row.original.emailStatus === BalanceConfirmationBetaStatuses.ReminderSent
                    ? row.original.balanceConfirmationStatusWithReminderCount || row.original.emailStatus
                    : row.original.emailStatus}
                </Button>
              </div>
            ) : (
              "-"
            )}
          </>
        ),
      },
      {
        id: "responseStatus",
        header: "Response Status",
        accessorFn: (row) => row.responseStatus || "-",
        size: 240,
        minSize: 240,
        filterVariant: "multi-select",
        filterFn: (row, _id, filterValue) => {
          if (filterValue.length === 0) return true;
          return filterValue.includes(row.original.responseStatus ? row.original.responseStatus : "-");
        },
      },
      {
        id: "closingBalanceDate",
        size: 350,
        minSize: 350,
        header: "Closing Balance Date",
        accessorFn: (row) => new Date(row.closingBalanceDate),
        Cell: ({ row }) => (
          <div className="textOverflow_hidden">
            {row.original.closingBalanceDate ? formatDate(row.original.closingBalanceDate) : "-"}
          </div>
        ),
        filterVariant: "date-range",
        filterFn: (row, _, filterValue) => {
          const startDate = filterValue[0] && filterValue[0].$d;
          const endDate = filterValue[1] && filterValue[1].$d;
          return filterDateBetweenStartAndEndDate(startDate, endDate, row.original.closingBalanceDate);
        },
      },
      {
        id: "partnerResponseDate",
        size: 350,
        minSize: 350,
        header: "Partner response date",
        accessorFn: (row) => new Date(row.partnerResponseDate),
        Cell: ({ row }) => (
          <div className="textOverflow_hidden">
            {row.original.partnerResponseDate ? formatDate(row.original.partnerResponseDate) : "-"}
          </div>
        ),
        filterVariant: "date-range",
        filterFn: (row, _, filterValue) => {
          const startDate = filterValue[0] && filterValue[0].$d;
          const endDate = filterValue[1] && filterValue[1].$d;
          return filterDateBetweenStartAndEndDate(startDate, endDate, row.original.partnerResponseDate);
        },
      },
      {
        header: "Own CB (PC)",
        accessorFn: (row) => Number(row.ownClosingBalance) || "",
        id: "ownClosingBalance",
        size: 290,
        minSize: 290,
        enableSorting: true,
        filterVariant: "range",
        filterFn: "betweenInclusive",
        Cell: ({ row }) => {
          return row.original.ownClosingBalance || row.original.ownClosingBalance === 0
            ? formatCurrencyCommaSeparated(row.original.ownClosingBalance)
            : null;
        },
      },
      {
        header: "Partner CB (PC)",
        accessorFn: (row) => Number(row.businessPartnerClosingBalance) || "",
        id: "businessPartnerClosingBalance",
        size: 340,
        minSize: 340,
        enableSorting: true,
        filterVariant: "range",
        filterFn: "betweenInclusive",
        Cell: ({ row }) => {
          return row.original.businessPartnerClosingBalance || row.original.businessPartnerClosingBalance === 0
            ? formatCurrencyCommaSeparated(row.original.businessPartnerClosingBalance)
            : null;
        },
      },
      {
        header: "CB Difference (PC)",
        accessorFn: (row) => Number(row.closingBalanceDifference) || "",
        id: "closingBalanceDifference",
        size: 300,
        minSize: 300,
        enableSorting: true,
        filterVariant: "range",
        filterFn: "betweenInclusive",
        Cell: ({ row }) => {
          return row.original.closingBalanceDifference || row.original.closingBalanceDifference === 0
            ? formatCurrencyCommaSeparated(row.original.closingBalanceDifference)
            : null;
        },
      },
      {
        header: "Additional Remark",
        id: "additionalRemark",
        accessorFn: (row) => row.additionalRemark || "-",
        size: 240,
        minSize: 240,
        filterFn: "contains",
        Cell: ({ row }) => (
          <div
            className="textOverflow_hidden"
            ref={(th) => {
              if (th && isOverFlowing(th)) th.title = th.innerText;
            }}
            style={{ width: "inherit", font: "inherit" }}
          >
            {row.original.additionalRemark ? row.original.additionalRemark : "-"}
          </div>
        ),
      },
      {
        header: "Issue Raised",
        id: "issueRaised",
        accessorFn: (row) => row.issueRaised || "-",
        size: 220,
        minSize: 220,
        filterVariant: "multi-select",
        filterFn: (row, _id, filterValue) => {
          if (filterValue.length === 0) return true;
          return filterValue.includes(row.original.issueRaised ? row.original.issueRaised : "-");
        },
      },
      {
        header: "Person Name",
        id: "personName",
        accessorFn: (row) => row.personName || "-",
        size: 210,
        minSize: 210,
        filterVariant: "multi-select",
        filterFn: (row, _id, filterValue) => {
          if (filterValue.length === 0) return true;
          return filterValue.includes(row.original.personName ? row.original.personName : "-");
        },
        Cell: ({ row }) => (
          <div
            className="textOverflow_hidden"
            ref={(th) => {
              if (th && isOverFlowing(th)) th.title = th.innerText;
            }}
            style={{ width: "inherit", font: "inherit" }}
          >
            {row.original.personName ? row.original.personName : "-"}
          </div>
        ),
      },
      {
        header: "Person Email",
        id: "personEmail",
        accessorFn: (row) => row.personEmail || "-",
        size: 210,
        minSize: 210,
        filterVariant: "multi-select",
        filterFn: (row, _id, filterValue) => {
          if (filterValue.length === 0) return true;
          return filterValue.includes(row.original.personEmail ? row.original.personEmail : "-");
        },
        Cell: ({ row }) => (
          <div
            className="textOverflow_hidden"
            ref={(th) => {
              if (th && isOverFlowing(th)) th.title = th.innerText;
            }}
            style={{ width: "inherit", font: "inherit" }}
          >
            {row.original.personEmail ? row.original.personEmail : "-"}
          </div>
        ),
      },
      {
        header: "Person Designation",
        id: "personDesignation",
        accessorFn: (row) => row.personDesignation || "-",
        size: 250,
        minSize: 250,
        filterVariant: "multi-select",
        filterFn: (row, _id, filterValue) => {
          if (filterValue.length === 0) return true;
          return filterValue.includes(row.original.personDesignation ? row.original.personDesignation : "-");
        },
        Cell: ({ row }) => (
          <div
            className="textOverflow_hidden"
            ref={(th) => {
              if (th && isOverFlowing(th)) th.title = th.innerText;
            }}
            style={{ width: "inherit", font: "inherit" }}
          >
            {row.original.personDesignation ? row.original.personDesignation : "-"}
          </div>
        ),
      },
      {
        header: "Person Phone Number",
        id: "personPhoneNumber",
        accessorFn: (row) => row.personPhoneNumber || "-",
        size: 290,
        minSize: 290,
        filterFn: "contains",
        Cell: ({ row }) => (
          <div
            className="textOverflow_hidden"
            ref={(th) => {
              if (th && isOverFlowing(th)) th.title = th.innerText;
            }}
            style={{ width: "inherit", font: "inherit" }}
          >
            {row.original.personPhoneNumber ? row.original.personPhoneNumber : "-"}
          </div>
        ),
      },
      {
        id: "partnerRepliedOnMail",
        size: 350,
        minSize: 350,
        header: "Partner Replied on Mail",
        accessorFn: (row) => new Date(row.partnerRepliedOnMail),
        Cell: ({ row }) => (
          <div className="textOverflow_hidden">
            {row.original.partnerRepliedOnMail ? formatDate(row.original.partnerRepliedOnMail) : "-"}
          </div>
        ),
        filterVariant: "date-range",
        filterFn: (row, _, filterValue) => {
          const startDate = filterValue[0] && filterValue[0].$d;
          const endDate = filterValue[1] && filterValue[1].$d;
          return filterDateBetweenStartAndEndDate(startDate, endDate, row.original.partnerRepliedOnMail);
        },
      },

      {
        header: "Follow Up - Mail (count)",
        id: "followUpMailCount",
        filterVariant: "range",
        filterFn: "betweenInclusive",
        accessorFn: (row) => row.followUpMailCount?.Mail || 0,
        Cell: ({ row }) => row.original.followUpMailCount.Mail || 0,
        size: 300,
        minSize: 300,
      },
      {
        header: "Follow Up - Conversation (count)",
        id: "followUpConversationCount",
        filterVariant: "range",
        filterFn: "betweenInclusive",
        accessorFn: (row) => row.followUpMailCount?.Conversation || 0,
        Cell: ({ row }) => row.original.followUpMailCount.Conversation || 0,
        size: 350,
        minSize: 350,
      },
    ];
    if (actor.integration === false) {
      columns.splice(2, 2);
    }
    if (actor.integration === true && actor.branchLevelReconcilation === false) {
      columns.splice(3, 1);
    }
    return columns as ColDef<TaskDetails>[];
  }, [allStatuses, taskCategories]);
  const TaskTrackerContextValue: ITaskTrackerContext = {
    setColumnFilters: setColumnFilters,
    appliedFilters: appliedFilters,
    setAppliedFilters: setAppliedFilters,
    listAllTasks: listAllTasks,
    appliedFiltersRef: appliedFiltersRef,
  };
  return (
    <LoggedInSkeleton topBarButtons={getReconTopBarButtons(" ", actor.name, location?.state?.openCollapseObj, actor)}>
      <TaskTrackerContext.Provider value={TaskTrackerContextValue}>
        <Box className={styles.container}>
          <Box className={styles.header}>
            <Explore sx={{ color: "#4A148C" }} />
            <Typography variant="h6" className="fw_400 fs_18" color={"#4A148C"}>
              Quick Navigation
            </Typography>
          </Box>
          <Box className={styles.content}>
            <Box className={styles.section}>
              <Typography variant="subtitle2" className={styles.sectionTitle}>
                Automated Reconciliation
              </Typography>
              {actor.integration && (
                <Typography variant="caption" className={styles.sectionSubtitle}>
                  {lastCompany && lastCompany.companyName}
                  {lastBranch && ` , ${lastBranch.branchName}`}
                </Typography>
              )}
              <Box className={styles.sectionDropdowns}>
                <IntegratedDropDownIcons
                  tallyCompanyNameSelect={companyNameSelect}
                  AfterListOwnTallyCompanies={null}
                  companyNameSelect={companyNameSelect}
                  AfterListCompanies={null}
                  branchNameSelect={branchNameSelect}
                  AfterListBranches={null}
                  companyId={companyId.current}
                  branchCode={branchCode.current}
                />
              </Box>
            </Box>
            <Box className={styles.section}>
              <Typography variant="subtitle2" className={styles.sectionTitle}>
                Partner Communication
              </Typography>
              <Button
                variant="text"
                className={styles.linkButton}
                endIcon={<CallMadeIcon />}
                onClick={() => {
                  uiLogger(
                    uiLoggerNamesTaskBoard.UI_WF_TB_PARTNERCOMMUNICATION_OPEN_CLICK.functionName,
                    companyId.current,
                    branchCode.current,
                    {
                      message: uiLoggerNamesTaskBoard.UI_WF_TB_PARTNERCOMMUNICATION_OPEN_CLICK.message,
                    }
                  );
                  const uri = `/${actor.name}/partnerCommunication/balanceConfirmBeta`;
                  window.open(uri, "_blank")?.focus();
                }}
              >
                OPEN
              </Button>
            </Box>
          </Box>
        </Box>
        <Box className={styles.tableContainer}>
          <Box className={styles.tableCustomHeader}>
            <Stack direction={"row"}>
              <Typography variant="h5">Task Board</Typography>
              <Box className={styles.selectContainer}>
                <Select
                  size="small"
                  value={selectedTableView}
                  onChange={handleTableViewChange}
                  variant="outlined"
                  className={styles.select}
                  startAdornment={
                    <InputAdornment position="start">
                      <SpaceDashboardOutlined className={styles.icon} />
                    </InputAdornment>
                  }
                >
                  <MenuItem value={TASK_BOARD_TABLE_VIEWS.defaultView}>Default view</MenuItem>
                  <MenuItem value={TASK_BOARD_TABLE_VIEWS.assignedToMe}>My tasks</MenuItem>
                  <MenuItem value={TASK_BOARD_TABLE_VIEWS.statusSummary}>Status summary</MenuItem>
                  <MenuItem value={TASK_BOARD_TABLE_VIEWS.reconTasks}>Recon tasks</MenuItem>
                </Select>
              </Box>
            </Stack>
            <Stack direction="row" gap={1}>
              <Button
                onClick={() => {
                  uiLogger(
                    uiLoggerNamesTaskBoard.UI_WF_TB_FILTER_CLICK.functionName,
                    companyId.current,
                    branchCode.current,
                    { message: uiLoggerNamesTaskBoard.UI_WF_TB_FILTER_CLICK.message }
                  );
                  setOpenTaskTrackerFilter(true);
                }}
                startIcon={<FilterAltIcon />}
                variant="outlined"
                className={styles.filterButton}
                sx={{
                  color: "rgba(0, 0, 0, 0.87)",
                  borderColor: "rgba(0, 0, 0, 0.87)",
                  "&:hover, &.Mui-focusVisible": { borderColor: "rgba(0, 0, 0, 0.87)" },
                }}
              >
                Filter
              </Button>
              <NdButton onClick={() => setShowBulkTasksModal(true)} variant="contained" className="fs_15 fw_500">
                Bulk Actions
              </NdButton>
              <IconButton
                onClick={handleDownloadReport}
                disabled={isDownloadingReport}
                sx={{ border: "1px solid rgba(0, 0, 0, 0.12)", borderRadius: "4px" }}
              >
                {isDownloadingReport ? <LoadingIcon loading={true} /> : <DownloadIcon />}
              </IconButton>
            </Stack>
          </Box>
          <TaskTrackerFiltersBars appliedFilters={appliedFilters} columnFilters={columnFilters} />

          <ReactTable
            enableRowSelection={true}
            enableRowVirtualization={true}
            columns={columnDefinitionTasks}
            rows={allTasks}
            enableFacetedValues={true}
            setSelectedRow={setSelectedRow}
            clearRowsSelection={clearRows}
            setClearRowsSelection={setClearRows}
            enableAggregations={true}
            loading={isLoadingAllTasks}
            onColumnFiltersChange={setColumnFilters}
            onGroupingChange={setGrouping}
            additionalState={{ columnFilters, grouping }}
            additionalInitialState={{ showColumnFilters: true, columnFilters }}
            internalActionsOrder={["globalFilterToggle", "filterToggle", "showHideColumns", "fullScreenToggle"]}
            isSaving={isEditing}
            renderRowActionMenuItems={({ row, closeMenu }) => [
              <MenuItem
                key="editTask"
                disabled={row.original.metaStatus !== TicketMetaStatuses.InProgress}
                onClick={() => {
                  uiLogger(
                    uiLoggerNamesTaskBoard.UI_WF_TB_ACTIONS_EDIT_CLICK.functionName,
                    companyId.current,
                    branchCode.current,
                    {
                      message: uiLoggerNamesTaskBoard.UI_WF_TB_ACTIONS_EDIT_CLICK.message,
                    }
                  );
                  setShowEditTaskModal(true);
                  setSelectedRowForEdit(row.original);
                  closeMenu();
                }}
              >
                <ListItemIcon>
                  <EditIcon fontSize="small" />
                </ListItemIcon>
                Edit Task
              </MenuItem>,
              <MenuItem
                key="viewAttachments"
                onClick={() => {
                  currentSelectedTaskDetails.current = {
                    businessPartnerName: row.original.businessPartnerName,
                    taskId: row.original.taskId,
                  };
                  setShowTaskAttachmentsModal(true);
                  closeMenu();
                }}
              >
                <ListItemIcon>
                  <AttachFileIcon />
                </ListItemIcon>
                View Attachments
              </MenuItem>,
              <MenuItem
                key={"viewHistory"}
                onClick={() => {
                  uiLogger(
                    uiLoggerNamesTaskBoard.UI_WF_TB_ACTIONS_VIEWHISTORY_CLICK.functionName,
                    companyId.current,
                    branchCode.current,
                    {
                      message: uiLoggerNamesTaskBoard.UI_WF_TB_ACTIONS_VIEWHISTORY_CLICK.message,
                    }
                  );
                  currentSelectedTaskDetails.current = {
                    businessPartnerName: row.original.businessPartnerName,
                    taskId: row.original.taskId,
                  };
                  setShowTasksHistoryModal(true);
                  closeMenu();
                }}
              >
                <ListItemIcon>
                  <HistoryIcon />
                </ListItemIcon>
                View History
              </MenuItem>,
              actor.userType === UserTypes.CustomerSuccess &&
                (actor.userRole === UserRole.CSM || actor.userRole === UserRole.KAM) && (
                  <MenuItem
                    key={"invoiceTask"}
                    onClick={() => {
                      uiLogger(
                        uiLoggerNamesTaskBoard.UI_WF_TB_ACTIONS_INVOICETASK_CLICK.functionName,
                        companyId.current,
                        branchCode.current,
                        {
                          message: uiLoggerNamesTaskBoard.UI_WF_TB_ACTIONS_INVOICETASK_CLICK.message,
                        }
                      );
                      const currentTaskPhase =
                        row.original.service === NAKAD_SERVICE.PartnerCommunication
                          ? TASK_PHASES.partnerCommunication
                          : row.original.ticketCreatedInPC
                          ? TASK_PHASES.pcRecon
                          : TASK_PHASES.recon;
                      if (actor.userType === UserTypes.CustomerSuccess && actor.userRole === UserRole.CSM) {
                        if (currentTaskPhase === TASK_PHASES.partnerCommunication && row.original.isPcInvoiced) {
                          toast.error(
                            <CustomToast message="You are restricted to only add Invoicing status and not edit it." />
                          );
                          return;
                        }
                        if (currentTaskPhase === TASK_PHASES.recon && row.original.isReconInvoiced) {
                          toast.error(
                            <CustomToast message="You are restricted to only add Invoicing status and not edit it." />
                          );
                          return;
                        }
                        if (
                          currentTaskPhase === TASK_PHASES.pcRecon &&
                          row.original.isPcInvoiced &&
                          row.original.isReconInvoiced
                        ) {
                          toast.error(
                            <CustomToast message="You are restricted to only add Invoicing status and not edit it." />
                          );
                          return;
                        }
                      }
                      taskInvoicingDetails.current = {
                        businessPartnerName: row.original.businessPartnerName,
                        taskId: row.original.taskId,
                        invoiceStatus: row.original.invoiceStatus,
                        pcInvoiceDate: row.original.pcInvoiceDate,
                        reconInvoiceDate: row.original.reconInvoiceDate,
                        isReconInvoiced: row.original.isReconInvoiced,
                        isPcInvoiced: row.original.isPcInvoiced,
                        taskPhase: currentTaskPhase,
                      };
                      setShowInvoicingTaskModal(true);
                      closeMenu();
                    }}
                  >
                    <ListItemIcon>
                      <ReceiptIcon />
                    </ListItemIcon>
                    Invoice Task
                  </MenuItem>
                ),
            ]}
            initialStateColumnVisibility={{
              createdAt: false,
              branchCode: false,
              companyName: false,
              partnerId: false,
              vendorCode: false,
              location: false,
              relationship: false,
              metaStatus: false,
              lastActiveUser: false,
              checker: false,
              emailInitiationDate: false,
              responseStatus: false,
              closingBalanceDate: false,
              additionalRemark: false,
              issueRaised: false,
              personName: false,
              personEmail: false,
              personDesignation: false,
              personPhoneNumber: false,
              partnerRepliedOnMail: false,
              followUpMailCount: false,
              followUpConversationCount: false,
              invoiceStatus: false,
              pcInvoiceDate: false,
              reconInvoiceDate: false,
            }}
          />
        </Box>

        {openTaskTrackerFilter && (
          <TaskTrackerFilter
            open={openTaskTrackerFilter}
            setOpen={setOpenTaskTrackerFilter}
            listAllTasks={listAllTasks}
            partnerOptions={partnerOptions}
            vendorCodeOptions={vendorCodeOptions}
            taskIdsOptions={taskIdsOptions}
            companyId={companyId.current}
            branchCode={branchCode.current}
          />
        )}
        {showSaveTaskModal && (
          <SaveTaskModal
            open={showSaveTaskModal}
            setOpen={setShowSaveTaskModal}
            taskId={changeStatusTaskId.current}
            changeStatus={updateTaskMetaStatus}
          />
        )}
        {showDiscardTaskModal && (
          <DiscardTaskModal
            open={showDiscardTaskModal}
            setOpen={setShowDiscardTaskModal}
            taskId={changeStatusTaskId.current}
            changeStatus={updateTaskMetaStatus}
          />
        )}
        {showTaskAttachmentsModal && (
          <TaskAttachmentsModal
            open={showTaskAttachmentsModal}
            setOpen={setShowTaskAttachmentsModal}
            businessPartnerName={currentSelectedTaskDetails.current.businessPartnerName}
            ticketId={currentSelectedTaskDetails.current.taskId}
            companyId={companyId.current}
            branchCode={branchCode.current}
            serviceType="taskBoard"
          />
        )}
        {showEditTaskModal && (
          <EditTaskModal
            type="taskBoard"
            open={showEditTaskModal}
            setOpen={setShowEditTaskModal}
            allUsers={allUsers}
            row={selectedRowForEdit}
            fetchRowsData={listAllTasks}
            isEditing={isEditing}
            setIsEditing={setIsEditing}
            companyId={companyId.current}
            branchCode={branchCode.current}
            allTaskLabels={allTaskLabels}
            getAllTaskLabels={getAllTaskLabels}
          />
        )}
        {showTasksHistoryModal && (
          <TasksHistoryModal
            showTasksHistoryModal={showTasksHistoryModal}
            setShowTasksHistoryModal={setShowTasksHistoryModal}
            businessPartnerName={currentSelectedTaskDetails.current.businessPartnerName}
            taskId={currentSelectedTaskDetails.current.taskId}
          />
        )}
        {showBulkTasksModal && (
          <BulkTasksModal
            type="taskBoard"
            showBulkTasksModal={showBulkTasksModal}
            setShowBulkTasksModal={setShowBulkTasksModal}
            selectedTasks={selectedRow}
            allUsers={allUsers}
            allStatuses={allStatuses.map((status) => {
              return {
                id: status.id,
                name: status.status,
              };
            })}
            refetchData={listAllTasks}
            setClearRows={setClearRows}
            allTaskLabels={allTaskLabels}
            getAllTaskLabels={getAllTaskLabels}
            companyId={companyId.current}
            branchCode={branchCode.current}
          />
        )}
        {showInvoicingTaskModal && (
          <InvoicingSingleTaskModal
            open={showInvoicingTaskModal}
            setOpen={setShowInvoicingTaskModal}
            taskInvoicingDetails={taskInvoicingDetails.current}
            listAllTasks={listAllTasks}
            companyId={companyId.current}
            branchCode={branchCode.current}
          />
        )}
      </TaskTrackerContext.Provider>
    </LoggedInSkeleton>
  );
}
