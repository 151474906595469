/* eslint-disable react-hooks/rules-of-hooks */
import { Preview } from "@mui/icons-material";
import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormLabel,
  Grid,
  MenuItem,
  TextField,
} from "@mui/material";
import { Buffer } from "buffer";
import React, { useContext, useEffect, useState } from "react";
import { API_ENDPOINTS } from "src/Utils/ApiConstants/ApiUrlConstants";
import { EmailTemplate, PDFTemplate, UseCaseType } from "../../../../entity/recon-entity/ReconInterfaces";
import {
  dynamicContentOptions as dynamicOrig,
  dynamicContentOptionsBeta as dynamicOrigBeta,
  dynamicContentOptionsForMsme as dynamicMsme,
} from "../../../../Utils/PartnerCommunication";
import useFetch from "../../../Common/useFetch";
import { DEFAULT_BRANCH_CODE, DEFAULT_COMPANY_ID, TallyOrSapCompanyId } from "../CommonLegacy/CommonComponents";
import { PartnerCommsContext } from "../Context/PartnerCommunicationContext";
import Editor from "../Editor/Editor";
import "../Styles/SendEmailTemplate.scss";

interface CreateEmailTemplateProps {
  companyId: TallyOrSapCompanyId;
  branchCode: string;
  listUserEmailTemplate: (param: string) => void;
  templateType: "LR" | "CB" | "MSME" | "BCB" | "LRB";
  msmeConfirmation?: boolean;
  beta?: boolean;
}

const CreateEmailTemplate = (props: CreateEmailTemplateProps) => {
  const [dynamicContent, setDynamicContent] = useState<string>("");
  const [emailSubject, setEmailSubject] = useState<string>("");
  // const [cursorPosition, setCursorPosition] = useState<number>(0);
  const [selectedTemplateName, setSelectedTemplateName] = useState<string>("");
  const [createTemplateName, setCreateTemplateName] = useState<any>("");
  const [errCreateTemplateName, setErrCreateTemplateName] = useState<boolean>(false);
  const [errEmailSubject, setErrEmailSubject] = useState<boolean>(false);
  const [selectedEmailTemplateContentForCreateEmailTemplate, setSelectedEmailTemplateContentForCreateEmailTemplate] =
    useState<EmailTemplate>(null);
  const [copyAlert, setCopyAlert] = useState<boolean>(false);
  const [existingTemplateId, setExistingTemplateId] = useState<number>(null);

  const dynamicContentOpts = [...(props.beta ? dynamicOrigBeta : props.msmeConfirmation ? dynamicMsme : dynamicOrig)];

  // Email States START
  const {
    openCreateEmailTemplate,
    setOpenCreateEmailTemplate,
    setOpenSendEmailTemplate,
    openSendEmailOnCancel,
    emailTemplates,
    setEmailTemplateContent,
    createPdfTemplate,
    setCreatePdfTemplate,
    emailTemplateContent,
    pdfTemplates,
    dynamicContentOptions,
    setDynamicContentOptions,
  } = useContext(PartnerCommsContext);
  // Email States END

  useEffect(() => {
    ListDynamicContent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClose = () => {
    setOpenCreateEmailTemplate(false);
    setSelectedTemplateName("");
    setCreateTemplateName("");
    setErrCreateTemplateName(false);
    setCreatePdfTemplate(false);
  };

  const clearAllState = () => {
    setDynamicContent("");
    setEmailSubject("");
    setCreateTemplateName("");
    setSelectedTemplateName("");
    setErrCreateTemplateName(false);
    setExistingTemplateId(null);
    setSelectedEmailTemplateContentForCreateEmailTemplate(null);
  };

  const saveCreateEmailTemplate = () => {
    if (createTemplateName.trim() === "") {
      setErrCreateTemplateName(true);
    }
    if (emailSubject.trim() === "") {
      setErrEmailSubject(true);
    }

    if (createTemplateName.trim() !== "" && emailSubject.trim() !== "") {
      const bodyFormData = new FormData();
      bodyFormData.append("templateName", createTemplateName);
      bodyFormData.append("emailBody", dynamicContent);
      bodyFormData.append("emailSubject", emailSubject);
      useFetch(API_ENDPOINTS.ADD_USER_EMAIL_TEMPLATE.url, "POST", {
        failureMessage: API_ENDPOINTS.ADD_USER_EMAIL_TEMPLATE.failureMessage,
        showSuccessToast: true,
        data: {
          templateName: createTemplateName,
          emailBody: dynamicContent,
          emailSubject,
          templateType: props.templateType,
        },
        thenCallBack: () => {
          handleClose();
          clearAllState();
          props.listUserEmailTemplate(createTemplateName);
        },
      });
    }
  };

  type InsertApiPayload = Omit<PDFTemplate, "templateId">;

  const savePDFTemplate = () => {
    if (createTemplateName.trim() === "") {
      setErrCreateTemplateName(true);
      return;
    }

    useFetch(API_ENDPOINTS.ADD_PDF_TEMPLATES.url, "POST", {
      failureMessage: API_ENDPOINTS.ADD_PDF_TEMPLATES.failureMessage,
      showSuccessToast: true,
      data: {
        templateName: createTemplateName,
        templateBody: dynamicContent,
        templateType: props.beta // using existing CB and LR PDF templates for BCB and LRB
          ? props.templateType === "BCB"
            ? UseCaseType.balanceConfirmation
            : UseCaseType.ledgerRequest
          : props.templateType,
        existingTemplateId: existingTemplateId,
      } as InsertApiPayload,
      thenCallBack: (_res) => {
        handleClose();
        props.listUserEmailTemplate(emailTemplateContent.templateName);
        clearAllState();
      },
    });
  };

  const getPDFTemplateBody = (_templateId: number) => {
    return new Promise<{ templateBody: string }>((resolve, reject) => {
      useFetch<{ templateBody: string }>(API_ENDPOINTS.GET_PDF_TEMPLATE_BODY.url, "POST", {
        failureMessage: API_ENDPOINTS.GET_PDF_TEMPLATE_BODY.failureMessage,
        showSuccessToast: true,
        data: {
          templateId: _templateId,
          templateType: props.beta // using existing CB and LR PDF templates for BCB and LRB
            ? props.templateType === "BCB"
              ? UseCaseType.balanceConfirmation
              : UseCaseType.ledgerRequest
            : props.templateType,
        },
        thenCallBack: (_res) => {
          if (_res.data.templateBody) {
            resolve({ templateBody: _res.data.templateBody });
          }
        },
        catchCallBack: reject,
        errorCallback: reject,
      });
    });
  };

  const ListDynamicContent = () => {
    useFetch<{ dynamicContents: string[] }>(API_ENDPOINTS.LIST_DYNAMIC_CONTENT_PARTNER_COMMUNICATION.url, "GET", {
      failureMessage: API_ENDPOINTS.LIST_DYNAMIC_CONTENT_PARTNER_COMMUNICATION.failureMessage,
      config: {
        params: {
          companyId: props.companyId || DEFAULT_COMPANY_ID,
          branchCode: props.branchCode || DEFAULT_BRANCH_CODE,
        },
      },
      thenCallBack: (res) => {
        dynamicContentOpts.push(...res.data.dynamicContents.map((_content) => `{${_content}}`));

        setDynamicContentOptions(dynamicContentOpts);
      },
    });
  };

  const openPdfInNewTab = (pdfBuffer: Uint8Array | ArrayBuffer) => {
    /* your PDF buffer as Uint8Array or ArrayBuffer */
    // Create a Blob from the PDF buffer
    const blob = new Blob([pdfBuffer], { type: "application/pdf" });

    const dataUrl = URL.createObjectURL(blob);

    // Open a new tab and set the URL to the data URL
    const newTab = window.open();
    newTab.location.href = dataUrl;
  };

  const previewPdfTemplate = () => {
    useFetch<{ pdf: string }>(API_ENDPOINTS.PREVIEW_PDF_TEMPLATE.url, "POST", {
      failureMessage: API_ENDPOINTS.PREVIEW_PDF_TEMPLATE.failureMessage,
      showSuccessToast: true,
      data: {
        templateBody: dynamicContent,
      },
      thenCallBack: (_res) => {
        openPdfInNewTab(Buffer.from(_res.data.pdf, "base64") as any);
      },
    });
  };

  return (
    <>
      <Dialog
        fullWidth={true}
        maxWidth="md"
        open={openCreateEmailTemplate}
        // onClose={handleClose}
        aria-labelledby="SendEmailTemplate"
        // className="editBpContactMaster_popup"
      >
        <DialogContent>
          <div className="email_text">
            <Box display={"flex"} gap={2} alignItems="flex-start" my={2}>
              <FormLabel className="w_100" sx={{ whiteSpace: "nowrap", mt: 1 }}>
                {createPdfTemplate ? "PDF Template Name:" : "Template Name:"}
              </FormLabel>
              <TextField
                fullWidth={true}
                id="outlined-basic"
                size="small"
                variant="outlined"
                required={true}
                placeholder={createPdfTemplate ? "Enter PDF Template Name" : "Enter Template Name"}
                value={createTemplateName}
                error={errCreateTemplateName}
                helperText={errCreateTemplateName ? "Please enter template name" : ""}
                onChange={(e) => {
                  setCreateTemplateName(e.target.value);
                  if (e.target.value.trim() !== "") {
                    setErrCreateTemplateName(false);
                  } else {
                    setErrCreateTemplateName(true);
                  }
                }}
              />
            </Box>
          </div>
          <div className="email_text" hidden={createPdfTemplate}>
            <Box display={"flex"} gap={2} alignItems="center" my={2}>
              <FormLabel className="w_100" sx={{ whiteSpace: "nowrap" }}>
                Subject:
              </FormLabel>
              <TextField
                fullWidth={true}
                id="outlined-basic"
                size="small"
                variant="outlined"
                placeholder="Enter Subject"
                value={emailSubject}
                error={errEmailSubject}
                helperText={errEmailSubject ? "please enter subject" : ""}
                onChange={(e) => {
                  setEmailSubject(e.target.value);
                  if (e.target.value.trim() !== "") {
                    setErrEmailSubject(false);
                  } else {
                    setErrEmailSubject(true);
                  }
                }}
              />
            </Box>
          </div>

          <div className="pt_10">
            <Editor
              // dynamicContent={dynamicContent}
              setDynamicContent={setDynamicContent}
              selectedTemplateName={selectedTemplateName}
              selectedEmailTemplateContent={selectedEmailTemplateContentForCreateEmailTemplate}
            />
          </div>
          <div className="mt_30">
            {createPdfTemplate && (
              <Button
                variant="outlined"
                startIcon={<Preview />}
                onClick={previewPdfTemplate}
                color="success"
                sx={{ my: 1, color: "#27b27c", borderColor: "#27b27c" }}
              >
                Preview PDF Template
              </Button>
            )}
            <div style={{ height: "50px" }} className="mb_15">
              {copyAlert && (
                <Alert severity="success" style={{ width: "200px", height: "48px" }}>
                  Copied!
                </Alert>
              )}
            </div>
            <Grid item={true} xs={12} sm={12} className="d_flex">
              <div className="email_width">
                <Grid item={true} xs={4} sm={4}>
                  <Autocomplete
                    size="small"
                    onChange={(_, value) => {
                      if (value !== null) {
                        navigator.clipboard.writeText(value);
                        // insertDynamiccontent(value);
                        setCopyAlert(true);
                        setTimeout(() => {
                          setCopyAlert(false);
                        }, 3000);
                      }
                    }}
                    disablePortal={true}
                    id="combo-box-demo"
                    options={dynamicContentOptions}
                    //   value={dynamicContent}
                    //   sx={{ width: 300 }}
                    renderInput={(params) => (
                      <TextField {...params} label="Dynamic Content" placeholder="Dynamic Content" />
                    )}
                  />
                </Grid>
              </div>
              <div className="email_width">
                <Grid item={true} xs={4} sm={4}>
                  {!createPdfTemplate ? (
                    <TextField
                      select={true}
                      size="small"
                      label="Choose Template"
                      name="ChooseTemplate"
                      variant="outlined"
                      fullWidth={true}
                      onChange={(e) => {
                        setSelectedTemplateName(e.target.value);
                        const filteredSelectedTemplate = emailTemplates.filter((item) => {
                          return item.templateName === e.target.value;
                        });
                        setCreateTemplateName(filteredSelectedTemplate[0].templateName);
                        setEmailSubject(filteredSelectedTemplate[0].emailSubject);
                        setDynamicContent(filteredSelectedTemplate[0].emailBody);
                        setSelectedEmailTemplateContentForCreateEmailTemplate(filteredSelectedTemplate[0]);

                        setEmailTemplateContent(filteredSelectedTemplate[0]);
                      }}
                      value={selectedTemplateName}
                    >
                      {emailTemplates.length > 0 &&
                        emailTemplates.map((item: EmailTemplate, itemInd: number) => (
                          <MenuItem key={itemInd} value={item.templateName}>
                            {item.templateName}
                          </MenuItem>
                        ))}
                    </TextField>
                  ) : (
                    <TextField
                      select={true}
                      size="small"
                      label="Choose Template"
                      name="ChoosePdfTemplate"
                      variant="outlined"
                      fullWidth={true}
                      onChange={async (e) => {
                        setSelectedTemplateName(e.target.value);
                        const filteredSelectedTemplate = pdfTemplates.filter((item) => {
                          return item.templateName === e.target.value;
                        });

                        const { templateId, templateName } = filteredSelectedTemplate[0];

                        setExistingTemplateId(templateId);

                        const templateBody = (await getPDFTemplateBody(templateId))?.templateBody || "";

                        const template = {
                          actorId: 0,
                          createdAt: "",
                          default: false,
                          emailBody: templateBody,
                          emailSubject: "",
                          templateId,
                          templateName,
                          templateType: props.templateType,
                          updatedAt: "",
                        };

                        setSelectedEmailTemplateContentForCreateEmailTemplate(template);
                      }}
                      value={selectedTemplateName}
                    >
                      {pdfTemplates.length > 0 &&
                        pdfTemplates.map((item, itemInd: number) => (
                          <MenuItem key={itemInd} value={item.templateName}>
                            {item.templateName}
                          </MenuItem>
                        ))}
                    </TextField>
                  )}
                </Grid>
              </div>
            </Grid>
          </div>
        </DialogContent>
        <DialogActions className="dialog_footer">
          <div className="email_dialog_footer_btn">
            <Button
              className="theme_outline_btn"
              onClick={() => {
                if (openSendEmailOnCancel) setOpenSendEmailTemplate(true);
                handleClose();
                clearAllState();
              }}
            >
              Cancel
            </Button>
            <Button
              className="theme_btn"
              onClick={() => {
                if (!createPdfTemplate) saveCreateEmailTemplate();
                else savePDFTemplate();
              }}
            >
              Save
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CreateEmailTemplate;
