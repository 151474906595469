import { uiLoggerNamesTaskBoard } from "src/Utils/Recon/UiLogger/Constants";

export interface TaskDetails {
  taskId: number;
  createdAt: Date;
  companyName?: string;
  branchCode?: string;
  partnerId: number;
  businessPartnerName: string;
  vendorCode: string;
  location: string;
  relationship: string;
  category: string[];
  taskLabel: string;
  status: string;
  metaStatus: string;
  lastActiveUser: string;
  maker: string;
  checker: string;
  approver: string;
  makerId: number;
  checkerId: number;
  approverId: number;
  dueDate: Date;
  priority: string;
  remarks: string;
  reconPeriodFrom: Date;
  reconPeriodTo: Date;
  ownCB: number;
  partnerCB: number;
  cbDifference: number;
  ownOB: number;
  partnerOB: number;
  obDifference: number;
  emailInitiationDate: Date;
  emailStatus: string;
  balanceConfirmationStatusWithReminderCount: string;
  responseStatus: string;
  closingBalanceDate: Date;
  partnerResponseDate: Date;
  ownClosingBalance: number;
  businessPartnerClosingBalance: number;
  closingBalanceDifference: number;
  additionalRemark: string;
  issueRaised: string;
  personName: string;
  personEmail: string;
  personDesignation: string;
  personPhoneNumber: string;
  partnerRepliedOnMail: Date;
  followUpMailCount: any;
  followUpConversationCount: number;
  currency: string;
  service: string;
  invoiceStatus: string;
  reconInvoiceDate: Date;
  pcInvoiceDate: Date;
  isPcInvoiced: boolean;
  isReconInvoiced: boolean;
  ticketCreatedInPC: boolean;
}
export enum TASK_BOARD_TABLE_VIEWS {
  defaultView = "Default view",
  reconTasks = "Recon tasks",
  assignedToMe = "Assigned to me",
  statusSummary = "Status summary",
}
export enum TASK_DETAILS_COLUMNS {
  taskId = "taskId",
  createdAt = "createdAt",
  companyName = "companyName",
  branchCode = "branchCode",
  partnerId = "partnerId",
  businessPartnerName = "businessPartnerName",
  vendorCode = "vendorCode",
  location = "location",
  relationship = "relationship",
  category = "category",
  taskLabel = "taskLabel",
  status = "status",
  metaStatus = "metaStatus",
  lastActiveUser = "lastActiveUser",
  maker = "maker",
  checker = "checker",
  approver = "approver",
  makerId = "makerId",
  checkerId = "checkerId",
  approverId = "approverId",
  dueDate = "dueDate",
  priority = "priority",
  remarks = "remarks",
  reconPeriodFrom = "reconPeriodFrom",
  reconPeriodTo = "reconPeriodTo",
  ownCB = "ownCB",
  partnerCB = "partnerCB",
  cbDifference = "cbDifference",
  ownOB = "ownOB",
  partnerOB = "partnerOB",
  obDifference = "obDifference",
  emailInitiationDate = "emailInitiationDate",
  emailStatus = "emailStatus",
  balanceConfirmationStatusWithReminderCount = "balanceConfirmationStatusWithReminderCount",
  responseStatus = "responseStatus",
  closingBalanceDate = "closingBalanceDate",
  partnerResponseDate = "partnerResponseDate",
  ownClosingBalance = "ownClosingBalance",
  businessPartnerClosingBalance = "businessPartnerClosingBalance",
  closingBalanceDifference = "closingBalanceDifference",
  additionalRemark = "additionalRemark",
  issueRaised = "issueRaised",
  personName = "personName",
  personEmail = "personEmail",
  personDesignation = "personDesignation",
  personPhoneNumber = "personPhoneNumber",
  partnerRepliedOnMail = "partnerRepliedOnMail",
  followUpMailCount = "followUpMailCount",
  followUpConversationCount = "followUpConversationCount",
  currency = "currency",
  service = "service",
  invoiceStatus = "invoiceStatus",
  reconInvoiceDate = "reconInvoiceDate",
  pcInvoiceDate = "pcInvoiceDate",
}
export const TASK_DETAILS_COLUMNS_UI_MAPPING = {
  [TASK_DETAILS_COLUMNS.taskId]: "Task ID",
  [TASK_DETAILS_COLUMNS.createdAt]: "Created at",
  [TASK_DETAILS_COLUMNS.companyName]: "Company Name",
  [TASK_DETAILS_COLUMNS.branchCode]: "Branch Code",
  [TASK_DETAILS_COLUMNS.partnerId]: "Partner ID",
  [TASK_DETAILS_COLUMNS.businessPartnerName]: "Business Partner Name",
  [TASK_DETAILS_COLUMNS.vendorCode]: "Vendor Code",
  [TASK_DETAILS_COLUMNS.location]: "Location",
  [TASK_DETAILS_COLUMNS.relationship]: "Relationship",
  [TASK_DETAILS_COLUMNS.category]: "Category",
  [TASK_DETAILS_COLUMNS.taskLabel]: "Label",
  [TASK_DETAILS_COLUMNS.status]: "Task Status",
  [TASK_DETAILS_COLUMNS.metaStatus]: "Bucket",
  [TASK_DETAILS_COLUMNS.lastActiveUser]: "Last Active User",
  [TASK_DETAILS_COLUMNS.maker]: "Maker",
  [TASK_DETAILS_COLUMNS.checker]: "Checker",
  [TASK_DETAILS_COLUMNS.approver]: "Approver",
  [TASK_DETAILS_COLUMNS.dueDate]: "Due Date",
  [TASK_DETAILS_COLUMNS.priority]: "Priority",
  [TASK_DETAILS_COLUMNS.remarks]: "Remarks",
  [TASK_DETAILS_COLUMNS.reconPeriodFrom]: "Recon period - (From)",
  [TASK_DETAILS_COLUMNS.reconPeriodTo]: "Recon period - (To)",
  [TASK_DETAILS_COLUMNS.ownCB]: "Own CB",
  [TASK_DETAILS_COLUMNS.partnerCB]: "Partner CB",
  [TASK_DETAILS_COLUMNS.cbDifference]: "CB Difference",
  [TASK_DETAILS_COLUMNS.ownOB]: "Own OB",
  [TASK_DETAILS_COLUMNS.partnerOB]: "Partner OB",
  [TASK_DETAILS_COLUMNS.obDifference]: "OB Difference",
  [TASK_DETAILS_COLUMNS.emailInitiationDate]: "Email Initiation Date",
  [TASK_DETAILS_COLUMNS.emailStatus]: "Email Status",
  [TASK_DETAILS_COLUMNS.responseStatus]: "Response Status",
  [TASK_DETAILS_COLUMNS.closingBalanceDate]: "Closing Balance Date",
  [TASK_DETAILS_COLUMNS.partnerResponseDate]: "Partner Response Date",
  [TASK_DETAILS_COLUMNS.ownClosingBalance]: "Own CB (PC)",
  [TASK_DETAILS_COLUMNS.businessPartnerClosingBalance]: "Partner CB (PC)",
  [TASK_DETAILS_COLUMNS.closingBalanceDifference]: "CB Difference (PC)",
  [TASK_DETAILS_COLUMNS.additionalRemark]: "Additional Remark",
  [TASK_DETAILS_COLUMNS.issueRaised]: "Issue Raised",
  [TASK_DETAILS_COLUMNS.personName]: "Person Name",
  [TASK_DETAILS_COLUMNS.personEmail]: "Person Email",
  [TASK_DETAILS_COLUMNS.personDesignation]: "Person Designation",
  [TASK_DETAILS_COLUMNS.personPhoneNumber]: "Person Phone Number",
  [TASK_DETAILS_COLUMNS.partnerRepliedOnMail]: "Partner Replied on Mail",
  [TASK_DETAILS_COLUMNS.followUpMailCount]: "Follow Up - Mail (count)",
  [TASK_DETAILS_COLUMNS.followUpConversationCount]: "Follow Up - Conversation (count)",
  [TASK_DETAILS_COLUMNS.invoiceStatus]: "Invoicing Status",
  [TASK_DETAILS_COLUMNS.pcInvoiceDate]: "PC Invoice Date",
  [TASK_DETAILS_COLUMNS.reconInvoiceDate]: "Recon Invoice Date",
};
export interface TaskTrackerFilters {
  businessPartnerName?: string[];
  vendorCode?: string[];
  taskId?: string[];
}

export const dateRangeColumns = [
  TASK_DETAILS_COLUMNS.createdAt,
  TASK_DETAILS_COLUMNS.dueDate,
  TASK_DETAILS_COLUMNS.reconPeriodFrom,
  TASK_DETAILS_COLUMNS.reconPeriodTo,
  TASK_DETAILS_COLUMNS.emailInitiationDate,
  TASK_DETAILS_COLUMNS.closingBalanceDate,
  TASK_DETAILS_COLUMNS.partnerResponseDate,
  TASK_DETAILS_COLUMNS.partnerRepliedOnMail,
  TASK_DETAILS_COLUMNS.pcInvoiceDate,
  TASK_DETAILS_COLUMNS.reconInvoiceDate,
];

export const numberRangeColumns = [
  TASK_DETAILS_COLUMNS.ownCB,
  TASK_DETAILS_COLUMNS.partnerCB,
  TASK_DETAILS_COLUMNS.cbDifference,
  TASK_DETAILS_COLUMNS.ownOB,
  TASK_DETAILS_COLUMNS.partnerOB,
  TASK_DETAILS_COLUMNS.obDifference,
  TASK_DETAILS_COLUMNS.ownClosingBalance,
  TASK_DETAILS_COLUMNS.businessPartnerClosingBalance,
  TASK_DETAILS_COLUMNS.closingBalanceDifference,
  TASK_DETAILS_COLUMNS.followUpMailCount,
  TASK_DETAILS_COLUMNS.followUpConversationCount,
];

export const formatCurrencyCommaSeparated = (number: number) => {
  if (isNaN(number)) {
    return number;
  }
  // Convert to a fixed 2-decimal string and split it
  const [integerPart] = number.toFixed(2).split(".");
  // Format the integer part in Indian numbering system
  const lastThreeDigits = integerPart.slice(-3);
  const otherDigits = integerPart.slice(0, -3);
  const formattedIntegerPart =
    otherDigits.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + (otherDigits ? "," : "") + lastThreeDigits;

  return formattedIntegerPart;
};

export const mappingForTaskBoardStatuses = {
  "Both Side Ledger Pending": uiLoggerNamesTaskBoard.UI_WF_TB_RECONSTATUS_BOTHSIDELEDGERPENDING_CLICK,
  "Partner Ledger Pending": uiLoggerNamesTaskBoard.UI_WF_TB_RECONSTATUS_PARTNERLEDGERPENDING_CLICK,
  "Own Ledger Pending": uiLoggerNamesTaskBoard.UI_WF_TB_RECONSTATUS_OWNLEDGERPENDING_CLICK,
  "Issue in Ledger": uiLoggerNamesTaskBoard.UI_WF_TB_RECONSTATUS_ISSUEINLEDGER_CLICK,
  "Yet to Start": uiLoggerNamesTaskBoard.UI_WF_TB_RECONSTATUS_YETTOSTART_CLICK,
  "Recon in Progress": uiLoggerNamesTaskBoard.UI_WF_TB_RECONSTATUS_RECONINPROGRESS_CLICK,
  "Maker Passed": uiLoggerNamesTaskBoard.UI_WF_TB_RECONSTATUS_MAKERPASSED_CLICK,
  "Checker Passed": uiLoggerNamesTaskBoard.UI_WF_TB_RECONSTATUS_CHECKERPASSED_CLICK,
  "Checker Rejected": uiLoggerNamesTaskBoard.UI_WF_TB_RECONSTATUS_CHECKERREJECTED_CLICK,
  "Sent to Approver": uiLoggerNamesTaskBoard.UI_WF_TB_RECONSTATUS_SENTTOAPPROVER_CLICK,
  "Approver Passed": uiLoggerNamesTaskBoard.UI_WF_TB_RECONSTATUS_APPROVERPASSED_CLICK,
  "Approver Rejected": uiLoggerNamesTaskBoard.UI_WF_TB_RECONSTATUS_APPROVERREJECTED_CLICK,
  "Technical Issue": uiLoggerNamesTaskBoard.UI_WF_TB_RECONSTATUS_TECHNICALISSUE_CLICK,
  Dropped: uiLoggerNamesTaskBoard.UI_WF_TB_RECONSTATUS_DROPPED_CLICK,
  Saved: uiLoggerNamesTaskBoard.UI_WF_TB_RECONSTATUS_SAVETICKET_CLICK,
  Discarded: uiLoggerNamesTaskBoard.UI_WF_TB_RECONSTATUS_DISCARDTICKET_CLICK,
  "Recon Sent to Partner": uiLoggerNamesTaskBoard.UI_WF_TB_RECONSTATUS_RECONSENTTOPARTNER_CLICK,
  "Work in Progress- Action items": uiLoggerNamesTaskBoard.UI_WF_TB_RECONSTATUS_WORKINPROGRESSACTIONITEMS_CLICK,
  "Sign-off Pending": uiLoggerNamesTaskBoard.UI_WF_TB_RECONSTATUS_SIGNOFFPENDINGWITHPARTNER_CLICK,
  "Signed-off with partner": uiLoggerNamesTaskBoard.UI_WF_TB_RECONSTATUS_SIGNEDOFFWITHPARTNER_CLICK,
  "On-Hold": uiLoggerNamesTaskBoard.UI_WF_TB_RECONSTATUS_ONHOLD_CLICK,
  "Recon Finished by user": uiLoggerNamesTaskBoard.UI_WF_TB_RECONSTATUS_RECONFINISHEDBYUSER_CLICK,
  "Balance Confirmed": uiLoggerNamesTaskBoard.UI_WF_TB_RECONSTATUS_BALANCECONFIRMED_CLICK,
};

export const mappingForTaskBoardViews = {
  "Default view": uiLoggerNamesTaskBoard.UI_WF_TB_DEFAULTVIEW_CLICK,
  "Recon tasks": uiLoggerNamesTaskBoard.UI_WF_TB_RECONTASKS_CLICK,
  "Assigned to me": uiLoggerNamesTaskBoard.UI_WF_TB_MY_TASKS_CLICK,
  "Status summary": uiLoggerNamesTaskBoard.UI_WF_TB_STATUSSUMMARY_CLICK,
};

export enum TASK_PHASES {
  recon = "recon",
  partnerCommunication = "partnerCommunication",
  pcRecon = "pcRecon",
}
