import { Button, ButtonProps, Checkbox, CircularProgress, Radio, StepIcon, styled } from "@mui/material";
import { TextField } from "@mui/material";
import React, { useState } from "react";

export const NdButton = styled(Button)(() => ({
  background: "#531c4c",
  borderRadius: "4px !important",

  "&:hover, &.Mui-focusVisible": {
    zIndex: 1,
    background: "#45173f",
  },
}));

export const NdButtonOutlined = styled(Button)(() => ({
  background: "#fff",
  borderRadius: "4px !important",
  color: "#531c4c",
  border: "1px solid #531c4c !important",

  "&:hover, &.Mui-focusVisible": {
    zIndex: 1,
    background: "#f5f5f5",
  },
}));

export const NdSecondaryButton = styled(Button)(() => ({
  background: "#27b27c",
  borderRadius: "4px !important",
  color: "#fff",

  "&:hover, &.Mui-focusVisible": {
    zIndex: 1,
    background: "#25a775",
  },
}));

export const NdTextField = styled(TextField)(() => ({
  ".MuiInputBase-root.MuiOutlinedInput-root": {
    borderRadius: 4,
  },
}));

export const NdRadio = styled(Radio)(() => ({
  color: "#45173f",
  "&.Mui-checked": {
    color: "#531c4c",
  },
}));

export const NdCheckbox = styled(Checkbox)(() => ({
  color: "#45173f",
  "&.Mui-checked": {
    color: "#531c4c",
  },
  "&:hover": {
    background: "rgb(83 28 76/ 4%)",
  },
}));

export const NdStepIcon = styled(StepIcon)(() => ({
  "&.Mui-active": {
    color: "#531c4c",
  },
  "&.Mui-completed": {
    color: "#2E7D32",
  },
}));

export type MsmeSegment = "Micro" | "Small" | "Medium";

export enum MsmeSegmentEnum {
  Micro = "Micro",
  Small = "Small",
  Medium = "Medium",
}

export enum MsmeDetailsStatus {
  NotFilled = "NotFilled",
  DetailsFilled = "DetailsFilled",
  FinalPage = "FinalPage",
}

export interface NotMsmeDetails {
  companyName: string;
  panNumber: string;
  personName: string;
  designation: string;
  email: string;
  phoneNumber?: string;
}

export interface IFilledDetails extends NotMsmeDetails {
  companyName: string;
  panNumber: string;
  personName: string;
  designation: string;
  msmeUdyamNumber: string;
  classificationDate: Date;
  segment: MsmeSegment;
  email: string;
  phoneNumber?: string;
}

export const validatePAN = (panNumber: string): boolean => {
  const panRegex = /^[A-Z]{3}[ABCFGHLJPT][A-Z]{1}[0-9]{4}[A-Z]{1}$/;
  return panRegex.test(panNumber);
};

export const validatePhoneNumber = (phoneNumber: string): boolean => {
  // eslint-disable-next-line no-useless-escape
  const phoneNumberRegex = /^(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[6789]\d{9}$/;
  return phoneNumberRegex.test(phoneNumber);
};

export const validateEmail = (email: string): boolean => {
  const emailRegex = /^[\w._%+-]+@[\w.-]+\.[\w]{2,5}$/i;
  return emailRegex.test(email);
};
interface AsyncButtonProps extends Omit<ButtonProps, "onClick"> {
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => Promise<void>;
  buttonComponent?: typeof Button;
  loadingIcon?: React.ReactNode;
}

export const NdAsyncButton = (props: AsyncButtonProps) => {
  const [loading, setLoading] = useState(false);

  const handleClick = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setLoading(true);
    await props.onClick(e);
    setLoading(false);
  };

  // delete buttonComponent from props to avoid passing it to the button component
  const deleteKEYS = (objectRef: AsyncButtonProps, keys: (keyof AsyncButtonProps)[]) => {
    keys.forEach((key: string) => delete objectRef[key]);
    return objectRef;
  };

  const modifiedProps = deleteKEYS({ ...props }, ["buttonComponent", "loadingIcon"]);

  if (props.buttonComponent) {
    return (
      <props.buttonComponent
        {...modifiedProps}
        startIcon={
          loading ? (
            props.loadingIcon ? (
              props.loadingIcon
            ) : (
              <CircularProgress color="inherit" size="16px" />
            )
          ) : (
            modifiedProps.startIcon
          )
        }
        disabled={modifiedProps.disabled || loading}
        onClick={(e) => handleClick(e)}
      />
    );
  }

  return (
    <NdButton
      {...props}
      startIcon={loading ? <CircularProgress color="inherit" size="16px" /> : props.startIcon}
      disabled={props.disabled || loading}
      onClick={(e) => handleClick(e)}
    />
  );
};
