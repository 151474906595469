import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CloseIcon from "@mui/icons-material/Close";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import ReceiptIcon from "@mui/icons-material/Receipt";
import SaveIcon from "@mui/icons-material/Save";
import UpdateIcon from "@mui/icons-material/Update";
import {
  Box,
  Dialog,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import React, { useContext, useRef, useState } from "react";
import useFetch from "src/Components/Common/useFetch";
import { userContext } from "src/Components/Contexts/userContext";
import { UserRole, UserTypes } from "src/entity/models/FrontendActor";
import { TicketDetails } from "src/entity/recon-entity/ReconInterfaces";
import { API_ENDPOINTS } from "src/Utils/ApiConstants/ApiUrlConstants";
import { uiLoggerNamesTaskBoard } from "src/Utils/Recon/UiLogger/Constants";
import uiLogger from "src/Utils/UiLogger";
import delete_icon_grey from "../../../Graphics/AutomatedRecon/delete_icon_grey.svg";
import delete_icon_red from "../../../Graphics/AutomatedRecon/delete_icon_red.svg";
import { StateDispatch } from "../Recon360";
import InvoiceBulkTasksModal from "../TaskTracker/Invoicing/InvoiceBulkTasksModal";
import SelectBulkInvoiceTypeModal from "../TaskTracker/Invoicing/SelectBulkInvoiceTypeModal";
import AddBulkTasksModal from "./AddBulkTasksModal";
import ClearBulkTasksConfirmationModal from "./ClearBulkTasksConfirmationModal";
import DeselectInvalidTasksModal from "./Comman/DeselectInvalidTasksModal";
import DiscardBulkTasksConfirmationModal from "./DiscardBulkTasksConfirmationModal";
import SaveBulkTasksConfirmationModal from "./SaveBulkTasksConfirmationModal";
import { NAKAD_SERVICE } from "./TasksHistoryModal";
import UpdateBulkTasksModal from "./UpdateBulkTasksModal";
import { TicketMetaStatuses } from "./ViewTickets";

interface bulkTasksModalProps {
  type: string;
  showBulkTasksModal: boolean;
  setShowBulkTasksModal: StateDispatch<boolean>;
  selectedTasks: TicketDetails[] | any;
  allUsers: { name: string; id: number }[];
  allStatuses: { name: string; id: number }[];
  refetchData: any;
  setClearRows: StateDispatch<boolean>;
  allTaskLabels: any;
  getAllTaskLabels: () => void;
  companyId: string;
  branchCode: string;
}
const deselectTasksModalTitleType = ["Save", "Discard", "Clear", "Update"];

export default function BulkTasksModal({
  type,
  showBulkTasksModal,
  setShowBulkTasksModal,
  selectedTasks,
  allUsers,
  allStatuses,
  refetchData,
  setClearRows,
  allTaskLabels,
  getAllTaskLabels,
  companyId,
  branchCode,
}: bulkTasksModalProps) {
  const { actor } = useContext(userContext);

  const [showAddBulkTasksModal, setShowAddBulkTasksModal] = useState(false);
  const [showDeselectTasksModal, setShowDeselectTasksModal] = useState(false);
  const [showUpdateBulkTasksModal, setShowUpdateBulkTasksModal] = useState(false);
  const [showInvoiceBulkTasksModal, setShowInvoiceBulkTasksModal] = useState<boolean>(false);
  const [invoiceType, setInvoiceType] = useState<string>(null);
  const [showSelectBulkInvoiceTypeModal, setShowSelectBulkInvoiceTypeModal] = useState<boolean>(false);

  const taskActionTypeIndex = useRef(0);

  const [showSaveBulkTasksConfirmationModal, setShowSaveBulkTasksConfirmationModal] = useState(false);
  const [showDiscardBulkTasksConfirmationModal, setShowDiscardBulkTasksConfirmationModal] = useState(false);
  const [showClearBulkTasksConfirmationModal, setShowClearBulkTasksConfirmationModal] = useState(false);

  const [isUpdatingBulkTasks, setIsUpdatingBulkTasks] = useState(false);
  const invalidTasksModalContent = useRef(null);

  const clearedTasksCount = selectedTasks.filter((task) => task.metaStatus === TicketMetaStatuses.Cleared).length;
  const savedTasksCount = selectedTasks.filter((task) => task.metaStatus === TicketMetaStatuses.Saved).length;
  const inProgressTasksCount = selectedTasks.filter((task) => task.metaStatus === TicketMetaStatuses.InProgress).length;
  const inProgressPcTasksCount = selectedTasks.filter(
    (task) => task.metaStatus === TicketMetaStatuses.InProgress && task.service === NAKAD_SERVICE.PartnerCommunication
  ).length;

  const bulkSaveORDiscardInvalidTasksContent = (
    <>
      {savedTasksCount > 0 && (
        <p>
          • {savedTasksCount} out of {selectedTasks.length} tasks are already Saved.
        </p>
      )}
      {clearedTasksCount > 0 && (
        <p>
          • {clearedTasksCount} out of {selectedTasks.length} tasks are already Cleared.
        </p>
      )}
      {inProgressPcTasksCount > 0 && (
        <p>
          • Partner communication is going on for {inProgressPcTasksCount} out of {selectedTasks.length} tasks
        </p>
      )}
      <p> De-select them to perform this action</p>
    </>
  );
  const bulkClearInvalidTasksContent = (
    <>
      {inProgressTasksCount > 0 && (
        <p>
          • {inProgressTasksCount} out of {selectedTasks.length} tasks are In-Progress.
        </p>
      )}
      {clearedTasksCount > 0 && (
        <p>
          • {clearedTasksCount} out of {selectedTasks.length} tasks are already Cleared.
        </p>
      )}
      <p>De-select them to perform this action as you can only clear the saved tasks.</p>
    </>
  );
  const bulkUpdateInvalidTasksContent = (
    <>
      {savedTasksCount > 0 && (
        <p>
          • {savedTasksCount} out of {selectedTasks.length} tasks are Saved.
        </p>
      )}
      {clearedTasksCount > 0 && (
        <p>
          • {clearedTasksCount} out of {selectedTasks.length} tasks are Cleared.
        </p>
      )}
      <p>De-select them to perform this action as you can update details of In-Progress tasks.</p>
    </>
  );
  const selectedTasksIds = selectedTasks.map((task) => task[type === "workflow" ? "id" : "taskId"]);

  const updateMetaStatusForBulkTasks = (metaStatus: string) => {
    setIsUpdatingBulkTasks(true);
    useFetch(API_ENDPOINTS.UPDATE_TASK_STATUS_IN_BULK.url, "POST", {
      failureMessage: API_ENDPOINTS.UPDATE_TASK_STATUS_IN_BULK.failureMessage,
      showSuccessToast: true,
      data: {
        ticketIds: selectedTasksIds,
        status: metaStatus,
      },
      thenCallBack: () => {
        setIsUpdatingBulkTasks(false);
        setShowBulkTasksModal(false);
        setShowSaveBulkTasksConfirmationModal(false);
        setShowDiscardBulkTasksConfirmationModal(false);
        setShowClearBulkTasksConfirmationModal(false);
        setClearRows(true);
        refetchData();
        getAllTaskLabels();
      },
      catchCallBack: () => {
        setShowBulkTasksModal(false);
        setIsUpdatingBulkTasks(false);
        setShowSaveBulkTasksConfirmationModal(false);
        setShowDiscardBulkTasksConfirmationModal(false);
        setShowClearBulkTasksConfirmationModal(false);
        getAllTaskLabels();
      },
    });
  };
  return (
    <Dialog open={showBulkTasksModal} sx={{ "& .MuiDialog-paper": { borderRadius: "4px", minWidth: "600px" } }}>
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: 2 }}>
        <Typography variant="h6">Bulk Tasks</Typography>
        <IconButton onClick={() => setShowBulkTasksModal(false)}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Divider />
      <List sx={{ padding: "16px" }}>
        {selectedTasks.length > 0 && (
          <Typography variant="subtitle2" sx={{ color: "rgba(0, 0, 0, 0.6)", marginBottom: 1 }}>
            {selectedTasks.length} Tasks selected
          </Typography>
        )}
        <ListItem disablePadding>
          <ListItemButton disabled={selectedTasks.length > 0} onClick={() => setShowAddBulkTasksModal(true)}>
            <ListItemIcon>
              <AddCircleOutlineIcon />
            </ListItemIcon>
            <ListItemText
              primary="Add Tasks"
              secondary={selectedTasks.length > 0 && "De-select tasks to add new tasks in bulk"}
            />
            <ListItemIcon sx={{ position: "relative", left: "30px" }}>
              <KeyboardArrowRightIcon />
            </ListItemIcon>
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton
            disabled={selectedTasks.length === 0}
            onClick={() => {
              if (savedTasksCount > 0 || clearedTasksCount > 0) {
                invalidTasksModalContent.current = bulkUpdateInvalidTasksContent;
                taskActionTypeIndex.current = 3;
                setShowDeselectTasksModal(true);
              } else {
                setShowUpdateBulkTasksModal(true);
              }
            }}
          >
            <ListItemIcon>
              <UpdateIcon />
            </ListItemIcon>
            <ListItemText
              primary="Update Tasks"
              secondary={selectedTasks.length === 0 && "Select tasks to perform this action"}
            />
            <ListItemIcon sx={{ position: "relative", left: "30px" }}>
              <KeyboardArrowRightIcon />
            </ListItemIcon>
          </ListItemButton>
        </ListItem>

        <ListItem disablePadding>
          <ListItemButton
            disabled={selectedTasks.length === 0 || isUpdatingBulkTasks}
            onClick={() => {
              if (savedTasksCount > 0 || clearedTasksCount > 0 || inProgressPcTasksCount > 0) {
                taskActionTypeIndex.current = 0;
                invalidTasksModalContent.current = bulkSaveORDiscardInvalidTasksContent;
                setShowDeselectTasksModal(true);
              } else {
                setShowSaveBulkTasksConfirmationModal(true);
              }
            }}
          >
            <ListItemIcon>
              <SaveIcon color={selectedTasks.length > 0 ? "success" : "disabled"} />
            </ListItemIcon>
            <ListItemText
              primary="Save Tasks"
              primaryTypographyProps={{
                color: selectedTasks.length > 0 && "#1E4620",
              }}
              secondary={selectedTasks.length === 0 && "Select tasks to perform this action"}
            />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton
            disabled={selectedTasks.length === 0 || isUpdatingBulkTasks}
            onClick={() => {
              if (savedTasksCount > 0 || clearedTasksCount > 0 || inProgressPcTasksCount > 0) {
                taskActionTypeIndex.current = 1;
                invalidTasksModalContent.current = bulkSaveORDiscardInvalidTasksContent;
                setShowDeselectTasksModal(true);
              } else {
                setShowDiscardBulkTasksConfirmationModal(true);
              }
            }}
          >
            <ListItemIcon>
              <img src={selectedTasks.length > 0 ? delete_icon_red : delete_icon_grey} alt="delete" />
            </ListItemIcon>
            <ListItemText
              primary="Discard Tasks"
              primaryTypographyProps={{
                color: selectedTasks.length > 0 && "#5F2120",
              }}
              secondary={selectedTasks.length === 0 && "Select tasks to perform this action"}
            />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton
            disabled={selectedTasks.length === 0 || isUpdatingBulkTasks}
            onClick={() => {
              if (inProgressTasksCount > 0 || clearedTasksCount > 0) {
                taskActionTypeIndex.current = 2;
                invalidTasksModalContent.current = bulkClearInvalidTasksContent;
                setShowDeselectTasksModal(true);
              } else {
                setShowClearBulkTasksConfirmationModal(true);
              }
            }}
          >
            <ListItemIcon>
              <CheckCircleOutlineIcon color={selectedTasks.length > 0 ? "primary" : "disabled"} />
            </ListItemIcon>
            <ListItemText
              primary="Clear Tasks"
              primaryTypographyProps={{
                color: selectedTasks.length > 0 && "#014361",
              }}
              secondary={selectedTasks.length === 0 && "Select tasks to perform this action"}
            />
          </ListItemButton>
        </ListItem>
        {type === "taskBoard" &&
          actor.userType === UserTypes.CustomerSuccess &&
          (actor.userRole === UserRole.KAM || actor.userRole === UserRole.CSM) && (
            <ListItem disablePadding>
              <ListItemButton
                disabled={selectedTasks.length === 0 || isUpdatingBulkTasks}
                onClick={() => {
                  uiLogger(
                    uiLoggerNamesTaskBoard.UI_WF_TB_BULKTASKS_INVOICETASKS_CLICK.functionName,
                    companyId,
                    branchCode,
                    {
                      message: uiLoggerNamesTaskBoard.UI_WF_TB_BULKTASKS_INVOICETASKS_CLICK.message,
                    }
                  );
                  setShowSelectBulkInvoiceTypeModal(true);
                }}
              >
                <ListItemIcon>
                  <ReceiptIcon color={selectedTasks.length > 0 ? "secondary" : "disabled"} />
                </ListItemIcon>
                <ListItemText
                  primary="Invoice Tasks"
                  primaryTypographyProps={{
                    color: selectedTasks.length > 0 && "#880E4F",
                  }}
                  secondary={selectedTasks.length === 0 && "Select tasks to perform this action"}
                />
              </ListItemButton>
            </ListItem>
          )}
      </List>
      {showUpdateBulkTasksModal && (
        <UpdateBulkTasksModal
          type={type}
          showUpdateBulkTasksModal={showUpdateBulkTasksModal}
          setShowUpdateBulkTasksModal={setShowUpdateBulkTasksModal}
          selectedTasks={selectedTasks}
          allUsers={allUsers}
          allStatuses={allStatuses}
          fetchTickets={refetchData}
          setShowBulkTasksModal={setShowBulkTasksModal}
          setClearRows={setClearRows}
          allTaskLabels={allTaskLabels}
          getAllTaskLabels={getAllTaskLabels}
          companyId={companyId}
          branchCode={branchCode}
        />
      )}
      <AddBulkTasksModal
        type={type}
        open={showAddBulkTasksModal}
        setOpen={setShowAddBulkTasksModal}
        fetchTickets={refetchData}
        setShowBulkTasksModal={setShowBulkTasksModal}
        companyId={companyId}
        branchCode={branchCode}
      />
      <SaveBulkTasksConfirmationModal
        type={type}
        open={showSaveBulkTasksConfirmationModal}
        setOpen={setShowSaveBulkTasksConfirmationModal}
        tasksCount={inProgressTasksCount}
        updateMetaStatusForBulkTasks={updateMetaStatusForBulkTasks}
        isLoading={isUpdatingBulkTasks}
        companyId={companyId}
        branchCode={branchCode}
      />
      <DiscardBulkTasksConfirmationModal
        type={type}
        open={showDiscardBulkTasksConfirmationModal}
        setOpen={setShowDiscardBulkTasksConfirmationModal}
        tasksCount={inProgressTasksCount}
        updateMetaStatusForBulkTasks={updateMetaStatusForBulkTasks}
        isLoading={isUpdatingBulkTasks}
        companyId={companyId}
        branchCode={branchCode}
      />
      <ClearBulkTasksConfirmationModal
        type={type}
        open={showClearBulkTasksConfirmationModal}
        setOpen={setShowClearBulkTasksConfirmationModal}
        tasksCount={savedTasksCount}
        updateMetaStatusForBulkTasks={updateMetaStatusForBulkTasks}
        isLoading={isUpdatingBulkTasks}
        companyId={companyId}
        branchCode={branchCode}
      />
      <DeselectInvalidTasksModal
        open={showDeselectTasksModal}
        setOpen={setShowDeselectTasksModal}
        modalContent={invalidTasksModalContent.current}
        totalTasksCount={selectedTasks.length}
        deselectTasksModalTitle={deselectTasksModalTitleType[taskActionTypeIndex.current]}
      />
      {showInvoiceBulkTasksModal && (
        <InvoiceBulkTasksModal
          open={showInvoiceBulkTasksModal}
          setOpen={setShowInvoiceBulkTasksModal}
          invoiceType={invoiceType}
          selectedTasks={selectedTasks}
          setShowBulkTasksModal={setShowBulkTasksModal}
          refetchData={refetchData}
          setClearRows={setClearRows}
          companyId={companyId}
          branchCode={branchCode}
        />
      )}
      {showSelectBulkInvoiceTypeModal && (
        <SelectBulkInvoiceTypeModal
          open={showSelectBulkInvoiceTypeModal}
          setOpen={setShowSelectBulkInvoiceTypeModal}
          setShowInvoiceBulkTasksModal={setShowInvoiceBulkTasksModal}
          selectedTasks={selectedTasks}
          setInvoiceType={setInvoiceType}
        />
      )}
    </Dialog>
  );
}
