import { Close } from "@mui/icons-material";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  IconButton,
  Switch,
  SxProps,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Loader from "src/Components/Common/Loader";
import useFetch from "src/Components/Common/useFetch";
import { API_ENDPOINTS } from "src/Utils/ApiConstants/ApiUrlConstants";
import { NdAsyncButton } from "../MsmePartnerPortal/CommonComponents";
import $ from "../MsmePartnerPortal/PartnerPortal.module.scss";
import {
  COMMUNICATION_DETAILS_KEYS,
  OTHER_DETAILS_KEYS,
  RESPONSE_DETAILS_KEYS,
  TASK_PARTNER_DETAILS_KEYS,
} from "./PartnerCommsInterfaces";

export interface ReportDetailsCustomisationRes {
  taskPartnerDetails: CustomisationKey<typeof TASK_PARTNER_DETAILS_KEYS>[];
  communicationDetails: CustomisationKey<typeof COMMUNICATION_DETAILS_KEYS>[];
  responseDetails: CustomisationKey<typeof RESPONSE_DETAILS_KEYS>[];
  otherDetails: CustomisationKey<typeof OTHER_DETAILS_KEYS>[];
}

export type CustomisationKeyType =
  | typeof TASK_PARTNER_DETAILS_KEYS
  | typeof COMMUNICATION_DETAILS_KEYS
  | typeof RESPONSE_DETAILS_KEYS
  | typeof OTHER_DETAILS_KEYS;

export type CustomisationKeyTypeKey<T = CustomisationKeyType> = keyof T;

export type CustomisationKey<T = CustomisationKeyType> = {
  name: T[keyof T];
  isVisible: boolean;
};

interface DownloadReportProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  downloadAction: (customization: ReportDetailsCustomisationRes) => Promise<void>;
}

const DownloadReport = ({ open, setOpen, downloadAction }: DownloadReportProps) => {
  const [loading, setLoading] = useState(false);
  const [taskPartnerDetails, setTaskPartnerDetails] = useState<ReportDetailsCustomisationRes["taskPartnerDetails"]>([]);
  const [communicationDetails, setCommunicationDetails] = useState<
    ReportDetailsCustomisationRes["communicationDetails"]
  >([]);
  const [responseDetails, setResponseDetails] = useState<ReportDetailsCustomisationRes["responseDetails"]>([]);
  const [otherDetails, setOtherDetails] = useState<ReportDetailsCustomisationRes["otherDetails"]>([]);

  const handleClose = () => {
    setOpen(false);
  };

  const GetReportCustomization = () => {
    return useFetch<{ customisationSetting: ReportDetailsCustomisationRes }>(
      API_ENDPOINTS.GET_EXCEL_REPORT_CUSTOMIZATION_SETTING.uri,
      "GET",
      {
        failureMessage: API_ENDPOINTS.GET_EXCEL_REPORT_CUSTOMIZATION_SETTING.failureMessage,
        thenCallBack: (res) => {
          if (res.data?.customisationSetting) {
            setTaskPartnerDetails(res.data?.customisationSetting?.taskPartnerDetails || []);
            setCommunicationDetails(res.data?.customisationSetting?.communicationDetails || []);
            setResponseDetails(res.data?.customisationSetting?.responseDetails || []);
            setOtherDetails(res.data?.customisationSetting?.otherDetails || []);
          }
        },
      }
    );
  };

  const GetData = async () => {
    setLoading(true);
    await GetReportCustomization();
    setLoading(false);
  };

  useEffect(() => {
    if (open) GetData();
  }, [open]);

  return (
    <>
      <Dialog open={open} maxWidth="sm" fullWidth sx={{ ".MuiPaper-root": { maxWidth: 650 } }}>
        <Box className="dialog_header space_between" alignItems="center" pr={1}>
          <DialogTitle component={"div"}>
            <Typography variant="h6">Download Report</Typography>
          </DialogTitle>
          <IconButton
            onClick={() => {
              handleClose();
            }}
          >
            <Close />
          </IconButton>
        </Box>
        <DialogContent className={$.flex_column} sx={{ py: 2, px: 0 }}>
          {loading ? (
            <Loader />
          ) : (
            <>
              <CheckBoxGroupSection<"taskPartnerDetails">
                title={"Task and Partner Details"}
                details={taskPartnerDetails}
                setDetails={setTaskPartnerDetails}
                sx={{ border: "none" }}
              />
              <CheckBoxGroupSection<"communicationDetails">
                title={"Communication Details"}
                details={communicationDetails}
                setDetails={setCommunicationDetails}
              />
              <CheckBoxGroupSection<"responseDetails">
                title={"Response Details"}
                details={responseDetails}
                setDetails={setResponseDetails}
                sx={{ borderTop: "none" }}
              />
              <CheckBoxGroupSection<"otherDetails">
                title={"Other Details"}
                details={otherDetails}
                setDetails={setOtherDetails}
                sx={{ border: "none" }}
              />
            </>
          )}
        </DialogContent>
        <DialogActions className="dialog_footer">
          <NdAsyncButton
            className={$.BR_fix}
            variant="contained"
            onClick={async () => {
              await downloadAction({
                taskPartnerDetails,
                communicationDetails,
                responseDetails,
                otherDetails,
              });
              setOpen(false);
            }}
          >
            Download Report
          </NdAsyncButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

interface CheckBoxGroupSectionProps<T extends keyof ReportDetailsCustomisationRes> {
  title: string;
  details: ReportDetailsCustomisationRes[T];
  setDetails: React.Dispatch<React.SetStateAction<ReportDetailsCustomisationRes[T]>>;
  sx?: SxProps;
}

const CheckBoxGroupSection = <T extends keyof ReportDetailsCustomisationRes>({
  title,
  details,
  setDetails,
  sx,
}: CheckBoxGroupSectionProps<T>) => {
  return (
    <>
      <Box
        sx={{
          padding: 2,
          border: "1px solid rgba(0, 0, 0, 0.12)",
          display: "flex",
          flexDirection: "column",
          gap: 2,
          borderInline: "none",
          ...sx,
        }}
      >
        <Box className="d_flex" sx={{ justifyContent: "space-between", alignItems: "center" }}>
          <Typography variant="body1">{title}</Typography>
          <Switch
            size="small"
            checked={(details as Array<CustomisationKey<CustomisationKeyType>>).every((item) => item.isVisible)}
            onChange={(_e, checked) => {
              setDetails((prev) => prev.map((val) => ({ ...val, isVisible: checked })));
            }}
          />
        </Box>
        <GroupedSwitches<T>
          values={details}
          onChange={(_e, v) => {
            setDetails((prev) => {
              const found = (prev as Array<CustomisationKey<CustomisationKeyType>>).find((val) => val.name === v.name);
              if (found) found.isVisible = v.isVisible;
              return [...(prev as any)];
            });
          }}
          sx={{
            display: "grid",
            gridTemplateColumns: "300px auto",
            columnGap: 2,
            rowGap: 1,
            paddingInline: 1,
            justifyItems: "start",
          }}
        >
          {details.map((detail, _index) => (
            <FormControlLabel
              key={detail.name}
              name={detail.name}
              control={<Switch size="small" checked={detail.isVisible} />}
              label={detail.name}
            />
          ))}
        </GroupedSwitches>
      </Box>
    </>
  );
};

interface GroupedSwitchesProps<T extends keyof ReportDetailsCustomisationRes> {
  values: ReportDetailsCustomisationRes[T];
  onChange: (_e: React.FormEvent<HTMLDivElement>, v: CustomisationKey) => void;
  children: JSX.Element | JSX.Element[];
  sx?: SxProps;
}

const GroupedSwitches = <T extends keyof ReportDetailsCustomisationRes>({
  values,
  onChange,
  children,
  sx,
}: GroupedSwitchesProps<T>) => {
  const ref: React.MutableRefObject<HTMLDivElement> = React.useRef(null);

  const handleChange = (_e: React.FormEvent<HTMLDivElement>) => {
    let found = { name: "", isVisible: false };

    ref.current.querySelectorAll("input[type=checkbox]").forEach((cb) => {
      const checkBox = cb as HTMLInputElement;
      found = (values as Array<CustomisationKey<CustomisationKeyType>>).find((value) => value.name === checkBox.name);
      if (found) {
        found.isVisible = checkBox.checked;
        onChange(_e, { name: found.name, isVisible: found.isVisible } as any);
      }
    });
  };
  return (
    <FormGroup className="CheckBoxGroup" onChange={handleChange} ref={ref} sx={sx}>
      {children}
    </FormGroup>
  );
};

export default DownloadReport;
