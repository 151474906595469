import SettingsIcon from "@mui/icons-material/Settings";
import { MenuItem, Select, Tooltip } from "@mui/material";
import Button from "@mui/material/Button";
import React, { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import LoadingIcon from "src/Components/Common/LoadingIcon";
import { userContext } from "src/Components/Contexts/userContext";
import CustomToast from "src/Components/CustomToast";
import {
  BUSINESS_PARTNER_LEDGER,
  convertDocumentTypeUIvalueToKeyName,
  Customized,
  OWN_LEDGER,
} from "src/Utils/Recon/Recon360/Constants";
import { Dialog } from "../../Dialog/Dialog";
import { Recon360Context } from "../Recon360";

export default function DocumentTypeModal() {
  const [selectedMapSubGroup, setSelectedMapSubGroup] = useState(false);
  const [sanitizerModalOpened, setSanitizerModalOpened] = useState(false);
  const [savingDocumentType, setSavingDocumentType] = useState(false);
  const { actor } = useContext(userContext);
  const {
    openDocumentTypeDialog,
    setOpenDocumentTypeDialog,
    fetchDataFromERP,
    sheetNameForUnidentified,
    documentTypesArr,
    groupedDocTypesArr,
    configUserName,
    ignoreDoctypesList,
    subTab,
    isChangedDocTypeOnScreenSuggestionMap,
    setSelectedDocumentTypeInUnassignedModal,
    changeDocumentTypeConfig,
    documentTypes,
    setItemForUnassignedDoctypeAdvancedDialog,
    setUnassignedDoctypeAdvancedDialog,
    setDuplicateTagName,
    configUserType,
    headerNameBp,
    finaldocumentTypesObj,
    saveRuleApi,
    templateNameBp,
    sanitizeColBusiness,
    businessPartnerIntegrationWithERP,
    compareLedgersCheckEndDate,
    headerName,
    saveRuleApiOwn,
    templateName,
    sanitizeColOwn,
    reUploadLedger,
    createdocumentTypeTagsForMappedTags,
    setDocumentTypeRow,
    extractTextBeforeTilde,
    extractTextAfterTilde,
    fileNameForUnidentified,
    cancelUploadRequest,
    setDisableUploadBothSide,
    openUnassignedDoctypeDialogFromView,
    listOfGroupsForDocTypeMapRef,
  } = useContext(Recon360Context);

  useEffect(() => {
    if (documentTypesArr?.length > 25) {
      setDisableUploadBothSide(false);
    }
  }, []);

  const docTypeOnScreenSuggestionMap: Record<string, any> = {
    Invoice: "Invoice",
    Payments: subTab === "Payment Advice" ? "Discount/Rebate" : "Payment",
    DNCN: "DN/CN",
    Reversal: "Reversal",
    InternalDoc: "Inter Document",
    TDS: "TDS",
    Ask: "Ignore this time",
    NeverAskAgain: "Never ask again",
  };

  const extractTextAndParticular = (unidentifiedText: string) => {
    // Check if "~~~" exists in the text
    if (unidentifiedText?.includes("~~~")) {
      // Split the text using "~~~" as the delimiter and get the first part
      const parts = unidentifiedText.split("~~~");
      const extractedTextFirst = parts[0];
      const extractedTextSecond = parts[1];

      // Return the extracted text or a default value
      return (
        <span>
          {extractedTextFirst} <span style={{ color: "#00c1ff" }}> + {extractedTextSecond || ""}</span>{" "}
        </span>
      );
    } else {
      // Return the original text if "~~~" is not found
      return unidentifiedText;
    }
  };
  const camelize = (str: string) => {
    return str
      .replace(/(?:^\w|[A-Z]|\b\w)/g, (word: string, index: number) => {
        return index === 0 ? word.toLowerCase() : word.toUpperCase();
      })
      .replace(/\s+/g, "");
  };

  const saveDocumentTypeConfig = async () => {
    setSavingDocumentType(true);

    const removeDuplicates = (arr) => {
      const seen = new Set();
      return arr.filter((item) => {
        const duplicate = seen.has(item.code);
        seen.add(item.code);
        return !duplicate;
      });
    };

    const updateHeader = (header) => {
      const ignoreDocTypeList = {
        neverAskAgain: header?.garbageDoctype ? removeDuplicates(header.garbageDoctype) : [],
        ignoreThisTime: Array.from(new Set(ignoreDoctypesList.current)),
      };
      const updatedHeader = { ...header, ignoreDocTypeList };
      // delete updatedHeader.garbageDoctype; // Remove garbageDoctype manually

      return updatedHeader;
    };

    if (configUserType === BUSINESS_PARTNER_LEDGER) {
      headerNameBp.garbageDoctype = headerNameBp?.garbageDoctype ? removeDuplicates(headerNameBp.garbageDoctype) : [];
      Object.keys(headerNameBp).map((k: any) => {
        Object.keys(finaldocumentTypesObj.current).map((val: any) => {
          let value1 = camelize(finaldocumentTypesObj.current[val].documentType.toLowerCase());
          if (value1 === "dn/Cn") {
            value1 = "debitAndCreditNotes";
          }
          if (k === value1 || k.toLowerCase() === value1) {
            headerNameBp[k].push({
              code: extractTextBeforeTilde(val),
              sign: finaldocumentTypesObj.current[val].transactionType,
              addParticulars: val?.includes("~~~") ? [extractTextAfterTilde(val)] : [],
              excludeParticulars: [],
              group: finaldocumentTypesObj.current[val].group,
              useReference: false,
            });
          }
          return headerNameBp;
        });
        return headerNameBp;
      });

      // Update headerNameBp with ignoreDocTypeList
      const updatedHeaderNameBp = updateHeader(headerNameBp);

      // Check if all document types are either "Ignore this time" or "garbageDoctype"
      const allTypesIgnored = Object.values(finaldocumentTypesObj.current).every(
        (docTypeObj: any) =>
          docTypeObj.documentType === "Ignore this time" || docTypeObj.documentType === "garbageDoctype"
      );

      // do not ask to reupload after unassigned doc type saved
      await saveRuleApi(
        updatedHeaderNameBp,
        allTypesIgnored ? templateNameBp : Customized,
        sanitizeColBusiness,
        openUnassignedDoctypeDialogFromView ? false : true,
        false,
        true
      );
      if (businessPartnerIntegrationWithERP) {
        compareLedgersCheckEndDate();
      }
    } else if (configUserType === OWN_LEDGER) {
      headerName.garbageDoctype = headerName?.garbageDoctype ? removeDuplicates(headerName.garbageDoctype) : [];
      Object.keys(headerName).map((k: any) => {
        Object.keys(finaldocumentTypesObj.current).map((val: any) => {
          let value1 = camelize(finaldocumentTypesObj.current[val].documentType.toLowerCase());
          if (value1 === "dn/Cn") {
            value1 = "debitAndCreditNotes";
          }
          if (k === value1 || k.toLowerCase() === value1) {
            headerName[k].push({
              code: extractTextBeforeTilde(val),
              sign: finaldocumentTypesObj.current[val].transactionType,
              addParticulars: val?.includes("~~~") ? [extractTextAfterTilde(val)] : [],
              excludeParticulars: [],
              group: finaldocumentTypesObj.current[val].group,
              useReference: false,
            });
          }
          return headerName;
        });
        return headerName;
      });

      // Update headerName with ignoreDocTypeList
      const updatedHeaderName = updateHeader(headerName);

      // Check if all document types are either "Ignore this time" or "garbageDoctype"
      const allTypesIgnored = Object.values(finaldocumentTypesObj.current).every(
        (docTypeObj: any) =>
          docTypeObj.documentType === "Ignore this time" || docTypeObj.documentType === "garbageDoctype"
      );
      // do not ask to reupload after unassigned doc type saved
      await saveRuleApiOwn(
        updatedHeaderName,
        allTypesIgnored ? templateName : Customized,
        sanitizeColOwn,
        openUnassignedDoctypeDialogFromView ? false : true,
        false,
        true
      );
      if (actor.integration) {
        compareLedgersCheckEndDate();
      }
    }
    setSavingDocumentType(false);
    setOpenDocumentTypeDialog(false);
    if (!fetchDataFromERP) {
      reUploadLedger("false");
    }
  };

  const extractCodeDetails = (obj: any, targetCode: string) => {
    const result = [];
    // Iterate over the keys in the object
    for (const key in obj) {
      if (Array.isArray(obj[key])) {
        // Check if the current key contains an array
        const codeArray = obj[key];

        // Iterate over the items in the array
        for (const item of codeArray) {
          if (item.code === targetCode) {
            // Add the matching code and its details to the result array
            result.push({
              name: key,
              codes: [
                {
                  code: item.code,
                  sign: item.sign,
                  addParticulars: item.addParticulars,
                  excludeParticulars: item.excludeParticulars,
                  group: item.group,
                  useReference: item?.useReference,
                },
              ],
            });
          }
        }
      }
    }

    return result;
  };
  const openSanitizerModal = (configUser: "OWN" | "BP") => {
    // get the config Btns to open config modal
    const configurationBtn = document.querySelectorAll(`#ConfigBtn`) as NodeListOf<HTMLSpanElement>;

    const handleOpenSanitizerModal = () => {
      clickSanitizerButton()
        .then()
        .catch(() => {
          toast.error(<CustomToast message="Could not open Sanitizer Window." />);
        });
    };

    const clickSanitizerButton = () => {
      return new Promise<void>((resolve, reject) => {
        setTimeout(() => {
          // get the sanitizer Btn of Document ID to open sanitizer modal
          const sanitizerBtn = document.querySelector(".sanitizerButton#docId") as HTMLSpanElement;
          if (sanitizerBtn) {
            sanitizerBtn.click();
            resolve();
          } else reject();
        }, 500);
      });
    };

    if (configUser === "OWN") {
      configurationBtn[0]?.click();
      handleOpenSanitizerModal();
    } else if (configUser === "BP") {
      configurationBtn[1]?.click();
      handleOpenSanitizerModal();
    }
  };
  return (
    <Dialog
      open={openDocumentTypeDialog}
      onClose={() => setOpenDocumentTypeDialog(true)}
      width70Per={documentTypesArr?.length <= 25}
    >
      <header className="modal-card-head">
        <p className="modal-card-title config_dialog_title">
          <p>
            {!fetchDataFromERP && <small>FileName: {fileNameForUnidentified}</small>}{" "}
            <small>{sheetNameForUnidentified && `  SheetName :${sheetNameForUnidentified}`}</small>
          </p>
          {documentTypesArr?.length > 25
            ? `Too Many Document Types Found!`
            : `Potentially missed document types in ${configUserName} ledger`}
        </p>
        <button
          className="delete"
          aria-label="close"
          onClick={() => {
            // clear ignore doc type list on close click
            ignoreDoctypesList.current = [];

            setOpenDocumentTypeDialog(false);
            setDisableUploadBothSide(true);
            if (!fetchDataFromERP) {
              cancelUploadRequest();
            } else {
              setDisableUploadBothSide(false);
            }
          }}
        />
      </header>
      <section className="modal-card-body" style={{ position: "relative" }}>
        {documentTypesArr?.length > 25 && (
          <div
            style={{
              position: "sticky",
              fontSize: 16,
              top: -20,
              right: 20,
              color: "#e42",
              background: "#fff",
              zIndex: 10,
            }}
          >
            Apply Sanitizer or Check With CS Team.
          </div>
        )}
        <div className="unassigned_documentType_table_container">
          <table className="unassigned_documentType_table">
            <thead className="fixed-header">
              <tr>
                <th>Unassigned DocType</th>
                <th># Entries</th>
                {documentTypesArr?.length <= 25 && <th>Map document type</th>}
                {documentTypesArr?.length <= 25 && <th>Map Sub Group</th>}
              </tr>
            </thead>
            <tbody>
              {documentTypesArr?.length > 25 &&
                Object.values(groupedDocTypesArr.current).map((valArr) => (
                  <tr key={valArr[0].unidentifiedText}>
                    <td>
                      <div className="documentType_name">
                        <p>
                          {valArr[0].unidentifiedText}
                          <span style={{ color: "#4a4", fontSize: 14 }}>
                            {valArr.length - 1 ? " + " + (valArr.length - 1) + " Others" : ""}
                          </span>
                        </p>
                      </div>
                    </td>
                    <td>
                      <div className="documentType_count">
                        <p>{valArr.map((ele) => ele.occurenceCount).reduce((a, b) => a + b)}</p>
                      </div>
                    </td>
                  </tr>
                ))}
              {documentTypesArr?.length <= 25 &&
                documentTypesArr.map((item, index) => {
                  // Determine the document type key based on `unidentifiedText`
                  const documentTypeKey = convertDocumentTypeUIvalueToKeyName(
                    finaldocumentTypesObj.current[item.unidentifiedText]?.documentType
                  );
                  return (
                    <tr key={index}>
                      <td>
                        <div className="documentType_name">
                          <p>{extractTextAndParticular(item.unidentifiedText)}</p>
                        </div>
                      </td>
                      <td>
                        <div className="documentType_count">
                          <p>{item.occurenceCount}</p>
                        </div>
                      </td>
                      {documentTypesArr?.length <= 25 && (
                        <td>
                          <div className="documentType_dropdown_div">
                            <span className="documentType_select">
                              <Select
                                size="small"
                                name="documentType"
                                variant="outlined"
                                fullWidth={true}
                                displayEmpty={true}
                                defaultValue={docTypeOnScreenSuggestionMap[item.suggestedDoctype]}
                                onChange={(e) => {
                                  isChangedDocTypeOnScreenSuggestionMap.current = true;
                                  setSelectedDocumentTypeInUnassignedModal(e.target.value);
                                  // selectedDocumentTypeInUnassignedModalRef.current = e.target.value;
                                  // Reset the groupId and groupName in finaldocumentTypesObj
                                  finaldocumentTypesObj.current[item.unidentifiedText].group = {
                                    groupId: "All",
                                    groupName: "All",
                                  };
                                  console.log(selectedMapSubGroup);
                                  setSelectedMapSubGroup((prev) => !prev); // Toggle to trigger a re-render
                                  changeDocumentTypeConfig(item.unidentifiedText, e.target.value);
                                }}
                              >
                                <MenuItem disabled={true}>Select Document Type</MenuItem>
                                {documentTypes.map((val, i) => (
                                  <MenuItem
                                    key={i}
                                    value={val}
                                    style={{
                                      color:
                                        val === "Ignore this time" ? "orange" : val === "Never ask again" ? "red" : "",
                                    }}
                                  >
                                    {val}
                                  </MenuItem>
                                ))}
                              </Select>
                            </span>
                          </div>
                        </td>
                      )}

                      {documentTypesArr?.length <= 25 && (
                        <td>
                          <div className="documentType_dropdown_div">
                            <span className="documentType_select">
                              <Select
                                size="small"
                                name="documentType"
                                variant="outlined"
                                fullWidth={true}
                                displayEmpty={true}
                                value={`${finaldocumentTypesObj.current[item.unidentifiedText].group?.groupId}|${
                                  finaldocumentTypesObj.current[item.unidentifiedText].group?.groupName
                                }`}
                                onChange={(e: any) => {
                                  // Split the value to get both `groupId` and `groupName`
                                  const [selectedGroupId, selectedGroupName] = e.target.value.split("|");

                                  // Find the corresponding group details using both `groupId` and `groupName`
                                  const selectedGroup = listOfGroupsForDocTypeMapRef.current[documentTypeKey]?.find(
                                    (group) =>
                                      group.groupDetails.groupName === selectedGroupName &&
                                      group.groupDetails.groupId === selectedGroupId
                                  );

                                  // Modify the `group` object in `finaldocumentTypesObj`
                                  if (selectedGroup) {
                                    finaldocumentTypesObj.current[item.unidentifiedText].group = {
                                      groupId: selectedGroup.groupDetails.groupId,
                                      groupName: selectedGroup.groupDetails.groupName,
                                    };
                                    setSelectedMapSubGroup((prev) => !prev); // Toggle to trigger a re-render
                                  } else {
                                    finaldocumentTypesObj.current[item.unidentifiedText].group = {
                                      groupId: "All",
                                      groupName: "All",
                                    };
                                    setSelectedMapSubGroup((prev) => !prev); // Toggle to trigger a re-render
                                  }
                                }}
                              >
                                <MenuItem value={"All|All"}>All</MenuItem>
                                {listOfGroupsForDocTypeMapRef.current &&
                                  Object.keys(listOfGroupsForDocTypeMapRef.current).length > 0 &&
                                  listOfGroupsForDocTypeMapRef.current[documentTypeKey]?.map((val, i) => (
                                    <MenuItem
                                      key={i}
                                      value={`${val.groupDetails.groupId}|${val.groupDetails.groupName}`}
                                    >
                                      {val.groupDetails.groupName}
                                    </MenuItem>
                                  ))}
                              </Select>
                            </span>
                            <Tooltip title="Advanced mapping: Map the unassigned doc type using particulars/narration. Also define using transaction type [Debit/Credit (+/-) transactions)]">
                              <span
                                className="documentType_advanced_settings"
                                onClick={() => {
                                  if (!isChangedDocTypeOnScreenSuggestionMap.current) {
                                    setSelectedDocumentTypeInUnassignedModal(
                                      docTypeOnScreenSuggestionMap[item.suggestedDoctype]
                                    );
                                  }
                                  setItemForUnassignedDoctypeAdvancedDialog(item);
                                  setUnassignedDoctypeAdvancedDialog(true);
                                  setDuplicateTagName("");

                                  if (configUserType === OWN_LEDGER) {
                                    const extractedDetails = extractCodeDetails(headerName, item.unidentifiedText);
                                    createdocumentTypeTagsForMappedTags(extractedDetails);
                                    setDocumentTypeRow(extractedDetails);
                                  } else if (configUserType === BUSINESS_PARTNER_LEDGER) {
                                    const extractedDetails = extractCodeDetails(headerNameBp, item.unidentifiedText);
                                    createdocumentTypeTagsForMappedTags(extractedDetails);
                                    setDocumentTypeRow(extractedDetails);
                                  }
                                }}
                              >
                                <SettingsIcon />
                              </span>
                            </Tooltip>
                          </div>
                        </td>
                      )}
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </section>
      <footer className="modal-card-foot flex_end">
        <span className="mr_20">{sanitizerModalOpened ? "" : ""}</span>
        <div>
          <Button
            className="theme_btn"
            disabled={savingDocumentType}
            // disabled={documentTypesArr?.length > 25}
            onClick={() => {
              if (documentTypesArr?.length <= 25) saveDocumentTypeConfig();
              else {
                setSanitizerModalOpened(true);
                openSanitizerModal(configUserType === OWN_LEDGER ? "OWN" : "BP");
              }
            }}
            startIcon={documentTypesArr?.length > 25 ? <SettingsIcon /> : <LoadingIcon loading={savingDocumentType} />}
          >
            {documentTypesArr?.length <= 25 ? `Save and proceed` : `Sanitizer`}
          </Button>
        </div>
      </footer>
    </Dialog>
  );
}
