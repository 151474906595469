import { Download } from "@mui/icons-material";
import { FormControl, FormControlLabel, Stack, Typography } from "@mui/material";
import { Alert, Box, Step, StepLabel, Stepper } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import useFetch from "src/Components/Common/useFetch";
import { API_ENDPOINTS } from "src/Utils/ApiConstants/ApiUrlConstants";
import { IFilledDetails, MsmeSegment, NdCheckbox, NdSecondaryButton, NdStepIcon } from "./CommonComponents";
import { CommonUploaderBox, FilledDetailsGrid, LoadingPage, UploadFileType } from "./PartnerPortal.common";
import {
  DownloadFilledPDFDialog,
  UploadConfirmDocumentDialog,
  UploadMsmeCertificateDialog,
} from "./PartnerPortal.dialogs";
import $ from "./PartnerPortal.module.scss";

export const CustomStepIcon = ({
  icon,
  completed,
  active,
}: {
  icon: React.ReactNode;
  completed: boolean;
  active?: boolean;
}) => {
  return <NdStepIcon icon={icon} active={active} completed={completed} />;
};

type StateDispatch<T = any> = React.Dispatch<React.SetStateAction<T>>;

export interface FilledDetailsAndFilesRes {
  isPdfDownloaded: boolean;
  isDetailsChanged: boolean;
  // pdfWithLetterHead: `base64 string`;
  // pdfWithoutLetterHead: `base64 string`;
  firmDetails: FirmDetails;
  authorisedPersonDetails: AuthorisedPersonDetails;
  uploadedFileDetails: UploadedFileDetails;
}

interface FirmDetails {
  name: string;
  pan: string;
  udyamRegistrationNumber: string;
  classificationDate: Date;
  segment: MsmeSegment;
}

interface AuthorisedPersonDetails {
  name: string;
  designation: string;
  email: string;
  phoneNumber?: string;
}

interface UploadedFileDetails {
  signedPdf: string;
  udyamCertificate: string;
}

export const DetailsPage2 = ({
  finalStepCompleted,
  setFinalStepCompleted,
}: {
  finalStepCompleted: 0 | 1;
  setFinalStepCompleted: StateDispatch<0 | 1>;
}) => {
  const location = useLocation<any>();
  const params = new URLSearchParams(location.search);
  const encryptedData = params.get("data");

  const [loading, setLoading] = useState<boolean>(true);
  const [filledDetailsAndFiles, setFilledDetailsAndFiles] = useState<FilledDetailsAndFilesRes>(null);

  function fetchData<T = any>(uri: string) {
    const promise = new Promise<T>((resolve, reject) => {
      useFetch<T>(uri, "GET", {
        config: {
          params: {
            data: encryptedData,
          },
        },
        thenCallBack: (res) => {
          resolve(res.data);
        },
        errorCallback: reject,
        catchCallBack: reject,
      });
    });
    return promise;
  }

  useEffect(() => {
    const getFilledDetailsAndFiles = async () => {
      setLoading(true);
      const _data = await fetchData<FilledDetailsAndFilesRes>("/api/partnerPortal/GetDetailsAndUploadedFiles");
      setFilledDetailsAndFiles(_data);
      setLoading(false);
    };
    getFilledDetailsAndFiles();
  }, []);

  return (
    <>
      {loading ? (
        <LoadingPage noHeader={true} />
      ) : (
        <DetailsPage
          promisedDetails={filledDetailsAndFiles}
          encryptedData={encryptedData}
          finalStepCompleted={finalStepCompleted}
          setFinalStepCompleted={setFinalStepCompleted}
        />
      )}
    </>
  );
};

const DetailsPage = ({
  promisedDetails,
  encryptedData,
  finalStepCompleted,
  setFinalStepCompleted,
}: {
  promisedDetails: FilledDetailsAndFilesRes;
  encryptedData: string;
  finalStepCompleted: 0 | 1;
  setFinalStepCompleted: StateDispatch<0 | 1>;
}) => {
  const data = promisedDetails;

  const [step1Completed, setStep1Completed] = useState<0 | 1>(0);
  const [step2Completed, setStep2Completed] = useState<0 | 1>(0);

  const [step3Checked, setStep3Checked] = useState(false);

  const [loadedData] = useState<IFilledDetails>({
    companyName: data?.firmDetails?.name,
    panNumber: data?.firmDetails?.pan,
    msmeUdyamNumber: data?.firmDetails?.udyamRegistrationNumber,
    segment: data?.firmDetails?.segment,
    personName: data?.authorisedPersonDetails?.name,
    email: data?.authorisedPersonDetails?.email,
    designation: data?.authorisedPersonDetails?.designation,
  } as IFilledDetails);

  const [openDownloadDialog, setOpenDownloadDialog] = useState<boolean>(false);
  const [openConfirmDocDialog, SetOpenConfirmDocDialog] = useState<boolean>(false);
  const [openUdyamCertDialog, SetOpenUdyamCertDialog] = useState<boolean>(false);
  const [openBackDrop, setOpenBackDrop] = useState<boolean>(false);

  const docFileRef = useRef<File>(null);
  const certFileRef = useRef<File>(null);

  const [docFileName, setDocFileName] = useState<string>(data.uploadedFileDetails?.signedPdf || "");
  const [certFileName, setCertFileName] = useState<string>(data.uploadedFileDetails?.udyamCertificate || "");

  const uploadDocAndCertificate = (fileRef: React.MutableRefObject<File>, fileType: UploadFileType) => {
    setOpenBackDrop(true);
    const bodyFormData = new FormData();
    bodyFormData.append("file", fileRef.current);
    bodyFormData.append("data", encryptedData);
    bodyFormData.append("type", fileType);

    useFetch<{ fileName: string }>(API_ENDPOINTS.UPLOAD_PDF.url, "POST", {
      failureMessage: API_ENDPOINTS.UPLOAD_PDF.failureMessage,
      showSuccessToast: true,
      data: bodyFormData,
      config: {
        headers: { "Content-Type": "multipart/form-data" },
      },
      thenCallBack: (_res) => {
        if (fileType === "signedPdf") setDocFileName(_res.data.fileName);
        else setCertFileName(_res.data.fileName);
        setOpenBackDrop(false);
        SetOpenConfirmDocDialog(false);
        SetOpenUdyamCertDialog(false);
      },
    });
  };

  const viewDocAndCertificate = (_type: UploadFileType) => {
    useFetch<{ signedUrl: string }>(API_ENDPOINTS.VIEW_UPLOADED_PDF.url, "POST", {
      failureMessage: API_ENDPOINTS.VIEW_UPLOADED_PDF.failureMessage,
      showSuccessToast: true,
      data: {
        data: encryptedData,
        type: _type,
      },
      thenCallBack: (_res) => {
        const link = document.createElement("a");
        link.href = _res.data.signedUrl;
        if (_type === "signedPdf") {
          link.download = docFileName;
        } else {
          link.download = certFileName;
        }
        link.target = "_blank";
        link.referrerPolicy = "no-referrer";
        link.style.display = "none";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      },
    });
  };

  const deleteDocAndCertificate = (_type: UploadFileType) => {
    useFetch(API_ENDPOINTS.DELETE_PDF.url, "POST", {
      failureMessage: API_ENDPOINTS.DELETE_PDF.failureMessage,
      showSuccessToast: true,
      data: {
        data: encryptedData,
        type: _type,
      },
      thenCallBack: () => {
        if (_type === "signedPdf") setDocFileName("");
        else setCertFileName("");
      },
    });
  };

  useEffect(() => {
    if (data.isDetailsChanged) {
      setOpenDownloadDialog(true);
    } else setOpenDownloadDialog(false);

    if (docFileName && certFileName) setStep2Completed(1);
    else {
      setStep2Completed(0);
      setFinalStepCompleted(0);
      setStep3Checked(false);
    }

    if (data.isPdfDownloaded) setStep1Completed(1);
    else setStep1Completed(0);

    if (step3Checked) setFinalStepCompleted(1);
    else setFinalStepCompleted(0);
  }, [data, docFileName, certFileName, step3Checked]);

  return (
    <>
      <Stack className="fade_in details_box" py={5} gap={4} maxWidth={900} mx={"auto"}>
        <Typography variant="h6">Upload required documents</Typography>
        <FormControl sx={{ gap: 4 }}>
          <Box className="step_1">
            <Stepper activeStep={step1Completed} sx={{ py: 2 }}>
              <Step sx={{ ".MuiStepIcon-root.Mui-active": { color: "#531c4c" } }}>
                <StepLabel
                  StepIconComponent={CustomStepIcon.bind(null, {
                    icon: 1,
                    completed: Boolean(step1Completed),
                    active: Boolean(!step1Completed),
                  })}
                >
                  Download and Print
                </StepLabel>
              </Step>
              <Step sx={{ p: 0 }}></Step>
            </Stepper>
            <Stack className="step_content" py={2} pl={4} gap={2}>
              <Stack className={$.BR_fix} border={"1px solid #d7d7d7"}>
                <Box className="first vertical_center_align" p={2}>
                  <Typography variant="subtitle1" fontWeight={700} color={"#000"} mr={"auto"}>
                    MSME Confirmation Document
                  </Typography>
                  <NdSecondaryButton
                    variant="contained"
                    startIcon={<Download />}
                    onClick={() => setOpenDownloadDialog(true)}
                  >
                    Download
                  </NdSecondaryButton>
                </Box>
                <Box sx={{ borderTop: "1px solid #D7D7D7", background: "#F3F3F3" }} p={2}>
                  <Typography variant="body2" component={"ol"} pl={2.5} sx={{ li: { font: "inherit" } }}>
                    <li>Download and print the document</li>
                    <li>All the necessary information are pre-filled in the document</li>
                    <li>Get the signature of Authorized Signatory and Seal of the Firm</li>
                    <li>Upload the scanned copy of the same in below section</li>
                  </Typography>
                </Box>
                <Stack sx={{ borderTop: "1px solid #D7D7D7", background: "#F3F3F3" }}>
                  <Box py={"12px"} px={2}>
                    <Typography variant="subtitle1" fontWeight={700}>
                      Company/Firm/Enterprise details used in MSME Confirmation Document
                    </Typography>
                  </Box>
                  <Box py={"12px"} px={2}>
                    <FilledDetailsGrid data={loadedData} />
                  </Box>
                </Stack>
              </Stack>
            </Stack>
          </Box>
          <Box className="step_2">
            <Stepper activeStep={step2Completed} sx={{ py: 2 }}>
              <Step>
                <StepLabel
                  StepIconComponent={CustomStepIcon.bind(null, {
                    icon: 2,
                    completed: Boolean(step2Completed),
                    active: Boolean(step1Completed),
                  })}
                >
                  Upload
                </StepLabel>
              </Step>
              <Step sx={{ p: 0 }}></Step>
            </Stepper>
            <Stack className="step_content" py={2} pl={4} gap={2}>
              <CommonUploaderBox
                fileName={docFileName}
                fileRef={docFileRef}
                fileType={"signedPdf"}
                viewFn={viewDocAndCertificate}
                deleteFn={deleteDocAndCertificate}
                setOpenDialog={SetOpenConfirmDocDialog}
                disabled={!step1Completed}
              />
              <CommonUploaderBox
                fileName={certFileName}
                fileRef={certFileRef}
                fileType={"udyamCertificate"}
                viewFn={viewDocAndCertificate}
                deleteFn={deleteDocAndCertificate}
                setOpenDialog={SetOpenUdyamCertDialog}
                disabled={!step1Completed}
              />
            </Stack>
          </Box>
          <Box className="step_3">
            <Stepper activeStep={finalStepCompleted} sx={{ py: 2 }}>
              <Step>
                <StepLabel
                  StepIconComponent={CustomStepIcon.bind(null, {
                    icon: 3,
                    completed: Boolean(finalStepCompleted),
                    active: Boolean(step2Completed),
                  })}
                >
                  Review and Submit
                </StepLabel>
              </Step>
              <Step sx={{ p: 0 }}></Step>
            </Stepper>
            <Stack className="step_content" py={2} pl={4} gap={1}>
              <Typography variant="subtitle1" fontWeight={700}>
                Confirm Company/Firm/Enterprise details and above uploaded files
              </Typography>
              <Alert className={$.BR_fix} severity="warning">
                <Typography variant="body2">Note: You cannot change your response once submitted</Typography>
              </Alert>
              <FormControlLabel
                control={
                  <NdCheckbox
                    checked={step3Checked && Boolean(step2Completed)}
                    onChange={() => setStep3Checked(!step3Checked)}
                    disabled={Boolean(!step2Completed)}
                  />
                }
                label="I confirm that Company/Firm/Enterprise details and above uploaded files are correct"
              />
            </Stack>
          </Box>
        </FormControl>
      </Stack>
      <DownloadFilledPDFDialog
        openDialog={openDownloadDialog}
        setOpenDialog={setOpenDownloadDialog}
        afterDownload={() => {
          data.isPdfDownloaded = true;
          setStep1Completed(1);
        }}
      />
      <UploadConfirmDocumentDialog
        openDialog={openConfirmDocDialog}
        setOpenDialog={SetOpenConfirmDocDialog}
        data={loadedData}
        fileRef={docFileRef}
        uploadFn={uploadDocAndCertificate.bind(null, docFileRef, "signedPdf")}
        openBackDrop={openBackDrop}
      />
      <UploadMsmeCertificateDialog
        openDialog={openUdyamCertDialog}
        setOpenDialog={SetOpenUdyamCertDialog}
        fileRef={certFileRef}
        uploadFn={uploadDocAndCertificate.bind(null, certFileRef, "udyamCertificate")}
        openBackDrop={openBackDrop}
      />
    </>
  );
};

export default DetailsPage2;
