import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import {
  Box,
  Button,
  Dialog,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Skeleton,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { Buffer } from "buffer";
import { saveAs } from "file-saver";
import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import LoadingIcon from "src/Components/Common/LoadingIcon";
import useFetch from "src/Components/Common/useFetch";
import CustomToast from "src/Components/CustomToast";
import { API_ENDPOINTS } from "src/Utils/ApiConstants/ApiUrlConstants";
import { isOverFlowing } from "src/Utils/Common/CheckTextOverflow";
import {
  uiLoggerNamesPartnerCommunication,
  uiLoggerNamesTaskBoard,
  uiLoggerNamesWorkflow,
} from "src/Utils/Recon/UiLogger/Constants";
import uiLogger from "src/Utils/UiLogger";
import download_icon from "../../../Graphics/AutomatedRecon/download_icon2.svg";
import { a11yProps, TabPanel } from "../ReconSettings/ReconSettings";
import DeleteTaskAttachmentModal from "./DeleteTaskAttachmentModal";
import { NAKAD_SERVICE } from "./TasksHistoryModal";

interface TaskAttachmentsModalProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  businessPartnerName: string;
  ticketId: number;
  companyId: string;
  branchCode: string;
  serviceType?: string;
}

interface AttachmentBuckets {
  recon: CategoryWiseAttachmentCount[];
  custom: string[];
  partnerCommunication: CategoryWiseAttachmentCount[];
}
interface CategoryWiseAttachmentCount {
  category: string;
  count: number;
}
enum AttachmentType {
  Custom = "Custom",
  ReconDocs = "Recon Docs",
  PartnerCommunication = "Partner Communication",
}
enum PartnerCommunicationAttachmentCategory {
  ConfirmationDocument = "Confirmation Document",
  Ledgers = "Ledgers",
  OpenItems = "Open Items",
  Attachments = "Attachments",
}
export default function TaskAttachmentsModal({
  open,
  setOpen,
  businessPartnerName,
  ticketId,
  companyId,
  branchCode,
  serviceType,
}: TaskAttachmentsModalProps) {
  const [tabValue, setTabValue] = useState<number>(0);

  const [taskAttachments, setTaskAttachments] = useState<AttachmentBuckets>({
    recon: [],
    custom: [],
    partnerCommunication: [],
  });
  const fileUploader = useRef<HTMLInputElement>(null);
  const customAttachmentsCount = useRef<number>(0);
  const [isUploadingCustomAttachments, setIsUploadingCustomAttachments] = useState<boolean>(false);
  const [isFetchingAttachments, setIsFetchingAttachments] = useState<boolean>(false);
  const [showDeleteTaskAttachmentModal, setShowDeleteTaskAttachmentModal] = useState<boolean>(false);
  const deleteAttachmentName = useRef<string>("");
  const [isDeletingAttachment, setIsDeletingAttachment] = useState<boolean>(false);
  const [downloadingAttachmentId, setDownloadingAttachmentId] = useState<string | null>(null);

  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };
  const listAttachments = () => {
    setIsFetchingAttachments(true);
    useFetch<{ attachments: AttachmentBuckets }>(API_ENDPOINTS.LIST_ATTACHMENTS(ticketId).url, "GET", {
      failureMessage: API_ENDPOINTS.LIST_ATTACHMENTS(ticketId).failureMessage,
      thenCallBack: (response) => {
        setTaskAttachments(response.data.attachments);
        customAttachmentsCount.current = response.data.attachments?.custom?.length || 0;
        setIsFetchingAttachments(false);
      },
      catchCallBack: () => {
        setIsFetchingAttachments(false);
      },
    });
  };

  const downloadAttachment = (attachmentType: string, attachmentName: string) => {
    setDownloadingAttachmentId(attachmentName + attachmentType);

    if (serviceType === NAKAD_SERVICE.PartnerCommunication && attachmentType === AttachmentType.PartnerCommunication) {
      let functionNameTemp;
      let messageTemp;
      if (attachmentName === PartnerCommunicationAttachmentCategory.ConfirmationDocument) {
        functionNameTemp = uiLoggerNamesPartnerCommunication.UI_PC_BCB_ATTACHMENTS_PDF_CLICK.functionName;
        messageTemp = uiLoggerNamesPartnerCommunication.UI_PC_BCB_ATTACHMENTS_PDF_CLICK.message;
      } else if (attachmentName === PartnerCommunicationAttachmentCategory.Ledgers) {
        functionNameTemp = uiLoggerNamesPartnerCommunication.UI_PC_BCB_ATTACHMENTS_LEDGERS_CLICK.functionName;
        messageTemp = uiLoggerNamesPartnerCommunication.UI_PC_BCB_ATTACHMENTS_LEDGERS_CLICK.message;
      } else if (attachmentName === PartnerCommunicationAttachmentCategory.OpenItems) {
        functionNameTemp = uiLoggerNamesPartnerCommunication.UI_PC_BCB_ATTACHMENTS_OPENITEMS_CLICK.functionName;
        messageTemp = uiLoggerNamesPartnerCommunication.UI_PC_BCB_ATTACHMENTS_OPENITEMS_CLICK.message;
      }
      uiLogger(functionNameTemp, companyId, branchCode, {
        message: messageTemp,
      });
    }
    if (serviceType === NAKAD_SERVICE.Recon) {
      if (attachmentType === AttachmentType.ReconDocs) {
        uiLogger(
          uiLoggerNamesWorkflow.UI_WF_DB_ACTIONS_VIEWATTACHMENTS_DOWNLOAD_CLICK.functionName,
          companyId,
          branchCode,
          {
            message: uiLoggerNamesWorkflow.UI_WF_DB_ACTIONS_VIEWATTACHMENTS_DOWNLOAD_CLICK.message,
            idOfTicket: ticketId,
          }
        );
      } else if (attachmentType === AttachmentType.Custom) {
        uiLogger(
          uiLoggerNamesWorkflow.UI_WF_DB_ACTIONS_VIEW_CUSTOMATTACHMENTS_DOWNLOAD_CLICK.functionName,
          companyId,
          branchCode,
          {
            message: uiLoggerNamesWorkflow.UI_WF_DB_ACTIONS_VIEW_CUSTOMATTACHMENTS_DOWNLOAD_CLICK.message,
            idOfTicket: ticketId,
          }
        );
      }
    }
    if (serviceType === "taskBoard") {
      if (attachmentType === AttachmentType.Custom) {
        uiLogger(
          uiLoggerNamesTaskBoard.UI_WF_TB_ACTIONS_VIEWATTACHMENTS_CUSTOM_DOWNLOAD_CLICK.functionName,
          companyId,
          branchCode,
          {
            message: uiLoggerNamesTaskBoard.UI_WF_TB_ACTIONS_VIEWATTACHMENTS_CUSTOM_DOWNLOAD_CLICK.message,
            idOfTicket: ticketId,
          }
        );
      } else {
        uiLogger(
          uiLoggerNamesTaskBoard.UI_WF_TB_ACTIONS_VIEWATTACHMENTS_TOOL_DOWNLOAD_CLICK.functionName,
          companyId,
          branchCode,
          {
            message: uiLoggerNamesTaskBoard.UI_WF_TB_ACTIONS_VIEWATTACHMENTS_TOOL_DOWNLOAD_CLICK.message,
            idOfTicket: ticketId,
          }
        );
      }
    }
    useFetch(API_ENDPOINTS.DOWNLOAD_ATTACHMENT.url, "POST", {
      failureMessage: API_ENDPOINTS.DOWNLOAD_ATTACHMENT.failureMessage,
      showSuccessToast: true,
      data: {
        type: attachmentType,
        ticketId,
        name: attachmentName,
        companyId: companyId,
        branchCode: branchCode,
      },
      thenCallBack: (response) => {
        const fileBase64 = Buffer.from(response.data.buffer, "base64");
        const fileType = response.data.contentType;
        const blob = new Blob([fileBase64], { type: fileType });
        saveAs(blob, response.data.filename);
        setDownloadingAttachmentId(null);
      },
      catchCallBack: () => {
        setDownloadingAttachmentId(null);
      },
    });
  };

  const deleteCustomAttachment = () => {
    if (serviceType === "taskBoard") {
      uiLogger(
        uiLoggerNamesTaskBoard.UI_WF_TB_ACTIONS_VIEWATTACHMENTS_DELETE_CLICK.functionName,
        companyId,
        branchCode,
        {
          message: uiLoggerNamesTaskBoard.UI_WF_TB_ACTIONS_VIEWATTACHMENTS_DELETE_CLICK.message,
          idOfTicket: ticketId,
        }
      );
    } else {
      uiLogger(
        uiLoggerNamesWorkflow.UI_WF_DB_ACTIONS_VIEWATTACHMENTS_DELETE_CLICK.functionName,
        companyId,
        branchCode,
        {
          message: uiLoggerNamesWorkflow.UI_WF_DB_ACTIONS_VIEWATTACHMENTS_DELETE_CLICK.message,
          idOfTicket: ticketId,
        }
      );
    }
    setIsDeletingAttachment(true);
    useFetch(API_ENDPOINTS.DELETE_CUSTOM_ATTACHMENT.url, "DELETE", {
      failureMessage: API_ENDPOINTS.DELETE_CUSTOM_ATTACHMENT.failureMessage,
      showSuccessToast: true,
      config: {
        data: {
          ticketId,
          name: deleteAttachmentName.current,
        },
      },
      thenCallBack: () => {
        listAttachments();
        setIsDeletingAttachment(false);
        setShowDeleteTaskAttachmentModal(false);
      },
      catchCallBack: () => {
        setIsDeletingAttachment(false);
        setShowDeleteTaskAttachmentModal(false);
      },
    });
  };

  const uploadCustomAttachment = (selectedAttachments: File[]) => {
    setIsUploadingCustomAttachments(true);

    if (selectedAttachments.length === 0) {
      toast.error(<CustomToast message="Please select a file to upload" />);
      setIsUploadingCustomAttachments(false);
      return;
    }

    if (selectedAttachments.length === 1) {
      if (customAttachmentsCount.current >= 100) {
        toast.error(
          <CustomToast message="File upload count limit exceeded. Please remove irrelevant files to continue upload." />
        );
        setIsUploadingCustomAttachments(false);
        return;
      }
      if (selectedAttachments[0].size > 200 * 1024 * 1024) {
        // 200MB in bytes
        toast.error(<CustomToast message="File size limit exceeded. Please upload a file less than 200 MB." />);
        setIsUploadingCustomAttachments(false);
        return;
      }
    }
    if (selectedAttachments.length > 1) {
      if (customAttachmentsCount.current + selectedAttachments.length > 100) {
        toast.error(
          <CustomToast
            message={`File upload count limit exceeded. Only ${
              100 - customAttachmentsCount.current
            } more files can be uploaded. Please remove irrelevant files to continue upload remaining.`}
          />
        );
        setIsUploadingCustomAttachments(false);
        return;
      }

      let isFileSizeValid = true;
      let invalidFilesCount = 0;
      selectedAttachments.forEach((item) => {
        if (item.size > 200 * 1024 * 1024) {
          isFileSizeValid = false;
          invalidFilesCount++;
        }
      });

      if (!isFileSizeValid) {
        toast.error(
          <CustomToast
            message={`File size limit exceeded for ${invalidFilesCount} out of ${selectedAttachments.length} files. Please upload files less than 200 MB.`}
          />
        );
        setIsUploadingCustomAttachments(false);
        return;
      }
    }

    const bodyFormData = new FormData();
    selectedAttachments.forEach((file) => {
      bodyFormData.append("file", file);
    });
    bodyFormData.append("ticketId", ticketId.toString());
    useFetch(API_ENDPOINTS.UPLOAD_CUSTOM_ATTACHMENT.url, "POST", {
      failureMessage: API_ENDPOINTS.UPLOAD_CUSTOM_ATTACHMENT.failureMessage,
      showSuccessToast: true,
      data: bodyFormData,
      config: { headers: { "Content-Type": "multipart/form-data" } },
      thenCallBack: () => {
        listAttachments();
        setIsUploadingCustomAttachments(false);
      },
      catchCallBack: () => {
        setIsUploadingCustomAttachments(false);
      },
    });
  };

  useEffect(() => {
    listAttachments();
  }, []);

  return (
    <>
      <Dialog open={open} sx={{ "& .MuiDialog-paper": { borderRadius: "4px", minWidth: "600px" } }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: 2,
            background: "#F1F1F1",
          }}
        >
          <Typography
            variant="h6"
            ref={(th) => {
              if (th && isOverFlowing(th)) th.title = th.innerText;
            }}
            className="textOverflow_hidden"
          >
            Attachments for {businessPartnerName}
          </Typography>
          <IconButton
            onClick={() => {
              setOpen(false);
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <Divider />
        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={tabValue}
              onChange={handleChange}
              aria-label="basic tabs example"
              variant="scrollable"
              scrollButtons="auto"
            >
              <Tab label="TOOL DOCUMENTS" {...a11yProps(0)} sx={{ textTransform: "none" }} />
              <Tab label="CUSTOM DOCUMENTS" {...a11yProps(1)} sx={{ textTransform: "none" }} />
            </Tabs>
          </Box>
          <TabPanel value={tabValue} index={0} key={0} sx={{ padding: 0 }}>
            <Box sx={{ height: "570" }}>
              <Box sx={{ minHeight: "300px" }}>
                <Box sx={{ padding: "8px 16px", background: "#F1F1F1", border: "1px solid #C4C4C4" }}>
                  <Typography variant="subtitle2">Partner Communication Documents</Typography>
                </Box>
                {isFetchingAttachments ? (
                  <Box sx={{ display: "flex", flexDirection: "column", gap: 4, padding: "16px" }}>
                    <Skeleton variant="rectangular" height={40} />
                    <Skeleton variant="rectangular" height={40} />
                    <Skeleton variant="rectangular" height={40} />
                  </Box>
                ) : taskAttachments.partnerCommunication.length === 0 ? (
                  <Box p={2}>
                    <Typography variant="body2" sx={{ color: "rgba(0, 0, 0, 0.60)" }}>
                      No Documents are available
                    </Typography>
                  </Box>
                ) : (
                  <List sx={{ padding: "0 16px" }}>
                    {taskAttachments.partnerCommunication.map((item) => {
                      return (
                        <ListItem
                          key={item.category}
                          secondaryAction={
                            <IconButton
                              disabled={item.count === 0 || downloadingAttachmentId !== null}
                              edge="end"
                              onClick={() => {
                                if (serviceType === NAKAD_SERVICE.Recon) {
                                  uiLogger(
                                    uiLoggerNamesWorkflow.UI_WF_DB_ACTIONS_VIEWATTACHMENTS_DOWNLOAD_CLICK.functionName,
                                    companyId,
                                    branchCode,
                                    {
                                      message:
                                        uiLoggerNamesWorkflow.UI_WF_DB_ACTIONS_VIEWATTACHMENTS_DOWNLOAD_CLICK.message,
                                      idOfTicket: ticketId,
                                    }
                                  );
                                }
                                downloadAttachment(AttachmentType.PartnerCommunication, item.category);
                              }}
                            >
                              {downloadingAttachmentId &&
                              downloadingAttachmentId === item.category + AttachmentType.PartnerCommunication ? (
                                <LoadingIcon loading={true} />
                              ) : (
                                <img src={download_icon} alt="download icon" />
                              )}
                            </IconButton>
                          }
                        >
                          <ListItemText primary={item.category} secondary={`${item.count} Files`} />
                        </ListItem>
                      );
                    })}
                  </List>
                )}
              </Box>
              <Box sx={{ minHeight: "270px" }}>
                <Box sx={{ padding: "8px 16px", background: "#F1F1F1", border: "1px solid #C4C4C4" }}>
                  <Typography variant="subtitle2">Reconciliation Documents</Typography>
                </Box>
                {isFetchingAttachments ? (
                  <Box sx={{ display: "flex", flexDirection: "column", gap: 4, padding: "16px" }}>
                    <Skeleton variant="rectangular" height={40} />
                    <Skeleton variant="rectangular" height={40} />
                    <Skeleton variant="rectangular" height={40} />
                  </Box>
                ) : taskAttachments.recon.length === 0 ? (
                  <Box p={2}>
                    <Typography variant="body2" sx={{ color: "rgba(0, 0, 0, 0.60)" }}>
                      Documents will be available once this task gets saved or closed
                    </Typography>
                  </Box>
                ) : (
                  <List sx={{ padding: "0 16px" }}>
                    {taskAttachments.recon.map((item) => {
                      return (
                        <ListItem
                          key={item.category}
                          secondaryAction={
                            <IconButton
                              disabled={item.count === 0 || downloadingAttachmentId !== null}
                              edge="end"
                              onClick={() => {
                                downloadAttachment(AttachmentType.ReconDocs, item.category);
                              }}
                            >
                              {downloadingAttachmentId &&
                              downloadingAttachmentId === item.category + AttachmentType.ReconDocs ? (
                                <LoadingIcon loading={true} />
                              ) : (
                                <img src={download_icon} alt="download icon" />
                              )}
                            </IconButton>
                          }
                        >
                          <ListItemText primary={item.category} secondary={`${item.count} Files`} />
                        </ListItem>
                      );
                    })}
                  </List>
                )}
              </Box>
            </Box>
          </TabPanel>
          <TabPanel value={tabValue} index={1} key={1} sx={{ padding: 0 }}>
            {isFetchingAttachments ? (
              <Box sx={{ display: "flex", flexDirection: "column", gap: 4, padding: "16px", height: "500px" }}>
                <Skeleton variant="rectangular" height={30} />
                <Skeleton variant="rectangular" height={30} />
                <Skeleton variant="rectangular" height={30} />
                <Skeleton variant="rectangular" height={30} />
                <Skeleton variant="rectangular" height={30} />
              </Box>
            ) : taskAttachments.custom.length === 0 ? (
              <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "500px" }}>
                <Typography variant="body2" sx={{ color: "rgba(0, 0, 0, 0.60)" }}>
                  Click on Upload Document below to upload any custom document
                </Typography>
              </Box>
            ) : (
              <List sx={{ padding: 2, height: "500px", overflow: "scroll" }}>
                <div
                  onClick={(event) => {
                    const target = event.target as HTMLElement;
                    // Check if the clicked element or its closest parent is a button (for IconButton clicks)
                    const button = target.closest("button"); // Find the closest button (IconButton)
                    if (button && button.classList.contains("delete-task-attachment")) {
                      const id = button.getAttribute("id");
                      if (id) {
                        const fileName = button.getAttribute("id");
                        if (fileName) {
                          setShowDeleteTaskAttachmentModal(true);
                          deleteAttachmentName.current = fileName;
                        }
                      }
                    } else if (button && button.classList.contains("download-task-attachment")) {
                      const fileName = button.getAttribute("id");
                      if (fileName) {
                        downloadAttachment(AttachmentType.Custom, fileName);
                      }
                    }
                  }}
                >
                  {taskAttachments.custom.map((item) => {
                    return (
                      <ListItem
                        key={item}
                        secondaryAction={
                          <Box sx={{ display: "flex", gap: 2 }}>
                            <IconButton edge="end" id={item} className="delete-task-attachment">
                              <DeleteIcon />
                            </IconButton>
                            <IconButton
                              edge="end"
                              id={item}
                              className="download-task-attachment"
                              disabled={downloadingAttachmentId !== null}
                            >
                              {downloadingAttachmentId && downloadingAttachmentId === item + AttachmentType.Custom ? (
                                <LoadingIcon loading={true} />
                              ) : (
                                <img src={download_icon} alt="download icon" />
                              )}
                            </IconButton>
                          </Box>
                        }
                      >
                        <ListItemIcon>
                          <UploadFileIcon color="primary" />
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            <div
                              style={{ width: "400px" }}
                              ref={(th) => {
                                if (th && isOverFlowing(th)) th.title = th.innerText;
                              }}
                              className="textOverflow_hidden"
                            >
                              {item}
                            </div>
                          }
                        />
                      </ListItem>
                    );
                  })}
                </div>
              </List>
            )}
            <Box sx={{ height: "70px" }}>
              <input
                ref={fileUploader}
                name="taskAttachmentsUploader"
                type="file"
                multiple={true}
                hidden
                onClick={(_e) => ((_e.target as HTMLInputElement).value = null)}
                onChange={(e) => {
                  const currentSelectedFiles = Array.from(e.target.files) || [];
                  uploadCustomAttachment(currentSelectedFiles);
                }}
              />
              <Button
                variant="contained"
                endIcon={
                  isUploadingCustomAttachments ? <LoadingIcon loading={isUploadingCustomAttachments} /> : <AddIcon />
                }
                disabled={isUploadingCustomAttachments}
                sx={{
                  position: "absolute",
                  bottom: "16px",
                  right: "16px",
                  padding: "8px 22px",
                  color: "#FFF",
                  borderRadius: "100px !important",
                  fontWeight: 500,
                  fontSize: "14px",
                }}
                onClick={() => {
                  if (serviceType === "taskBoard") {
                    uiLogger(
                      uiLoggerNamesTaskBoard.UI_WF_TB_ACTIONS_VIEWATTACHMENTS_UPLOAD_CLICK.functionName,
                      companyId,
                      branchCode,
                      {
                        message: uiLoggerNamesTaskBoard.UI_WF_TB_ACTIONS_VIEWATTACHMENTS_UPLOAD_CLICK.message,
                        idOfTicket: ticketId,
                      }
                    );
                  } else {
                    uiLogger(
                      uiLoggerNamesWorkflow.UI_WF_DB_ACTIONS_VIEWATTACHMENTS_UPLOAD_CLICK.functionName,
                      companyId,
                      branchCode,
                      {
                        message: uiLoggerNamesWorkflow.UI_WF_DB_ACTIONS_VIEWATTACHMENTS_UPLOAD_CLICK.message,
                        idOfTicket: ticketId,
                      }
                    );
                  }
                  fileUploader.current && fileUploader.current.click();
                }}
              >
                UPLOAD DOCUMENT
              </Button>
            </Box>
          </TabPanel>
        </Box>
      </Dialog>
      <DeleteTaskAttachmentModal
        open={showDeleteTaskAttachmentModal}
        setOpen={setShowDeleteTaskAttachmentModal}
        deleteCustomAttachment={deleteCustomAttachment}
        isDeletingAttachment={isDeletingAttachment}
      />
    </>
  );
}
