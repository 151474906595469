import CloseIcon from "@mui/icons-material/Close";
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  IconButton,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import LoadingIcon from "src/Components/Common/LoadingIcon";
import useFetch from "src/Components/Common/useFetch";

export default function DeleteStatusConfirmationModal({
  open,
  setOpen,
  statusId,
  getAllStatuses,
  statusName,
}: {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  statusId: number;
  getAllStatuses: () => void;
  statusName: string;
}) {
  const [isDeletingReport, setIsDeletingReport] = useState<boolean>(false);
  const handleDeleteReport = () => {
    setIsDeletingReport(true);
    useFetch(`/api/Workflow/Status/${statusId}`, "DELETE", {
      thenCallBack: () => {
        setIsDeletingReport(false);
        setOpen(false);
        getAllStatuses();
      },
      catchCallBack: () => {
        setIsDeletingReport(false);
        setOpen(false);
      },
    });
  };
  return (
    <Dialog open={open} sx={{ "& .MuiDialog-paper": { borderRadius: "4px", minWidth: "600px" } }}>
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: 1 }}>
        <Typography variant="h6">Are you sure?</Typography>
        <IconButton onClick={() => setOpen(false)}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Divider />
      <DialogContent>
        <Alert severity="error">{statusName} will be deleted and cannot be recovered</Alert>
      </DialogContent>
      <DialogActions sx={{ padding: 1, background: "#F1F1F1" }}>
        <Button
          variant="text"
          onClick={() => {
            setOpen(false);
          }}
        >
          Cancel
        </Button>
        <Button
          disabled={isDeletingReport}
          variant="contained"
          color="error"
          sx={{ borderRadius: "4px !important" }}
          onClick={() => {
            handleDeleteReport();
          }}
        >
          {isDeletingReport && <LoadingIcon loading={true} />}
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
}
