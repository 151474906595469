import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Stack, Typography } from "@mui/material";
import React, { useContext, useState } from "react";
import LoadingIcon from "src/Components/Common/LoadingIcon";
import useFetch from "src/Components/Common/useFetch";
import { userContext } from "src/Components/Contexts/userContext";
import { UserRole, UserTypes } from "src/entity/models/FrontendActor";
import { isOverFlowing } from "src/Utils/Common/CheckTextOverflow";
import { uiLoggerNamesTaskBoard } from "src/Utils/Recon/UiLogger/Constants";
import uiLogger from "src/Utils/UiLogger";
import { NdButton } from "../../PartnerCommunication/MsmePartnerPortal/CommonComponents";
import { TASK_PHASES } from "../Interfaces";
import InvoicingTaskComponent from "./InvoicingTaskComponent";

export default function InvoicingSingleTaskModal({
  open,
  setOpen,
  taskInvoicingDetails,
  listAllTasks,
  companyId,
  branchCode,
}) {
  const { actor } = useContext(userContext);

  const [isPCChecked, setIsPCChecked] = useState(taskInvoicingDetails.pcInvoiceDate ? true : false);
  const [isReconChecked, setIsReconChecked] = useState(taskInvoicingDetails.reconInvoiceDate ? true : false);
  const [selectedPCDate, setSelectedPCDate] = useState<Date | null>(
    taskInvoicingDetails.pcInvoiceDate ? new Date(taskInvoicingDetails.pcInvoiceDate) : null
  );
  const [selectedPCDateErrorText, setSelectedPCDateErrorText] = useState("");
  const [selectedReconDate, setSelectedReconDate] = useState<Date | null>(
    taskInvoicingDetails.reconInvoiceDate ? new Date(taskInvoicingDetails.reconInvoiceDate) : null
  );
  const [selectedReconDateErrorText, setSelectedReconDateErrorText] = useState("");
  const [isUpdatingInvoiceDetails, setIsUpdatingInvoiceDetails] = useState<boolean>(false);

  let isSaveDisabled = false;
  if (taskInvoicingDetails.taskPhase === TASK_PHASES.recon) {
    isSaveDisabled = isReconChecked && (selectedReconDate === null || selectedReconDateErrorText !== "");
  } else if (taskInvoicingDetails.taskPhase === TASK_PHASES.partnerCommunication) {
    isSaveDisabled = isPCChecked && (selectedPCDate === null || selectedPCDateErrorText !== "");
  } else {
    isSaveDisabled =
      (isPCChecked && (selectedPCDate === null || selectedPCDateErrorText !== "")) ||
      (isReconChecked && (selectedReconDate === null || selectedReconDateErrorText !== ""));
  }
  const updateInvoicingDetails = () => {
    uiLogger(uiLoggerNamesTaskBoard.UI_WF_TB_ACTIONS_INVOICETASK_SAVE_CLICK.functionName, companyId, branchCode, {
      message: uiLoggerNamesTaskBoard.UI_WF_TB_ACTIONS_INVOICETASK_SAVE_CLICK.message,
    });
    setIsUpdatingInvoiceDetails(true);
    const payloadObj = {
      invoiceStatus: taskInvoicingDetails.invoiceStatus,
      taskId: taskInvoicingDetails.taskId,
    };

    if (taskInvoicingDetails.taskPhase === TASK_PHASES.recon) {
      payloadObj["reconInvoicingDate"] = isReconChecked ? selectedReconDate : null;
      payloadObj["isReconInvoiced"] = taskInvoicingDetails.isReconInvoiced;
      payloadObj["isReconInvoicing"] = isReconChecked;
    } else if (taskInvoicingDetails.taskPhase === TASK_PHASES.partnerCommunication) {
      payloadObj["pcInvoicingDate"] = isPCChecked ? selectedPCDate : null;
      payloadObj["isPcInvoiced"] = taskInvoicingDetails.isPcInvoiced;
      payloadObj["isPcInvoicing"] = isPCChecked;
    } else {
      payloadObj["pcInvoicingDate"] = isPCChecked ? selectedPCDate : null;
      payloadObj["isPcInvoiced"] = taskInvoicingDetails.isPcInvoiced;
      payloadObj["isPcInvoicing"] = isPCChecked;
      payloadObj["reconInvoicingDate"] = isReconChecked ? selectedReconDate : null;
      payloadObj["isReconInvoiced"] = taskInvoicingDetails.isReconInvoiced;
      payloadObj["isReconInvoicing"] = isReconChecked;
      if (actor.userType === UserTypes.CustomerSuccess && actor.userRole === UserRole.CSM) {
        if (taskInvoicingDetails.isPcInvoiced) {
          delete payloadObj["pcInvoicingDate"];
          delete payloadObj["isPcInvoicing"];
        } else if (taskInvoicingDetails.isReconInvoiced) {
          delete payloadObj["reconInvoicingDate"];
          delete payloadObj["isReconInvoicing"];
        }
      }
    }
    useFetch("/api/tickets/UpdateInvoiceDetailsForSingleTask", "POST", {
      showSuccessToast: true,
      data: payloadObj,
      thenCallBack: () => {
        setOpen(false);
        setIsUpdatingInvoiceDetails(false);
        listAllTasks();
      },
      catchCallBack: () => {
        setOpen(false);
        setIsUpdatingInvoiceDetails(false);
      },
    });
  };

  return (
    <Dialog open={open} sx={{ "& .MuiDialog-paper": { borderRadius: "4px", minWidth: "600px" } }}>
      <DialogTitle>
        <Typography
          ref={(th) => {
            if (th && isOverFlowing(th)) th.title = th.innerText;
          }}
          className="textOverflow_hidden"
          variant="h6"
        >
          Invoice Tasks for {taskInvoicingDetails.businessPartnerName}
        </Typography>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Stack gap={2}>
          {actor.userType === UserTypes.CustomerSuccess &&
          actor.userRole === UserRole.CSM &&
          taskInvoicingDetails.isPcInvoiced
            ? null
            : taskInvoicingDetails.taskPhase !== TASK_PHASES.recon && (
                <InvoicingTaskComponent
                  key="pcInvoiced"
                  title={"Mark PC task as ready to invoice"}
                  isChecked={isPCChecked}
                  setIsChecked={setIsPCChecked}
                  selectedDate={selectedPCDate}
                  setSelectedDate={setSelectedPCDate}
                  selectedDateErrorText={selectedPCDateErrorText}
                  setSelectedDateErrorText={setSelectedPCDateErrorText}
                />
              )}
          {actor.userType === UserTypes.CustomerSuccess &&
          actor.userRole === UserRole.CSM &&
          taskInvoicingDetails.isReconInvoiced
            ? null
            : taskInvoicingDetails.taskPhase !== TASK_PHASES.partnerCommunication && (
                <InvoicingTaskComponent
                  key="reconInvoiced"
                  title={"Mark Recon task as ready to invoice"}
                  isChecked={isReconChecked}
                  setIsChecked={setIsReconChecked}
                  selectedDate={selectedReconDate}
                  setSelectedDate={setSelectedReconDate}
                  selectedDateErrorText={selectedReconDateErrorText}
                  setSelectedDateErrorText={setSelectedReconDateErrorText}
                />
              )}
        </Stack>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button
          variant="text"
          onClick={() => {
            setOpen(false);
          }}
        >
          Cancel
        </Button>
        <NdButton variant="contained" disabled={isSaveDisabled} onClick={updateInvoicingDetails}>
          {isUpdatingInvoiceDetails && <LoadingIcon loading={true} />}
          Save
        </NdButton>
      </DialogActions>
    </Dialog>
  );
}
