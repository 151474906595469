import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { Box, List, ListItem, ListItemSecondaryAction, ListItemText, Switch, Typography } from "@mui/material";
import React from "react";
import { STATUS_REPORT_COLUMNS } from "./Interfaces";

export default function ColumnVisibiltySection({ columnsVisibilty, handleColumnsVisibiltyToggle }) {
  return (
    <Box
      sx={{
        padding: 2,
        display: "flex",
        flexDirection: "column",
        gap: 2,
        width: "100%",
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
        <RemoveRedEyeIcon color="primary" />
        <Typography
          variant="subtitle2"
          sx={{
            fontWeight: 500,
            fontSize: "14px",
            letterSpacing: "0.1px",
            lineHeight: "157%",
            color: "#000000de",
          }}
        >
          Column Visibility
        </Typography>
      </Box>
      <List>
        {columnsVisibilty.map((column) => (
          <ListItem key={column.key}>
            <ListItemText primary={column.label} sx={{ marginRight: 2 }} />
            <ListItemSecondaryAction>
              <Switch
                disabled={column.label === STATUS_REPORT_COLUMNS.numberOfPartners}
                edge="end"
                onChange={() => {
                  handleColumnsVisibiltyToggle(column.key, column.isVisible);
                }}
                checked={column.isVisible}
              />
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>
    </Box>
  );
}
