import { Alert, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import React, { useContext } from "react";
import LoadingIcon from "src/Components/Common/LoadingIcon";
import { ManualReconContext } from "./ManualRecon";

const DeleteAllMatches = () => {
  const { openDeleteAllMatchesModal, setOpenDeleteAllMatchesModal, loadingDeleteAllMatches, deleteNotAppliedHistory } =
    useContext(ManualReconContext);
  const handleCloseDeleteAllMatches = () => {
    setOpenDeleteAllMatchesModal(false);
  };
  return (
    <>
      <Dialog
        open={openDeleteAllMatchesModal}
        onClose={handleCloseDeleteAllMatches}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Are you sure you want to delete all matches?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Alert severity="error" style={{ fontSize: "14px" }}>
              All the matches in the table will be deleted and you cannot undo this action
            </Alert>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseDeleteAllMatches}
            variant="text"
            style={{ color: "#000" }}
            disabled={loadingDeleteAllMatches}
          >
            cancel
          </Button>
          <Button
            onClick={deleteNotAppliedHistory}
            variant="contained"
            color="error"
            style={{ borderRadius: "4px" }}
            startIcon={<LoadingIcon loading={loadingDeleteAllMatches} />}
            disabled={loadingDeleteAllMatches}
          >
            delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DeleteAllMatches;
