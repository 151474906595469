import { Close } from "@mui/icons-material";
import { Box, Chip, IconButton, Menu, MenuItem, Stack, Typography } from "@mui/material";
import React, { useContext, useState } from "react";
import styles from "./FilterAutocomplete.module.scss";
import { dateRangeColumns, numberRangeColumns, TASK_DETAILS_COLUMNS_UI_MAPPING } from "./Interfaces";
import { TaskTrackerContext } from "./TaskTracker";

export default function FilterAutocomplete({ filterName, filteredValues }) {
  const { setColumnFilters, appliedFilters, setAppliedFilters, listAllTasks, appliedFiltersRef } =
    useContext(TaskTrackerContext);
  const isDateRangeFilter = dateRangeColumns.includes(filterName);
  const isNumberRangeFilter = numberRangeColumns.includes(filterName);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    if ((event.target as HTMLElement).id !== "close-button") {
      setAnchorEl(event.currentTarget);
    }
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <Box className={styles.container}>
      <Typography variant="caption" className={styles.caption}>
        {TASK_DETAILS_COLUMNS_UI_MAPPING[filterName]}
      </Typography>
      <Box className={styles.autocomplete} onClick={handleMenuOpen}>
        {isDateRangeFilter || isNumberRangeFilter ? (
          <Stack direction="row" gap={1}>
            {filteredValues[0] && (
              <Chip
                size="small"
                label={
                  <span className="fs_13">
                    <span className="fs_13 fw_900">{isDateRangeFilter ? "Start:" : "Min:"}</span> {filteredValues[0]}
                  </span>
                }
              />
            )}
            {filteredValues[1] && (
              <Chip
                size="small"
                label={
                  <span className="fs_13">
                    <span className="fs_13 fw_900">{isDateRangeFilter ? "End:" : "Max:"}</span> {filteredValues[1]}
                  </span>
                }
              />
            )}
          </Stack>
        ) : (
          <Stack direction="row" gap={1} alignItems={"center"}>
            <Chip
              sx={{
                ".MuiChip-label": {
                  fontSize: "13px",
                },
              }}
              size="small"
              label={filteredValues.length > 0 ? (filteredValues[0] === "-" ? "None" : filteredValues[0]) : ""}
            />
            {filteredValues.length > 1 && (
              <Typography variant="body2" className={styles.more}>
                +{filteredValues.length - 1}
              </Typography>
            )}
          </Stack>
        )}
        <IconButton
          size="small"
          id="close-button"
          onClick={(e) => {
            e.stopPropagation();
            setColumnFilters((prev) => {
              return prev.filter((filter) => filter.id !== filterName);
            });
            const latestFilters = { ...appliedFilters };
            if (Object.prototype.hasOwnProperty.call(latestFilters, filterName)) {
              delete latestFilters[filterName];
            }
            setAppliedFilters(latestFilters);
            appliedFiltersRef.current = latestFilters;
            if (filterName === "taskId" || filterName === "businessPartnerName" || filterName === "vendorCode") {
              listAllTasks();
            }
          }}
        >
          <Close className={styles.icon} />
        </IconButton>
      </Box>
      {!(isDateRangeFilter || isNumberRangeFilter) && (
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            style: {
              minWidth: anchorEl ? anchorEl.clientWidth : "auto",
            },
          }}
        >
          {filteredValues.map((value, index) => (
            <MenuItem key={index} onClick={handleClose} className={styles.menuItem}>
              {value === "-" ? "None" : value}
            </MenuItem>
          ))}
        </Menu>
      )}
    </Box>
  );
}
