import CloseIcon from "@mui/icons-material/Close";
import { Box, Button, Dialog, DialogActions, DialogContent, Divider, IconButton, Typography } from "@mui/material";
import React, { useContext, useState } from "react";
import { uiLoggerNamesTaskBoard } from "src/Utils/Recon/UiLogger/Constants";
import uiLogger from "src/Utils/UiLogger";
import { NdButton } from "../PartnerCommunication/MsmePartnerPortal/CommonComponents";
import { TagsInputUniqueKeys } from "../ReconMIS/ClientReporting/AutoCompletes";
import { TaskTrackerContext } from "./TaskTracker";

export default function TaskTrackerFilter({
  open,
  setOpen,
  listAllTasks,
  taskIdsOptions,
  partnerOptions,
  vendorCodeOptions,
  companyId,
  branchCode,
}) {
  const { appliedFiltersRef, setAppliedFilters, appliedFilters } = useContext(TaskTrackerContext);
  const [partnerNames, setPartnerNames] = useState<string[]>(appliedFilters.businessPartnerName || []);
  const [vendorCodes, setVendorCodes] = useState<string[]>(appliedFilters.vendorCode || []);
  const [taskIds, setTaskIds] = useState<string[]>(appliedFilters.taskId || []);

  const handleApplyFilter = () => {
    const filtersObj = {};
    if (partnerNames.length > 0) filtersObj["businessPartnerName"] = partnerNames;
    if (vendorCodes.length > 0) filtersObj["vendorCode"] = vendorCodes;
    if (taskIds.length > 0) filtersObj["taskId"] = taskIds;
    setAppliedFilters(filtersObj);
    appliedFiltersRef.current = filtersObj;
    listAllTasks();
    setOpen(false);
  };

  const handleClearFilters = () => {
    setAppliedFilters({});
    appliedFiltersRef.current = {};
    setPartnerNames([]);
    setVendorCodes([]);
    setTaskIds([]);
    setOpen(false);
    listAllTasks();
  };

  return (
    <Dialog open={open} sx={{ "& .MuiDialog-paper": { borderRadius: "4px", minWidth: "600px" } }}>
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: "16px 24px" }}>
        <Typography variant="h6" sx={{ flexGrow: 1 }}>
          Apply Filter
        </Typography>
        <IconButton
          aria-label="close"
          onClick={() => {
            setOpen(false);
          }}
          sx={{ position: "absolute", right: 8, top: 8 }}
        >
          <CloseIcon />
        </IconButton>
      </Box>
      <Divider />
      <DialogContent>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "8px 16px",
            }}
          >
            <Typography variant="subtitle2">Task ID</Typography>
            <Box sx={{ width: "400px" }}>
              <TagsInputUniqueKeys
                tags={taskIds}
                setTags={setTaskIds}
                className="partnerNamesAutocomplete"
                fullWidth={true}
                label=""
                placeholder="Newline separated Task IDs"
                splitter="newline"
                limitTags={2}
                textFieldProps={{ multiline: true, maxRows: 4 }}
                options={taskIdsOptions}
                accessor={"taskId"}
                uniqueKeyAccessor={"taskId"}
              />
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "8px 16px",
            }}
          >
            <Typography variant="subtitle2">Partner Name</Typography>
            <Box sx={{ width: "400px" }}>
              <TagsInputUniqueKeys
                tags={partnerNames}
                setTags={setPartnerNames}
                className="partnerNamesAutocomplete"
                fullWidth={true}
                label=""
                placeholder="Newline separated Names"
                splitter="newline"
                limitTags={1}
                textFieldProps={{ multiline: true, maxRows: 4 }}
                options={partnerOptions}
                accessor={"partnerName"}
                uniqueKeyAccessor={"partnerName"}
              />
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "8px 16px",
            }}
          >
            <Typography variant="subtitle2">Vendor Code</Typography>
            <Box sx={{ width: "400px" }}>
              <TagsInputUniqueKeys
                tags={vendorCodes}
                setTags={setVendorCodes}
                className="partnerNamesAutocomplete"
                fullWidth={true}
                label=""
                placeholder="Newline separated Vendor Codes"
                splitter="newline"
                limitTags={1}
                textFieldProps={{ multiline: true, maxRows: 4 }}
                options={vendorCodeOptions}
                accessor={"vendorCode"}
                uniqueKeyAccessor={"vendorCode"}
              />
            </Box>
          </Box>
        </Box>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button
          onClick={() => {
            handleClearFilters();
          }}
          sx={{ color: "#00000099" }}
        >
          Clear Filter
        </Button>
        <NdButton
          variant="contained"
          onClick={() => {
            uiLogger(uiLoggerNamesTaskBoard.UI_WF_TB_FILTER_APPLY_CLICK.functionName, companyId, branchCode, {
              message: uiLoggerNamesTaskBoard.UI_WF_TB_FILTER_APPLY_CLICK.message,
            });
            handleApplyFilter();
          }}
        >
          Apply
        </NdButton>
      </DialogActions>
    </Dialog>
  );
}
