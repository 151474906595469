import CloseIcon from "@mui/icons-material/Close";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, TextField } from "@mui/material";
import React, { useContext } from "react";
import LoadingIcon from "src/Components/Common/LoadingIcon";
import { ManualReconContext } from "./ManualRecon";

const UnreconcileConfirmWarningModal = () => {
  const {
    isOpenUnreconcileConfirmWarningModal,
    setIsOpenUnreconcileConfirmWarningModal,
    unReconcileEntriesOnMatchId,
    showLoadingIconUnreconcile,
    unreconcileRemarks,
    setUnreconcileRemarks,
  } = useContext(ManualReconContext);

  const handleRemarksChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUnreconcileRemarks(event.target.value);
  };

  return (
    <div>
      <Dialog
        open={isOpenUnreconcileConfirmWarningModal}
        aria-labelledby="UnreconcileConfirmWarningModal"
        maxWidth="md"
        fullWidth
        sx={{ width: "664px", margin: "auto" }}
      >
        <DialogTitle sx={{ m: 0, p: 2 }}>Are you sure you want to unreconcile?</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => {
            setIsOpenUnreconcileConfirmWarningModal(false);
            setUnreconcileRemarks("");
          }}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          {" "}
          <CloseIcon />
        </IconButton>
        <DialogContent dividers sx={{ p: 2 }}>
          <TextField
            className="update-custom-border-radius"
            fullWidth
            variant="outlined"
            multiline
            placeholder="Remarks (This will help us improve the tool)"
            value={unreconcileRemarks}
            onChange={handleRemarksChange}
          />
        </DialogContent>
        <DialogActions sx={{ p: 2 }} className="bg_footer">
          <div className="flex_end">
            <Button
              variant="contained"
              className="red_btn"
              disabled={showLoadingIconUnreconcile}
              onClick={() => {
                unReconcileEntriesOnMatchId();
              }}
              sx={{ marginRight: 1 }}
            >
              <LoadingIcon loading={showLoadingIconUnreconcile} />
              Unreconcile
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default UnreconcileConfirmWarningModal;
