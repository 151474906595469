import { Alert, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import React from "react";
import { NdButton } from "../../PartnerCommunication/MsmePartnerPortal/CommonComponents";
import { StateDispatch } from "../../Recon360";

interface DeselectInvalidTasksModalProps {
  open: boolean;
  setOpen: StateDispatch<boolean>;
  modalContent: any;
  deselectTasksModalTitle: string;
  totalTasksCount: number;
}
export default function DeselectInvalidTasksModal({
  open,
  setOpen,
  modalContent,
  totalTasksCount,
  deselectTasksModalTitle,
}: DeselectInvalidTasksModalProps) {
  return (
    <Dialog open={open} sx={{ "& .MuiDialog-paper": { borderRadius: "4px", minWidth: "600px" } }}>
      <DialogTitle>
        Unable to {deselectTasksModalTitle} {totalTasksCount} Tasks
      </DialogTitle>
      <DialogContent>
        <Alert severity="error">{modalContent}</Alert>
      </DialogContent>
      <DialogActions sx={{ padding: 2 }}>
        <NdButton
          variant="contained"
          onClick={() => {
            setOpen(false);
          }}
        >
          OKAY
        </NdButton>
      </DialogActions>
    </Dialog>
  );
}
