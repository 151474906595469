export type AdminTopBarType =
  | "Userlist"
  | "Recon Mapping"
  | "Sap Data"
  | "Ledger Data"
  | "Disbursment Account"
  | "Collection Account"
  | "Config Templates"
  | "Tally Voucher"
  | "Tally Ledger"
  | "CB Update"
  | "Unpaid Invoice"
  | "Payment Details"
  | "Register";

export type ReconTopBarType =
  | "Recon360"
  | "Recon MIS"
  | "Process Management"
  | "AP Recon Insights"
  | "AR Recon Insights"
  | "Payment Advice"
  | "Ledger"
  | "Recon Dashboard"
  | "Supplier Chain Dashboard"
  | "Partner Communication"
  | "Partner Master"
  | "Recon Settings"
  // tier1
  | "Invoice Approval"
  | "Entry Adjustment"
  | "Audit Trail"
  | "Upload"
  | "Settings"
  | "Recon360"
  | "Supplier Chain Dashboard"
  | "Recon Dashboard"
  | "Partner Communication"
  | "Recon Settings";

export const AdminTopBar = {
  Userlist: "Userlist",
  ReconMapping: "Recon Mapping",
  SapData: "Sap Data",
  LedgerData: "Ledger Data",
  DisbursmentAccount: "Disbursment Account",
  CollectionAccount: "Collection Account",
  ConfigTemplates: "Config Templates",
  TallyVoucher: "Tally Voucher",
  TallyLedger: "Tally Ledger",
  CBUpdate: "CB Update",
  UnpaidInvoice: "Unpaid Invoice",
  PaymentDetails: "Payment Details",
  Register: "Register",
};

export const ReconTopBar = {
  Recon360: "Recon360",
  ReconMIS: "Recon MIS",
  ProcessManagement: "Process Management",
  APReconInsights: "AP Recon Insights",
  ARReconInsights: "AR Recon Insights",
  PaymentAdvice: "Payment Advice",
  Ledger: "Ledger",
  ReconDashboard: "Recon Dashboard",
  SupplierChainDashboard: "Supplier Chain Dashboard",
  PartnerCommunication: "Partner Communication",
  LedgerRequest: "Ledger Request",
  BalanceConfirmation: "Balance Confirmation",
  PartnerMaster: "Partner Master",
  ReconSettings: "Recon Settings",
};

export interface SubMenu {
  name: string;
  to: string;
  className?: string;
  icon?: JSX.Element;
  eventName?: string;
  show?: boolean;
}

export interface NavBarStructure {
  hasSubTab: boolean;
  name: string;
  to?: string;
  className?: string;
  subMenu?: SubMenu[];
  show?: boolean;
  icon?: JSX.Element;
  eventName?: string;
}

export enum BalanceConfirmationShownAccounts {
  AtherEnergy = 32941,
  Mindavast = 21289,
  "TATA PROJECT LTD" = 26966,
  "Pratham Patel" = 224620,
  newqatest = 221747,
  "SKH SMC" = 44781,
}

export enum LedgerRequestShownAccounts {
  "TATA PROJECT LTD" = 26966,
  "Blackberrys vendor" = 20983,
  "Instamart-2" = 715237,
  "Instamart-3" = 716263,
  "Instamart-4" = 722700,
  "Instamart-5" = 866519,
  "Pratham Patel" = 224620,
  "newqatest" = 221747,
  "APOLLO TYRES LTD" = 26883,
  "Apollo hospital" = 90641,
}
