import CloseIcon from "@mui/icons-material/Close";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import {
  Box,
  Dialog,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import React, { useContext } from "react";
import { toast } from "react-toastify";
import { userContext } from "src/Components/Contexts/userContext";
import CustomToast from "src/Components/CustomToast";
import { UserRole, UserTypes } from "src/entity/models/FrontendActor";
import { NAKAD_SERVICE } from "../../Tickets/TasksHistoryModal";

export default function SelectBulkInvoiceTypeModal({
  open,
  setOpen,
  setShowInvoiceBulkTasksModal,
  selectedTasks,
  setInvoiceType,
}) {
  const { actor } = useContext(userContext);
  const allTasksAreReconReady = selectedTasks.every((task) => task.service === NAKAD_SERVICE.Recon);
  const allTasksArePCReady = selectedTasks.every(
    (task) => task.service === NAKAD_SERVICE.PartnerCommunication || task.ticketCreatedInPC
  );
  const isAnyTaskAlreadyPcInvoiced = selectedTasks.some((task) => task.isPcInvoiced);
  const isAnyTaskAlreadyReconInvoiced = selectedTasks.some((task) => task.isReconInvoiced);

  return (
    <Dialog open={open} sx={{ "& .MuiDialog-paper": { borderRadius: "4px", minWidth: "600px" } }}>
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", paddingX: 2, paddingY: 1 }}>
        <Typography variant="h6">Invoice Tasks</Typography>
        <IconButton onClick={() => setOpen(false)}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Divider />
      <List sx={{ padding: "16px" }}>
        <ListItem disablePadding>
          <ListItemButton
            onClick={() => {
              if (
                actor.userType === UserTypes.CustomerSuccess &&
                actor.userRole === UserRole.CSM &&
                isAnyTaskAlreadyReconInvoiced
              ) {
                toast.error(
                  <CustomToast message="You have selected a few tasks which are already invoiced. You are restricted to only add Invoicing status and not edit it." />
                );
                setOpen(false);
                return;
              }
              if (allTasksAreReconReady) {
                setInvoiceType(NAKAD_SERVICE.Recon);
                setShowInvoiceBulkTasksModal(true);
              } else {
                toast.error(<CustomToast message="Please select Recon tasks to proceed" />);
                setOpen(false);
              }
            }}
          >
            <ListItemText primary="Mark Recon tasks as ready to invoice" />
            <ListItemIcon sx={{ position: "relative", left: "30px" }}>
              <KeyboardArrowRightIcon />
            </ListItemIcon>
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton
            onClick={() => {
              if (
                actor.userType === UserTypes.CustomerSuccess &&
                actor.userRole === UserRole.CSM &&
                isAnyTaskAlreadyPcInvoiced
              ) {
                toast.error(
                  <CustomToast message="You have selected a few tasks which are already invoiced. You are restricted to only add Invoicing status and not edit it." />
                );
                setOpen(false);
                return;
              }
              if (allTasksArePCReady) {
                setInvoiceType(NAKAD_SERVICE.PartnerCommunication);
                setShowInvoiceBulkTasksModal(true);
              } else {
                toast.error(<CustomToast message="Please select PC tasks to proceed" />);
                setOpen(false);
              }
            }}
          >
            <ListItemText primary="Mark PC tasks as ready to invoice" />
            <ListItemIcon sx={{ position: "relative", left: "30px" }}>
              <KeyboardArrowRightIcon />
            </ListItemIcon>
          </ListItemButton>
        </ListItem>
      </List>
    </Dialog>
  );
}
