import { Close, Download, FilterAlt, FilterAltOff, RateReview, ViewWeek } from "@mui/icons-material";
import { AttachFile, DeleteForever } from "@mui/icons-material";
import { Autocomplete, Avatar, Box, Button, Card, createFilterOptions, Paper } from "@mui/material";
import { Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormGroup, Popover } from "@mui/material";
import { FormLabel, Grid, IconButton, MenuItem, Select, TextField, Tooltip, Typography } from "@mui/material";
import { Column, ColumnDef, Row } from "@tanstack/react-table";
import { Buffer } from "buffer";
// import * as Excel from "exceljs";
import { saveAs } from "file-saver";
import moment from "moment";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router";
import { toast } from "react-toastify";
import FilterAppliedBar from "src/Components/Common/FilterAppliedBar";
import LoadingIcon from "src/Components/Common/LoadingIcon";
import CustomToast from "src/Components/CustomToast";
import { API_ENDPOINTS } from "src/Utils/ApiConstants/ApiUrlConstants";
import { ENV_NAME } from "src/Utils/Recon/ENV/Constants";
import { ZapScaleEventsConstants } from "src/Utils/ZapscaleEvents/Contsants";
import * as XLSX from "xlsx";
import {
  GetReconMisDataResponse,
  ListOwnTallyCompanies,
  ListOwnTallyCompaniesRes,
  // GetReconMisDataResponse,
  PartnerLevelData,
  ReconOverviewData,
  ReconOverviewDataObj,
  ReconOverviewFilters,
  TrackerData,
} from "../../../entity/recon-entity/ReconInterfaces";
import { AccountingSoftware } from "../../../Utils/Recon/Recon360/Constants";
import { MOM, QOQ, TRACKER_LEVEL, TRACKER_SUMMARY } from "../../../Utils/Recon/ReconMIS/Constants";
import IndeterminateCheckbox from "../../Admin/IndeterminateCheckbox";
import Loader from "../../Common/Loader";
import { LoggedInSkeleton } from "../../Common/LoggedInSkeleton";
import { getReconTopBarButtons } from "../../Common/TopNavBar";
import useFetch from "../../Common/useFetch";
import { userContext } from "../../Contexts/userContext";
import BpDashboardTable from "../../ReactTable/BpDashboardTable";
import { DEFAULT_BRANCH_CODE, DEFAULT_COMPANY_ID } from "../PartnerCommunication/CommonLegacy/CommonComponents";
import OverviewLevelFilter from "./OverviewLevelFilter";
import "./ReconMIS.scss";
import OverViewChart from "./ReconMISOverviewChart";
import TrackerCommentModal from "./TrackerCommentModal";
import TrackerLevelFilter from "./TrackerLevelFilter";

type ColDef<T = any> = ColumnDef<T> & {
  sticky?: "left" | "right";
};
const filterCategory = createFilterOptions<any>();

type SegmentType = typeof TRACKER_SUMMARY | typeof TRACKER_LEVEL;

const ReconMIS = () => {
  const { actor } = useContext(userContext);
  const location = useLocation<any>();
  const [selectedSegment, setSelectedSegment] = useState<SegmentType>(TRACKER_SUMMARY);

  const periodOptions = {
    // [DOD]: "DoD (Current + Last 7 days)",
    // [WOW]: "WoW (Current + Last 4 weeks)",
    [MOM]: "MoM (Current + Last 3 months)",
    [QOQ]: "QoQ (Current + Last 3 quarters)",
  };
  // const selectedMetricValue = useRef<string>("Balance Confirmation");
  const selectedPeriodValue = useRef<string>("MoM (Current + Last 3 months)");
  const [rowsDataTrackerLevel, setRowsDataTrackerLevel] = useState<any[]>([]);
  const storeTrackerLevelData = useRef([]);
  const [rowsDataOverview, setRowsDataOverview] = useState<ReconOverviewData[]>([]);
  // const [openTrackerLevelFilter, setOpenTrackerLevelFilter] = useState<boolean>(false);
  const storeAllCategories = useRef<any[]>([]);
  const [storeRowsDataTrackerLevel, setStoreRowsDataTrackerLevel] = useState<any[]>([]);
  const [isFilterAppliedTrackerLevelFilter, setIsFilterAppliedTrackerLevelFilter] = useState<boolean>(false);
  // const [downloadOverview, setDownloadOverview] = useState<any>(null);
  // const [downloadEntryLevel, setDownloadEntryLevel] = useState<any>(null);
  const [loader, setLoader] = useState<boolean>(true);
  const [openOverviewLevelFilter, setOpenOverviewLevelFilter] = useState<boolean>(false);
  const [isFilterAppliedOverviewLevelFilter, setIsFilterAppliedOverviewLevelFilter] = useState<boolean>(false);
  const filter = useRef<ReconOverviewFilters | "all">("all");
  const [showLoadingIcon, setShowLoadingIcon] = useState<boolean>(false);
  const [appliedFilters, setAppliedFilters] = useState<{ [key: string]: string }>({});
  const [openTrackerCommentModal, setOpenTrackerCommentModal] = useState<boolean>(false);
  const selectedRowTrackerComment = useRef(null);
  const [openTrackerLevelFilter, setOpenTrackerLevelFilter] = useState<boolean>(false);
  const [openColumnModal, setOpenColumnModal] = useState<boolean>(false);
  const [allLeafColumns, setAllLeafColumns] = useState<Column<any>[]>(null);
  const [userEmails, setUserEmails] = useState<string[]>([]);
  const [allCategories, setAllCategories] = useState<string[]>([]);
  const [openTargetModal, setOpenTargetModal] = useState(false);
  const [openTargetUploadErrorModal, setOpenTargetUploadErrorModal] = useState<boolean>(false);
  const [targetUploadErrorMsg, setTargetUploadErrorMsg] = useState<string>("");
  const [targetUploadExcelBase64, setTargetUploadExcelBase64] = useState();
  const companyId = useRef<string>(null);
  const [listOfOwnTallyCompanies, setListOfOwnTallyCompanies] = useState<ListOwnTallyCompanies[]>([]);
  const [lastOwnTallyCompany, setLastOwnTallyCompany] = useState<ListOwnTallyCompanies>(null);
  const [dataLoaded, setDataLoaded] = useState<{ track: boolean; summary: boolean }>({ track: false, summary: false });

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openMenu, setOpen] = useState(false);
  const [fileList, setFileList] = useState<{ name: string }[]>([]);
  const currentRow = useRef<Row<any>>(null);
  const fileUploader = useRef<HTMLInputElement>(null);

  const columnDefinitionTrackerLevel: ColDef<PartnerLevelData>[] = [
    {
      header: "Partner Name",
      accessorKey: "businessPartnerName",
      sticky: "left",
      size: 240,
      enableHiding: false,
      cell: ({ row }) => (
        <div className="textOverflow_hidden_left" style={{ paddingLeft: 8 }}>
          {row.original.businessPartnerName}
        </div>
      ),
    },
    {
      header: "Vendor Code",
      accessorKey: "vendorCode",
      size: 150,
      enableHiding: true,
      cell: ({ row }) => <div>{row.original.vendorCode ? row.original.vendorCode : "-"}</div>,
    },
    {
      header: "Activity Month",
      accessorKey: "activityMonth",
      size: 180,
      enableHiding: false,
    },
    {
      header: "Target Type",
      accessorKey: "targetType",
      size: 180,
    },
    {
      header: "Category",
      accessorFn: (row) => row.category?.[0]?.toLowerCase(),
      size: 200,
      cell: ({ row }) => (
        <div className="category_ui" key={row.original.businessPartnerId}>
          <Autocomplete
            className="category_autocomplete"
            openOnFocus={true}
            limitTags={1}
            value={row.original.category !== null && row.original.category?.length > 0 ? row.original.category : []}
            onChange={(_ev, val) => {
              let value = val as string[];
              if (value !== null && value?.length > 0) {
                if (value[value.length - 1].includes("+Add => ")) {
                  value[value.length - 1] = value[value.length - 1].replace('+Add => "', "");
                  value[value.length - 1] = value[value.length - 1].replace('"', "");
                  const removeEmptyVal = value.filter((el) => {
                    return el !== "";
                  });
                  value = removeEmptyVal;
                }
              }
              storeAllCategories.current = [...new Set([...storeAllCategories.current, ...value])];
              updateOwnAndBusinessPartnerMappingCategories(row, value);
            }}
            noOptionsText={"Enter a new category"}
            multiple={true}
            id="tags-outlined"
            options={storeAllCategories?.current}
            getOptionLabel={(option: any) => option}
            filterSelectedOptions={true}
            filterOptions={(_, params) => {
              const filtered = filterCategory(
                storeAllCategories?.current === null ? [] : storeAllCategories?.current,
                params
              );
              // Suggest the creation of a new value
              if (params.inputValue !== "") {
                filtered.push(`+Add => "${params.inputValue.trim()}"`);
              }
              return filtered;
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                // placeholder="Add"
                sx={{
                  "& input": {
                    px: "12px !important",
                  },
                }}
              />
            )}
            sx={{}}
          />
        </div>
      ),
    },
    {
      header: "User Email",
      accessorKey: "userEmailsConcatenated",
      size: 300,
    },
    {
      header: "Balance confirmation/Ledger request Date​",
      accessorKey: "balanceConfirmationLedgerRequestDate",
      enableSorting: true,
      size: 280,
      cell: ({ row }) => (
        <p>
          {row.original.balanceConfirmationLedgerRequestDate !== null &&
          row.original.balanceConfirmationLedgerRequestDate !== "Not Requested"
            ? moment(row.original.balanceConfirmationLedgerRequestDate)?.format("DD-MM-YYYY")
            : row.original.balanceConfirmationLedgerRequestDate}
        </p>
      ),
    },
    {
      header: "Reminder count in Month",
      accessorKey: "reminderCount",
      size: 160,
    },
    {
      header: "Days Past since last partner reply",
      accessorKey: "daysPastSinceLastPartnerReply",
      size: 220,
    },
    {
      header: `Days past since last mail by ${actor.name}`,
      accessorKey: "daysPastSinceLastMailByRU",
      size: 220,
    },
    {
      header: "Ledger received date",
      accessorKey: "ledgerReceivedDate",
      enableSorting: true,
      size: 180,
      cell: ({ row }) => (
        <p>
          {row.original.ledgerReceivedDate !== null && row.original.ledgerReceivedDate !== "Not Received"
            ? moment(row.original.ledgerReceivedDate)?.format("DD-MM-YYYY")
            : row.original.ledgerReceivedDate}
        </p>
      ),
    },
    {
      header: "Recon run on tool",
      accessorKey: "reconRunDate",
      enableSorting: true,
      size: 150,
      cell: ({ row }) => (
        <p>
          {row.original.reconRunDate !== null && row.original.reconRunDate !== "Not Run"
            ? moment(row.original.reconRunDate)?.format("DD-MM-YYYY")
            : row.original.reconRunDate}
        </p>
      ),
    },
    {
      header: "Generated Gap summary",
      accessorKey: "summaryGeneratedDate",
      enableSorting: true,
      size: 150,
      cell: ({ row }) => (
        <p>
          {row.original.summaryGeneratedDate !== null && row.original.summaryGeneratedDate !== "Not Generated"
            ? moment(row.original.summaryGeneratedDate)?.format("DD-MM-YYYY")
            : row.original.summaryGeneratedDate}
        </p>
      ),
    },
    {
      header: "Date of Recon sent to Partner",
      accessorKey: "reconSentToPartnerDate",
      enableSorting: true,
      size: 200,
      cell: ({ row }) => (
        <p>
          {row.original.reconSentToPartnerDate !== null && row.original.reconSentToPartnerDate !== "Not Sent"
            ? moment(row.original.reconSentToPartnerDate)?.format("DD-MM-YYYY")
            : row.original.reconSentToPartnerDate}
        </p>
      ),
    },
    {
      header: "Comments/ Remarks",
      accessorKey: "remarks",
      enableSorting: false,
      size: 80,
      cell: ({ row }) => (
        <div key={`${row.original.businessPartnerId}-${row.original.activityMonth}`} className="center_align_ver_horiz">
          <RateReview
            className="cursor_pointer"
            onClick={() => {
              selectedRowTrackerComment.current = row;
              setOpenTrackerCommentModal(true);
            }}
          />
        </div>
      ),
    },
    {
      header: "Status",
      accessorKey: "statusDone",
      enableSorting: false,
      size: 200,
      enableHiding: false,
      cell: ({ row }) => (
        <div key={`${row.original.businessPartnerId}-${row.original.activityMonth}`}>
          <FormControl>
            <Select
              labelId={`dropdown-label-${row.original.businessPartnerId}`}
              id={`dropdown-${row.original.businessPartnerId}`}
              value={row.original.statusDone ? "Done" : "Not done"}
              onChange={(e) => {
                updateStatus(row, e.target.value === "Done" ? true : false);
              }}
              size="small"
              sx={{ width: "150px", height: 30 }}
            >
              <MenuItem value="Not done">Not done</MenuItem>
              <MenuItem value="Done">Done</MenuItem>
            </Select>
          </FormControl>
        </div>
      ),
    },
    {
      header: "Your Files",
      accessorKey: "fileNames",
      enableSorting: false,
      size: 160,
      enableHiding: false,
      cell: ({ row }) => (
        <div className="d_flex" style={{ gap: 4, justifyContent: "flex-end" }} key={row.original.businessPartnerId}>
          <Button
            key={row.original.businessPartnerId + "_btn"}
            startIcon={<AttachFile />}
            variant="outlined"
            size="small"
            onClick={(ev) => {
              currentRow.current = row;
              setFileList((row.original.fileNames || [])?.map((_file) => ({ name: _file })));
              if (row.original?.fileNames?.length > 0) handleFileMenuClick(ev);
            }}
            sx={{ textTransform: "capitalize" }}
          >
            {row.original?.fileNames?.length || 0} Files
          </Button>
          <Button
            className="theme_btn"
            size="small"
            variant="contained"
            sx={{ fontSize: "13px !important" }}
            onClick={() => {
              currentRow.current = row;
              fileUploader.current?.click();
            }}
          >
            UPLOAD
          </Button>
        </div>
      ),
    },
    {
      header: "Download Result",
      accessorKey: "businessPartnerId",
      enableSorting: false,
      sticky: "right",
      size: 100,
      enableHiding: false,
      cell: ({ row }) => (
        <div key={`${row.original.businessPartnerId}-${row.original.activityMonth}`} className="center_align_ver_horiz">
          <Download
            className="cursor_pointer"
            onClick={() => {
              downloadResult(row);
            }}
            sx={{ fill: "#27b27c" }}
          />
        </div>
      ),
    },
  ];

  const handleFileMenuClick = (ev: React.MouseEvent<HTMLButtonElement>) => {
    let elementPositionObj: DOMRect = null;
    const getBoundingClientRect = () => {
      const newPosition = ((ev.target as HTMLElement) || ev.currentTarget).getBoundingClientRect();
      if (newPosition.x > 0) elementPositionObj = newPosition;
      return elementPositionObj;
    };

    setOpen(true);
    if (getBoundingClientRect() === null) setAnchorEl(ev.currentTarget);
    else setAnchorEl({ getBoundingClientRect, nodeType: 1 });
  };

  const replacePeriodValue = (input: string) => {
    return Object.entries(periodOptions).find((period) => period[1] === input)[0];
  };

  const selectPeriod = (e: any, value: any) => {
    if (value !== null) {
      e.persist();
      selectedPeriodValue.current = value;
      //   call api function
      getReconMis(selectedSegment);
    } else {
      selectedPeriodValue.current = null;
    }
  };
  const getMISPage = async () => {
    if (actor?.zsMonitorAccount && import.meta.env.VITE_APP_NAKAD_ENV === ENV_NAME.production && window.zapscale) {
      window.zapscale.trackEvent(ZapScaleEventsConstants.RECON_MIS_OPEN);
    }

    if (actor.integration && actor.actorInfo.accountingSoftware === AccountingSoftware.Tally) {
      await listOwnTallyCompanies();
    } else {
      await getReconMis(TRACKER_LEVEL);
      await getReconMis(TRACKER_SUMMARY);
    }
  };

  useEffect(() => {
    getMISPage();
  }, []);

  useEffect(() => {
    if (dataLoaded.track && dataLoaded.summary) {
      setLoader(false);
    }
  }, [dataLoaded]);

  const listOwnTallyCompanies = async () => {
    useFetch<ListOwnTallyCompaniesRes>(API_ENDPOINTS.LIST_OWN_TALLY_COMPANIES_FOR_RECON.url, "GET", {
      failureMessage: API_ENDPOINTS.LIST_OWN_TALLY_COMPANIES_FOR_RECON.failureMessage,
      thenCallBack: async (response) => {
        setListOfOwnTallyCompanies(response?.data?.tallyCompanies);
        const lastTallyCompID = response?.data?.lastSelectedTallyCompanyId;
        if (lastTallyCompID) {
          const foundTallyCompany = response.data.tallyCompanies.find((c) => c.companyId === lastTallyCompID);
          const foundLastOwnTallyCompany = {
            firstLetter: foundTallyCompany?.companyName[0].toUpperCase(),
            ...foundTallyCompany,
          };
          setLastOwnTallyCompany(foundLastOwnTallyCompany);
          await companyNameSelect({}, foundLastOwnTallyCompany);
        }
      },
    });
  };

  const companyNameSelect = async (e: any, option: ListOwnTallyCompanies) => {
    if (option !== null) {
      if (e?.persist) e.persist();
      companyId.current = option.companyId;
      setLastOwnTallyCompany(option);
      await getReconMis(TRACKER_LEVEL);
      await getReconMis(TRACKER_SUMMARY);
    } else if (option === null) {
      companyId.current = null;
    }
  };

  const optionsListOwnTallyCompanies = listOfOwnTallyCompanies?.map((option) => {
    const firstLetter = option.companyName[0]?.toUpperCase();
    return {
      firstLetter,
      ...option,
    };
  });

  const getReconMis = async (selectedSegmentName: SegmentType) => {
    const endpoint =
      selectedSegmentName === TRACKER_LEVEL
        ? API_ENDPOINTS.GET_CLOSURE_TRACKER_DATA.url
        : API_ENDPOINTS.GET_CLOSURE_TRACKER_OVERVIEW.url;

    await useFetch<GetReconMisDataResponse>(endpoint, "POST", {
      failureMessage:
        selectedSegmentName === TRACKER_LEVEL
          ? API_ENDPOINTS.GET_CLOSURE_TRACKER_DATA.failureMessage
          : API_ENDPOINTS.GET_CLOSURE_TRACKER_OVERVIEW.failureMessage,
      data: {
        viewLevel: selectedSegmentName,
        // tableId: selectedMetricValue.current,
        dateRange: replacePeriodValue(selectedPeriodValue.current),
        filters: filter.current,
        companyId: companyId.current,
      },
      thenCallBack: (response) => {
        if (selectedSegmentName === TRACKER_SUMMARY) {
          // const processed = processOverViewData(response.data?.dataObj as unknown as ReconOverviewData[] as any);
          if ((response.data?.dataObj as ReconOverviewDataObj)?.summaryData)
            setRowsDataOverview((response.data?.dataObj as ReconOverviewDataObj)?.summaryData);
          setUserEmails((response.data?.dataObj as ReconOverviewDataObj)?.allUserEmails || []);
          setAllCategories((response.data?.dataObj as ReconOverviewDataObj)?.allCategories || []);
          setDataLoaded((old) => ({ ...old, track: true }));
        } else if (selectedSegmentName === TRACKER_LEVEL) {
          // Sorting function
          const dataObj = (response.data.dataObj as TrackerData) || ({ data: [], allCategories: [] } as TrackerData);
          const sortedData = [...dataObj.data]?.sort((a, b) => {
            // Parse activityMonth strings into Date objects
            const dateA = new Date(a.activityMonth?.replace(/(\w+) (\d+)$/, "$2 $1"));
            const dateB = new Date(b.activityMonth?.replace(/(\w+) (\d+)$/, "$2 $1"));

            // Compare the dates
            if (dateA > dateB) return -1;
            if (dateA < dateB) return 1;

            // If dates are equal, compare businessPartnerName in ascending order
            return a.businessPartnerName?.localeCompare(b.businessPartnerName);
          });

          setRowsDataTrackerLevel(sortedData);
          setStoreRowsDataTrackerLevel(sortedData);
          storeTrackerLevelData.current = sortedData;
          storeAllCategories.current = dataObj.allCategories;
          setDataLoaded((old) => ({ ...old, summary: true }));
        }
      },
      catchCallBack: () => {
        setLoader(false);
      },
    });
  };

  const downloadExcelReconMIS = (fileBase64: WithImplicitCoercion<string>, fileName: string) => {
    const excelData = Buffer.from(fileBase64, "base64");
    const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    const blob = new Blob([excelData], { type: fileType });
    saveAs(blob, `${fileName}.xlsx`);
    setShowLoadingIcon(false);
  };

  const createSummaryDataExcel = async (summaryData: ReconOverviewData[]) => {
    const workbook = XLSX.utils.book_new();

    const rows = summaryData.map((row) => {
      const Entries = row.Entries;

      return {
        Section: row.Section,
        ...Entries,
      };
    });

    const worksheet = XLSX.utils.json_to_sheet(rows);

    XLSX.utils.book_append_sheet(workbook, worksheet, "Summary Data");

    XLSX.writeFile(workbook, `${actor.name}-${selectedPeriodValue.current}-SummaryData.xlsx`);
  };

  const createTrackerDataExcel = async (trackerData: PartnerLevelData[]) => {
    setShowLoadingIcon(true);
    const Excel = await import("exceljs");

    const reportWorkbook = new Excel.Workbook();
    reportWorkbook.addWorksheet("Tracker Level");
    const headerColumn = [
      "Partner Name",
      "Vendor Code",
      "Activity Month",
      "Category",
      "User Email",
      "Balance confirmation/Ledger request Date",
      "Reminder count",
      "Days Past since last partner reply",
      "Days past since last mail by own",
      "Ledger received date",
      "Recon run on tool",
      "Generated Gap summary",
      "Date of Recon sent to Partner",
      "Comments/ Remarks",
      "Status",
      "Location",
    ];

    for (let index = 1; index <= headerColumn.length; index++) {
      reportWorkbook.worksheets[0].getRow(1).getCell(index).value = headerColumn[index - 1];
    }

    for (let i = 0; i < trackerData.length; i++) {
      const row = trackerData[i];

      reportWorkbook.worksheets[0].getRow(i + 2).getCell(1).value = row.businessPartnerName;
      reportWorkbook.worksheets[0].getRow(i + 2).getCell(2).value = row.vendorCode;
      reportWorkbook.worksheets[0].getRow(i + 2).getCell(3).value = row.activityMonth;
      reportWorkbook.worksheets[0].getRow(i + 2).getCell(4).value = row.category?.join(",");
      reportWorkbook.worksheets[0].getRow(i + 2).getCell(5).value = row.userEmailsConcatenated;
      reportWorkbook.worksheets[0].getRow(i + 2).getCell(6).value =
        row.balanceConfirmationLedgerRequestDate !== null &&
        row.balanceConfirmationLedgerRequestDate !== "Not Requested"
          ? moment(row.balanceConfirmationLedgerRequestDate)?.format("DD-MM-YYYY")
          : row.balanceConfirmationLedgerRequestDate;
      reportWorkbook.worksheets[0].getRow(i + 2).getCell(7).value = row.reminderCount;
      reportWorkbook.worksheets[0].getRow(i + 2).getCell(8).value = row.daysPastSinceLastPartnerReply;
      reportWorkbook.worksheets[0].getRow(i + 2).getCell(9).value = row.daysPastSinceLastMailByRU;
      reportWorkbook.worksheets[0].getRow(i + 2).getCell(10).value =
        row.ledgerReceivedDate !== null && row.ledgerReceivedDate !== "Not Received"
          ? moment(row.ledgerReceivedDate)?.format("DD-MM-YYYY")
          : row.ledgerReceivedDate;
      reportWorkbook.worksheets[0].getRow(i + 2).getCell(11).value =
        row.reconRunDate !== null && row.reconRunDate !== "Not Run"
          ? moment(row.reconRunDate)?.format("DD-MM-YYYY")
          : row.reconRunDate;
      reportWorkbook.worksheets[0].getRow(i + 2).getCell(12).value =
        row.summaryGeneratedDate !== null && row.summaryGeneratedDate !== "Not Generated"
          ? moment(row.summaryGeneratedDate)?.format("DD-MM-YYYY")
          : row.summaryGeneratedDate;
      reportWorkbook.worksheets[0].getRow(i + 2).getCell(13).value =
        row.reconSentToPartnerDate !== null && row.reconSentToPartnerDate !== "Not Sent"
          ? moment(row.reconSentToPartnerDate)?.format("DD-MM-YYYY")
          : row.reconSentToPartnerDate;
      reportWorkbook.worksheets[0].getRow(i + 2).getCell(14).value = row.remarks;
      reportWorkbook.worksheets[0].getRow(i + 2).getCell(15).value = row.statusDone ? "Done" : "Not done";
      reportWorkbook.worksheets[0].getRow(i + 2).getCell(16).value = row.location;
    }

    const reportWorkbookBuffer: any = await reportWorkbook.xlsx.writeBuffer();
    const reportWorkbookBase64: string = reportWorkbookBuffer.toString("base64");

    return downloadExcelReconMIS(reportWorkbookBase64, TRACKER_LEVEL);
  };

  const updateOwnAndBusinessPartnerMappingCategories = async (row: any, value: any) => {
    await useFetch(API_ENDPOINTS.UPDATE_OWN_AND_BUSINESS_PARTNER_MAPPING_CATEGORIES.url, "POST", {
      failureMessage: API_ENDPOINTS.UPDATE_OWN_AND_BUSINESS_PARTNER_MAPPING_CATEGORIES.failureMessage,
      data: {
        ownId: actor.id,
        businessPartnerId: row.original.businessPartnerId,
        category: value,
        companyId: companyId.current || DEFAULT_COMPANY_ID,
        branchCode: DEFAULT_BRANCH_CODE,
      },
      thenCallBack: () => {
        updateCategory(row, value);
      },
    });
  };
  const updateCategory = (row: any, value: any) => {
    storeTrackerLevelData.current.find(
      (v: any) =>
        v.businessPartnerId === row.original.businessPartnerId && v.activityMonth === row.original.activityMonth
    ).category = value;
  };

  const updateStatus = (row: any, value: any) => {
    const updatedRowsDataTrackerLevel = rowsDataTrackerLevel?.map((v) =>
      v.businessPartnerId === row.original.businessPartnerId && v.activityMonth === row.original.activityMonth
        ? { ...v, statusDone: value }
        : v
    );
    setRowsDataTrackerLevel(updatedRowsDataTrackerLevel);
    setStoreRowsDataTrackerLevel(updatedRowsDataTrackerLevel);

    saveUpdatedStatus(row.original.businessPartnerId, row.original.activityMonth, value);
  };

  const saveUpdatedStatus = (id: number, month: string, statusVal: boolean) => {
    useFetch(API_ENDPOINTS.UPDATE_STATUS_DONE_TRACKER.url, "POST", {
      failureMessage: API_ENDPOINTS.UPDATE_STATUS_DONE_TRACKER.failureMessage,
      data: {
        businessPartnerId: id,
        activityMonth: month,
        statusDone: statusVal,
        companyId: lastOwnTallyCompany ? lastOwnTallyCompany.companyId : null,
      },
    });
  };
  const saveUpdateRemarksTracker = (id: number, month: string, comment: string) => {
    useFetch(API_ENDPOINTS.UPDATE_REMARKS_TRACKER.url, "POST", {
      failureMessage: API_ENDPOINTS.UPDATE_REMARKS_TRACKER.failureMessage,
      data: {
        businessPartnerId: id,
        activityMonth: month,
        remarks: comment,
        companyId: lastOwnTallyCompany ? lastOwnTallyCompany.companyId : null,
      },
    });
  };
  const downloadResult = (row: Row<any>) => {
    useFetch<any>(
      API_ENDPOINTS.DOWNLOAD_ACTIVITY_REPORT(row.original.activityMonth, actor.id, row.original.businessPartnerId).url,
      "GET",
      {
        failureMessage: API_ENDPOINTS.DOWNLOAD_ACTIVITY_REPORT(
          row.original.activityMonth,
          actor.id,
          row.original.businessPartnerId
        ).failureMessage,
        config: {
          responseType: "blob",
        },
        thenCallBack: (response) => {
          const blob = new Blob([response.data], { type: response.headers["content-type"] });
          // Create a temporary URL for the blob
          const url = window.URL.createObjectURL(blob);
          // Create a link element to trigger the download
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            `${row.original.businessPartnerName}-${row.original.activityMonth} Recon Output.xlsm`
          ); // Set the desired file name add
          // Trigger the download by clicking the link
          link.click();
          // Clean up the temporary URL
          window.URL.revokeObjectURL(url);
        },
        catchCallBack: (error) => {
          if (error?.response?.status === 404) {
            // const dataObj = error.response.data as ErrorResponse;
            toast.error(<CustomToast message="No recon output found for partner in given activity month" />);
          }
        },
      }
    );
  };

  const toBase64 = (file: File) =>
    new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result.toString());
      reader.onerror = reject;
    });

  const base64ToBlob = (base64str: string, fileType = "") => {
    const fileData = Buffer.from(base64str, "base64");
    let foundType = "";
    try {
      if (!fileType) foundType = base64str?.split(";")[0].split(":")[1];
    } finally {
      if (!foundType) foundType = "";
    }
    const blob = new Blob([fileData], { type: foundType || fileType });
    return blob;
  };

  const uploadTrackerFile = async (row: Row<TrackerData["data"][0]>, file: File) => {
    const fileBase64 = (await toBase64(file)) || null;

    useFetch(API_ENDPOINTS.UPLOAD_TRACKER_FILE.url, "POST", {
      failureMessage: API_ENDPOINTS.UPLOAD_TRACKER_FILE.failureMessage,
      showSuccessToast: true,
      data: {
        businessPartnerId: row.original.businessPartnerId,
        activityMonth: row.original.activityMonth,
        fileName: file.name,
        fileBase64: fileBase64,
      },
      thenCallBack: (_res) => {
        getReconMis(TRACKER_LEVEL);
      },
    });
  };

  const downloadTrackerFile = async (row: Row<TrackerData["data"][0]>, fileName: string) => {
    useFetch<{ fileBase64: string }>(API_ENDPOINTS.DOWNLOAD_TRACKER_FILE.url, "POST", {
      failureMessage: API_ENDPOINTS.DOWNLOAD_TRACKER_FILE.failureMessage,
      showSuccessToast: true,
      data: {
        businessPartnerId: row.original.businessPartnerId,
        activityMonth: row.original.activityMonth,
        fileName: fileName,
      },
      thenCallBack: (_res) => {
        const blob = base64ToBlob(_res.data.fileBase64);
        saveAs(blob, fileName);
      },
    });
  };

  const deleteTrackerFile = async (row: Row<TrackerData["data"][0]>, fileName: string) => {
    useFetch(API_ENDPOINTS.DELETE_TRACKER_FILE.url, "POST", {
      failureMessage: API_ENDPOINTS.DELETE_TRACKER_FILE.failureMessage,
      showSuccessToast: true,
      data: {
        businessPartnerId: row.original.businessPartnerId,
        activityMonth: row.original.activityMonth,
        fileName: fileName,
      },
      thenCallBack: (_res) => {
        setOpen(false);
        getReconMis(TRACKER_LEVEL);
      },
    });
  };

  return (
    <LoggedInSkeleton
      topBarButtons={getReconTopBarButtons("Process Management", actor.name, location?.state?.openCollapseObj, actor)}
    >
      {loader ? (
        <Loader />
      ) : (
        <Grid>
          <Grid className="mb_15" display={"flex"} flexWrap={"nowrap"}>
            <Button
              variant="contained"
              className={`${selectedSegment === TRACKER_SUMMARY ? "theme_btn" : "theme_outline_btn"} width_180`}
              onClick={() => {
                setSelectedSegment(TRACKER_SUMMARY);
                // getReconMis(TRACKER_SUMMARY);
              }}
            >
              Overview
            </Button>

            <Button
              variant="contained"
              className={`${selectedSegment === TRACKER_LEVEL ? "theme_btn" : "theme_outline_btn"} width_180 ml_20`}
              onClick={() => {
                setSelectedSegment(TRACKER_LEVEL);
                // getReconMis(TRACKER_LEVEL);
              }}
            >
              Closure Tracker
            </Button>

            {actor.integration === true && actor.actorInfo.accountingSoftware === AccountingSoftware.Tally && (
              <Card className="" sx={{ p: 0.5, ml: "auto" }}>
                <Autocomplete
                  className="w_250"
                  size="small"
                  defaultValue={lastOwnTallyCompany}
                  onChange={(e, value) => companyNameSelect(e, value as ListOwnTallyCompanies)}
                  id="businessPartner"
                  options={optionsListOwnTallyCompanies.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))}
                  disableClearable={true}
                  groupBy={(option) => option.firstLetter}
                  getOptionLabel={(option) => (option as ListOwnTallyCompanies).companyName}
                  renderInput={(params) => <TextField {...params} placeholder="Select Company" />}
                />
              </Card>
            )}
          </Grid>
          {selectedSegment === TRACKER_SUMMARY && (
            <Grid maxHeight={"60vh"}>
              <Grid className="space_between mb_25">
                <div className="d_flex">
                  <Card sx={{ p: 0.5 }}>
                    <Autocomplete
                      onChange={(e, value) => selectPeriod(e, value)}
                      id="selectPeriod"
                      className="w_300"
                      options={Object.values(periodOptions)}
                      size="small"
                      getOptionLabel={(option) => option}
                      value={selectedPeriodValue.current}
                      disableClearable={true}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </Card>
                </div>
                <div className="d_flex" style={{ gap: 16 }}>
                  <Tooltip title={"Filter"} arrow={true}>
                    <Avatar sx={{ background: "#27b27c" }}>
                      <IconButton onClick={() => setOpenOverviewLevelFilter(true)} color="inherit">
                        {isFilterAppliedOverviewLevelFilter ? <FilterAlt /> : <FilterAltOff />}
                      </IconButton>
                    </Avatar>
                  </Tooltip>
                  <Tooltip title={"Download"} arrow={true}>
                    <Avatar sx={{ background: "#27b27c" }}>
                      <IconButton onClick={() => createSummaryDataExcel(rowsDataOverview)} color="inherit">
                        <Download />
                      </IconButton>
                    </Avatar>
                  </Tooltip>
                </div>

                {/* <Button
                    variant="contained"
                    className="theme_btn width_180 ml_20"
                    onClick={() => {
                      // downloadExcelReconMIS(downloadOverview, "Overview");
                    }}
                  >
                    Download
                  </Button> */}
              </Grid>

              {/* Filter Applied Bar */}
              {Object.keys(appliedFilters).length > 0 && <FilterAppliedBar appliedFilters={appliedFilters} />}

              <Card className="center_align" sx={{ height: "61vh" }}>
                <OverViewChart
                  pData={rowsDataOverview}
                  pDateRangeType={replacePeriodValue(selectedPeriodValue.current) as any}
                />
              </Card>
            </Grid>
          )}

          {selectedSegment === TRACKER_LEVEL && (
            <Grid maxHeight={"75vh"}>
              <Grid className="mb_25">
                <div className="flex_end" style={{ gap: 16 }}>
                  <Tooltip title={"Filter"} arrow={true}>
                    <Avatar sx={{ background: "#27b27c" }}>
                      <IconButton onClick={() => setOpenTrackerLevelFilter(true)} color="inherit">
                        {isFilterAppliedTrackerLevelFilter ? <FilterAlt /> : <FilterAltOff />}
                      </IconButton>
                    </Avatar>
                  </Tooltip>

                  {/* <Button variant="contained" className="theme_btn  ml_20" onClick={() => {}}>
                    Set Target
                  </Button> */}
                  <Tooltip title={"Set Target"} arrow={true}>
                    <Avatar sx={{ background: "#27b27c" }}>
                      <IconButton onClick={() => setOpenTargetModal(true)} color="inherit">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="32px"
                          height="32px"
                          viewBox="-5 0 32 32"
                          fill="#fff"
                        >
                          <path d="M21.56 15.16h-1.56c-0.44-4.2-3.76-7.56-7.96-7.96v-1.56c0-0.48-0.36-0.84-0.84-0.84s-0.84 0.36-0.84 0.84v1.56c-4.2 0.4-7.56 3.76-7.96 7.96h-1.56c-0.48 0-0.84 0.36-0.84 0.84s0.36 0.84 0.84 0.84h1.56c0.4 4.2 3.76 7.56 7.96 7.96v1.56c0 0.48 0.36 0.84 0.84 0.84s0.84-0.36 0.84-0.84v-1.56c4.2-0.4 7.56-3.76 7.96-7.96h1.56c0.48 0 0.84-0.36 0.84-0.84s-0.4-0.84-0.84-0.84zM16.8 16.84h1.48c-0.4 3.28-3 5.88-6.24 6.24v-1.48c0-0.48-0.36-0.84-0.84-0.84s-0.84 0.36-0.84 0.84v1.48c-3.28-0.4-5.88-3-6.24-6.24h1.48c0.48 0 0.84-0.36 0.84-0.84s-0.36-0.84-0.84-0.84h-1.48c0.4-3.28 3-5.88 6.24-6.24v1.48c0 0.48 0.36 0.84 0.84 0.84s0.84-0.36 0.84-0.84v-1.48c3.28 0.4 5.88 3 6.24 6.24h-1.48c-0.48 0-0.84 0.36-0.84 0.84s0.4 0.84 0.84 0.84z" />
                        </svg>
                      </IconButton>
                    </Avatar>
                  </Tooltip>

                  <Tooltip title={"Toggle Columns"} arrow={true}>
                    <Avatar sx={{ background: "#27b27c" }}>
                      <IconButton onClick={() => setOpenColumnModal(true)} color="inherit">
                        <ViewWeek sx={{ fontSize: "22px !important" }} />
                      </IconButton>
                    </Avatar>
                  </Tooltip>

                  <Tooltip title={"Download Report"} arrow={true}>
                    <Avatar sx={{ background: "#27b27c" }}>
                      <IconButton onClick={() => createTrackerDataExcel(rowsDataTrackerLevel)} color="inherit">
                        {showLoadingIcon ? (
                          <span
                            className="file-icon"
                            style={{
                              position: "absolute",
                              transform: "scale(2.5)",
                              left: 11,
                            }}
                          >
                            <i className="fas fa-circle-notch fa-spin" />
                          </span>
                        ) : null}
                        <Download />
                      </IconButton>
                    </Avatar>
                  </Tooltip>
                </div>
              </Grid>
              <Grid height={"50vh"}>
                <BpDashboardTable
                  columns={columnDefinitionTrackerLevel}
                  rows={rowsDataTrackerLevel}
                  sortEnable={true}
                  setAllLeafColumns={setAllLeafColumns}
                />
              </Grid>
            </Grid>
          )}
        </Grid>
      )}

      <OverviewLevelFilter
        openOverviewLevelFilter={openOverviewLevelFilter}
        setOpenOverviewLevelFilter={setOpenOverviewLevelFilter}
        filter={filter}
        getReconMis={() => {
          getReconMis(selectedSegment);
        }}
        setIsFilterAppliedOverviewLevelFilter={setIsFilterAppliedOverviewLevelFilter}
        allCategories={allCategories}
        allEmails={userEmails}
        setAppliedFilters={setAppliedFilters}
      />
      {openTrackerCommentModal && (
        <TrackerCommentModal
          openTrackerCommentModal={openTrackerCommentModal}
          setOpenTrackerCommentModal={setOpenTrackerCommentModal}
          selectedRowTrackerComment={selectedRowTrackerComment.current}
          rowsDataTrackerLevel={rowsDataTrackerLevel}
          setRowsDataTrackerLevel={setRowsDataTrackerLevel}
          setStoreRowsDataTrackerLevel={setStoreRowsDataTrackerLevel}
          saveUpdateRemarksTracker={(id, month, val) => {
            saveUpdateRemarksTracker(id, month, val);
          }}
        />
      )}
      <TrackerLevelFilter
        openTrackerLevelFilter={openTrackerLevelFilter}
        setOpenTrackerLevelFilter={setOpenTrackerLevelFilter}
        allCategories={storeAllCategories.current}
        rowsDataTrackerLevel={rowsDataTrackerLevel}
        setRowsDataTrackerLevel={setRowsDataTrackerLevel}
        storeRowsDataTrackerLevel={storeRowsDataTrackerLevel}
        setIsFilterAppliedTrackerLevelFilter={setIsFilterAppliedTrackerLevelFilter}
        // setAppliedFilters={setAppliedFilters}
      />
      <ShowHideColumnModal open={openColumnModal} setOpen={setOpenColumnModal} leafColumns={allLeafColumns} />
      <TargetModal
        open={openTargetModal}
        setOpen={setOpenTargetModal}
        setOpenTargetUploadErrorModal={setOpenTargetUploadErrorModal}
        setTargetUploadErrorMsg={setTargetUploadErrorMsg}
        setTargetUploadExcelBase64={setTargetUploadExcelBase64}
        getReconMis={() => {
          getReconMis(TRACKER_LEVEL);
        }}
      />
      <TargetModalUploadError
        open={openTargetUploadErrorModal}
        setOpen={setOpenTargetUploadErrorModal}
        targetUploadErrorMsg={targetUploadErrorMsg}
        targetUploadExcelBase64={targetUploadExcelBase64}
      />
      <input
        ref={fileUploader}
        name="TrackerUploader"
        type="file"
        multiple={false}
        onClick={(_e) => ((_e.target as HTMLInputElement).value = null)}
        onChange={(_e) => {
          uploadTrackerFile(currentRow.current, _e.target.files[0]);
        }}
        hidden
        style={{ visibility: "hidden" }}
        accept=".xlsx,.xls,.xlsm,image/*,.doc,.docx,.ppt,.pptx,.txt,.pdf"
      />
      <Popover
        open={openMenu}
        anchorEl={anchorEl}
        onClose={() => setOpen(false)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <Paper>
          {fileList.map((file, _i) => (
            <MenuItem
              key={file.name + _i}
              onClick={() => {
                downloadTrackerFile(currentRow.current, file.name);
                setOpen(false);
              }}
              sx={{ px: 1.25, gap: 1 }}
            >
              <AttachFile fontSize="small" />
              <Typography href="#" component={"a"} variant="subtitle2">
                {file.name}
              </Typography>
              <IconButton
                aria-label="delete"
                onClick={(_ev) => {
                  _ev.stopPropagation();
                  deleteTrackerFile(currentRow.current, file.name);
                }}
                size="small"
                color="error"
              >
                <DeleteForever fontSize="small" />
              </IconButton>
            </MenuItem>
          ))}
        </Paper>
      </Popover>
    </LoggedInSkeleton>
  );
};

const ShowHideColumnModal = ({ open, setOpen, leafColumns }: { leafColumns: Column<any>[]; [k: string]: any }) => {
  const [update, setUpdate] = useState(0);

  const camelToWords = (str: string) => str.replace(/[A-Z]/g, (letter) => ` ${letter}`).trim();

  return (
    <Dialog open={open} maxWidth="xs" fullWidth={true}>
      <Box className="dialog_header space_between" alignItems="center" pr={1}>
        <DialogTitle>Show/Hide Columns</DialogTitle>
        <IconButton onClick={() => setOpen(false)}>
          <Close />
        </IconButton>
      </Box>
      <DialogContent key={update} sx={{ display: "flex", flexFlow: "column", gap: 0.5 }}>
        {leafColumns &&
          leafColumns.map((column) => (
            <React.Fragment key={column.id}>
              {column.getCanHide() ? (
                <FormGroup
                  row={true}
                  sx={{
                    "&:has(:hover)": {
                      background: "#eee",
                    },
                    px: 1,
                  }}
                >
                  <FormLabel sx={{ display: "inline-flex", gap: 1, alignItems: "center", cursor: "pointer" }}>
                    <IndeterminateCheckbox
                      checked={column.getIsVisible()}
                      onChange={() => {
                        column.toggleVisibility();
                        setUpdate(update + 1);
                      }}
                    />
                    {column.id[0].toUpperCase() + camelToWords(column.id.slice(1))}
                  </FormLabel>
                </FormGroup>
              ) : (
                <></>
              )}
            </React.Fragment>
          ))}
      </DialogContent>
      <DialogActions className="dialog_footer">
        <Button autoFocus={true} onClick={() => setOpen(false)}>
          Done
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const TargetModal = ({
  open,
  setOpen,
  setOpenTargetUploadErrorModal,
  setTargetUploadErrorMsg,
  setTargetUploadExcelBase64,
  getReconMis,
}: {
  [k: string]: any;
}) => {
  const [showLoadingIcon, setShowLoadingIcon] = useState<boolean>(false);
  const [showLoadingIconUpload, setShowLoadingIconUpload] = useState<boolean>(false);

  const uploadTrackerTargetExcel = (e) => {
    setShowLoadingIconUpload(true);
    const bodyFormData = new FormData();
    bodyFormData.append("file", e.target.files[0]);

    useFetch(API_ENDPOINTS.UPLOAD_TRACKER_TARGET_EXCEL.url, "POST", {
      failureMessage: API_ENDPOINTS.UPLOAD_TRACKER_TARGET_EXCEL.failureMessage,
      data: bodyFormData,
      config: { headers: { "Content-Type": "multipart/form-data" } },
      thenCallBack: (response) => {
        setShowLoadingIconUpload(false);
        if (response.data?.addedPartners === response.data?.readPartners) {
          getReconMis();
          toast.success(<CustomToast message={response.data?.message} />);
        } else if (response.data?.addedPartners < response.data?.readPartners) {
          setOpenTargetUploadErrorModal(true);
          const errMsg = `${response.data?.addedPartners}/${
            response.data?.readPartners
          } entries are successfully added, please correct ${
            response.data?.readPartners - response.data?.addedPartners
          } entries as per file below`;
          setTargetUploadErrorMsg(errMsg);
          setTargetUploadExcelBase64(response.data?.excelBase64);
        }
      },
      catchCallBack: () => {
        setShowLoadingIconUpload(false);
      },
    });
  };
  /* eslint-disable react-hooks/rules-of-hooks */
  const getTrackerUploadExcelTemplate = () => {
    setShowLoadingIcon(true);
    useFetch(API_ENDPOINTS.GET_TRACKER_UPLOAD_EXCEL_TEMPLATE.url, "POST", {
      failureMessage: API_ENDPOINTS.GET_TRACKER_UPLOAD_EXCEL_TEMPLATE.failureMessage,
      thenCallBack: (res) => {
        const excelData = Buffer.from(res.data?.excelBase64, "base64");
        const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
        const blob = new Blob([excelData], { type: fileType });
        saveAs(blob, "Tracker upload template.xlsx");
        setShowLoadingIcon(false);
      },
    });
  };

  return (
    <Dialog open={open} fullWidth={true}>
      <Box className="dialog_header space_between" alignItems="center" pr={1}>
        <DialogTitle>Set Target</DialogTitle>
        <IconButton onClick={() => setOpen(false)}>
          <Close />
        </IconButton>
      </Box>
      <DialogContent sx={{ display: "flex", flexFlow: "column", gap: 0.5 }}>
        <p className="mb_15">
          You can have a filtered view of the partner on which you want to take target this month to get better view of
          the tracker dashboard
        </p>
        {/* <Button autoFocus={true} onClick={() => setOpen(false)}>
          Upload Target
        </Button> */}
        <div>
          <label
            id="file-js-example"
            className="field file has-name uploadSection center_align_ver_horiz"
            style={{ marginBottom: "0" }}
          >
            <input
              className="file-input"
              type="file"
              name="invoicefile"
              value={""}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => uploadTrackerTargetExcel(e)}
              required={true}
              multiple={true}
              accept=".xlsx"
            />
            <div>
              <div className="center_align m_20">
                <span className="file-icon">
                  <i className="fas fa-upload" style={{ fontSize: "25px" }} />
                </span>
              </div>
              <div>
                <span
                  className="file-cta closingblc_input businesspartner_btn theme_btn"
                  style={{
                    minWidth: "300px",
                    justifyContent: "center",
                  }}
                >
                  {showLoadingIconUpload ? (
                    <span className="file-icon">
                      <i className="fas fa-circle-notch fa-spin" />
                    </span>
                  ) : null}
                  <span className="file-label">Upload Target Partners</span>
                </span>
              </div>
            </div>
          </label>
        </div>
      </DialogContent>
      <DialogActions className="dialog_footer flex_end">
        <Button
          onClick={() => {
            getTrackerUploadExcelTemplate();
          }}
          className="theme_btn"
          disabled={showLoadingIcon}
        >
          <LoadingIcon loading={showLoadingIcon} />
          Download Format
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const TargetModalUploadError = ({
  open,
  setOpen,
  targetUploadErrorMsg,
  targetUploadExcelBase64,
}: {
  [k: string]: any;
}) => {
  const [showLoadingIcon, setShowLoadingIcon] = useState<boolean>(false);
  /* eslint-disable react-hooks/rules-of-hooks */
  const getTrackerUploadExcelError = () => {
    setShowLoadingIcon(true);
    const excelData = Buffer.from(targetUploadExcelBase64, "base64");
    const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    const blob = new Blob([excelData], { type: fileType });
    saveAs(blob, "Tracker upload error report.xlsx");
    setShowLoadingIcon(false);
  };

  return (
    <Dialog open={open} fullWidth={true}>
      <Box className="dialog_header space_between" alignItems="center" pr={1}>
        <DialogTitle>Target upload error</DialogTitle>
        <IconButton onClick={() => setOpen(false)}>
          <Close />
        </IconButton>
      </Box>
      <DialogContent sx={{ display: "flex", flexFlow: "column", gap: 0.5 }}>
        <p className="mb_15">{targetUploadErrorMsg}</p>
      </DialogContent>
      <DialogActions className="dialog_footer flex_end">
        <Button
          onClick={() => {
            getTrackerUploadExcelError();
          }}
          className="theme_btn"
        >
          <LoadingIcon loading={showLoadingIcon} />
          Download
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ReconMIS;
