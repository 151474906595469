import { ChevronRight } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
  Box,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  ListItem,
  MenuItem,
  Select,
} from "@mui/material";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { clarity } from "react-microsoft-clarity";
import { toast } from "react-toastify";
import LoadingIcon from "src/Components/Common/LoadingIcon";
import useFetch from "src/Components/Common/useFetch";
import CustomToast from "src/Components/CustomToast";
import { RECON360_SUB_TAB } from "src/Utils/Recon/Recon360/Constants";
import { uiLoggerNamesRecon } from "src/Utils/Recon/UiLogger/Constants";
import uiLogger from "src/Utils/UiLogger";
import download_icon from "../../../../Graphics/AutomatedRecon/download_icon.svg";
import recon_icon from "../../../../Graphics/AutomatedRecon/recon_icon.svg";
import SummaryTable from "../../../ReactTable/SummaryTable";
import { Recon360Context } from "../../Recon360";
import { defaultResultFile } from "../../ReconSettings/ReconOutput";
import styles from "./ReconciliationStatement.module.scss";

export default function ReconciliationStatement({
  showExpandedSummary,
  toggleExpandSummary,
  dateOfLastRecon,
  isExpandedSummary,
  renderRowSubComponent,
  generatingSummary,
  reconSummaryDetailColDefImported,
  summaryTypeSelected,
  waitingForOutputFile,
  ledgerComparisonSummaryData,
  hasReconciled,
}) {
  const {
    companyId,
    branchCode,
    businessPartnerSelectedRef,
    downloadExcelFromApi,
    showDownloadResultsLoadingIcon,
    getManuallyChangedEntries,
    subTab,
    disableUploadBothSide,
  } = useContext(Recon360Context);
  const [openDownloadResult, setOpenDownloadResult] = useState(false);
  // State to manage all sections in defaultResultFile dynamically
  const [defaultResultFileState, setDefaultResultFileState] = useState({
    reconciliationSummary: "",
    internalSummary: "",
    ownVsPartner: "",
    // fullLedgers: "",
    ownLedger: "",
    partnerLedger: "",
    help: "",
    ledgerSummary: "",
    qcCsUsers: "Show",
  });

  useEffect(() => {
    getDefaultDownloadSettings();
  }, []);

  const getDefaultDownloadSettings = () => {
    useFetch("/api/recon/GetDefaultDownloadSettings", "GET", {
      thenCallBack: (response) => {
        setDefaultResultFileState({
          reconciliationSummary: response.data.data.ReconciliationSummaryAndAnnexures || "",
          internalSummary: response.data.data.InternalSummary || "",
          ownVsPartner: response.data.data.OwnVsPartner || "",
          // fullLedgers: response.data.data.FullLedgers || "",
          ownLedger: response.data.data.OwnLedger || "",
          partnerLedger: response.data.data.PartnerLedger || "",
          help: response.data.data.Help || "",
          ledgerSummary: response.data.data.LedgerSummary || "",
          qcCsUsers: response.data.data.QcSheets || "Show",
        });
      },
    });
  };

  // Handle changes for defaultResultFile dynamically
  const handleDropdownChange = (event, stateKey) => {
    setDefaultResultFileState((prevState) => ({
      ...prevState,
      [stateKey]: event.target.value,
    }));
  };

  const handleClose = () => {
    setOpenDownloadResult(false);
  };
  return (
    <>
      {showExpandedSummary && (
        <Grid xs={12}>
          <div onClick={toggleExpandSummary} className="vertical_center_align space_between recon_segment">
            <span style={{ fontSize: "inherit" }}>
              Reconciliation Statement :{" "}
              <span className="recon_statement_as_on fw_400 fs_14">
                {dateOfLastRecon && `As on ${moment(dateOfLastRecon)?.format("DD-MM-YY hh:mmA")}`}
              </span>
            </span>
            <ChevronRight
              className="up_down_arrow"
              sx={{ rotate: isExpandedSummary ? "90deg" : "-90deg", transition: "all 0.2s ease" }}
            />
          </div>
        </Grid>
      )}

      <>
        <div className="recon360 account_table_column" id="table-1">
          {summaryTypeSelected === "legacy" && subTab === "Ledger" ? (
            <Collapse className="" in={isExpandedSummary} timeout="auto">
              {waitingForOutputFile ? (
                <div className="mt_25 mb_25 center_align w_100_per">
                  <Button
                    variant="contained"
                    disabled={true}
                    className="fw_700 fs_16"
                    sx={{
                      background: "#27B27C",
                      padding: "16px 22px",
                      color: "#FFF",
                      borderRadius: "10px",
                      "&:hover, &.Mui-focusVisible": { background: "#27B27C" },
                    }}
                  >
                    <span className="file-icon">
                      <i className="fas fa-circle-notch fa-spin" />
                    </span>
                    Preparing Results
                  </Button>
                </div>
              ) : (
                // }
                // {!waitingForOutputFile && (
                <div className={styles.actions_section}>
                  <Button
                    variant="contained"
                    className="fw_700 fs_16"
                    startIcon={
                      showDownloadResultsLoadingIcon ? (
                        <LoadingIcon loading={showDownloadResultsLoadingIcon} />
                      ) : (
                        <img src={download_icon} alt="download" />
                      )
                    }
                    onClick={() => {
                      clarity.setTag("Reconciliation Download", `Click`);
                      downloadExcelFromApi("fromDownloadBtn");
                      uiLogger(
                        uiLoggerNamesRecon.UI_RL_DOWNLOAD_RESULTS_CLICK.functionName,
                        companyId.current,
                        branchCode.current,
                        {
                          message: uiLoggerNamesRecon.UI_RL_DOWNLOAD_RESULTS_CLICK.message,
                          businessPartnerId: businessPartnerSelectedRef.current,
                        }
                      );
                    }}
                    sx={{
                      background: "#27B27C",
                      padding: "16px 22px",
                      color: "#FFF",
                      borderRadius: "10px",
                      "&:hover, &.Mui-focusVisible": { background: "#27B27C" },
                    }}
                    disabled={showDownloadResultsLoadingIcon || disableUploadBothSide}
                  >
                    {generatingSummary ? "Preparing Results" : "DOWNLOAD RESULTS"}
                  </Button>
                  <IconButton
                    className={styles.download_result_select_btn}
                    disabled={showDownloadResultsLoadingIcon || disableUploadBothSide}
                    onClick={() => {
                      setOpenDownloadResult(true);
                    }}
                  >
                    <KeyboardArrowDownIcon />
                  </IconButton>
                  <Button
                    className="fw_700 fs_16"
                    startIcon={<img src={recon_icon} alt="recon"></img>}
                    variant="outlined"
                    onClick={() => {
                      getManuallyChangedEntries();
                      uiLogger(
                        uiLoggerNamesRecon.UI_RL_RECONCILE_MANUALLY_CLICK.functionName,
                        companyId.current,
                        branchCode.current,
                        {
                          message: uiLoggerNamesRecon.UI_RL_RECONCILE_MANUALLY_CLICK.message,
                          businessPartnerId: businessPartnerSelectedRef.current,
                        }
                      );
                    }}
                    sx={{
                      background: "#fff",
                      padding: "16px 22px",
                      color: "#6B6B6B",
                      borderRadius: "10px",
                      borderColor: "#6B6B6B",
                      "&:hover, &.Mui-focusVisible": { borderColor: "#6B6B6B" },
                    }}
                    disabled={disableUploadBothSide}
                  >
                    RECONCILE MANUALLY
                  </Button>
                </div>
              )}
              {ledgerComparisonSummaryData === null ? (
                ""
              ) : (
                <div className="fade_in">
                  <SummaryTable
                    id="summaryTable"
                    getCellProps={(cellInfo: any) => ({
                      style: {
                        backgroundColor:
                          cellInfo.value === "Reconciled"
                            ? "#82FB7F"
                            : cellInfo.value === "Mismatched Entry"
                            ? "#FDE485"
                            : cellInfo.value === "Missed Entry" ||
                              cellInfo.value === "Extra Entry with Suggested Match" ||
                              cellInfo.value === "Extra Entry" ||
                              cellInfo.value === "Missed Entry with Suggested Match"
                            ? "#FD8C85"
                            : "",
                      },
                    })}
                    columns={reconSummaryDetailColDefImported}
                    rows={ledgerComparisonSummaryData}
                    sortEnable={false}
                    renderRowSubComponent={renderRowSubComponent}
                    // openDetailsMismatched={openDetailsMismatched}
                    className="center_table"
                  />
                </div>
              )}
            </Collapse>
          ) : (
            ""
          )}
        </div>
      </>

      {waitingForOutputFile && summaryTypeSelected !== "legacy" && (
        //  props.subTab !== "Ledger" &&
        <div className="mt_40 center_align w_100_per">
          <Button
            variant="contained"
            disabled={true}
            className="fw_700 fs_16"
            sx={{
              background: "#27B27C",
              padding: "16px 22px",
              color: "#FFF",
              borderRadius: "10px",
              "&:hover, &.Mui-focusVisible": { background: "#27B27C" },
            }}
          >
            <span className="file-icon">
              <i className="fas fa-circle-notch fa-spin" />
            </span>
            Preparing Results
          </Button>
        </div>
      )}
      {!waitingForOutputFile &&
        businessPartnerSelectedRef.current &&
        hasReconciled &&
        (summaryTypeSelected === "Ledger + Gaps" || summaryTypeSelected === "Both" || subTab === "Payment Advice") && (
          <div className={styles.actions_section}>
            <Button
              variant="contained"
              onClick={() => downloadExcelFromApi("fromDownloadBtn")}
              disabled={showDownloadResultsLoadingIcon || disableUploadBothSide}
              className="fw_700 fs_16"
              startIcon={
                showDownloadResultsLoadingIcon ? (
                  <LoadingIcon loading={showDownloadResultsLoadingIcon} />
                ) : (
                  <img src={download_icon} alt="download"></img>
                )
              }
              sx={{
                background: "#27B27C",
                padding: "16px 22px",
                color: "#FFF",
                borderRadius: "10px",
                "&:hover, &.Mui-focusVisible": { background: "#27B27C" },
              }}
            >
              {generatingSummary ? "Preparing Results" : "DOWNLOAD RESULTS"}
            </Button>
            {subTab === RECON360_SUB_TAB.LEDGER && (
              <Button
                className="fw_700 fs_16"
                startIcon={<img src={recon_icon} alt="recon"></img>}
                variant="outlined"
                onClick={() => {
                  getManuallyChangedEntries();
                }}
                sx={{
                  background: "#fff",
                  padding: "16px 22px",
                  color: "#6B6B6B",
                  borderRadius: "10px",
                  borderColor: "#6B6B6B",
                  "&:hover, &.Mui-focusVisible": { borderColor: "#6B6B6B" },
                }}
                disabled={disableUploadBothSide}
              >
                RECONCILE MANUALLY
              </Button>
            )}
          </div>
        )}
      {openDownloadResult && (
        <Dialog
          // onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={openDownloadResult}
          maxWidth="md"
          fullWidth
          sx={{ width: "664px", margin: "auto" }}
        >
          <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
            Download Results
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            {" "}
            <CloseIcon />
          </IconButton>
          <DialogContent dividers sx={{ p: 2 }}>
            <Box sx={{ pt: 2, pb: 2 }}>
              <Grid container>
                <Grid item container alignItems="center" sx={{ mb: 2, background: "#F3F3F3" }}>
                  <Grid item xs={9}>
                    <ListItem className="col_header_name" sx={{ pt: "4px", pb: "4px", fontWeight: 600 }}>
                      Sheet Name
                    </ListItem>
                  </Grid>
                  <Grid item xs={3}>
                    <ListItem className="col_header_name" sx={{ pt: "4px", pb: "4px", fontWeight: 600 }}>
                      Action
                    </ListItem>
                  </Grid>
                </Grid>
                {defaultResultFile.map(({ label, stateKey }) => (
                  <Grid item container alignItems="center" key={stateKey} sx={{ pb: 1 }}>
                    <Grid item xs={9}>
                      <ListItem className="col_header_name">{label}</ListItem>
                    </Grid>
                    <Grid item xs={3}>
                      <FormControl fullWidth className="update-custom-border-radius" sx={{ pr: 2 }}>
                        <Select
                          fullWidth
                          size="small"
                          value={defaultResultFileState[stateKey]}
                          onChange={(e) => handleDropdownChange(e, stateKey)}
                        >
                          <MenuItem value="Show">Show</MenuItem>
                          <MenuItem value="Hide">Hide</MenuItem>
                          <MenuItem value="Remove">Remove</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </DialogContent>
          <DialogActions sx={{ p: 2, background: "#F1F1F1" }}>
            <Button
              className="green_btn"
              style={{ width: "143px" }}
              onClick={() => {
                // Check if all fields are either "Hide" or "Remove"
                const { ...allHiddenOrRemovedWithoutQCSheet } = defaultResultFileState;
                delete allHiddenOrRemovedWithoutQCSheet.qcCsUsers;
                const allHiddenOrRemoved = Object.values(allHiddenOrRemovedWithoutQCSheet).every(
                  (value) => value === "Hide" || value === "Remove"
                );

                if (allHiddenOrRemoved) {
                  toast.error(<CustomToast message={"No sheets selected"} />);
                } else if (
                  // Validate relationship between Internal Summary and Own vs Partner
                  (defaultResultFileState.internalSummary === "Hide" ||
                    defaultResultFileState.internalSummary === "Show") &&
                  defaultResultFileState.ownVsPartner === "Remove"
                ) {
                  toast.error(
                    <CustomToast
                      message={"Cannot download Internal Summary without Own vs Partner, please correct your settings"}
                    />
                  );
                } else {
                  handleClose();
                  downloadExcelFromApi("CustomResultDownload", defaultResultFileState);
                  uiLogger(
                    uiLoggerNamesRecon.UI_RL_CUSTOMSHEETDROPDOWN_DOWNLOAD_CLICK.functionName,
                    companyId.current,
                    branchCode.current,
                    {
                      message: uiLoggerNamesRecon.UI_RL_CUSTOMSHEETDROPDOWN_DOWNLOAD_CLICK.message,
                      businessPartnerId: businessPartnerSelectedRef.current,
                    }
                  );
                }
              }}
            >
              DOWNLOAD
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}
