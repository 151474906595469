import { PersonAdd, Upload } from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/Delete";
import DownloadIcon from "@mui/icons-material/Download";
import EditIcon from "@mui/icons-material/Edit";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import SaveIcon from "@mui/icons-material/Save";
import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  Card,
  createFilterOptions,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";
import { ColumnDef } from "@tanstack/react-table";
import { Buffer } from "buffer";
import { saveAs } from "file-saver";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useLocation } from "react-router";
import { toast } from "react-toastify";
import FilterAppliedBar from "src/Components/Common/FilterAppliedBar";
import useFetch from "src/Components/Common/useFetch";
import CustomToast from "src/Components/CustomToast";
import { API_ENDPOINTS } from "src/Utils/ApiConstants/ApiUrlConstants";
import {
  ListAllBusinessPartnerContactsInterface,
  ListOwnTallyCompanies,
} from "../../../entity/recon-entity/ReconInterfaces";
import Loader from "../../Common/Loader";
import { LoggedInSkeleton } from "../../Common/LoggedInSkeleton";
import { getReconTopBarButtons } from "../../Common/TopNavBar";
import { userContext } from "../../Contexts/userContext";
import { Dialog } from "../../Dialog/Dialog";
import BpDashboardTable from "../../ReactTable/BpDashboardTable";
import AddNewBusinessPartner from "../NewBusinessPartner/AddNewBusinessPartner";
import {
  BranchInfo,
  CompanyInfo,
  DEFAULT_BRANCH_CODE,
  DEFAULT_COMPANY_ID,
  IntegratedDropDown,
} from "../PartnerCommunication/CommonLegacy/CommonComponents";
import EditBusinessPartner from "../ReconDashboard/EditBusinessPartner";
import AddNewPartnerUser from "./AddNewPartnerUser";
import InvalidEmailDialog from "./InvalidEmailDialog";
import ListBusinessPartnerUsers from "./ListBusinessPartnerUsers";
import PartnerUseCaseMasterFilter from "./PartnerUseCaseMasterFilter";
import PartnerUserBulkUploadErrorReport from "./PartnerUserBulkUploadErrorReport";
import PartnerUserMasterFilter from "./PartnerUserMasterFilter";

const filter = createFilterOptions<any>();

interface ApplicableTds {
  value: number;
}

type ColDef = ColumnDef<any> & {
  sticky?: "left" | "right";
};

const getBlankData = (count = 20) => {
  const dataArray = [];
  for (let i = 0; i < count; i++) {
    dataArray.push({
      businessPartnerName: " ",
      businessPartnerId: 0,
      allCategories: [],
      category: [],
    });
  }
  return dataArray;
};

const PartnerMaster = () => {
  const location = useLocation<any>();
  const { actor } = useContext(userContext);
  const [loader, setLoader] = useState<boolean>(false);
  const [rowsLoading, setRowsLoading] = useState<boolean>(false);
  const optionsPartnerMaster = ["Partner use case master", "Partner user master"] as const;
  const [partnerMasterSelected, setPartnerMasterSelected] = useState<string>("Partner use case master");
  const partnerMasterSelectedValue = useRef<string>("Partner use case master");
  const [openPartnerUseCaseMasterFilter, setOpenPartnerUseCaseMasterFilter] = useState<boolean>(false);
  const [openPartnerUserMasterFilter, setOpenPartnerUserMasterFilter] = useState<boolean>(false);
  const [isFilterAppliedPartnerUseCaseMaster, setIsFilterAppliedPartnerUseCaseMaster] = useState<boolean>(false);
  const [isFilterAppliedPartnerUserMaster, setIsFilterAppliedPartnerUserMaster] = useState<boolean>(false);
  const [rowsDataPartnerUseCaseMaster, setRowsDataPartnerUseCaseMaster] = useState<any[]>([]);
  const [storeRowsDataPartnerUseCaseMaster, setStoreRowsDataPartnerUseCaseMaster] = useState<any[]>([]);
  const [rowsDataPartnerUserMaster, setRowsDataPartnerUserMaster] = useState<any[]>([]);
  const [storeRowsDataPartnerUserMaster, setStoreRowsDataPartnerUserMaster] = useState<any[]>([]);
  const storeAllCategories = useRef<any[]>([]);
  const storePartnerUseCaseMasterData = useRef<any[]>([]);
  const storePartnerUserMasterData = useRef<any[]>([]);
  const [partnerUsersTemplateBase64, setPartnerUsersTemplateBase64] = useState<string>(null);
  const [openPartnerUserBulkUploadErrorReport, setOpenPartnerUserBulkUploadErrorReport] = useState<boolean>(false);
  const [rowsDataPartnerUserBulkUploadErrorReport, setRowsDataPartnerUserBulkUploadErrorReport] = useState<any[]>([]);
  const [numberOfUsersFailed, setNumberOfUsersFailed] = useState<number>(null);
  const [numberOfUsersSaved, setNumberOfUsersSaved] = useState<number>(null);
  const [errorListsPartnerUsersBase64, setErrorListsPartnerUsersBase64] = useState<string>(null);
  const [toggle, setToggle] = useState<boolean>(false);
  const [storePartnerUserNameValue, setStorePartnerUserNameValue] = useState<any>(null);
  const storePartnerUserNameValueRef = useRef<any>(null);
  const storePartnerUserNameValueFlag = useRef<boolean>(false);
  const [storePartnerNameValue, setStorePartnerNameValue] = useState<any>(null);
  const storePartnerNameValueRef = useRef<any>(null);
  const storePartnerIdValueRef = useRef<any>(null);
  const storePartnerNameValueFlag = useRef<boolean>(false);
  const [storeEmailValue, setStoreEmailValue] = useState<any>(null);
  const storeEmailValueRef = useRef<any>(null);
  const storeEmailValueFlag = useRef<boolean>(false);
  const [storeDesignationValue, setStoreDesignationValue] = useState<string>("");
  const storeDesignationValueRef = useRef<any>(null);
  const storeDesignationValueFlag = useRef<boolean>(false);
  const [storeBalanceConfirmationValue, setStoreBalanceConfirmationValue] = useState<any>(null);
  const storeBalanceConfirmationValueRef = useRef<any>(null);
  const storeBalanceConfirmationValueFlag = useRef<boolean>(false);
  const [storeLedgerRequestValue, setStoreLedgerRequestValue] = useState<any>(null);
  const storeLedgerRequestValueRef = useRef<any>(null);
  const storeLedgerRequestValueFlag = useRef<boolean>(false);
  const [storePaymentAdviceValue, setStorePaymentAdviceValue] = useState<any>(null);
  const storePaymentAdviceValueRef = useRef<any>(null);
  const storePaymentAdviceValueFlag = useRef<boolean>(false);
  const [, setMsmeConfirmationValue] = useState<string>(null);
  const msmeConfirmationValueRef = useRef<string>(null);
  const msmeConfirmationValueFlag = useRef<boolean>(false);
  const [openListBusinessPartnerUsers, setOpenListBusinessPartnerUsers] = useState<boolean>(false);
  const storeRowOpenListBusinessPartnerUsers = useRef<any>(null);
  const [listAllDesignation, setListAllDesignation] = useState<any[]>([]);

  const [, setLastCompany] = useState<CompanyInfo>(null);
  const companyId = useRef<string>(null);

  const [, setLastBranch] = useState<BranchInfo>(null);
  const branchCode = useRef<string>(null);

  const [showLoadingIconUpload, setShowLoadingIconUpload] = useState<boolean>(false);
  const [uploadCategoriesInBulkTemplateBase64, setUploadCategoriesInBulkTemplateBase64] = useState<string>(null);
  const [downloadPartnerUseCaseMasterReportBase64, setDownloadPartnerUseCaseMasterReportBase64] =
    useState<string>(null);
  const [downloadPartnerUserMasterReportBase64, setDownloadPartnerUserMasterReportBase64] = useState<string>(null);
  const [appliedUMFilters, setAppliedUMFilters] = useState<{ [key: string]: string }>({});
  const [appliedUCMFilters, setAppliedUCMFilters] = useState<{ [key: string]: string }>({});
  const [appliedFilters, setAppliedFilters] = useState<{ [key: string]: string }>({});
  const [openUnverified, setOpenUnverified] = useState(false);

  // edit partner
  const [openBusinessPopup, setOpenBusinessPopup] = useState(false);
  const [businessPartnerId, setBusinessPartnerId] = useState<number>(null);
  const anchorEl = useRef(null);
  const [openAddPartnerMenu, setOpenAddPartnerMenu] = useState(false);
  const anchorUploadEl = useRef(null);
  const uploadInput = useRef(null);
  const [openUploadBulkMenu, setOpenUploadBulkMenu] = useState(false);

  const handleClickOpen = (id: any) => {
    setOpenBusinessPopup(true);
    setBusinessPartnerId(id);
  };
  const formatValuesToPercentage = (values: ApplicableTds[] | string) => {
    if (values === null || values === undefined) {
      return "";
    } else if (Array.isArray(values)) {
      const formattedValues = values.map((obj) => `${obj?.value}%`);
      const formattedString = formattedValues.join(", ");

      return formattedString;
    } else return `${values}%`;
  };

  const columnDefinitionPartnerUseCaseMaster = useMemo(
    (): ColDef[] => [
      {
        header: "Partner Name",
        sticky: "left",
        accessorFn: (row) => row.businessPartnerName?.toLowerCase(),
        size: 240,
        cell: ({ row }: any) => (
          <div className="textOverflow_hidden_anchor" style={{ paddingLeft: 8 }}>
            <a
              onClick={() => {
                // // this is for to open user list and edit
                // storeRowOpenListBusinessPartnerUsers.current = row;
                // setOpenListBusinessPartnerUsers(true);

                // this is for to open edit partner
                handleClickOpen(row?.original?.businessPartnerId);
              }}
              style={{ overflowWrap: "break-word" }}
            >
              {row.original.businessPartnerName}
            </a>
          </div>
        ),
      },
      {
        header: "Relationship",
        accessorKey: "relationship",
        size: 150,
        cell: ({ row }: any) => <div>{row.original?.relationship}</div>,
      },
      {
        header: "Category",
        accessorFn: (row: any) => row.category?.[0]?.toLowerCase(),
        enableSorting: false,
        size: 200,
        cell: ({ row }: any) => (
          <p className="category_ui">
            <Autocomplete
              limitTags={1}
              value={
                row.original.category !== null && row.original.category?.length > 0 && row.original.category?.[0] !== ""
                  ? row.original.category
                  : []
              }
              onChange={(_, value) => {
                if (value !== null && value?.length > 0) {
                  if (value[value.length - 1]?.includes("+Add => ")) {
                    value[value.length - 1] = value[value.length - 1].replace('+Add => "', "");
                    value[value.length - 1] = value[value.length - 1].replace('"', "");
                    const removeEmptyVal = value?.filter((el) => {
                      return el !== "";
                    });
                    value = removeEmptyVal;
                  }
                }
                storeAllCategories.current = [...new Set([...storeAllCategories.current, ...value])];
                updateOwnAndBusinessPartnerMappingCategories(row, value);
              }}
              noOptionsText={"Enter a new category"}
              multiple={true}
              id="tags-outlined"
              options={storeAllCategories?.current}
              getOptionLabel={(option: any) => option}
              filterSelectedOptions={true}
              filterOptions={(_optionsFilt, params) => {
                const filtered = filter(
                  storeAllCategories?.current === null ? [] : storeAllCategories?.current,
                  params
                );
                // Suggest the creation of a new value
                if (params.inputValue !== "") {
                  filtered.push(`+Add => "${params.inputValue.trim()}"`);
                }
                return filtered;
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  // placeholder="Add"
                />
              )}
            />
          </p>
        ),
      },
      {
        header: "ERP Software",
        accessorKey: "erpSoftware",
        size: 150,
        cell: ({ row }: any) => <div>{row.original?.erpSoftware}</div>,
      },
      {
        header: "Applicable TDS",
        accessorKey: "applicableTds",
        size: 200,
        cell: ({ row }: any) => <div>{formatValuesToPercentage(row.original?.applicableTds)}</div>,
      },
      {
        header: "# Contacts",
        accessorKey: "numberOfUsers",
        size: 150,
      },
      {
        header: "Balance Confirmation",
        accessorKey: "balanceConfirmation",
        size: 240,
        cell: ({ row }: any) => (
          <div>
            {row.original?.balanceConfirmation ? (
              <span style={{ color: "#27b27c" }}>Contacts added</span>
            ) : (
              <span style={{ color: "red" }}>Contacts not added</span>
            )}
          </div>
        ),
      },
      {
        header: "Ledger request",
        accessorKey: "ledgerRequest",
        size: 180,
        cell: ({ row }: any) => (
          <div>
            {row.original?.ledgerRequest ? (
              <span style={{ color: "#27b27c" }}>Contacts added</span>
            ) : (
              <span style={{ color: "red" }}>Contacts not added</span>
            )}
          </div>
        ),
      },
      {
        header: "Payment advice",
        accessorKey: "paymentAdvice",
        size: 200,
        cell: ({ row }: any) => (
          <div>
            {row.original?.paymentAdvice ? (
              <span style={{ color: "#27b27c" }}>Contacts added</span>
            ) : (
              <span style={{ color: "red" }}>Contacts not added</span>
            )}
          </div>
        ),
      },
    ],
    // eslint-disable-next-line
    []
  );
  const columnDefinitionPartnerUserMaster = useMemo(
    (): ColumnDef<any>[] => [
      {
        header: "Partner user name",
        accessorFn: (row: any) => row.name?.toLowerCase(),
        size: 200,
        cell: ({ row }: any) => (
          <div>
            {row.original.isEdit ? (
              <TextField
                size="small"
                variant="outlined"
                // fullWidth={true}
                // label="Name"
                name="name"
                value={storePartnerUserNameValueFlag.current ? storePartnerUserNameValueRef.current : row.original.name}
                onClick={() => {
                  storePartnerUserNameValueFlag.current = true;
                  storePartnerUserNameValueRef.current = row.original.name;
                  setStorePartnerUserNameValue(row.original.name);
                }}
                onChange={(e) => {
                  setStorePartnerUserNameValue(e.target.value);
                  storePartnerUserNameValueRef.current = e.target.value;
                }}
              />
            ) : (
              <div className="textOverflow_hidden_left" style={{ paddingLeft: 8 }}>
                {row.original.name}
              </div>
            )}
          </div>
        ),
      },
      {
        header: () => (
          <div className="header" style={{ marginRight: "auto" }}>
            Partner Name
          </div>
        ),
        id: "partnerName",
        accessorFn: (row: any) => row.businessPartnerName?.toLowerCase(),
        size: 220,
        cell: ({ row }: any) => (
          <div>
            {row.original.isEdit ? (
              <Select
                size="small"
                fullWidth={true}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="businessPartnerName"
                value={
                  storePartnerNameValueFlag.current
                    ? storePartnerNameValueRef.current
                    : row.original.businessPartnerName
                }
                onChange={(e) => {
                  storePartnerNameValueFlag.current = true;
                  setStorePartnerNameValue(e.target.value);
                  storePartnerNameValueRef.current = e.target.value;

                  let bpId;
                  storePartnerUseCaseMasterData.current?.forEach((ele: any) => {
                    if (e.target.value === ele.businessPartnerName) {
                      return (bpId = ele.businessPartnerId);
                    }
                  });
                  storePartnerIdValueRef.current = bpId;
                }}
              >
                {storePartnerUseCaseMasterData.current?.map((val: any, valInd: number) => (
                  <MenuItem value={val.businessPartnerName} key={valInd}>
                    {val.businessPartnerName}
                  </MenuItem>
                ))}
              </Select>
            ) : (
              <div className="textOverflow_hidden_left">{row.original.businessPartnerName}</div>
            )}
          </div>
        ),
      },
      {
        header: "Mail ID",
        accessorFn: (row: any) => row.email?.toLowerCase(),
        size: 240,
        cell: ({ row }: any) => (
          <div>
            {row.original.isEdit ? (
              <TextField
                size="small"
                variant="outlined"
                // fullWidth={true}
                // label="Name"
                disabled={true}
                name="email"
                value={storeEmailValueFlag.current ? storeEmailValueRef.current : row.original.email}
                onClick={() => {
                  storeEmailValueFlag.current = true;
                  storeEmailValueRef.current = row.original.email;
                  setStoreEmailValue(row.original.email);
                }}
                onChange={(e) => {
                  setStoreEmailValue(e.target.value);
                  storeEmailValueRef.current = e.target.value;
                }}
              />
            ) : (
              <span>{row.original.email}</span>
            )}
          </div>
        ),
      },
      {
        header: "Designation",
        accessorFn: (row: any) => row.designationName?.toLowerCase(),
        size: 200,
        cell: ({ row }: any) => (
          <div>
            {row.original.isEdit ? (
              <TextField
                select
                size="small"
                variant="outlined"
                // fullWidth={true}
                // label="Name"
                name="designationName"
                value={
                  storeDesignationValueFlag.current ? storeDesignationValueRef.current : row.original.designationName
                }
                onChange={(e) => {
                  storeDesignationValueFlag.current = true;
                  setStoreDesignationValue(e.target.value);
                  storeDesignationValueRef.current = e.target.value;
                }}
              >
                <MenuItem value={""}>{"{Blank}"}</MenuItem>
                <MenuItem value={"Accountant"}>Accountant</MenuItem>
                <MenuItem value={"Partner POC"}>Partner POC</MenuItem>
                <MenuItem value={"Own POC"}>Own POC</MenuItem>
                <MenuItem value={"Escalation POC"}>Escalation POC</MenuItem>
              </TextField>
            ) : (
              <span>{row.original.designationName}</span>
            )}
          </div>
        ),
      },

      {
        header: "Balance Confirmation",
        accessorKey: "balanceConfirmation",
        size: 190,
        cell: ({ row }: any) => (
          <div>
            {row.original.isEdit ? (
              <Select
                size="small"
                fullWidth={true}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="balanceConfirmation"
                value={
                  storeBalanceConfirmationValueFlag.current
                    ? storeBalanceConfirmationValueRef.current
                    : row.original.balanceConfirmation
                }
                onChange={(e) => {
                  storeBalanceConfirmationValueFlag.current = true;
                  setStoreBalanceConfirmationValue(e.target.value);
                  storeBalanceConfirmationValueRef.current = e.target.value;
                }}
              >
                <MenuItem value={"NA"}>NA</MenuItem>
                <MenuItem value={"to"}>to</MenuItem>
                <MenuItem value={"cc"}>cc</MenuItem>
                <MenuItem value={"bcc"}>bcc</MenuItem>
              </Select>
            ) : (
              <span>{row.original.balanceConfirmation}</span>
            )}
          </div>
        ),
      },
      {
        header: "Ledger request",
        accessorKey: "ledgerRequest",
        size: 140,
        cell: ({ row }: any) => (
          <div>
            {row.original.isEdit ? (
              <Select
                size="small"
                fullWidth={true}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="ledgerRequest"
                value={
                  storeLedgerRequestValueFlag.current ? storeLedgerRequestValueRef.current : row.original.ledgerRequest
                }
                onChange={(e) => {
                  storeLedgerRequestValueFlag.current = true;
                  setStoreLedgerRequestValue(e.target.value);
                  storeLedgerRequestValueRef.current = e.target.value;
                }}
              >
                <MenuItem value={"NA"}>NA</MenuItem>
                <MenuItem value={"to"}>to</MenuItem>
                <MenuItem value={"cc"}>cc</MenuItem>
                <MenuItem value={"bcc"}>bcc</MenuItem>
              </Select>
            ) : (
              <span>{row.original.ledgerRequest}</span>
            )}
          </div>
        ),
      },
      {
        header: "Payment advice",
        accessorKey: "paymentAdvice",
        size: 145,
        cell: ({ row }: any) => (
          <div>
            {row.original.isEdit ? (
              <Select
                size="small"
                fullWidth={true}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="paymentAdvice"
                value={
                  storePaymentAdviceValueFlag.current ? storePaymentAdviceValueRef.current : row.original.paymentAdvice
                }
                onChange={(e) => {
                  storePaymentAdviceValueFlag.current = true;
                  setStorePaymentAdviceValue(e.target.value);
                  storePaymentAdviceValueRef.current = e.target.value;
                }}
              >
                <MenuItem value={"NA"}>NA</MenuItem>
                <MenuItem value={"to"}>to</MenuItem>
                <MenuItem value={"cc"}>cc</MenuItem>
                <MenuItem value={"bcc"}>bcc</MenuItem>
              </Select>
            ) : (
              <span>{row.original.paymentAdvice}</span>
            )}
          </div>
        ),
      },
      {
        header: "MSME Confirmation",
        accessorKey: "msmeConfirmation",
        size: 175,
        cell: ({ row }: any) => (
          <div>
            {row.original.isEdit ? (
              <Select
                size="small"
                fullWidth={true}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="msmeConfirmation"
                value={
                  msmeConfirmationValueFlag.current ? msmeConfirmationValueRef.current : row.original.msmeConfirmation
                }
                onChange={(e) => {
                  msmeConfirmationValueFlag.current = true;
                  setMsmeConfirmationValue(e.target.value);
                  msmeConfirmationValueRef.current = e.target.value;
                }}
              >
                <MenuItem value={"NA"}>NA</MenuItem>
                <MenuItem value={"to"}>to</MenuItem>
                <MenuItem value={"cc"}>cc</MenuItem>
                <MenuItem value={"bcc"}>bcc</MenuItem>
              </Select>
            ) : (
              <span>{row.original.msmeConfirmation}</span>
            )}
          </div>
        ),
      },
      {
        header: "Actions",
        columns: [
          {
            header: "",
            id: "edit",
            accessorKey: "businessPartnerId",
            size: 50,
            cell: ({ row }: any) => (
              <div>
                {row.original.isEdit ? (
                  <span
                    className="cursor_pointer"
                    onClick={() => {
                      let emailSettingErr = false;
                      if (
                        storeLedgerRequestValueRef.current === "NA" &&
                        storeBalanceConfirmationValueRef.current === "NA" &&
                        storePaymentAdviceValueRef.current === "NA" &&
                        msmeConfirmationValueRef.current === "NA" &&
                        storeBalanceConfirmationValueFlag.current &&
                        storeLedgerRequestValueFlag.current &&
                        storePaymentAdviceValueFlag.current &&
                        msmeConfirmationValueFlag.current
                      ) {
                        emailSettingErr = true;
                      }
                      let partnerNameErr = false;
                      if (storePartnerNameValueFlag.current && storePartnerNameValueRef.current === null) {
                        partnerNameErr = true;
                      }
                      let emailErr = false;
                      if (
                        storeEmailValueFlag.current &&
                        !storeEmailValueRef.current.match("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,4}$")
                      ) {
                        emailErr = true;
                      }
                      if (!partnerNameErr && !emailErr && !emailSettingErr) {
                        addNewPartnerUser(row);
                      } else {
                        if (emailErr) {
                          toast.error(<CustomToast message="please enter valid email" />);
                        } else if (partnerNameErr) {
                          toast.error(<CustomToast message="please select Email Setting" />);
                        } else if (emailSettingErr) {
                          toast.error(<CustomToast message="please select Email Setting" />);
                        }
                      }
                    }}
                  >
                    <SaveIcon />
                  </span>
                ) : (
                  <span
                    className="cursor_pointer"
                    onClick={() => {
                      storePartnerUserMasterData.current?.forEach((ele: any, eleInd: number) => {
                        if (eleInd === row.index) {
                          ele.isEdit = true;
                        } else {
                          ele.isEdit = false;
                        }
                        return;
                      });
                      setRowsDataPartnerUserMaster(storePartnerUserMasterData.current);
                      setStoreRowsDataPartnerUserMaster(storePartnerUserMasterData.current);
                      setToggle(!toggle);
                    }}
                  >
                    <EditIcon />
                  </span>
                )}
              </div>
            ),
          },
          {
            header: "",
            id: "delete",
            accessorKey: "businessPartnerId",
            size: 50,
            cell: ({ row }: any) => (
              <div
                className="cursor_pointer"
                onClick={() => {
                  deleteBusinessPartnerUser(row);
                }}
              >
                <DeleteIcon />
              </div>
            ),
          },
        ],
      },
    ],
    // eslint-disable-next-line
    [toggle]
  );

  useEffect(() => {
    setLoader(true);
    if (!actor.integration) {
      listAllBusinessPartnersForPartnerUseCaseMaster();
      listAllBusinessPartnersForPartnerUserMaster();
      getPartnerUserBulkUploadExcelTemplate();
      getCategoriesBulkUploadExcelTemplate();
    }
    getListAllDesignation();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    partnerMasterSelected === "Partner user master"
      ? setAppliedFilters(appliedUMFilters)
      : setAppliedFilters(appliedUCMFilters);
    // eslint-disable-next-line
  }, [partnerMasterSelected, appliedUCMFilters, appliedUMFilters]);

  const listAllBusinessPartnersForPartnerUseCaseMaster = () => {
    setRowsDataPartnerUseCaseMaster(getBlankData());
    setStoreRowsDataPartnerUseCaseMaster(getBlankData());
    setRowsLoading(true);
    useFetch(API_ENDPOINTS.LIST_ALL_BUSINESS_PARTNERS_FOR_PARTNER_MASTER.url, "GET", {
      failureMessage: API_ENDPOINTS.LIST_ALL_BUSINESS_PARTNERS_FOR_PARTNER_MASTER.failureMessage,
      config: {
        params: {
          companyId: companyId.current || DEFAULT_COMPANY_ID,
          branchCode: branchCode.current || DEFAULT_BRANCH_CODE,
        },
      },
      thenCallBack: (response) => {
        storePartnerUseCaseMasterData.current = response.data.businessPartnersList;
        storeAllCategories.current = response.data.businessPartnersList[0]?.allCategories || [];
        setRowsDataPartnerUseCaseMaster(response.data.businessPartnersList);
        setStoreRowsDataPartnerUseCaseMaster(response.data.businessPartnersList);
        setDownloadPartnerUseCaseMasterReportBase64(response.data?.reportWorkbookBase64);
        setOpenPartnerUseCaseMasterFilter(true);
        const buttonElement = document.getElementById("filterPartnerUseCaseMaster_button");
        if (buttonElement) {
          buttonElement.click();
          setOpenPartnerUseCaseMasterFilter(false);
        }
        setOpenAddPartnerMenu(false);
        setLoader(false);
        setRowsLoading(false);
      },
      catchCallBack: () => {
        setLoader(false);
        setRowsLoading(false);
      },
    });
  };

  const listAllBusinessPartnersForPartnerUserMaster = () => {
    useFetch<ListAllBusinessPartnerContactsInterface>(API_ENDPOINTS.LIST_ALL_BUSINESS_PARTNER_CONTACTS.url, "GET", {
      failureMessage: API_ENDPOINTS.LIST_ALL_BUSINESS_PARTNER_CONTACTS.failureMessage,
      config: {
        params: {
          companyId: companyId.current || DEFAULT_COMPANY_ID,
          branchCode: branchCode.current || DEFAULT_BRANCH_CODE,
        },
      },
      thenCallBack: (response) => {
        const businessPartnerContactList = response.data.businessPartnerContactList?.map((object: any) => {
          return { ...object, isEdit: false };
        });
        storePartnerUserMasterData.current = businessPartnerContactList;
        setRowsDataPartnerUserMaster(businessPartnerContactList);
        setStoreRowsDataPartnerUserMaster(businessPartnerContactList);
        setDownloadPartnerUserMasterReportBase64(response.data?.reportWorkbookBase64);
        // setLoader(false);
        setOpenPartnerUserMasterFilter(true);
        const buttonElement = document.getElementById("filterPartnerUserMaster_button");
        if (buttonElement) {
          buttonElement.click();
          setOpenPartnerUserMasterFilter(false);
        }
        setOpenAddPartnerMenu(false);
      },
    });
  };

  const updateOwnAndBusinessPartnerMappingCategories = async (row: any, value: any) => {
    await useFetch(API_ENDPOINTS.UPDATE_OWN_AND_BUSINESS_PARTNER_MAPPING_CATEGORIES.url, "POST", {
      failureMessage: API_ENDPOINTS.UPDATE_OWN_AND_BUSINESS_PARTNER_MAPPING_CATEGORIES.failureMessage,
      data: {
        ownId: actor.id,
        businessPartnerId: row.original.businessPartnerId,
        category: value,
        companyId: companyId.current || DEFAULT_COMPANY_ID,
        branchCode: branchCode.current || DEFAULT_BRANCH_CODE,
      },
      thenCallBack: () => {
        getCategoriesBulkUploadExcelTemplate();
        updateCategory(row, value);
      },
    });
  };

  const updateCategory = (row: any, value: any) => {
    storePartnerUseCaseMasterData.current.find(
      (v: any) => v.businessPartnerId === row.original.businessPartnerId
    ).category = value;

    // storePartnerUserMasterData.current.find(
    //   (v: any) => v.businessPartnerId === row.original.businessPartnerId
    // ).category = value;
  };

  const partnerMasterSelect = (e: any, value: any) => {
    if (value !== null) {
      e.persist();
      setPartnerMasterSelected(value);
      partnerMasterSelectedValue.current = value;
    } else {
      setPartnerMasterSelected(null);
      partnerMasterSelectedValue.current = null;
    }
  };

  const getPartnerUserBulkUploadExcelTemplate = () => {
    useFetch(API_ENDPOINTS.GET_BUSINESS_PARTNER_USER_BULK_UPLOAD_EXCEL_TEMPLATE.url, "GET", {
      failureMessage: API_ENDPOINTS.GET_BUSINESS_PARTNER_USER_BULK_UPLOAD_EXCEL_TEMPLATE.failureMessage,
      config: {
        params: {
          companyId: companyId.current || DEFAULT_COMPANY_ID,
          branchCode: branchCode.current || DEFAULT_BRANCH_CODE,
        },
      },
      thenCallBack: (response) => {
        setPartnerUsersTemplateBase64(response.data.response);
      },
    });
  };

  const addNewPartnerUser = async (row: any) => {
    await useFetch(API_ENDPOINTS.ADD_BUSINESS_PARTNER_USER_WITH_EMAIL_SETTING.url, "POST", {
      failureMessage: API_ENDPOINTS.ADD_BUSINESS_PARTNER_USER_WITH_EMAIL_SETTING.failureMessage,
      showSuccessToast: true,
      data: {
        businessPartnerId: storePartnerNameValueFlag.current
          ? storePartnerIdValueRef.current
          : row.original.businessPartnerId,
        contact: {
          name: storePartnerUserNameValueFlag.current ? storePartnerUserNameValueRef.current : row.original.name,
          email: storeEmailValueFlag.current ? storeEmailValueRef.current : row.original.email,
          designationName: storeDesignationValueFlag.current
            ? storeDesignationValueRef.current
            : row.original.designationName,
          ledgerRequest: storeLedgerRequestValueFlag.current
            ? storeLedgerRequestValueRef.current
            : row.original.ledgerRequest,
          balanceConfirmation: storeBalanceConfirmationValueFlag.current
            ? storeBalanceConfirmationValueRef.current
            : row.original.balanceConfirmation,
          paymentAdvice: storePaymentAdviceValueFlag.current
            ? storePaymentAdviceValueRef.current
            : row.original.paymentAdvice,
          msmeConfirmation: msmeConfirmationValueFlag.current
            ? msmeConfirmationValueRef.current
            : row.original.msmeConfirmation,
        },
        companyId: companyId.current || DEFAULT_COMPANY_ID,
        branchCode: branchCode.current || DEFAULT_BRANCH_CODE,
      },
      thenCallBack: () => {
        console.log(
          storePartnerUserNameValue,
          storePartnerNameValue,
          storeEmailValue,
          storeDesignationValue,
          storeBalanceConfirmationValue,
          storeLedgerRequestValue,
          storePaymentAdviceValue
        );

        const partnerUserMasterDataObj = {
          businessPartnerId: storePartnerNameValueFlag.current
            ? storePartnerIdValueRef.current
            : row.original.businessPartnerId,
          businessPartnerName: storePartnerNameValueFlag.current
            ? storePartnerNameValueRef.current
            : row.original.businessPartnerName,
          name: storePartnerUserNameValueFlag.current ? storePartnerUserNameValueRef.current : row.original.name,
          email: storeEmailValueFlag.current ? storeEmailValueRef.current : row.original.email,
          designationName: storeDesignationValueFlag.current
            ? storeDesignationValueRef.current
            : row.original.designationName,
          ledgerRequest: storeLedgerRequestValueFlag.current
            ? storeLedgerRequestValueRef.current
            : row.original.ledgerRequest,
          balanceConfirmation: storeBalanceConfirmationValueFlag.current
            ? storeBalanceConfirmationValueRef.current
            : row.original.balanceConfirmation,
          paymentAdvice: storePaymentAdviceValueFlag.current
            ? storePaymentAdviceValueRef.current
            : row.original.paymentAdvice,
          msmeConfirmation: msmeConfirmationValueFlag.current
            ? msmeConfirmationValueRef.current
            : row.original.msmeConfirmation,
          isEdit: false,
        };

        const newPartnerUserList: any = [];
        storePartnerUserMasterData.current?.forEach((ele: any, eleInd: number) => {
          if (eleInd === row.index) {
            newPartnerUserList.push(partnerUserMasterDataObj);
          } else {
            newPartnerUserList.push(ele);
          }
        });

        storePartnerUserMasterData.current = newPartnerUserList;
        setRowsDataPartnerUserMaster(newPartnerUserList);
        setStoreRowsDataPartnerUserMaster(newPartnerUserList);

        storePartnerUserNameValueFlag.current = false;
        storePartnerNameValueFlag.current = false;
        storeEmailValueFlag.current = false;
        storeDesignationValueFlag.current = false;
        storeBalanceConfirmationValueFlag.current = false;
        storeLedgerRequestValueFlag.current = false;
        storePaymentAdviceValueFlag.current = false;
        msmeConfirmationValueFlag.current = false;
      },
    });
    // }
  };

  const deleteBusinessPartnerUser = async (row: any) => {
    await useFetch(API_ENDPOINTS.DELETE_BUSINESS_PARTNER_USER.url, "POST", {
      failureMessage: API_ENDPOINTS.DELETE_BUSINESS_PARTNER_USER.failureMessage,
      showSuccessToast: true,
      data: {
        businessPartnerId: row.original.businessPartnerId,
        businessPartnerName: row.original.businessPartnerName,
        emailId: row.original.email,
        companyId: companyId.current || DEFAULT_COMPANY_ID,
        branchCode: branchCode.current || DEFAULT_BRANCH_CODE,
      },
      thenCallBack: () => {
        storePartnerUserMasterData.current.splice(row.index, 1);
        setToggle(!toggle);

        listAllBusinessPartnersForPartnerUseCaseMaster();
        listAllBusinessPartnersForPartnerUserMaster();
      },
    });
  };

  const getListAllDesignation = async () => {
    await useFetch(API_ENDPOINTS.LIST_ALL_DESIGNATION.url, "GET", {
      failureMessage: API_ENDPOINTS.LIST_ALL_DESIGNATION.failureMessage,
      thenCallBack: (response) => {
        setListAllDesignation(response.data.designations);
      },
    });
  };

  const companyNameSelect = (e: any, option: ListOwnTallyCompanies | CompanyInfo) => {
    if (option !== null) {
      if (e?.persist) e.persist();
      companyId.current = option.companyId;
      setLastCompany(option);
      if (!actor.branchLevelReconcilation) {
        listAllBusinessPartnersForPartnerUseCaseMaster();
        listAllBusinessPartnersForPartnerUserMaster();
        getPartnerUserBulkUploadExcelTemplate();
        getCategoriesBulkUploadExcelTemplate();
      }
    } else if (option === null) {
      companyId.current = null;
    }
  };

  const AfterListCompanies = (_res) => {
    setLoader(false);
  };

  const branchNameSelect = (e: any, option: BranchInfo) => {
    if (option !== null) {
      if (e?.persist) e.persist();
      branchCode.current = option.branchCode;
      setLastBranch(option);
      if (actor.branchLevelReconcilation) {
        listAllBusinessPartnersForPartnerUseCaseMaster();
        listAllBusinessPartnersForPartnerUserMaster();
        getPartnerUserBulkUploadExcelTemplate();
        getCategoriesBulkUploadExcelTemplate();
      }
    } else if (option === null) {
      setLastBranch(option);
      branchCode.current = null;
    }
  };

  const AfterListBranches = (_res) => {
    setLoader(false);
  };

  const downloadUploadCategoriesInBulkTemplate = () => {
    const excelData = Buffer.from(uploadCategoriesInBulkTemplateBase64, "base64");
    const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    const blob = new Blob([excelData], { type: fileType });
    saveAs(blob, "Upload Categories template.xlsx");
  };

  const uploadCategoriesInBulk = (filePayload: FileList | Blob[]) => {
    const reader = new FileReader();
    let data = null;
    reader.readAsDataURL(filePayload[0]);
    reader.onloadend = (event) => {
      data = event.target.result;
      setShowLoadingIconUpload(true);

      useFetch(API_ENDPOINTS.UPLOAD_CATEGORIES_IN_BULK.url, "POST", {
        failureMessage: API_ENDPOINTS.UPLOAD_CATEGORIES_IN_BULK.failureMessage,
        showSuccessToast: true,
        data: {
          userName: actor?.name,
          categoriesDataBase64: data
            .toString()
            .split("data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,")[1],
          companyId: companyId.current || DEFAULT_COMPANY_ID,
          branchCode: branchCode.current || DEFAULT_BRANCH_CODE,
        },
        thenCallBack: () => {
          listAllBusinessPartnersForPartnerUseCaseMaster();
          listAllBusinessPartnersForPartnerUserMaster();
          setShowLoadingIconUpload(false);
        },
        catchCallBack: () => {
          setShowLoadingIconUpload(false);
        },
      });
    };
  };

  const getCategoriesBulkUploadExcelTemplate = () => {
    useFetch(API_ENDPOINTS.GET_CATEGORIES_BULK_UPLOAD_EXCEL_TEMPLATE.url, "POST", {
      failureMessage: API_ENDPOINTS.GET_CATEGORIES_BULK_UPLOAD_EXCEL_TEMPLATE.failureMessage,
      data: {
        userName: actor?.name,
        companyId: companyId.current || DEFAULT_COMPANY_ID,
        branchCode: branchCode.current || DEFAULT_BRANCH_CODE,
      },
      thenCallBack: (response) => {
        setUploadCategoriesInBulkTemplateBase64(response.data?.response);
      },
    });
  };

  const downloadPartnerUseCaseMasterReport = () => {
    const excelData = Buffer.from(downloadPartnerUseCaseMasterReportBase64, "base64");
    const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    const blob = new Blob([excelData], { type: fileType });
    saveAs(blob, `${actor.name} UseCaseMasterReport.xlsx`);
  };
  const downloadPartnerUserMasterReport = () => {
    const excelData = Buffer.from(downloadPartnerUserMasterReportBase64, "base64");
    const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    const blob = new Blob([excelData], { type: fileType });
    saveAs(blob, `${actor.name} UserMasterReport.xlsx`);
  };

  return (
    <LoggedInSkeleton
      topBarButtons={getReconTopBarButtons("Partner Master", actor.name, location?.state?.openCollapseOb, actor)}
    >
      {loader ? (
        <Loader />
      ) : (
        <Grid className="table_list">
          <Grid>
            <Grid className="space_between mb_15 mt_15">
              <div className="d_flex" style={{ gap: 8 }}>
                <Card sx={{ p: 0.5 }}>
                  <Autocomplete
                    onChange={(e, value) => partnerMasterSelect(e, value)}
                    id="partnerMasterSelect"
                    className="w_250"
                    options={optionsPartnerMaster}
                    size="small"
                    getOptionLabel={(option) => option}
                    defaultValue={partnerMasterSelected}
                    disableClearable={true}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </Card>
                {actor.integration === true && (
                  <IntegratedDropDown
                    tallyCompanyNameSelect={companyNameSelect}
                    AfterListOwnTallyCompanies={AfterListCompanies}
                    companyNameSelect={companyNameSelect}
                    AfterListCompanies={AfterListCompanies}
                    branchNameSelect={branchNameSelect}
                    AfterListBranches={AfterListBranches}
                  />
                )}
              </div>

              <Box display={"inline-flex"} gap={2}>
                <Button
                  className="theme_btn_no_bg"
                  size="small"
                  startIcon={
                    <svg
                      className="icon"
                      style={{ height: "24px", width: "24px", stroke: "#C27F00", fill: "transparent" }}
                    >
                      <use xlinkHref="#icon-mail-question" />
                    </svg>
                  }
                  sx={{
                    background: "#FFF7E9",
                    color: "#000",
                    borderRadius: "32px !important",
                    height: 40,
                    border: "1px solid #C27F00",
                  }}
                  onClick={() => setOpenUnverified(true)}
                >
                  Unverified Emails
                </Button>
                <Tooltip title="Add Partner Menu" arrow={true}>
                  <Avatar sx={{ background: "#27b27c" }}>
                    <IconButton ref={anchorEl} onClick={() => setOpenAddPartnerMenu(true)} color="inherit">
                      <PersonAdd fontSize={"medium"} />
                    </IconButton>
                  </Avatar>
                </Tooltip>
                <PopOverMenu
                  anchorEl={anchorEl}
                  open={openAddPartnerMenu}
                  closeFn={() => {
                    setOpenAddPartnerMenu(false);
                  }}
                  clickFn={() => {
                    setOpenAddPartnerMenu(false);
                  }}
                >
                  <AddNewBusinessPartner
                    from={"Partner Master"}
                    listAllBusinessPartnersForPartnerUseCaseMaster={listAllBusinessPartnersForPartnerUseCaseMaster}
                    listAllBusinessPartnersForPartnerUserMaster={listAllBusinessPartnersForPartnerUserMaster}
                    getPartnerUserBulkUploadExcelTemplate={getPartnerUserBulkUploadExcelTemplate}
                  />
                  <AddNewPartnerUser
                    listAllBusinessPartnersForPartnerUseCaseMaster={listAllBusinessPartnersForPartnerUseCaseMaster}
                    listAllBusinessPartnersForPartnerUserMaster={listAllBusinessPartnersForPartnerUserMaster}
                    rowsDataPartnerUseCaseMaster={rowsDataPartnerUseCaseMaster}
                    setLoader={setLoader}
                    partnerUsersTemplateBase64={partnerUsersTemplateBase64}
                    setOpenPartnerUserBulkUploadErrorReport={setOpenPartnerUserBulkUploadErrorReport}
                    setRowsDataPartnerUserBulkUploadErrorReport={setRowsDataPartnerUserBulkUploadErrorReport}
                    setNumberOfUsersFailed={setNumberOfUsersFailed}
                    setNumberOfUsersSaved={setNumberOfUsersSaved}
                    setErrorListsPartnerUsersBase64={setErrorListsPartnerUsersBase64}
                    listAllDesignation={listAllDesignation}
                    getListAllDesignation={getListAllDesignation}
                    companyId={companyId.current}
                    branchCode={branchCode.current}
                  />
                </PopOverMenu>

                {partnerMasterSelected === "Partner use case master" && (
                  <>
                    <Tooltip title="Filter" arrow={true}>
                      <Avatar sx={{ background: "#27b27c" }}>
                        <IconButton onClick={() => setOpenPartnerUseCaseMasterFilter(true)} color="inherit">
                          {isFilterAppliedPartnerUseCaseMaster ? <FilterAltIcon /> : <FilterAltOffIcon />}
                        </IconButton>
                      </Avatar>
                    </Tooltip>
                    <Tooltip title="Download Report" arrow={true}>
                      <Avatar sx={{ background: "#27b27c" }}>
                        <IconButton onClick={() => downloadPartnerUseCaseMasterReport()} color="inherit">
                          <DownloadIcon />
                        </IconButton>
                      </Avatar>
                    </Tooltip>
                  </>
                )}
                {partnerMasterSelected === "Partner user master" && (
                  <>
                    <Tooltip title="Filter" arrow={true}>
                      <Avatar sx={{ background: "#27b27c" }}>
                        <IconButton onClick={() => setOpenPartnerUserMasterFilter(true)} color="inherit">
                          {isFilterAppliedPartnerUserMaster ? <FilterAltIcon /> : <FilterAltOffIcon />}
                        </IconButton>
                      </Avatar>
                    </Tooltip>
                    <Tooltip title="Download Report" arrow={true}>
                      <Avatar sx={{ background: "#27b27c" }}>
                        <IconButton onClick={() => downloadPartnerUserMasterReport()} color="inherit">
                          <DownloadIcon />
                        </IconButton>
                      </Avatar>
                    </Tooltip>
                  </>
                )}
                <Tooltip title="Upload Categories Menu" arrow={true}>
                  <Avatar sx={{ background: "#27b27c" }}>
                    <IconButton ref={anchorUploadEl} onClick={() => setOpenUploadBulkMenu(true)} color="inherit">
                      <Upload fontSize={"medium"} />
                    </IconButton>
                  </Avatar>
                </Tooltip>
                <PopOverMenu
                  anchorEl={anchorUploadEl}
                  open={openUploadBulkMenu}
                  closeFn={() => {
                    setOpenUploadBulkMenu(false);
                  }}
                >
                  <MenuItem onClick={() => uploadInput.current?.click()}>
                    {showLoadingIconUpload ? null : (
                      <span className="file-icon">
                        <i className="fas fa-upload" />
                      </span>
                    )}
                    {showLoadingIconUpload ? (
                      <span className="file-icon">
                        <i className="fas fa-circle-notch fa-spin" />
                      </span>
                    ) : null}
                    Upload Categories in Bulk
                    <input
                      ref={uploadInput}
                      hidden={true}
                      accept=".xlsx"
                      multiple={true}
                      required={true}
                      type="file"
                      onChange={(e) => {
                        uploadCategoriesInBulk(e.target.files);
                        e.target.value = null; // Clear the selected files from the input field
                      }}
                    />
                  </MenuItem>
                  <MenuItem onClick={downloadUploadCategoriesInBulkTemplate}>
                    <span className="file-icon">
                      <i className="fas fa-download" />
                    </span>
                    Download Categories Format
                  </MenuItem>
                </PopOverMenu>
              </Box>
            </Grid>

            {/* Filter Applied Bar */}
            {Object.values(appliedFilters).filter((v) => v).length > 0 && (
              <FilterAppliedBar appliedFilters={appliedFilters} />
            )}
            <div className="bpDashboard ">
              <Grid>
                {partnerMasterSelected === "Partner use case master" && (
                  <BpDashboardTable
                    columns={columnDefinitionPartnerUseCaseMaster}
                    rows={rowsDataPartnerUseCaseMaster}
                    sortEnable={true}
                    loading={rowsLoading}
                  />
                )}
                {partnerMasterSelected === "Partner user master" && (
                  <BpDashboardTable
                    columns={columnDefinitionPartnerUserMaster}
                    rows={rowsDataPartnerUserMaster}
                    sortEnable={true}
                    loading={rowsLoading}
                  />
                )}
              </Grid>
            </div>
          </Grid>
        </Grid>
      )}
      <Dialog
        open={openPartnerUserBulkUploadErrorReport}
        onClose={() => setOpenPartnerUserBulkUploadErrorReport(true)}
        width70Per={true}
      >
        <PartnerUserBulkUploadErrorReport
          setOpenPartnerUserBulkUploadErrorReport={setOpenPartnerUserBulkUploadErrorReport}
          rowsDataPartnerUserBulkUploadErrorReport={rowsDataPartnerUserBulkUploadErrorReport}
          numberOfUsersFailed={numberOfUsersFailed}
          numberOfUsersSaved={numberOfUsersSaved}
          errorListsPartnerUsersBase64={errorListsPartnerUsersBase64}
        />
      </Dialog>
      <Dialog
        open={openListBusinessPartnerUsers}
        onClose={() => setOpenListBusinessPartnerUsers(true)}
        width70Per={true}
      >
        <ListBusinessPartnerUsers
          setOpenListBusinessPartnerUsers={setOpenListBusinessPartnerUsers}
          storeRowOpenListBusinessPartnerUsers={storeRowOpenListBusinessPartnerUsers}
          companyId={companyId.current}
          branchCode={branchCode.current}
        />
      </Dialog>
      <PartnerUseCaseMasterFilter
        openPartnerUseCaseMasterFilter={openPartnerUseCaseMasterFilter}
        setOpenPartnerUseCaseMasterFilter={setOpenPartnerUseCaseMasterFilter}
        allCategories={storeAllCategories.current}
        rowsDataPartnerUseCaseMaster={rowsDataPartnerUseCaseMaster}
        setRowsDataPartnerUseCaseMaster={setRowsDataPartnerUseCaseMaster}
        storeRowsDataPartnerUseCaseMaster={storeRowsDataPartnerUseCaseMaster}
        setIsFilterAppliedPartnerUseCaseMaster={setIsFilterAppliedPartnerUseCaseMaster}
        setAppliedFilters={setAppliedUCMFilters}
      />
      <PartnerUserMasterFilter
        openPartnerUserMasterFilter={openPartnerUserMasterFilter}
        setOpenPartnerUserMasterFilter={setOpenPartnerUserMasterFilter}
        rowsDataPartnerUserMaster={rowsDataPartnerUserMaster}
        setRowsDataPartnerUserMaster={setRowsDataPartnerUserMaster}
        storeRowsDataPartnerUserMaster={storeRowsDataPartnerUserMaster}
        setIsFilterAppliedPartnerUserMaster={setIsFilterAppliedPartnerUserMaster}
        storePartnerUserMasterData={storePartnerUserMasterData}
        listAllBusinessPartnersForPartnerUserMaster={listAllBusinessPartnersForPartnerUserMaster}
        setAppliedFilters={setAppliedUMFilters}
      />

      {/* edit partner dialog */}
      {openBusinessPopup ? (
        <EditBusinessPartner
          openBusinessPopup={openBusinessPopup}
          setOpenBusinessPopup={setOpenBusinessPopup}
          businessPartnerId={businessPartnerId}
          companyId={companyId.current}
          branchCode={branchCode.current}
          storePartnerUseCaseMasterData={storePartnerUseCaseMasterData}
          afterDelete={() => {
            listAllBusinessPartnersForPartnerUseCaseMaster();
            setOpenBusinessPopup(false);
          }}
        />
      ) : null}

      <InvalidEmailDialog
        open={openUnverified}
        setOpen={setOpenUnverified}
        afterClose={() => {
          listAllBusinessPartnersForPartnerUserMaster();
          listAllBusinessPartnersForPartnerUseCaseMaster();
        }}
        companyId={companyId.current}
        branchCode={branchCode.current}
      />
    </LoggedInSkeleton>
  );
};

interface PopOverMenuProps {
  anchorEl: React.MutableRefObject<HTMLElement>;
  open: boolean;
  closeFn: () => void;
  clickFn?: () => void;
  children: React.ReactElement<typeof MenuItem>[];
}
const PopOverMenu = ({ anchorEl, open, closeFn, clickFn, children }: PopOverMenuProps) => {
  return (
    <Menu
      anchorEl={anchorEl.current}
      open={open}
      keepMounted={true}
      onClose={closeFn}
      onClick={clickFn}
      PopoverClasses={{ root: "zIndex_10" }}
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: "visible",
          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
          mt: 1.5,
          "&:before": {
            content: '""',
            display: "block",
            position: "absolute",
            top: 0,
            right: 14,
            width: 10,
            height: 10,
            bgcolor: "background.paper",
            transform: "translateY(-50%) rotate(45deg)",
            zIndex: 0,
          },
        },
      }}
      transformOrigin={{ horizontal: "right", vertical: "top" }}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
    >
      {children as unknown as JSX.Element[]}
    </Menu>
  );
};

export default PartnerMaster;
