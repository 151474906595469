import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import React from "react";
import LoadingIcon from "src/Components/Common/LoadingIcon";
import { uiLoggerNamesTaskBoard, uiLoggerNamesWorkflow } from "src/Utils/Recon/UiLogger/Constants";
import uiLogger from "src/Utils/UiLogger";
import { NdButton } from "../PartnerCommunication/MsmePartnerPortal/CommonComponents";
import { StateDispatch } from "../Recon360";
import { TicketMetaStatuses } from "./ViewTickets";

interface ClearBulkTasksConfirmationModalProps {
  open: boolean;
  setOpen: StateDispatch<boolean>;
  tasksCount: number;
  updateMetaStatusForBulkTasks: any;
  isLoading: boolean;
  companyId: string;
  branchCode: string;
  type: string;
}
export default function ClearBulkTasksConfirmationModal({
  type,
  open,
  setOpen,
  tasksCount,
  updateMetaStatusForBulkTasks,
  isLoading,
  companyId,
  branchCode,
}: ClearBulkTasksConfirmationModalProps) {
  return (
    <Dialog open={open} sx={{ "& .MuiDialog-paper": { borderRadius: "4px", minWidth: "600px" } }}>
      <DialogTitle>Are you sure you want to Clear {tasksCount} Tasks?</DialogTitle>
      <DialogContent>
        <Alert severity="info">
          This action will move {tasksCount} Tasks to <span className="fw_700">previous tasks section.</span>This action
          cannot be reverted
        </Alert>
      </DialogContent>
      <DialogActions sx={{ padding: 2 }}>
        <Button
          variant="text"
          onClick={() => {
            if (type === "taskBoard") {
              uiLogger(
                uiLoggerNamesTaskBoard.UI_WF_TB_BULKTASKS_CLEARTASKS_CANCEL_CLICK.functionName,
                companyId,
                branchCode,
                {
                  message: uiLoggerNamesTaskBoard.UI_WF_TB_BULKTASKS_CLEARTASKS_CANCEL_CLICK.message,
                }
              );
            }
            if (type === "workflow") {
              uiLogger(
                uiLoggerNamesWorkflow.UI_WF_DB_BULKTASKS_CLEARTASKS_CANCEL_CLICK.functionName,
                companyId,
                branchCode,
                {
                  message: uiLoggerNamesWorkflow.UI_WF_DB_BULKTASKS_CLEARTASKS_CANCEL_CLICK.message,
                }
              );
            }
            setOpen(false);
          }}
        >
          Cancel
        </Button>
        <NdButton
          disabled={isLoading}
          variant="contained"
          onClick={() => {
            if (type === "taskBoard") {
              uiLogger(
                uiLoggerNamesTaskBoard.UI_WF_TB_BULKTASKS_CLEARTASKS_CLEAR_CLICK.functionName,
                companyId,
                branchCode,
                {
                  message: uiLoggerNamesTaskBoard.UI_WF_TB_BULKTASKS_CLEARTASKS_CLEAR_CLICK.message,
                }
              );
            }
            if (type === "workflow") {
              uiLogger(
                uiLoggerNamesWorkflow.UI_WF_DB_BULKTASKS_CLEARTASKS_CLEAR_CLICK.functionName,
                companyId,
                branchCode,
                {
                  message: uiLoggerNamesWorkflow.UI_WF_DB_BULKTASKS_CLEARTASKS_CLEAR_CLICK.message,
                }
              );
            }
            updateMetaStatusForBulkTasks(TicketMetaStatuses.Cleared);
          }}
        >
          <LoadingIcon loading={isLoading} />
          Clear
        </NdButton>
      </DialogActions>
    </Dialog>
  );
}
