import DeleteIcon from "@mui/icons-material/Delete";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Tab,
  Tabs,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { ColumnDef } from "@tanstack/react-table";

import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import RefreshIcon from "@mui/icons-material/Refresh";
import RestoreIcon from "@mui/icons-material/Restore";
import dayjs from "dayjs";
import { Currency } from "dinero.js";
import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import LoadingIcon from "src/Components/Common/LoadingIcon";
import useFetch from "src/Components/Common/useFetch";
import ReactTable from "src/Components/ReactTable/ReactTable";
import { formatMoney, ToDineroObj } from "src/Utils/MoneyUtils";
import uiLogger from "src/Utils/UiLogger";
import { MatchedEntriesForManualRecon } from "../../../entity/recon-entity/ReconInterfaces";
import sparkles from "../../../Graphics/AutomatedRecon/ManualRecon/sparkles.svg";
import suggestedMatchLogo from "../../../Graphics/AutomatedRecon/ManualRecon/suggested matches.png";
import FormatDate, { filterDateBetweenStartAndEndDate, formatDate } from "../../../Utils/DateUtils";
import {
  FetchAndUpdateType,
  gapAccountedAsName,
  ManualReconHistoryRecordState,
  OptionsFilterEntries,
  showEntriesSuggestion,
} from "../../../Utils/Recon/Recon360/Constants";
import { uiLoggerNamesRecon } from "../../../Utils/Recon/UiLogger/Constants";
import IndeterminateCheckbox from "../../Admin/IndeterminateCheckbox";
import BpDashboardTable from "../../ReactTable/BpDashboardTable";
import { Recon360Context } from "../Recon360";
import { a11yProps, TabPanel } from "../ReconSettings/ReconSettings";
import DeleteAllMatches from "./DeleteAllMatches";
import "./ManualRecon.scss";
import ManualReconReconcileBpFilter from "./ManualReconReconcileBpFilter";
import ManualReconReconcileOwnFilter from "./ManualReconReconcileOwnFilter";
import ReconcileConfirmWarningModal from "./ReconcileConfirmWarningModal";
import UnreconcileConfirmWarningModal from "./UnreconcileConfirmWarningModal";

type ColDefOld = ColumnDef<any> & {
  sticky?: "left" | "right";
};

const ActiveTableInterface = {
  ReconcileTable: "ReconcileTable",
  SuggestedMatchesTable: "SuggestedMatchesTable",
  ManualReconcileHistoryTable: "ManualReconcileHistoryTable",
};

type StateDispatch<T = any> = Dispatch<SetStateAction<T>>;

export interface ManualReconContextInterface {
  isOpenUnreconcileConfirmWarningModal: boolean;
  setIsOpenUnreconcileConfirmWarningModal: StateDispatch<boolean>;
  isOpenReconcileConfirmWarningModal: boolean;
  setIsOpenReconcileConfirmWarningModal: StateDispatch<boolean>;
  unReconcileEntriesOnMatchId: () => void;
  reconcileEntries: (val) => void;
  reconcileTotalSum: number;
  selectedRowOwn: any[];
  selectedRowBp: any[];
  amountTolerance: number;
  openManualReconReconcileOwnFilter: boolean;
  setOpenManualReconReconcileOwnFilter: StateDispatch<boolean>;
  storeRowsDataReconciledOwn: any[];
  entryNumOwn: string;
  setEntryNumOwn: StateDispatch<string>;
  setIsFilterAppliedOwnSide: StateDispatch<boolean>;
  setRowsDataReconciledOwn: StateDispatch<any[]>;
  openManualReconReconcileBpFilter: boolean;
  setOpenManualReconReconcileBpFilter: StateDispatch<boolean>;
  storeRowsDataReconciledBp: any[];
  entryNumBp: string;
  setEntryNumBp: StateDispatch<string>;
  setIsFilterAppliedBpSide: StateDispatch<boolean>;
  setRowsDataReconciledBp: StateDispatch<any[]>;
  absoluteReconcileTotalSum: number;
  showLoadingIconUnreconcile: boolean;
  showLoadingIconReconcile: boolean;
  gapAccountedName: string;
  setGapAccountedName: StateDispatch<string>;
  reFilter: boolean;
  setReFilter: StateDispatch<boolean>;
  isFilterAppliedOwnSide: boolean;
  isFilterAppliedBpSide: boolean;
  reconcileTotalPercentage: any;
  unreconcileRemarks: string;
  setUnreconcileRemarks: StateDispatch<string>;
  reconcileRemarks: string;
  setReconcileRemarks: StateDispatch<string>;
  getSuggestedHistory: (val) => void;
  createAndFetchManualReconSuggestions: (val) => void;
  openDeleteAllMatchesModal: boolean;
  setOpenDeleteAllMatchesModal: StateDispatch<boolean>;
  loadingDeleteAllMatches: boolean;
  deleteNotAppliedHistory: () => void;
}

export const ManualReconContext = createContext<ManualReconContextInterface>(null);

const ManualRecon = () => {
  const {
    currency,
    businessPartnerSelected,
    businessPartnerSelectedRef,
    // businessPartnerSelectedCompanyIdRef,
    companyId,
    branchCode,
    amountTolerance,
    setOpenManualRecon,
    getSummaryForReconciliationCommonFunction,
  } = useContext(Recon360Context);

  const [rowsDataUnreconciled, setRowsDataUnreconciled] = useState<MatchedEntriesForManualRecon[]>([]);
  const [rowsDataReconciledOwn, setRowsDataReconciledOwn] = useState([]);
  const [storeRowsDataReconciledOwn, setStoreRowsDataReconciledOwn] = useState([]);
  const [rowsDataReconciledBp, setRowsDataReconciledBp] = useState([]);
  const [storeRowsDataReconciledBp, setStoreRowsDataReconciledBp] = useState([]);
  const [matchIdValue, setMatchIdValue] = useState<string>("");
  const [isOpenUnreconcileConfirmWarningModal, setIsOpenUnreconcileConfirmWarningModal] = useState<boolean>(false);
  const [isOpenReconcileConfirmWarningModal, setIsOpenReconcileConfirmWarningModal] = useState<boolean>(false);
  const [isFilterAppliedOwnSide, setIsFilterAppliedOwnSide] = useState<boolean>(false);
  const [isFilterAppliedBpSide, setIsFilterAppliedBpSide] = useState<boolean>(false);
  const [entryNumOwn, setEntryNumOwn] = useState<string>("");
  const [entryNumBp, setEntryNumBp] = useState<string>("");
  const [selectedEntryNumbersOwn, setSelectedEntryNumbersOwn] = useState([]);
  const [selectedEntryNumbersBp, setSelectedEntryNumbersBp] = useState([]);
  const [reconcileTotalSum, setReconcileTotalSum] = useState<number>(0);
  const [reconcileTotalPercentage, setReconcileTotalPercentage] = useState<any>(0);
  const [reconcileOwnSum, setReconcileOwnSum] = useState<number>(0);
  const [reconcileBpSum, setReconcileBpSum] = useState<number>(0);
  const [absoluteReconcileTotalSum, setAbsoluteReconcileTotalSum] = useState<number>(0);
  const [selectedRowOwn, setSelectedRowOwn] = useState<any[]>([]);
  const [selectedRowBp, setSelectedRowBp] = useState<any[]>([]);
  const textFieldRefUnReconcile = useRef(null);
  const textFieldRefReconcileOwn = useRef(null);
  const textFieldRefReconcileBp = useRef(null);
  const [openManualReconReconcileOwnFilter, setOpenManualReconReconcileOwnFilter] = useState<boolean>(false);
  const [openManualReconReconcileBpFilter, setOpenManualReconReconcileBpFilter] = useState<boolean>(false);
  const [showLoadingIconUnreconcile, setShowLoadingIconUnreconcile] = useState<boolean>(false);
  const [showLoadingIconReconcile, setShowLoadingIconReconcile] = useState<boolean>(false);
  const [gapAccountedName, setGapAccountedName] = useState<string>("");
  const [reFilter, setReFilter] = useState(false);
  const [value, setValue] = useState<number>(0);
  const [suggestionsPresent, setSuggestionsPresent] = useState(false);
  // const [loader, setLoader] = useState(true);
  const [statusDataFetching, setStatusDataFetching] = useState(false);
  const [reconcileSuggestionsLoading, setReconcileSuggestionsLoading] = useState(false);
  const [selectedSuggestedEntry, setSelectedSuggestedEntry] = useState([]);
  const [rowsDataSuggestion, setRowsDataSuggestion] = useState([]);

  const [showEntries, setShowEntries] = useState(null);
  const [suggestedEntryTrackIndex, setSuggestedEntryTrackIndex] = useState(null);
  const [selectedFilterEntry, setSelectedFilterEntry] = useState(null);
  const [entryNumChangeStatus, setEntryNumChangeStatus] = useState("");
  const [matchIDChangeStatus, setMatchIDChangeStatus] = useState("");
  const [selectLedger, setSelectLedger] = useState(null);
  const [optionsReconcileStatus, setOptionsReconcileStatus] = useState([]);
  const [selectedOptionReconcileStatus, setSelectedOptionReconcileStatus] = useState(null);
  const [selectedOptionChangeReconcileStatus, setSelectedOptionChangeReconcileStatus] = useState(null);
  const [showChangeStatus, setShowChangeStatus] = useState(false);
  const [rowsDataChangeReconcileStatus, setRowsDataChangeReconcileStatus] = useState([]);
  const [getReconciledEntriesLoading, setGetReconciledEntriesLoading] = useState(false);
  const [selectedRowChangeReconcileStatus, setSelectedRowChangeReconcileStatus] = useState([]);
  const [updateReconStatusManuallyLoading, setUpdateReconStatusManuallyLoading] = useState(false);
  const autoOutOfPeriodLoading = false;
  const [loadingReconcileTableOwn, setLoadingReconcileTableOwn] = useState(false);
  const [loadingReconcileTableBp, setLoadingReconcileTableBp] = useState(false);

  const [clearRowsOwn, setClearRowsOwn] = useState(false);
  const [clearRowsBp, setClearRowsBp] = useState(false);
  const [activeTable, setActiveTable] = useState<string>(ActiveTableInterface.ReconcileTable);
  const [unreconcileRemarks, setUnreconcileRemarks] = useState("");
  const [reconcileRemarks, setReconcileRemarks] = useState("");
  const [suggestedHistoryPresent, setSuggestedHistoryPresent] = useState(false);
  const [rowsDataSuggestedHistoryPresent, setRowsDataSuggestedHistoryPresent] = useState([]);
  const [selectedSuggestedHistoryPresent, setSelectedSuggestedHistoryPresent] = useState([]);
  const [getSuggestedHistoryLoading, setGetSuggestedHistoryLoading] = useState(false);
  const [rowsDataAppliedHistory, setRowsDataAppliedHistory] = useState([]);
  const [selectedAppliedHistory, setSelectedAppliedHistory] = useState([]);
  const [loadingAppliedHistory, setLoadingAppliedHistory] = useState(true);
  const [loadingRevertAppliedHistory, setLoadingRevertAppliedHistory] = useState(false);
  const [lastSuggestionCreateDateTime, setLastSuggestionCreateDateTime] = useState("");
  const [openDeleteAllMatchesModal, setOpenDeleteAllMatchesModal] = useState(false);
  const [loadingDeleteAllMatches, setLoadingDeleteAllMatches] = useState(false);

  const columnDefinitionManualReconcileHistoryTable = useMemo(
    (): any => [
      {
        header: "Own",
        id: "own",
        columns: [
          {
            header: "Entry no.",
            id: "own_entry_no",
            size: 130,
            accessorFn: (row) => row?.ownEntries?.map((item) => item.entryNumber) || [],
            Cell: ({ row }: any) => (
              <div>
                {row.original?.ownEntries?.map((item, index) => (
                  <div key={index} className="textOverflow_hidden" title={item?.entryNumber || ""}>
                    {item?.entryNumber || "-"}
                  </div>
                ))}
              </div>
            ),
          },
          {
            header: "Date",
            id: "own_date",
            size: 120,
            accessorFn: (row) => row?.ownEntries?.map((item) => item.documentDate) || [],
            minSize: 130,
            maxSize: 360,
            filterVariant: "date-range",

            filterFn: (row, _id, filterValue, _meta) => {
              const minDate = filterValue[0] ? dayjs(filterValue[0]).toDate() : null;
              const maxDate = filterValue[1] ? dayjs(filterValue[1]).toDate() : null;
              if (minDate || maxDate) {
                return row.original.ownEntries?.some((item) =>
                  filterDateBetweenStartAndEndDate(minDate, maxDate, item.documentDate)
                );
              }
              return true; // No filter applied
            },
            Cell: ({ row }: any) => (
              <div>
                {row.original?.ownEntries?.map((item, index) => (
                  <div key={index}>{item.documentDate ? formatDate(item.documentDate) : "-"}</div>
                ))}
              </div>
            ),
          },
          {
            header: "Reference",
            id: "own_reference",
            size: 100,
            accessorFn: (row) => row?.ownEntries?.map((item) => item.referenceNumber) || [],
            Cell: ({ row }: any) => (
              <div>
                {row.original?.ownEntries?.map((item, index) => (
                  <div key={index} className="textOverflow_hidden" title={item?.referenceNumber || ""}>
                    {item?.referenceNumber || "-"}
                  </div>
                ))}
              </div>
            ),
          },
          {
            header: "Type",
            id: "own_type",
            size: 100,
            accessorFn: (row) => row?.ownEntries?.map((item) => item.entryType) || [],
            Cell: ({ row }: any) => (
              <div>
                {row.original?.ownEntries?.map((item, index) => (
                  <div key={index} className="textOverflow_hidden" title={item?.entryType || ""}>
                    {item?.entryType || "-"}
                  </div>
                ))}
              </div>
            ),
          },
          {
            header: "Amount",
            id: "own_amount",
            size: 130,
            accessorFn: (row) => row?.ownEntries?.map((item) => item.amount) || [],
            Cell: ({ row }: any) => (
              <div>
                {row.original?.ownEntries?.map((item, index) => (
                  <div
                    key={index}
                    className="textOverflow_hidden"
                    title={
                      item?.amount !== null ? formatMoney(ToDineroObj(item.amount, currency.current as Currency)) : ""
                    }
                  >
                    {item?.amount !== null ? formatMoney(ToDineroObj(item.amount, currency.current as Currency)) : "-"}
                  </div>
                ))}
              </div>
            ),
          },
          {
            header: "Doc Type",
            id: "own_doc_type",
            size: 120,
            accessorFn: (row) => row?.ownEntries?.map((item) => item.documentType) || [],
            Cell: ({ row }: any) => (
              <div>
                {row.original?.ownEntries?.map((item, index) => (
                  <div key={index} className="textOverflow_hidden" title={item?.documentType || ""}>
                    {item?.documentType || "-"}
                  </div>
                ))}
              </div>
            ),
          },
        ],
      },
      {
        header: "Partner",
        id: "bpUnreconcile",
        columns: [
          {
            header: "Entry no.",
            id: "bp_entry_no",
            size: 130,
            accessorFn: (row) => row?.bpEntries?.map((item) => item.entryNumber) || [],
            Cell: ({ row }: any) => (
              <div>
                {row.original?.bpEntries?.map((item, index) => (
                  <div key={index} className="textOverflow_hidden" title={item?.entryNumber || ""}>
                    {item?.entryNumber || "-"}
                  </div>
                ))}
              </div>
            ),
          },
          {
            header: "Date",
            id: "bp_date",
            size: 120,
            accessorFn: (row) => row?.bpEntries?.map((item) => item.documentDate) || [],
            minSize: 130,
            maxSize: 360,
            filterVariant: "date-range",

            filterFn: (row, _id, filterValue, _meta) => {
              const minDate = filterValue[0] ? dayjs(filterValue[0]).toDate() : null;
              const maxDate = filterValue[1] ? dayjs(filterValue[1]).toDate() : null;
              if (minDate || maxDate) {
                return row.original.bpEntries?.some((item) =>
                  filterDateBetweenStartAndEndDate(minDate, maxDate, item.documentDate)
                );
              }
              return true; // No filter applied
            },
            Cell: ({ row }: any) => (
              <div>
                {row.original?.bpEntries?.map((item, index) => (
                  <div key={index}>{item.documentDate ? formatDate(item.documentDate) : "-"}</div>
                ))}
              </div>
            ),
          },
          {
            header: "Reference",
            id: "bp_reference",
            size: 100,
            accessorFn: (row) => row?.bpEntries?.map((item) => item.referenceNumber) || [],
            Cell: ({ row }: any) => (
              <div>
                {row.original?.bpEntries?.map((item, index) => (
                  <div key={index} className="textOverflow_hidden" title={item?.referenceNumber || ""}>
                    {item?.referenceNumber || "-"}
                  </div>
                ))}
              </div>
            ),
          },
          {
            header: "Type",
            id: "bp_type",
            size: 100,
            accessorFn: (row) => row?.bpEntries?.map((item) => item.entryType) || [],
            Cell: ({ row }: any) => (
              <div>
                {row.original?.bpEntries?.map((item, index) => (
                  <div key={index} className="textOverflow_hidden" title={item?.entryType || ""}>
                    {item?.entryType || "-"}
                  </div>
                ))}
              </div>
            ),
          },
          {
            header: "Amount",
            id: "bp_amount",
            size: 130,
            accessorFn: (row) => row?.bpEntries?.map((item) => item.amount) || [],
            Cell: ({ row }: any) => (
              <div>
                {row.original?.bpEntries?.map((item, index) => (
                  <div
                    key={index}
                    className="textOverflow_hidden"
                    title={
                      item?.amount !== null ? formatMoney(ToDineroObj(item.amount, currency.current as Currency)) : ""
                    }
                  >
                    {item?.amount !== null ? formatMoney(ToDineroObj(item.amount, currency.current as Currency)) : "-"}
                  </div>
                ))}
              </div>
            ),
          },
          {
            header: "Doc Type",
            id: "bp_doc_type",
            size: 120,
            accessorFn: (row) => row?.bpEntries?.map((item) => item.documentType) || [],
            Cell: ({ row }: any) => (
              <div>
                {row.original?.bpEntries?.map((item, index) => (
                  <div key={index} className="textOverflow_hidden" title={item?.documentType || ""}>
                    {item?.documentType || "-"}
                  </div>
                ))}
              </div>
            ),
          },
        ],
      },
      {
        header: "",
        id: "amtdiff",
        columns: [
          {
            header: "Date difference",
            id: "selection_date_difference",
            accessorFn: (row) => row?.dateDifference,
            size: 130,
            Cell: ({ row }: any) => <div>{row.original?.dateDifference}</div>,
          },
          {
            header: "Amount difference",
            id: "selection_amount_difference",
            size: 130,
            accessorFn: (row) => row.amountDifference,
            Cell: ({ row }: any) => (
              <div
                className="textOverflow_hidden"
                title={
                  row.original?.amountDifference !== null
                    ? formatMoney(ToDineroObj(row.original?.amountDifference, currency.current as Currency))
                    : ""
                }
              >
                {row.original?.amountDifference !== null
                  ? formatMoney(ToDineroObj(row.original?.amountDifference, currency.current as Currency))
                  : "-"}
              </div>
            ),
          },
          {
            header: "Percentage difference",
            id: "selection_percentage_difference",
            accessorFn: (row) => row?.percentageDifference || "",
            size: 130,
            Cell: ({ row }: any) => <div>{row.original?.percentageDifference || "-"}</div>,
          },
          {
            header: "Action",
            accessorKey: "action",
            size: 130,
          },
          {
            header: "Status",
            accessorKey: "status",
            size: 130,
          },
          {
            header: "Comments",
            accessorKey: "comments",
            size: 130,
            Cell: ({ row }: any) => {
              const text = row.original?.comments || "";
              const truncatedText = text.length > 15 ? text.substring(0, 15) + " ..." : text;
              return <div title={text}>{truncatedText}</div>;
            },
          },
        ],
      },
    ],
    []
  );

  // columnDefinitionSuggestionViewMore----- start
  const columnDefinitionSuggestionViewMore: any[] = [];

  // Conditionally include "Own" section
  if (showEntries !== showEntriesSuggestion.BP) {
    columnDefinitionSuggestionViewMore.push({
      header: "Own",
      id: "own",
      columns: [
        {
          header: "Entry no.",
          id: "own_entry_no",
          size: 130,
          accessorFn: (row) => row?.ownEntries?.map((item) => item.entryNumber) || [],
          Cell: ({ row }: any) => (
            <div>
              {row.original?.ownEntries?.map((item, index) => (
                <div key={`${item.entryNumber}_${index}`} className="textOverflow_hidden" title={item.entryNumber}>
                  {item.entryNumber}
                </div>
              ))}
            </div>
          ),
        },
        {
          header: "Date",
          id: "own_date",
          size: 120,
          accessorFn: (row) => row?.ownEntries?.map((item) => item.documentDate) || [],
          minSize: 130,
          maxSize: 360,
          filterVariant: "date-range",

          filterFn: (row, _id, filterValue, _meta) => {
            const minDate = filterValue[0] ? dayjs(filterValue[0]).toDate() : null;
            const maxDate = filterValue[1] ? dayjs(filterValue[1]).toDate() : null;
            if (minDate || maxDate) {
              return row.original.ownEntries?.some((item) =>
                filterDateBetweenStartAndEndDate(minDate, maxDate, item.documentDate)
              );
            }
            return true; // No filter applied
          },
          Cell: ({ row }: any) => (
            <div>
              {row.original?.ownEntries?.map((item, index) => (
                <div key={`${item.documentDate}_${index}`}>{formatDate(item.documentDate)}</div>
              ))}
            </div>
          ),
        },
        {
          header: "Reference",
          id: "own_reference",
          size: 100,
          accessorFn: (row) => row?.ownEntries?.map((item) => item.referenceNumber) || [],
          Cell: ({ row }: any) => (
            <div>
              {row.original?.ownEntries?.map((item, index) => (
                <div
                  key={`${item.referenceNumber}_${index}`}
                  className="textOverflow_hidden"
                  title={item.referenceNumber}
                >
                  {item.referenceNumber}
                </div>
              ))}
            </div>
          ),
        },
        {
          header: "Type",
          id: "own_type",
          size: 100,
          accessorFn: (row) => row?.ownEntries?.map((item) => item.entryType) || [],
          Cell: ({ row }: any) => (
            <div>
              {row.original?.ownEntries?.map((item, index) => (
                <div key={`${item.entryType}_${index}`} className="textOverflow_hidden" title={item.entryType}>
                  {item.entryType}
                </div>
              ))}
            </div>
          ),
        },
        {
          header: "Amount",
          id: "own_amount",
          size: 130,
          accessorFn: (row) => row?.ownEntries?.map((item) => item.amount) || [],
          Cell: ({ row }: any) => (
            <div>
              {row.original?.ownEntries?.map((item, index) => (
                <div
                  key={`${item.amount}_${index}`}
                  className="textOverflow_hidden"
                  title={formatMoney(ToDineroObj(item.amount, currency.current as Currency))}
                >
                  {item.amount !== null ? formatMoney(ToDineroObj(item.amount, currency.current as Currency)) : "-"}
                </div>
              ))}
            </div>
          ),
        },
        {
          header: "Doc Type",
          id: "own_doc_type",
          size: 120,
          accessorFn: (row) => row?.ownEntries?.map((item) => item.documentType) || [],
          Cell: ({ row }: any) => (
            <div>
              {row.original?.ownEntries?.map((item, index) => (
                <div key={`${item.documentType}_${index}`} className="textOverflow_hidden" title={item.documentType}>
                  {item.documentType}
                </div>
              ))}
            </div>
          ),
        },
      ],
    });
  }

  // Conditionally include "BP" section
  if (showEntries !== showEntriesSuggestion.OWN) {
    columnDefinitionSuggestionViewMore.push({
      header: "Partner",
      id: "bpUnreconcile",
      columns: [
        {
          header: "Entry no.",
          id: "bp_entry_no",
          size: 130,
          accessorFn: (row) => row?.partnerEntries?.map((item) => item.entryNumber) || [],
          Cell: ({ row }: any) => (
            <div>
              {row.original?.partnerEntries?.map((item, index) => (
                <div key={`${item.entryNumber}_${index}`} className="textOverflow_hidden" title={item.entryNumber}>
                  {item.entryNumber}
                </div>
              ))}
            </div>
          ),
        },
        {
          header: "Date",
          id: "bp_date",
          size: 120,
          accessorFn: (row) => row?.partnerEntries?.map((item) => item.documentDate) || [],
          minSize: 130,
          maxSize: 360,
          filterVariant: "date-range",

          filterFn: (row, _id, filterValue, _meta) => {
            const minDate = filterValue[0] ? dayjs(filterValue[0]).toDate() : null;
            const maxDate = filterValue[1] ? dayjs(filterValue[1]).toDate() : null;
            if (minDate || maxDate) {
              return row.original.partnerEntries?.some((item) =>
                filterDateBetweenStartAndEndDate(minDate, maxDate, item.documentDate)
              );
            }
            return true; // No filter applied
          },
          Cell: ({ row }: any) => (
            <div>
              {row.original?.partnerEntries?.map((item, index) => (
                <div key={`${item.documentDate}_${index}`}>{formatDate(item.documentDate)}</div>
              ))}
            </div>
          ),
        },
        {
          header: "Reference",
          id: "bp_reference",
          size: 100,
          accessorFn: (row) => row?.partnerEntries?.map((item) => item.referenceNumber) || [],
          Cell: ({ row }: any) => (
            <div>
              {row.original?.partnerEntries?.map((item, index) => (
                <div
                  key={`${item.referenceNumber}_${index}`}
                  className="textOverflow_hidden"
                  title={item.referenceNumber}
                >
                  {item.referenceNumber}
                </div>
              ))}
            </div>
          ),
        },
        {
          header: "Type",
          id: "bp_type",
          size: 100,
          accessorFn: (row) => row?.partnerEntries?.map((item) => item.entryType) || [],
          Cell: ({ row }: any) => (
            <div>
              {row.original?.partnerEntries?.map((item, index) => (
                <div key={`${item.entryType}_${index}`} className="textOverflow_hidden" title={item.entryType}>
                  {item.entryType}
                </div>
              ))}
            </div>
          ),
        },
        {
          header: "Amount",
          id: "bp_amount",
          size: 130,
          accessorFn: (row) => row?.partnerEntries?.map((item) => item.amount) || [],
          Cell: ({ row }: any) => (
            <div>
              {row.original?.partnerEntries?.map((item, index) => (
                <div
                  key={`${item.amount}_${index}`}
                  className="textOverflow_hidden"
                  title={formatMoney(ToDineroObj(item.amount, currency.current as Currency))}
                >
                  {item.amount !== null ? formatMoney(ToDineroObj(item.amount, currency.current as Currency)) : "-"}
                </div>
              ))}
            </div>
          ),
        },
        {
          header: "Doc Type",
          id: "bp_doc_type",
          size: 120,
          accessorFn: (row) => row?.partnerEntries?.map((item) => item.documentType) || [],
          Cell: ({ row }: any) => (
            <div>
              {row.original?.partnerEntries?.map((item, index) => (
                <div key={`${item.documentType}_${index}`} className="textOverflow_hidden" title={item.documentType}>
                  {item.documentType}
                </div>
              ))}
            </div>
          ),
        },
      ],
    });
  }

  // Always include the "Selection" section
  if (showEntries) {
    columnDefinitionSuggestionViewMore.push({
      header: "",
      id: "amtdiff",
      columns: [
        {
          header: "Date difference",
          id: "selection_date_difference",
          accessorFn: (row) => row?.dateDifference,
          size: 130,
        },
        {
          header: "Amount difference",
          id: "selection_amount_difference",
          size: 130,
          accessorFn: (row) => row.amountDifference,
          Cell: ({ row }: any) => (
            <div>
              <div
                className="textOverflow_hidden"
                title={formatMoney(ToDineroObj(row.original?.amountDifference, currency.current as Currency))}
              >
                {row.original?.amountDifference !== null
                  ? formatMoney(ToDineroObj(row.original?.amountDifference, currency.current as Currency))
                  : "-"}
              </div>
            </div>
          ),
        },
        {
          header: "Percentage difference",
          id: "selection_percentage_difference",
          accessorFn: (row) => row?.percentageDifference || "",
          size: 130,
        },
      ],
    });
  }
  // columnDefinitionSuggestionViewMore----- end

  const columnDefinitionUnreconciled: ColDefOld[] = [
    {
      header: "MATCH ID",
      accessorKey: "matchId",
      sticky: "left",
      size: 140,
    },
    {
      header: () => <div className="borderRight">Own</div>,
      id: "own",
      size: null,
      accessorKey: "",
      columns: [
        {
          header: "Date",
          accessorKey: "ownDate",
          size: 140,
          cell: ({ row }) => (row.original.ownDate ? FormatDate(row.original.ownDate) : ""),
        },
        {
          header: "Doc Type",
          accessorKey: "ownDocumentType",
          size: 100,
        },
        {
          header: "Reference",
          accessorKey: "ownReferenceNumber",
          size: 180,
        },
        {
          header: "Amount",
          accessorKey: "ownAmount",
          size: 180,
          cell: ({ row }: any) => (
            <div>
              {row.original.ownAmount !== null
                ? formatMoney(ToDineroObj(row.original.ownAmount, currency.current as Currency))
                : "-"}
            </div>
          ),
        },
        {
          header: "Type",
          accessorKey: "ownType",
          size: 150,
        },
        {
          header: "Posting Date",
          accessorKey: "ownPostingDate",
          size: 150,
          cell: ({ row }) => (row.original.ownPostingDate ? FormatDate(row.original.ownPostingDate) : ""),
        },
      ],
    },
    {
      header: () => <div className="borderLeft">Partner</div>,
      id: "bpUnreconcile",
      size: null,
      accessorKey: "",
      columns: [
        {
          header: "Date",
          accessorKey: "businessPartnerDate",
          size: 120,
          cell: ({ row }) => (row.original.businessPartnerDate ? FormatDate(row.original.businessPartnerDate) : ""),
        },
        {
          header: "Doc Type",
          accessorKey: "businessPartnerDocumentType",
          size: 100,
        },
        {
          header: "Reference",
          accessorKey: "businessPartnerReferenceNumber",
          size: 180,
        },
        {
          header: "Amount",
          accessorKey: "businessPartnerAmount",
          size: 180,
          cell: ({ row }: any) => (
            <div>
              {row.original.businessPartnerAmount !== null
                ? formatMoney(ToDineroObj(row.original.businessPartnerAmount, currency.current as Currency))
                : "-"}
            </div>
          ),
        },
        {
          header: "Type",
          accessorKey: "businessPartnerType",
          size: 150,
        },
        {
          header: "Posting Date",
          accessorKey: "businessPartnerPostingDate",
          size: 150,
          cell: ({ row }) =>
            row.original.businessPartnerPostingDate ? FormatDate(row.original.businessPartnerPostingDate) : "",
        },
      ],
    },
  ];

  // columnDefinitionChangeReconcileStatus----- start
  const columnDefinitionChangeReconcileStatus: ColDefOld[] = [
    {
      id: "selection",
      sticky: "left",
      // The header can use the table's getToggleAllRowsSelectedProps method
      // to render a checkbox
      header: ({ table }) => (
        <div className="center_align_ver_horiz">
          <IndeterminateCheckbox
            checked={table.getIsAllRowsSelected()}
            indeterminate={table.getIsSomeRowsSelected()}
            onChange={table.getToggleAllRowsSelectedHandler()}
          />
        </div>
      ),
      // The cell can use the individual row's getToggleRowSelectedProps method
      // to the render a checkbox
      cell: ({ row }) => (
        <div className="center_align_ver_horiz">
          <IndeterminateCheckbox checked={row.getIsSelected()} onChange={row.getToggleSelectedHandler()} />
        </div>
      ),
      size: 35,
    },
    {
      header: "MATCH ID",
      accessorKey: "matchId",
      sticky: "left",
      size: 140,
    },
  ];

  if (selectLedger !== showEntriesSuggestion.BP) {
    columnDefinitionChangeReconcileStatus.push({
      header: () => <div className="borderRight">Own</div>,
      id: "own",
      size: null,
      accessorKey: "",
      columns: [
        {
          header: "Date",
          accessorKey: "ownDate",
          size: 140,
          cell: ({ row }) => (row.original.ownDate ? FormatDate(row.original.ownDate) : ""),
        },
        {
          header: "Doc Type",
          accessorKey: "ownDocumentType",
          size: 100,
        },
        {
          header: "Reference",
          accessorKey: "ownReferenceNumber",
          size: 180,
        },
        {
          header: "Amount",
          accessorKey: "ownAmount",
          size: 180,
          cell: ({ row }: any) => (
            <div>
              {row.original.ownAmount !== null
                ? formatMoney(ToDineroObj(row.original.ownAmount, currency.current as Currency))
                : "-"}
            </div>
          ),
        },
        {
          header: "Type",
          accessorKey: "ownType",
          size: 150,
        },
        // {
        //   header: "Credit Amount",
        //   accessorKey: "ownCreditAmount",
        //   maxSize: 150,
        //   minSize: 150,
        //   size: 150,
        //   cell: ({ row }: any) => <div>{formatNumberINR(row.original.ownCreditAmount)}</div>,
        // },
        // {
        //   header: "Debit Amount",
        //   accessorKey: "ownDebitAmount",
        //   maxSize: 150,
        //   minSize: 150,
        //   size: 150,
        //   cell: ({ row }: any) => <div>{formatNumberINR(row.original.ownDebitAmount)}</div>,
        // },
        {
          header: "Posting Date",
          accessorKey: "ownPostingDate",
          size: 150,
          cell: ({ row }) => (row.original.ownPostingDate ? FormatDate(row.original.ownPostingDate) : ""),
        },
      ],
    });
  }

  if (selectLedger !== showEntriesSuggestion.OWN) {
    columnDefinitionChangeReconcileStatus.push({
      header: () => <div className="borderLeft">Partner</div>,
      id: "bpUnreconcile",
      size: null,
      accessorKey: "",
      columns: [
        {
          header: "Date",
          accessorKey: "businessPartnerDate",
          size: 120,
          cell: ({ row }) => (row.original.businessPartnerDate ? FormatDate(row.original.businessPartnerDate) : ""),
        },
        {
          header: "Doc Type",
          accessorKey: "businessPartnerDocumentType",
          size: 100,
        },
        {
          header: "Reference",
          accessorKey: "businessPartnerReferenceNumber",
          size: 180,
        },
        {
          header: "Amount",
          accessorKey: "businessPartnerAmount",
          size: 180,
          cell: ({ row }: any) => (
            <div>
              {row.original.businessPartnerAmount !== null
                ? formatMoney(ToDineroObj(row.original.businessPartnerAmount, currency.current as Currency))
                : "-"}
            </div>
          ),
        },
        {
          header: "Type",
          accessorKey: "businessPartnerType",
          size: 150,
        },
        // {
        //   header: "Credit Amount",
        //   accessorKey: "businessPartnerCreditAmount",
        //   maxSize: 150,
        //   minSize: 150,
        //   size: 150,
        //   cell: ({ row }: any) => <div>{formatNumberINR(row.original.businessPartnerCreditAmount)}</div>,
        // },
        // {
        //   header: "Debit Amount",
        //   accessorKey: "businessPartnerDebitAmount",
        //   maxSize: 150,
        //   minSize: 150,
        //   size: 150,
        //   cell: ({ row }: any) => <div>{formatNumberINR(row.original.businessPartnerDebitAmount)}</div>,
        // },
        {
          header: "Posting Date",
          accessorKey: "businessPartnerPostingDate",
          size: 150,
          cell: ({ row }) =>
            row.original.businessPartnerPostingDate ? FormatDate(row.original.businessPartnerPostingDate) : "",
        },
      ],
    });
  }

  columnDefinitionChangeReconcileStatus.push(
    {
      header: "Amount Difference",
      accessorKey: "valueDifference.amountDiff",
      size: 180,
      cell: ({ row }: any) => (
        <div>
          {row.original.valueDifference?.amountDiff !== null
            ? formatMoney(ToDineroObj(row.original.valueDifference?.amountDiff, currency.current as Currency))
            : "-"}
        </div>
      ),
    },
    {
      header: "% Amount Difference",
      accessorKey: "valueDifference.percentDiff",
      size: 190,
    },
    {
      header: "Reconciliation Status",
      accessorKey: "reconStatus",
      sticky: "right",
      size: 200,
    }
  );
  // columnDefinitionChangeReconcileStatus----- end

  const columnDefinitionReconciledOwn = useMemo(
    (): any => [
      // {
      //   id: "selection",
      //   sticky: "left",
      //   // The header can use the table's getToggleAllRowsSelectedProps method
      //   // to render a checkbox
      //   header: ({ table }) => (
      //     <div className="center_align_ver_horiz">
      //       <IndeterminateCheckbox
      //         checked={table.getIsAllRowsSelected()}
      //         indeterminate={table.getIsSomeRowsSelected()}
      //         onChange={table.getToggleAllRowsSelectedHandler()}
      //       />
      //     </div>
      //   ),
      //   // The cell can use the individual row's getToggleRowSelectedProps method
      //   // to the render a checkbox
      //   cell: ({ row }) => (
      //     <div className="center_align_ver_horiz">
      //       <IndeterminateCheckbox checked={row.getIsSelected()} onChange={row.getToggleSelectedHandler()} />
      //     </div>
      //   ),
      //   size: 35,
      // },
      {
        header: "Entry no.",
        accessorFn: (row) => row?.entryNo || "",
        sticky: "left",
        size: 100,
        filterVariant: "autocomplete",
      },

      {
        header: "Date",
        // accessorKey: "date",
        accessorFn: (row) => new Date(row.date),
        minSize: 130,
        maxSize: 360,
        filterVariant: "date-range",

        filterFn: (row, _id, filterValue, _meta) => {
          const minDate = filterValue[0] ? dayjs(filterValue[0]).toDate() : null;
          const maxDate = filterValue[1] ? dayjs(filterValue[1]).toDate() : null;
          if (minDate || maxDate) {
            return filterDateBetweenStartAndEndDate(minDate, maxDate, row.original.date);
          }
        },
        Cell: ({ row }) => (row.original.date ? FormatDate(row.original.date) : ""),
      },
      {
        header: "Reference",
        // accessorKey: "referenceNumber",
        accessorFn: (row) => row?.referenceNumber || "",
        size: 180,
      },
      {
        header: "Type",
        // accessorKey: "type",
        accessorFn: (row) => row?.type || "",
        size: 100,
        filterVariant: "autocomplete",
      },
      {
        header: "Amount",
        accessorFn: (row) => row?.amount || "",
        minSize: 140,
        maxSize: 250,
        filterVariant: "range",
        filterFn: "betweenInclusive",
        Cell: ({ row }: any) => (
          <div>
            {row.original.amount !== null
              ? formatMoney(ToDineroObj(row.original.amount, currency.current as Currency))
              : "-"}
          </div>
        ),
      },
      {
        header: "Doc Type",
        // accessorKey: "documentType",
        accessorFn: (row) => row?.documentType || "",
        size: 100,
        filterVariant: "autocomplete",
      },
      {
        header: "Other Unique ID",
        // accessorKey: "otherUniqueId",
        accessorFn: (row) => row?.otherUniqueId || "",
        size: 100,
      },
      {
        header: "Posting Date",
        accessorFn: (row) => new Date(row.postingDate),
        minSize: 130,
        maxSize: 360,
        filterVariant: "date-range",

        filterFn: (row, _id, filterValue, _meta) => {
          const minDate = filterValue[0] ? dayjs(filterValue[0]).toDate() : null;
          const maxDate = filterValue[1] ? dayjs(filterValue[1]).toDate() : null;
          if (minDate || maxDate) {
            return filterDateBetweenStartAndEndDate(minDate, maxDate, row.original.postingDate);
          }
        },
        Cell: ({ row }) => (row.original.postingDate ? FormatDate(row.original.postingDate) : ""),
      },
      {
        header: "Particulars",
        // accessorKey: "particulars",
        accessorFn: (row) => row?.particulars || "",
        size: 100,
      },
      // {
      //   header: "Credit Amount",
      //   accessorKey: "creditAmount",
      //   maxSize: 100,
      //   minSize: 100,
      //   size: 100,
      //   cell: ({ row }: any) => <div>{formatNumberINR(row.original.creditAmount)}</div>,
      // },
      // {
      //   header: "Debit Amount",
      //   accessorKey: "debitAmount",
      //   maxSize: 100,
      //   minSize: 100,
      //   size: 100,
      //   cell: ({ row }: any) => <div>{formatNumberINR(row.original.debitAmount)}</div>,
      // },
      {
        header: "DNCN ID",
        // accessorKey: "dncnId",
        accessorFn: (row) => row?.dncnId || "",
        size: 100,
      },
      {
        header: "Payment ID",
        // accessorKey: "paymentId",
        accessorFn: (row) => row?.paymentId || "",
        size: 100,
      },
    ],
    []
  );
  const columnDefinitionReconciledBp = useMemo(
    (): any => [
      {
        header: "Entry no.",
        accessorFn: (row) => row?.entryNo || "",
        sticky: "left",
        size: 100,
        filterVariant: "autocomplete",
      },

      {
        header: "Date",
        // accessorKey: "date",
        accessorFn: (row) => new Date(row.date),
        minSize: 130,
        maxSize: 360,
        filterVariant: "date-range",

        filterFn: (row, _id, filterValue, _meta) => {
          const minDate = filterValue[0] ? dayjs(filterValue[0]).toDate() : null;
          const maxDate = filterValue[1] ? dayjs(filterValue[1]).toDate() : null;
          if (minDate || maxDate) {
            return filterDateBetweenStartAndEndDate(minDate, maxDate, row.original.date);
          }
        },
        Cell: ({ row }) => (row.original.date ? FormatDate(row.original.date) : ""),
      },
      {
        header: "Reference",
        accessorFn: (row) => row?.referenceNumber || "",
        size: 180,
      },
      {
        header: "Type",
        accessorFn: (row) => row?.type || "",
        size: 100,
        filterVariant: "autocomplete",
      },
      {
        header: "Amount",
        accessorFn: (row) => row?.amount || "",
        minSize: 140,
        maxSize: 250,
        filterVariant: "range",
        filterFn: "betweenInclusive",
        Cell: ({ row }: any) => (
          <div>
            {row.original.amount !== null
              ? formatMoney(ToDineroObj(row.original.amount, currency.current as Currency))
              : "-"}
          </div>
        ),
      },
      {
        header: "Doc Type",
        accessorFn: (row) => row?.documentType || "",
        size: 100,
        filterVariant: "autocomplete",
      },
      {
        header: "Other Unique ID",
        accessorFn: (row) => row?.otherUniqueId || "",
        size: 100,
      },
      {
        header: "Posting Date",
        accessorFn: (row) => new Date(row.postingDate),
        minSize: 130,
        maxSize: 360,
        filterVariant: "date-range",

        filterFn: (row, _id, filterValue, _meta) => {
          const minDate = filterValue[0] ? dayjs(filterValue[0]).toDate() : null;
          const maxDate = filterValue[1] ? dayjs(filterValue[1]).toDate() : null;
          if (minDate || maxDate) {
            return filterDateBetweenStartAndEndDate(minDate, maxDate, row.original.postingDate);
          }
        },
        Cell: ({ row }) => (row.original.postingDate ? FormatDate(row.original.postingDate) : ""),
      },
      {
        header: "Particulars",
        accessorFn: (row) => row?.particulars || "",
        size: 100,
      },
      {
        header: "DNCN ID",
        accessorFn: (row) => row?.dncnId || "",
        size: 100,
      },
      {
        header: "Payment ID",
        accessorFn: (row) => row?.paymentId || "",
        size: 100,
      },
    ],
    []
  );

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  useEffect(() => {
    getSuggestedHistory(ManualReconHistoryRecordState.suggested);
    createAndFetchManualReconSuggestions("");
    getPossibleReconStatuses();
    getUnreconciledEntriesOnReconcileClick();
  }, []);

  useEffect(() => {
    // Calculate the sum of "amount" from selectedRowOwn
    const sumSelectedRowOwn =
      selectedRowOwn.length > 0 ? selectedRowOwn.reduce((accumulator, item) => accumulator + item.amount, 0) : 0;

    // Calculate the sum of "amount" from selectedRowBP
    const sumSelectedRowBP =
      selectedRowBp.length > 0 ? selectedRowBp.reduce((accumulator, item) => accumulator + item.amount, 0) : 0;

    // Calculate the overall sum
    const overallSum = sumSelectedRowOwn + sumSelectedRowBP;
    setReconcileTotalSum(overallSum);
    const overallPercentage =
      (Math.abs(overallSum) * 100) / Math.max(Math.abs(sumSelectedRowOwn), Math.abs(sumSelectedRowBP));
    setReconcileTotalPercentage(overallPercentage.toFixed(2));
    setReconcileOwnSum(sumSelectedRowOwn);
    setReconcileBpSum(sumSelectedRowBP);
    setAbsoluteReconcileTotalSum(Math.abs(overallSum));

    // Extract entry numbers from selectedRowOwn
    const entryNumbersArrayOwn = selectedRowOwn?.map((item) => item.entryNo);
    setSelectedEntryNumbersOwn(entryNumbersArrayOwn);
    const entryNumbersArrayBp = selectedRowBp?.map((item) => item.entryNo);
    setSelectedEntryNumbersBp(entryNumbersArrayBp);
  }, [selectedRowOwn, selectedRowBp]);

  useEffect(() => {
    // Generalize for all entries in selectedSuggestedEntry with handling for empty arrays
    const sumSelectedRowOwn = selectedSuggestedEntry.reduce((ownTotal, entry) => {
      const ownSum = (entry.ownEntries || []).reduce((acc, item) => acc + parseFloat(item.amount || 0), 0);
      return ownTotal + ownSum;
    }, 0);

    const sumSelectedRowBP = selectedSuggestedEntry.reduce((bpTotal, entry) => {
      const bpSum = (entry.partnerEntries || []).reduce((acc, item) => acc + parseFloat(item.amount || 0), 0);
      return bpTotal + bpSum;
    }, 0);

    // Calculate the overall sum
    const overallSum = sumSelectedRowOwn + sumSelectedRowBP;
    setReconcileTotalSum(overallSum);

    // Calculate the overall percentage
    const overallPercentage =
      (Math.abs(overallSum) * 100) / Math.max(Math.abs(sumSelectedRowOwn || 1), Math.abs(sumSelectedRowBP || 1));
    setReconcileTotalPercentage(overallPercentage.toFixed(2));

    // Set individual sums
    setReconcileOwnSum(sumSelectedRowOwn);
    setReconcileBpSum(sumSelectedRowBP);
  }, [selectedSuggestedEntry]);

  const showDetailsOfEntriesOnMatchId = async (matchId) => {
    await useFetch("/api/recon/manual/GetEntries", "POST", {
      data: {
        businessPartnerId: businessPartnerSelected,
        companyId: companyId.current,
        branchCode: branchCode.current,
        fetchType: FetchAndUpdateType.MatchIds,
        fetchData: {
          matchIds: matchId,
        },
      },
      thenCallBack: (response) => {
        setRowsDataUnreconciled(response.data.data.data || []);
      },
      catchCallBack: () => {
        setRowsDataUnreconciled([]);
      },
    });
  };
  const unReconcileEntriesOnMatchId = async () => {
    const matchIds = rowsDataUnreconciled?.map((entry) => entry.matchId);
    setShowLoadingIconUnreconcile(true);
    await useFetch("/api/recon/manual/Unreconcile", "POST", {
      showSuccessToast: true,
      data: {
        businessPartnerId: businessPartnerSelected,
        companyId: companyId.current,
        branchCode: branchCode.current,
        matchIds: matchIds || [],
        comments: unreconcileRemarks,
      },
      thenCallBack: () => {
        setUnreconcileRemarks("");
        setRowsDataUnreconciled([]);
        setMatchIdValue("");
        setIsOpenUnreconcileConfirmWarningModal(false);
        setShowLoadingIconUnreconcile(false);
      },
      catchCallBack: () => {
        setShowLoadingIconUnreconcile(false);
      },
    });
  };
  const reconcileEntries = async (gapAccountedAs) => {
    setShowLoadingIconReconcile(true);
    await useFetch("/api/recon/manual/Reconcile", "POST", {
      showSuccessToast: true,
      data: {
        businessPartnerId: businessPartnerSelected,
        companyId: companyId.current,
        branchCode: branchCode.current,
        ownEntries: selectedEntryNumbersOwn,
        bpEntries: selectedEntryNumbersBp,
        gapAccountedAs,
        comments: reconcileRemarks,
      },
      thenCallBack: () => {
        setClearRowsOwn(true);
        setClearRowsBp(true);
        setRowsDataReconciledOwn([]);
        setStoreRowsDataReconciledOwn([]);
        setRowsDataReconciledBp([]);
        setStoreRowsDataReconciledBp([]);
        setSelectedEntryNumbersOwn([]);
        setSelectedEntryNumbersBp([]);
        setReconcileTotalSum(0);
        setReconcileTotalPercentage(0);
        setReconcileOwnSum(0);
        setReconcileBpSum(0);
        setAbsoluteReconcileTotalSum(0);
        setSelectedRowOwn([]);
        setSelectedRowBp([]);
        setIsOpenReconcileConfirmWarningModal(false);
        setShowLoadingIconReconcile(false);
        setGapAccountedName("");
        setReconcileRemarks("");
        getUnreconciledEntriesOnReconcileClick();
      },
      catchCallBack: () => {
        setShowLoadingIconReconcile(false);
        setGapAccountedName("");
      },
    });
  };
  const getUnreconciledEntriesOnReconcileClick = async () => {
    setLoadingReconcileTableOwn(true);
    setLoadingReconcileTableBp(true);
    await useFetch("/api/recon/manual/GetEntries", "POST", {
      data: {
        businessPartnerId: businessPartnerSelected,
        companyId: companyId.current,
        branchCode: branchCode.current,
        fetchType: FetchAndUpdateType.Unmatched,
        fetchData: {},
      },

      thenCallBack: (response) => {
        setRowsDataReconciledOwn(response.data.data.ownEntries);
        setStoreRowsDataReconciledOwn(response.data.data.ownEntries);
        setRowsDataReconciledBp(response.data.data.bpEntries);
        setStoreRowsDataReconciledBp(response.data.data.bpEntries);
        setReFilter(true);
        setLoadingReconcileTableOwn(false);
        setLoadingReconcileTableBp(false);
      },
      catchCallBack: () => {
        setLoadingReconcileTableOwn(false);
        setLoadingReconcileTableBp(false);
      },
    });
  };

  const unReconcileShowDetailsClick = () => {
    const cleanedValue = matchIdValue.replace(/\s*,\s*/g, " "); // Replace commas surrounded by spaces with a single space
    const arrayFromString = cleanedValue.split(/\s+/).filter(Boolean);
    showDetailsOfEntriesOnMatchId(arrayFromString);
  };

  const handleEnterKeyPress = (e) => {
    if (e.keyCode === 13) {
      textFieldRefUnReconcile.current.blur(); // Remove focus from the TextField
      unReconcileShowDetailsClick();
    }
  };
  const handleEnterKeyPressReconcileOwn = (e) => {
    if (e.keyCode === 13) {
      textFieldRefReconcileOwn.current.blur(); // Remove focus from the TextField
      filterRowsDataReconciledOwnFromEntryNum();
    }
  };
  const handleEnterKeyPressReconcileBp = (e) => {
    if (e.keyCode === 13) {
      textFieldRefReconcileBp.current.blur(); // Remove focus from the TextField
      filterRowsDataReconciledBpFromEntryNum();
    }
  };

  const filterRowsDataReconciledOwnFromEntryNum = () => {
    const tempRowData = storeRowsDataReconciledOwn;
    const entryNumArray = entryNumOwn.split(/[ ,]+/);
    if (entryNumOwn?.trim()) {
      const filteredRowsData = tempRowData?.filter((item) => {
        // Convert item.entryNo to lowercase string for case-insensitive comparison
        const entryNoAsString = String(item.entryNo).toLowerCase();

        // Check if entryNoAsString is included in entryNumArray (converted to lowercase)
        return entryNumArray?.map((val) => val.toLowerCase()).includes(entryNoAsString);
      });
      setRowsDataReconciledOwn(filteredRowsData);
      setIsFilterAppliedOwnSide(true);
    } else {
      // entryNumOwn is empty or undefined, so do not apply filtering
      // Use the original data
      setIsFilterAppliedOwnSide(false);
      setRowsDataReconciledOwn(storeRowsDataReconciledOwn);
    }
  };
  const filterRowsDataReconciledBpFromEntryNum = () => {
    const tempRowData = storeRowsDataReconciledBp;
    const entryNumArray = entryNumBp.split(/[ ,]+/);
    if (entryNumBp?.trim()) {
      const filteredRowsData = tempRowData?.filter((item) => {
        // Convert item.entryNo to lowercase string for case-insensitive comparison
        const entryNoAsString = String(item.entryNo).toLowerCase();

        // Check if entryNoAsString is included in entryNumArray (converted to lowercase)
        return entryNumArray?.map((val) => val.toLowerCase()).includes(entryNoAsString);
      });
      setRowsDataReconciledBp(filteredRowsData);
      setIsFilterAppliedBpSide(true);
    } else {
      setIsFilterAppliedBpSide(false);
      setRowsDataReconciledBp(storeRowsDataReconciledBp);
    }
  };

  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    clearChangeReconcileStatusStates();
    setValue(newValue);
    if (newValue === 0) {
      // reconcile tab
      setActiveTable(ActiveTableInterface.ReconcileTable);
      createAndFetchManualReconSuggestions("");
      getUnreconciledEntriesOnReconcileClick();
      getSuggestedHistory(ManualReconHistoryRecordState.suggested);
    } else if (newValue === 3) {
      setActiveTable(null);
      getAppliedHistory(ManualReconHistoryRecordState.applied);
    }
    setSuggestedEntryTrackIndex(null);
  };

  const createAndFetchManualReconSuggestions = async (create) => {
    setStatusDataFetching(true);
    await useFetch("/api/recon/manual/CreateAndFetchManualReconSuggestions", "POST", {
      showSuccessToast: create !== "" ? true : false,
      data: {
        businessPartnerId: businessPartnerSelected,
        companyId: companyId.current,
        branchCode: branchCode.current,
        userAction: create,
      },
      thenCallBack: (response) => {
        setStatusDataFetching(false);
        setSuggestionsPresent(response.data?.data?.SuggestionsPresent);
        setRowsDataSuggestion(response.data?.data?.SuggestionSummary);
        setLastSuggestionCreateDateTime(response.data?.data?.LastSuggestionCreateDateTime);
      },
      catchCallBack: () => {
        setStatusDataFetching(false);
      },
    });
  };

  const reconcileSuggestedMatch = async () => {
    setReconcileSuggestionsLoading(true);
    const matchIds = selectedSuggestedEntry?.map((entry) => entry.matchId);
    await useFetch("/api/recon/manual/ReconcileSuggestedMatch", "POST", {
      showSuccessToast: true,
      data: {
        businessPartnerId: businessPartnerSelected,
        companyId: companyId.current,
        branchCode: branchCode.current,
        suggestedIds: matchIds || [],
      },
      thenCallBack: (_response) => {
        createAndFetchManualReconSuggestions("");
        setActiveTable(ActiveTableInterface.ReconcileTable);
        setSuggestedEntryTrackIndex(null);
        getUnreconciledEntriesOnReconcileClick();
        setShowEntries(null);
        setSelectedSuggestedEntry([]);
        setReconcileSuggestionsLoading(false);
      },
      catchCallBack: () => {
        setReconcileSuggestionsLoading(false);
      },
    });
  };

  const getSuggestedHistory = async (historyStatus) => {
    await useFetch("/api/recon/manual/GetHistory", "GET", {
      config: {
        params: {
          businessPartnerId: businessPartnerSelected,
          companyId: companyId.current,
          branchCode: branchCode.current,
          historyStatus,
        },
      },
      thenCallBack: (response) => {
        setSuggestedHistoryPresent(response.data?.data?.length > 0);
        setRowsDataSuggestedHistoryPresent(response.data?.data || []);
        setSelectedSuggestedHistoryPresent([]);
      },
      catchCallBack: () => {
        setGetSuggestedHistoryLoading(false);
      },
    });
  };
  const applySuggestedHistoryMatches = async () => {
    // Extract the array of `id`
    const specificChangesIds = selectedSuggestedHistoryPresent?.map((item) => item.id);
    setGetSuggestedHistoryLoading(true);
    await useFetch("/api/recon/manual/ReapplyPreviousChanges", "POST", {
      showSuccessToast: true,
      data: {
        businessPartnerId: businessPartnerSelected,
        companyId: companyId.current,
        branchCode: branchCode.current,
        specificChanges: specificChangesIds,
      },
      thenCallBack: (_response) => {
        getSuggestedHistory(ManualReconHistoryRecordState.suggested);
        getUnreconciledEntriesOnReconcileClick();
        setGetSuggestedHistoryLoading(false);
        setActiveTable(ActiveTableInterface.ReconcileTable);
      },
      catchCallBack: () => {
        setGetSuggestedHistoryLoading(false);
      },
    });
  };

  const getAppliedHistory = async (historyStatus) => {
    setLoadingAppliedHistory(true);
    await useFetch("/api/recon/manual/GetHistory", "GET", {
      showSuccessToast: true,
      config: {
        params: {
          businessPartnerId: businessPartnerSelected,
          companyId: companyId.current,
          branchCode: branchCode.current,
          historyStatus,
        },
      },
      thenCallBack: (response) => {
        setRowsDataAppliedHistory(response.data?.data || []);
        setSelectedAppliedHistory([]);
        setLoadingAppliedHistory(false);
      },
      catchCallBack: () => {
        setLoadingAppliedHistory(false);
      },
    });
  };
  const revertAppliedHistory = async () => {
    const selectedAppliedHistoryIds = selectedAppliedHistory?.map((item) => item.matchId) || [];
    setLoadingRevertAppliedHistory(true);
    await useFetch("/api/recon/manual/Unreconcile", "POST", {
      showSuccessToast: true,
      data: {
        businessPartnerId: businessPartnerSelected,
        companyId: companyId.current,
        branchCode: branchCode.current,
        matchIds: selectedAppliedHistoryIds,
      },
      thenCallBack: (_response) => {
        getAppliedHistory(ManualReconHistoryRecordState.applied);
        setLoadingRevertAppliedHistory(false);
      },
      catchCallBack: () => {
        setLoadingRevertAppliedHistory(false);
      },
    });
  };
  const selectOptionFilterEntries = (e: any, value: string) => {
    clearChangeReconcileStatusStates();
    if (value !== null) {
      e.persist();
      setSelectedFilterEntry(value);
    } else {
      setSelectedFilterEntry(null);
    }
  };
  const selectOptionReconcileStatus = (e: any, value: string) => {
    if (value !== null) {
      e.persist();
      setSelectedOptionReconcileStatus(value);
    } else {
      setSelectedOptionReconcileStatus(null);
    }
  };
  const selectOptionChangeReconcileStatus = (e: any, value: string) => {
    if (value !== null) {
      e.persist();
      setSelectedOptionChangeReconcileStatus(value);
    } else {
      setSelectedOptionChangeReconcileStatus(null);
    }
  };

  const getPossibleReconStatuses = async () => {
    await useFetch("/api/recon/manual/GetPossibleReconStatuses", "GET", {
      thenCallBack: (response) => {
        setOptionsReconcileStatus(response.data.data);
      },
    });
  };

  const getReconciledEntries = async () => {
    let fetchType = "";
    let fetchData = {};

    if (selectedFilterEntry === OptionsFilterEntries.matchId) {
      fetchType = FetchAndUpdateType.MatchIds;
      fetchData = {
        matchIds: convertArrayFromString(matchIDChangeStatus),
      };
    } else if (selectedFilterEntry === OptionsFilterEntries.entryNumber) {
      const isOwn = selectLedger === showEntriesSuggestion.OWN; // Determine if it's own entries
      const entryNumbers = convertArrayFromString(entryNumChangeStatus.toUpperCase());
      fetchType = FetchAndUpdateType.EntryNumbers;
      fetchData = {
        entryNumbers,
        isOwn,
      };
    } else if (selectedFilterEntry === OptionsFilterEntries.reconciliationStatus) {
      const status = selectedOptionReconcileStatus;
      fetchType = FetchAndUpdateType.Status;
      fetchData = {
        status,
      };
    }

    setGetReconciledEntriesLoading(true);

    await useFetch("/api/recon/manual/GetEntries", "POST", {
      data: {
        businessPartnerId: businessPartnerSelected,
        companyId: companyId.current,
        branchCode: branchCode.current,
        fetchType,
        fetchData,
      },
      thenCallBack: (response) => {
        setGetReconciledEntriesLoading(false);
        setShowChangeStatus(true);
        setRowsDataChangeReconcileStatus(response.data.data.data || []);
      },
      catchCallBack: () => {
        setGetReconciledEntriesLoading(false);
      },
    });
  };
  const updateReconStatusManually = async () => {
    setUpdateReconStatusManuallyLoading(true);
    const matchId = selectedRowChangeReconcileStatus?.map((entry) => entry.matchId);
    const ownEntryNumbers = selectedRowChangeReconcileStatus?.map((entry) => entry.ownEntryNumber);
    const businessPartnerEntryNumbers = selectedRowChangeReconcileStatus?.map(
      (entry) => entry.businessPartnerEntryNumber
    );

    let updateType = "";
    let updateData = {};

    // Logic based on the selectedFilterEntry
    if (selectedFilterEntry === OptionsFilterEntries.matchId) {
      updateType = FetchAndUpdateType.MatchIds;
      updateData = {
        matchIds: matchId || [],
      };
    } else if (selectedFilterEntry === OptionsFilterEntries.entryNumber) {
      // Check if `businessPartnerEntryNumbers` is empty or contains only empty strings
      const isOwn =
        !businessPartnerEntryNumbers ||
        businessPartnerEntryNumbers.length === 0 ||
        businessPartnerEntryNumbers.every((entry) => entry === "" || entry === undefined);

      updateType = FetchAndUpdateType.EntryNumbers;
      updateData = {
        entryNumbers: isOwn ? ownEntryNumbers : businessPartnerEntryNumbers,
        isOwn,
      };
    } else if (selectedFilterEntry === OptionsFilterEntries.reconciliationStatus) {
      const initialStatus = selectedRowChangeReconcileStatus?.[0]?.reconStatus || "Unknown Status";
      updateType = FetchAndUpdateType.Status;
      updateData = {
        status: initialStatus,
        matchIds: matchId || [],
        ownEntriesNumber: ownEntryNumbers || [],
        bpEntriesNumber: businessPartnerEntryNumbers || [],
      };
    }

    await useFetch("/api/recon/manual/UpdateStatus", "PUT", {
      showSuccessToast: true,
      data: {
        businessPartnerId: businessPartnerSelected,
        companyId: companyId.current,
        branchCode: branchCode.current,
        updateType,
        updateData,
        finalStatus: selectedOptionChangeReconcileStatus,
      },
      thenCallBack: () => {
        setSelectedFilterEntry(null);
        clearChangeReconcileStatusStates();
        setUpdateReconStatusManuallyLoading(false);
      },
      catchCallBack: () => {
        setUpdateReconStatusManuallyLoading(false);
      },
    });
  };

  function convertArrayFromString(matchIdValue) {
    const cleanedValue = matchIdValue.replace(/\s*,\s*/g, " "); // Replace commas surrounded by spaces with a single space
    const arrayFromString = cleanedValue.split(/\s+/).filter(Boolean);
    return arrayFromString;
  }

  // const reconcileOutOfPeriodEntries = async () => {
  //   setAutoOutOfPeriodLoading(true);
  //   // ParamsStateUpdate({ AOOP: "true" });
  //   await useFetch("/api/ReconcileOutOfPeriodEntries", "POST", {
  //     showSuccessToast: true,
  //     data: {
  //       businessPartnerId: businessPartnerSelected,
  //       businessPartnerCompanyId: businessPartnerSelectedCompanyIdRef.current,
  //       ownCompanyId: companyId.current,
  //     },
  //     thenCallBack: () => {
  //       setAutoOutOfPeriodLoading(false);
  //     },
  //     catchCallBack: () => {
  //       setAutoOutOfPeriodLoading(false);
  //     },
  //   });
  // };

  const deleteNotAppliedHistory = async () => {
    setLoadingDeleteAllMatches(true);
    await useFetch("/api/recon/manual/DeleteNotAppliedHistory", "DELETE", {
      showSuccessToast: true,
      config: {
        data: {
          businessPartnerId: businessPartnerSelected,
          companyId: companyId.current,
          branchCode: branchCode.current,
          manualChangeIds: [],
        },
      },
      thenCallBack: (_response) => {
        setActiveTable(ActiveTableInterface.ReconcileTable);
        getSuggestedHistory(ManualReconHistoryRecordState.suggested);
        createAndFetchManualReconSuggestions("");
        getUnreconciledEntriesOnReconcileClick();
        setLoadingDeleteAllMatches(false);
        setOpenDeleteAllMatchesModal(false);
      },
      catchCallBack: () => {
        setLoadingDeleteAllMatches(false);
      },
    });
  };

  const clearChangeReconcileStatusStates = () => {
    setEntryNumChangeStatus("");
    setMatchIDChangeStatus("");
    setSelectLedger(null);
    setSelectedOptionReconcileStatus(null);
    setSelectedOptionChangeReconcileStatus(null);
    setShowChangeStatus(false);
    setRowsDataChangeReconcileStatus([]);
    setSelectedRowChangeReconcileStatus([]);
    setSelectedFilterEntry(null);
    setSelectedRowOwn([]);
    setSelectedRowBp([]);
    setSelectedSuggestedEntry([]);
    setActiveTable(ActiveTableInterface.ReconcileTable);
    setSuggestedHistoryPresent(false);
    setRowsDataSuggestedHistoryPresent([]);
    setSelectedSuggestedHistoryPresent([]);
    setRowsDataAppliedHistory([]);
    setSelectedAppliedHistory([]);
  };
  const manualReconContextValue: ManualReconContextInterface = {
    isOpenUnreconcileConfirmWarningModal,
    setIsOpenUnreconcileConfirmWarningModal,
    isOpenReconcileConfirmWarningModal,
    setIsOpenReconcileConfirmWarningModal,
    unReconcileEntriesOnMatchId,
    reconcileEntries,
    reconcileTotalSum,
    selectedRowOwn,
    selectedRowBp,
    amountTolerance,
    openManualReconReconcileOwnFilter,
    setOpenManualReconReconcileOwnFilter,
    storeRowsDataReconciledOwn,
    entryNumOwn,
    setEntryNumOwn,
    setIsFilterAppliedOwnSide,
    setRowsDataReconciledOwn,
    openManualReconReconcileBpFilter,
    setOpenManualReconReconcileBpFilter,
    storeRowsDataReconciledBp,
    entryNumBp,
    setEntryNumBp,
    setIsFilterAppliedBpSide,
    setRowsDataReconciledBp,
    absoluteReconcileTotalSum,
    showLoadingIconUnreconcile,
    showLoadingIconReconcile,
    gapAccountedName,
    setGapAccountedName,
    reFilter,
    setReFilter,
    isFilterAppliedOwnSide,
    isFilterAppliedBpSide,
    reconcileTotalPercentage,
    unreconcileRemarks,
    setUnreconcileRemarks,
    reconcileRemarks,
    setReconcileRemarks,
    getSuggestedHistory,
    createAndFetchManualReconSuggestions,
    openDeleteAllMatchesModal,
    setOpenDeleteAllMatchesModal,
    loadingDeleteAllMatches,
    deleteNotAppliedHistory,
  };

  return (
    <>
      <ManualReconContext.Provider value={manualReconContextValue}>
        <Grid>
          <div>
            <Grid container={true} className=" manual_box_container" sx={{ position: "relative" }}>
              <Box sx={{ width: "100%" }}>
                <Box sx={{ borderBottom: 1, borderColor: "divider", textAlign: "center" }} className="space_between">
                  <Grid md={12} className="d_flex">
                    <Grid md={1} className="center_align">
                      <Tooltip
                        title={
                          autoOutOfPeriodLoading ? "Please wait until Auto out of period action is completed." : ""
                        }
                        classes={{ tooltip: "fs_12" }}
                      >
                        <Button
                          sx={{ color: "rgba(0, 0, 0, 0.60)" }}
                          startIcon={<ChevronLeftIcon />}
                          onClick={() => {
                            if (autoOutOfPeriodLoading) return;
                            setOpenManualRecon(false);
                            getSummaryForReconciliationCommonFunction();
                            uiLogger(
                              uiLoggerNamesRecon.UI_RL_RECONCILE_MANUALLY_BACK_CLICK.functionName,
                              companyId.current,
                              branchCode.current,
                              {
                                message: uiLoggerNamesRecon.UI_RL_RECONCILE_MANUALLY_BACK_CLICK.message,
                                businessPartnerId: businessPartnerSelectedRef.current,
                              }
                            );
                          }}
                        >
                          Back
                        </Button>
                      </Tooltip>
                    </Grid>
                    <Grid md={11}>
                      <Tabs
                        value={value}
                        onChange={handleChange}
                        aria-label="basic tabs example"
                        variant="scrollable"
                        scrollButtons="auto"
                        style={{ textTransform: "uppercase" }}
                      >
                        <Tab label="Reconcile" {...a11yProps(0)} sx={{ textTransform: "none" }} />
                        <Tab label="Change Reconciliation Status" {...a11yProps(1)} sx={{ textTransform: "none" }} />
                        <Tab label="Unreconcile" {...a11yProps(2)} sx={{ textTransform: "none" }} />
                        {/* <Tab label="Out of Period" {...a11yProps(3)} sx={{ textTransform: "none" }} /> */}
                        <Tab label="History" {...a11yProps(3)} sx={{ textTransform: "none" }} />
                      </Tabs>
                    </Grid>
                  </Grid>
                </Box>

                <TabPanel value={value} index={0}>
                  <>
                    <>
                      <Grid className="suggestedReconciliationMatches">
                        <Box className="suggested-reconciliation-container">
                          <img src={suggestedMatchLogo} alt="Logo" className="logo" />
                          <Box className="content">
                            <Typography variant="h6" className="title">
                              Suggested Reconciliation Matches
                            </Typography>
                            <Typography variant="body2" className="subtitle">
                              Make your Manual Reconciliation simple by our AI-powered Suggested Matches
                            </Typography>
                          </Box>
                          <Box className="button-container">
                            <Button
                              variant="outlined"
                              className="button"
                              startIcon={
                                statusDataFetching ? <LoadingIcon loading={statusDataFetching} /> : <RefreshIcon />
                              }
                              onClick={() => {
                                setShowEntries(null);
                                getSuggestedHistory(ManualReconHistoryRecordState.suggested);
                                createAndFetchManualReconSuggestions("create");
                                uiLogger(
                                  uiLoggerNamesRecon.UI_RL_RECONCILE_MANUALLY_SUGGESTED_MATCHES_REFRESH_SUGGESTION_CLICK
                                    .functionName,
                                  companyId.current,
                                  branchCode.current,
                                  {
                                    message:
                                      uiLoggerNamesRecon
                                        .UI_RL_RECONCILE_MANUALLY_SUGGESTED_MATCHES_REFRESH_SUGGESTION_CLICK.message,
                                    businessPartnerId: businessPartnerSelectedRef.current,
                                  }
                                );
                              }}
                              disabled={statusDataFetching}
                            >
                              REFRESH SUGGESTION
                            </Button>

                            <Box className="refresh-info">
                              {lastSuggestionCreateDateTime && (
                                <>
                                  <Typography variant="caption" className="last-refresh-label">
                                    Last Refresh:
                                  </Typography>
                                  <Typography variant="caption" className="last-refresh-date">
                                    {lastSuggestionCreateDateTime}
                                  </Typography>
                                </>
                              )}
                            </Box>
                          </Box>
                        </Box>
                      </Grid>
                      {suggestionsPresent || suggestedHistoryPresent ? (
                        <Grid className="suggestedReconciliationList">
                          <Box className="suggested-reconciliation-list">
                            {suggestedHistoryPresent && (
                              <Grid
                                className="suggested-matches-history"
                                onClick={() => {
                                  setSuggestedEntryTrackIndex(null);
                                  setActiveTable(ActiveTableInterface.ManualReconcileHistoryTable);
                                }}
                              >
                                <Card className="suggested-matches-card">
                                  <CardContent className="suggested-matches-content">
                                    <RestoreIcon className="restoreIcon" />
                                    <Typography variant="body1" className="link-text">
                                      View Suggested manual matches based on your manual reconciliation history
                                    </Typography>
                                  </CardContent>
                                </Card>
                              </Grid>
                            )}

                            {rowsDataSuggestion?.map((item, index) => (
                              <Grid
                                className="reconciliation-card"
                                key={index}
                                style={{ backgroundColor: suggestedEntryTrackIndex === index ? "#EDF7FE" : "" }}
                                onClick={() => {
                                  // clear all the previous states
                                  setReconcileOwnSum(0);
                                  setReconcileBpSum(0);
                                  setReconcileTotalSum(0);
                                  setReconcileTotalPercentage(0);
                                  setSelectedRowOwn([]);
                                  setSelectedRowBp([]);
                                  setSelectedSuggestedEntry([]);
                                  // view suggested matches
                                  setSuggestedEntryTrackIndex(index);
                                  setShowEntries(item.showEntries);
                                  setActiveTable(ActiveTableInterface.SuggestedMatchesTable);
                                  uiLogger(
                                    uiLoggerNamesRecon.UI_RL_RECONCILE_MANUALLY_SUGGESTED_MATCHES_VIEW_MORE_CLICK
                                      .functionName,
                                    companyId.current,
                                    branchCode.current,
                                    {
                                      message:
                                        uiLoggerNamesRecon.UI_RL_RECONCILE_MANUALLY_SUGGESTED_MATCHES_VIEW_MORE_CLICK
                                          .message,
                                      businessPartnerId: businessPartnerSelectedRef.current,
                                    }
                                  );
                                }}
                              >
                                <Box className="suggestedReconciliationList-card-content">
                                  <Box className="message-section">
                                    <Typography variant="body1" className="title">
                                      {item.suggestionMessage}
                                    </Typography>
                                    <Typography variant="body2" className="details">
                                      {`${item.reconType}  |  ${item.matchCount} Match${
                                        item.matchCount > 1 ? "es" : ""
                                      }  |  ${
                                        item.amount !== null
                                          ? formatMoney(ToDineroObj(item.amount, currency.current as Currency))
                                          : "-"
                                      }`}
                                    </Typography>
                                  </Box>
                                  <Box className="suggestion-info">
                                    <Box className="icon-background">
                                      <img src={sparkles} alt="Icon" className="icon" />
                                    </Box>
                                    <Typography variant="overline" className="suggested-text">
                                      AI Suggested
                                    </Typography>
                                  </Box>
                                </Box>
                              </Grid>
                            ))}
                          </Box>
                        </Grid>
                      ) : (
                        <Box className="no-suggestions">
                          <Typography variant="caption" className="no-suggestions-text">
                            No AI-Suggestions to show for now
                          </Typography>
                        </Box>
                      )}

                      <Grid className="fade_in">
                        {suggestionsPresent || suggestedHistoryPresent ? (
                          <>
                            {
                              // showViewMore
                              (activeTable === ActiveTableInterface.SuggestedMatchesTable ||
                                activeTable === ActiveTableInterface.ManualReconcileHistoryTable) && (
                                <>
                                  <Grid sx={{ mt: 4 }}>
                                    <Alert
                                      variant="filled"
                                      severity="info"
                                      action={
                                        <Button
                                          color="inherit"
                                          size="small"
                                          onClick={() => {
                                            setActiveTable(ActiveTableInterface.ReconcileTable);
                                            console.log("suggestedEntryTrackIndex,view", suggestedEntryTrackIndex);
                                            setSuggestedEntryTrackIndex(null);
                                            setShowEntries(null);
                                            setSelectedSuggestedHistoryPresent([]);
                                            setSelectedSuggestedEntry([]);
                                          }}
                                        >
                                          Close View
                                        </Button>
                                      }
                                    >
                                      Currently Viewing:{" "}
                                      <span className="fw_700">
                                        {activeTable === ActiveTableInterface.SuggestedMatchesTable
                                          ? rowsDataSuggestion?.[suggestedEntryTrackIndex]?.suggestionMessage
                                          : "Suggested manual matches based on your manual reconciliation history"}
                                      </span>
                                    </Alert>
                                  </Grid>

                                  <Grid item={true} md={12} style={{ width: "fit-content", marginTop: "8px" }}>
                                    {activeTable === ActiveTableInterface.SuggestedMatchesTable && (
                                      <ReactTable
                                        enableFacetedValues={true}
                                        enableRowVirtualization={false}
                                        layoutMode="semantic"
                                        columns={columnDefinitionSuggestionViewMore}
                                        rows={rowsDataSuggestion?.[suggestedEntryTrackIndex].suggestedEntryTrack}
                                        enableAggregations={true}
                                        setSelectedRow={setSelectedSuggestedEntry}
                                        positionToolbarAlertBanner={"top"}
                                        internalActionsOrder={[
                                          "globalFilterToggle",
                                          "filterToggle",
                                          "showHideColumns",
                                          "fullScreenToggle",
                                        ]}
                                        paginationDisplayMode={"default"}
                                        renderTopToolbarCustomActions={() => {
                                          return <Box sx={{ display: "flex", ml: "auto" }}></Box>;
                                        }}
                                        renderBottomToolbarCustomActions={() => {
                                          return <Box sx={{ display: "flex", ml: "auto" }}></Box>;
                                        }}
                                      />
                                    )}
                                    {activeTable === ActiveTableInterface.ManualReconcileHistoryTable && (
                                      <ReactTable
                                        enableFacetedValues={true}
                                        enableRowVirtualization={false}
                                        layoutMode="semantic"
                                        columns={columnDefinitionManualReconcileHistoryTable}
                                        rows={rowsDataSuggestedHistoryPresent}
                                        enableAggregations={true}
                                        setSelectedRow={setSelectedSuggestedHistoryPresent}
                                        positionToolbarAlertBanner={"top"}
                                        internalActionsOrder={[
                                          "globalFilterToggle",
                                          "filterToggle",
                                          "showHideColumns",
                                          "fullScreenToggle",
                                        ]}
                                        paginationDisplayMode={"default"}
                                        renderTopToolbarCustomActions={() => {
                                          return <Box sx={{ display: "flex", ml: "auto" }}></Box>;
                                        }}
                                        renderBottomToolbarCustomActions={() => {
                                          return (
                                            <Box sx={{ display: "flex" }}>
                                              <Button
                                                variant="outlined"
                                                color="error"
                                                startIcon={<DeleteIcon />}
                                                style={{ borderRadius: "4px" }}
                                                onClick={() => {
                                                  setOpenDeleteAllMatchesModal(true);
                                                }}
                                              >
                                                Delete all matches
                                              </Button>
                                            </Box>
                                          );
                                        }}
                                      />
                                    )}
                                  </Grid>
                                </>
                              )
                            }
                          </>
                        ) : (
                          <></>
                        )}
                      </Grid>
                    </>

                    {/* reconcile */}
                    {activeTable === ActiveTableInterface.ReconcileTable && (
                      <>
                        <Grid
                          container={true}
                          className="min_height_500 manual_box_container fade_up"
                          sx={{ position: "relative", mt: 5 }}
                        >
                          <Grid item={true} md={12} className="br_10 main_tables_box">
                            <>
                              <Grid container={true} className="">
                                <Grid item={true} md={5.9}>
                                  <Grid container={true} className="mb_15 manual_recon_search_entry_box">
                                    <Grid item={true} md={7}>
                                      <TextField
                                        inputRef={textFieldRefReconcileOwn}
                                        className="pl_10 update-custom-border-radius"
                                        size="small"
                                        variant="outlined"
                                        fullWidth={true}
                                        name="name"
                                        placeholder="Insert Entry no.s like 23, 24"
                                        value={entryNumOwn}
                                        onChange={(e) => {
                                          setEntryNumOwn(e.target.value);
                                          setIsFilterAppliedOwnSide(true);
                                        }}
                                        onKeyDown={handleEnterKeyPressReconcileOwn}
                                        sx={{ background: "white" }}
                                      />
                                    </Grid>
                                    <Grid item={true} md={1} className="vertical_center_align">
                                      <Button
                                        sx={{ color: "#27B27C", marginLeft: "4px" }}
                                        onClick={() => {
                                          setLoadingReconcileTableOwn(true);
                                          setTimeout(() => {
                                            setLoadingReconcileTableOwn(false);
                                          }, 500);

                                          filterRowsDataReconciledOwnFromEntryNum();
                                        }}
                                      >
                                        Search
                                      </Button>
                                    </Grid>
                                    <Grid item={true} md={4} className="vertical_center_align flex_end">
                                      <Button
                                        sx={{ color: "rgba(0, 0, 0, 0.87)" }}
                                        startIcon={isFilterAppliedOwnSide ? <FilterAltIcon /> : <FilterAltOffIcon />}
                                        onClick={() => {
                                          setOpenManualReconReconcileOwnFilter(true);
                                        }}
                                      >
                                        Filters
                                      </Button>
                                    </Grid>
                                  </Grid>

                                  <>
                                    <ReactTable
                                      enableFacetedValues={true}
                                      enableRowVirtualization={false}
                                      layoutMode="semantic"
                                      columns={columnDefinitionReconciledOwn}
                                      rows={rowsDataReconciledOwn}
                                      enableAggregations={true}
                                      setSelectedRow={setSelectedRowOwn}
                                      positionToolbarAlertBanner={"top"}
                                      internalActionsOrder={[
                                        "globalFilterToggle",
                                        "filterToggle",
                                        "showHideColumns",
                                        "fullScreenToggle",
                                      ]}
                                      renderTopToolbarCustomActions={() => (
                                        <Box className="label-box own-label-box ">
                                          <Typography variant="subtitle2" className="label-text">
                                            Own Entries
                                          </Typography>
                                        </Box>
                                      )}
                                      renderBottomToolbarCustomActions={() => {
                                        return <Box sx={{ display: "flex", ml: "auto" }}></Box>;
                                      }}
                                      paginationDisplayMode={"default"}
                                      clearRowsSelection={clearRowsOwn}
                                      setClearRowsSelection={setClearRowsOwn}
                                      loading={loadingReconcileTableOwn}
                                    />
                                  </>
                                </Grid>
                                <Grid item={true} md={0.2} /> {/* space between tables */}
                                <Grid item={true} md={5.9}>
                                  <Grid container={true} className="mb_15 manual_recon_search_entry_box">
                                    <Grid item={true} md={7}>
                                      <TextField
                                        inputRef={textFieldRefReconcileBp}
                                        className="pl_10 update-custom-border-radius"
                                        size="small"
                                        variant="outlined"
                                        fullWidth={true}
                                        name="name"
                                        placeholder="Insert Entry no.s like 23, 24"
                                        value={entryNumBp}
                                        onChange={(e) => {
                                          setEntryNumBp(e.target.value);
                                          setIsFilterAppliedBpSide(true);
                                        }}
                                        onKeyDown={handleEnterKeyPressReconcileBp}
                                        sx={{ background: "white" }}
                                      />
                                    </Grid>
                                    <Grid item={true} md={1} className="vertical_center_align">
                                      <Button
                                        sx={{ color: "#27B27C", marginLeft: "4px" }}
                                        onClick={() => {
                                          setLoadingReconcileTableBp(true);
                                          setTimeout(() => {
                                            setLoadingReconcileTableBp(false);
                                          }, 500);
                                          filterRowsDataReconciledBpFromEntryNum();
                                        }}
                                      >
                                        Search
                                      </Button>
                                    </Grid>

                                    <Grid item={true} md={4} className="vertical_center_align flex_end">
                                      <Button
                                        sx={{ color: "rgba(0, 0, 0, 0.87)" }}
                                        startIcon={isFilterAppliedBpSide ? <FilterAltIcon /> : <FilterAltOffIcon />}
                                        onClick={() => {
                                          setOpenManualReconReconcileBpFilter(true);
                                        }}
                                      >
                                        Filters
                                      </Button>
                                    </Grid>
                                  </Grid>

                                  <>
                                    <ReactTable
                                      enableFacetedValues={true}
                                      enableRowVirtualization={false}
                                      layoutMode="semantic"
                                      columns={columnDefinitionReconciledBp}
                                      rows={rowsDataReconciledBp}
                                      enableAggregations={true}
                                      setSelectedRow={setSelectedRowBp}
                                      positionToolbarAlertBanner={"top"}
                                      internalActionsOrder={[
                                        "globalFilterToggle",
                                        "filterToggle",
                                        "showHideColumns",
                                        "fullScreenToggle",
                                      ]}
                                      renderTopToolbarCustomActions={() => (
                                        <Box className="label-box bp-label-box">
                                          <Typography
                                            variant="subtitle2"
                                            className="label-text"
                                            style={{ width: "105px" }}
                                          >
                                            Partner Entries
                                          </Typography>
                                        </Box>
                                      )}
                                      renderBottomToolbarCustomActions={() => {
                                        return <Box sx={{ display: "flex", ml: "auto" }}></Box>;
                                      }}
                                      paginationDisplayMode={"default"}
                                      clearRowsSelection={clearRowsBp}
                                      setClearRowsSelection={setClearRowsBp}
                                      loading={loadingReconcileTableBp}
                                    />
                                  </>
                                </Grid>
                              </Grid>
                            </>
                          </Grid>
                        </Grid>
                      </>
                    )}
                  </>
                </TabPanel>

                <TabPanel value={value} index={1}>
                  <>
                    <Grid className="fade_in">
                      <Grid md={10}>
                        <Card className="p_20">
                          <div className="space_between">
                            <div className="d_flex">
                              <div>
                                <Autocomplete
                                  onChange={(e, value) => selectOptionFilterEntries(e, value)}
                                  value={selectedFilterEntry}
                                  id="optionsFilterEntries"
                                  className="w_210"
                                  options={Object.values(OptionsFilterEntries)} // Extract the values from the object
                                  size="small"
                                  getOptionLabel={(option) => option}
                                  disableClearable={true}
                                  renderInput={(params) => <TextField {...params} placeholder="Filter Entries By" />}
                                />
                              </div>

                              {selectedFilterEntry === OptionsFilterEntries.entryNumber && (
                                <>
                                  <div className="ml_10">
                                    <TextField
                                      className="pl_10 w_300"
                                      size="small"
                                      variant="outlined"
                                      fullWidth={true}
                                      name="name"
                                      placeholder="Enter Entry Number"
                                      value={entryNumChangeStatus}
                                      onChange={(e) => {
                                        setEntryNumChangeStatus(e.target.value);
                                        setShowChangeStatus(false);
                                      }}
                                      sx={{ background: "white" }}
                                    />
                                  </div>

                                  <div className="vertical_center_align ml_10">
                                    <span className="mr_10">Select Ledger</span>
                                    <FormControl>
                                      <RadioGroup
                                        row={true}
                                        aria-labelledby="demo-controlled-radio-buttons-group"
                                        name="controlled-radio-buttons-group"
                                        value={selectLedger}
                                        onChange={(e) => {
                                          setSelectLedger(e.target.value);
                                          setShowChangeStatus(false);
                                        }}
                                      >
                                        <FormControlLabel
                                          value={showEntriesSuggestion.OWN}
                                          control={<Radio />}
                                          label="Own"
                                        />
                                        <FormControlLabel
                                          value={showEntriesSuggestion.BP}
                                          control={<Radio />}
                                          label="Partner"
                                        />
                                      </RadioGroup>
                                    </FormControl>
                                  </div>
                                </>
                              )}

                              {selectedFilterEntry === OptionsFilterEntries.matchId && (
                                <div className="ml_10">
                                  <TextField
                                    className="pl_10 w_300"
                                    size="small"
                                    variant="outlined"
                                    fullWidth={true}
                                    name="name"
                                    placeholder="Enter Match ID"
                                    value={matchIDChangeStatus}
                                    onChange={(e) => {
                                      setMatchIDChangeStatus(e.target.value);
                                      setShowChangeStatus(false);
                                    }}
                                    sx={{ background: "white" }}
                                  />
                                </div>
                              )}
                              {selectedFilterEntry === OptionsFilterEntries.reconciliationStatus && (
                                <div className="ml_10">
                                  <Autocomplete
                                    onChange={(e, value) => {
                                      selectOptionReconcileStatus(e, value), setShowChangeStatus(false);
                                    }}
                                    id="optionsReconcileStatus"
                                    className="w_210"
                                    options={optionsReconcileStatus}
                                    size="small"
                                    getOptionLabel={(option) => option}
                                    disableClearable={true}
                                    renderInput={(params) => (
                                      <TextField {...params} placeholder="Select Recon Status" />
                                    )}
                                  />
                                </div>
                              )}
                            </div>
                            <div>
                              <Button
                                disabled={
                                  getReconciledEntriesLoading ||
                                  selectedFilterEntry === null ||
                                  (selectedFilterEntry === OptionsFilterEntries.entryNumber &&
                                    (entryNumChangeStatus?.trim() === "" || selectLedger === null)) ||
                                  (selectedFilterEntry === OptionsFilterEntries.matchId &&
                                    matchIDChangeStatus?.trim() === "") ||
                                  (selectedFilterEntry === OptionsFilterEntries.reconciliationStatus &&
                                    selectedOptionReconcileStatus === null)
                                }
                                startIcon={<LoadingIcon loading={getReconciledEntriesLoading} />}
                                onClick={() => {
                                  getReconciledEntries();
                                }}
                                className="theme_btn"
                              >
                                View Entries
                              </Button>
                            </div>
                          </div>
                        </Card>
                      </Grid>
                      {showChangeStatus && (
                        <Grid className="mt_20">
                          <Grid>
                            <div className="d_flex">
                              <div className="ml_10">
                                <Autocomplete
                                  onChange={(e, value) => selectOptionChangeReconcileStatus(e, value)}
                                  id="optionsReconcileStatus"
                                  className="w_210"
                                  options={optionsReconcileStatus}
                                  size="small"
                                  getOptionLabel={(option) => option}
                                  disableClearable={true}
                                  renderInput={(params) => <TextField {...params} placeholder="Change Status" />}
                                />
                              </div>
                              <div className="ml_10">
                                <Button
                                  disabled={
                                    updateReconStatusManuallyLoading ||
                                    selectedOptionChangeReconcileStatus === null ||
                                    selectedRowChangeReconcileStatus.length === 0
                                  }
                                  startIcon={<LoadingIcon loading={updateReconStatusManuallyLoading} />}
                                  onClick={() => {
                                    updateReconStatusManually();
                                    uiLogger(
                                      uiLoggerNamesRecon.UI_RL_RECONCILE_MANUALLY_CHANGE_RECON_STATUS_APPLY_CLICK
                                        .functionName,
                                      companyId.current,
                                      branchCode.current,
                                      {
                                        message:
                                          uiLoggerNamesRecon.UI_RL_RECONCILE_MANUALLY_CHANGE_RECON_STATUS_APPLY_CLICK
                                            .message,
                                        businessPartnerId: businessPartnerSelectedRef.current,
                                      }
                                    );
                                  }}
                                  className="theme_btn"
                                >
                                  Apply
                                </Button>
                              </div>
                            </div>
                          </Grid>
                          <Grid container={true} className="mt_20">
                            <Grid item={true} md={12}>
                              <BpDashboardTable
                                columns={columnDefinitionChangeReconcileStatus}
                                setSelectedRow={setSelectedRowChangeReconcileStatus}
                                rows={rowsDataChangeReconcileStatus}
                                sortEnable={true}
                                fromTab="ManualRecon"
                                actorType="BPDashboard"
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      )}
                    </Grid>
                  </>
                </TabPanel>

                <TabPanel value={value} index={2}>
                  <>
                    <Grid className="mb_15 fade_up" justifyContent="center" display="flex">
                      <div className="center_align matchId_heading">Type in the Match IDs You want to unreconcile</div>
                    </Grid>

                    <Grid
                      container={true}
                      className="min_height_500 manual_box_container fade_up"
                      sx={{ position: "relative" }}
                    >
                      <Grid item={true} md={12} className="br_10 main_tables_box">
                        <>
                          <Grid container={true} className="mb_15">
                            <Grid item={true} md={9}>
                              <TextField
                                inputRef={textFieldRefUnReconcile}
                                size="small"
                                variant="outlined"
                                fullWidth={false}
                                name="name"
                                placeholder="Insert Id's like I1, P7"
                                value={matchIdValue}
                                onChange={(e) => {
                                  setMatchIdValue(e.target.value);
                                }}
                                onKeyDown={handleEnterKeyPress}
                                sx={{ background: "#fff", width: "400px" }}
                              />
                            </Grid>
                            <Grid item={true} md={3} className="flex_end">
                              <Button
                                className="theme_btn"
                                onClick={() => {
                                  unReconcileShowDetailsClick();
                                }}
                              >
                                Show Details
                              </Button>
                            </Grid>
                          </Grid>
                          <Grid container={true}>
                            <Grid item={true} md={12}>
                              <BpDashboardTable
                                columns={columnDefinitionUnreconciled}
                                rows={rowsDataUnreconciled}
                                sortEnable={true}
                                fromTab="ManualRecon"
                              />
                            </Grid>
                          </Grid>
                          <Grid className="mt_30" display="flex" justifyContent="center" position="relative">
                            {/* {manualReconType === manualReconTypeName.UNRECONCILE && showUnreconcileButton && ( */}
                            <Button
                              onClick={() => {
                                setIsOpenUnreconcileConfirmWarningModal(true);
                                uiLogger(
                                  uiLoggerNamesRecon.UI_RL_RECONCILE_MANUALLY_UNRECONCILE_CLICK.functionName,
                                  companyId.current,
                                  branchCode.current,
                                  {
                                    message: uiLoggerNamesRecon.UI_RL_RECONCILE_MANUALLY_UNRECONCILE_CLICK.message,
                                    businessPartnerId: businessPartnerSelectedRef.current,
                                  }
                                );
                              }}
                              disabled={rowsDataUnreconciled.length === 0}
                              className="theme_btn theme_btn_warning w_300"
                            >
                              Unreconcile
                            </Button>
                          </Grid>
                        </>
                      </Grid>
                    </Grid>
                  </>
                </TabPanel>
                {/* <TabPanel value={value} index={3}>
                  <>
                    <Grid className="mb_15 fade_in" justifyContent="center" display="flex">
                      <Button
                        disabled={autoOutOfPeriodLoading}
                        startIcon={<LoadingIcon loading={autoOutOfPeriodLoading} />}
                        onClick={() => {
                          reconcileOutOfPeriodEntries();
                        }}
                        className="theme_btn"
                      >
                        Auto Checking for out of Period
                      </Button>
                    </Grid>
                  </>
                </TabPanel> */}
                <TabPanel value={value} index={3}>
                  <Grid>
                    <ReactTable
                      enableFacetedValues={true}
                      enableRowVirtualization={false}
                      layoutMode="semantic"
                      columns={columnDefinitionManualReconcileHistoryTable}
                      rows={rowsDataAppliedHistory}
                      enableRowSelection={(row) => row.original.action === "Matched"} //enable row selection conditionally per row
                      enableAggregations={true}
                      setSelectedRow={setSelectedAppliedHistory}
                      loading={loadingAppliedHistory}
                      positionToolbarAlertBanner={"top"}
                      internalActionsOrder={[
                        "globalFilterToggle",
                        "filterToggle",
                        "showHideColumns",
                        "fullScreenToggle",
                      ]}
                      paginationDisplayMode={"default"}
                      renderBottomToolbarCustomActions={() => {
                        return <Box sx={{ display: "flex", ml: "auto" }}></Box>;
                      }}
                      renderTopToolbarCustomActions={() => {
                        return <Box sx={{ display: "flex", ml: "auto" }}></Box>;
                      }}
                    />
                  </Grid>
                </TabPanel>

                {/* Fixed Footer */}
                {(selectedRowOwn?.length > 0 ||
                  selectedRowBp?.length > 0 ||
                  selectedSuggestedEntry?.length > 0 ||
                  selectedSuggestedHistoryPresent?.length > 0 ||
                  selectedAppliedHistory?.length > 0) && (
                  <Box position="sticky" bottom={0} className="footer-box-manual-recon">
                    <Grid sx={{ padding: "16px 24px" }} className="vertical_center_align">
                      {/* reconcile table btn */}
                      {activeTable === ActiveTableInterface.ReconcileTable && (
                        <>
                          <Box className="button-box" sx={{ mr: 5 }}>
                            <Button
                              disabled={
                                (selectedRowOwn?.length < 1 && selectedRowBp?.length < 1) || showLoadingIconReconcile
                              }
                              onClick={() => {
                                if (absoluteReconcileTotalSum < 1) {
                                  setGapAccountedName(gapAccountedAsName.roundingError);
                                }
                                setIsOpenReconcileConfirmWarningModal(true);
                                uiLogger(
                                  uiLoggerNamesRecon.UI_RL_RECONCILE_MANUALLY_RECONCILE_CLICK.functionName,
                                  companyId.current,
                                  branchCode.current,
                                  {
                                    message: uiLoggerNamesRecon.UI_RL_RECONCILE_MANUALLY_RECONCILE_CLICK.message,
                                    businessPartnerId: businessPartnerSelectedRef.current,
                                  }
                                );
                              }}
                              className="green_btn"
                              startIcon={<LoadingIcon loading={showLoadingIconReconcile} />}
                            >
                              Reconcile
                            </Button>
                          </Box>
                        </>
                      )}
                      {activeTable === ActiveTableInterface.SuggestedMatchesTable && (
                        <>
                          <Button
                            disabled={reconcileSuggestionsLoading || selectedSuggestedEntry?.length === 0}
                            startIcon={<LoadingIcon loading={reconcileSuggestionsLoading} />}
                            onClick={async () => {
                              await reconcileSuggestedMatch();
                              await getSuggestedHistory(ManualReconHistoryRecordState.suggested);
                              uiLogger(
                                uiLoggerNamesRecon.UI_RL_RECONCILE_MANUALLY_SUGGESTED_MATCHES_VIEW_MORE_RECONCILE_CLICK
                                  .functionName,
                                companyId.current,
                                branchCode.current,
                                {
                                  message:
                                    uiLoggerNamesRecon
                                      .UI_RL_RECONCILE_MANUALLY_SUGGESTED_MATCHES_VIEW_MORE_RECONCILE_CLICK.message,
                                  businessPartnerId: businessPartnerSelectedRef.current,
                                }
                              );
                            }}
                            className="green_btn"
                            sx={{ mr: 5 }}
                          >
                            Reconcile
                          </Button>
                        </>
                      )}
                      {/*  */}
                      {(activeTable === ActiveTableInterface.ReconcileTable ||
                        activeTable === ActiveTableInterface.SuggestedMatchesTable) && (
                        <Grid item className="totals-container">
                          <Grid container spacing={2} alignItems="center">
                            <Grid item>
                              <Typography variant="caption" sx={{ color: "#2E7D32" }}>
                                Own Sum
                              </Typography>
                              <Typography variant="subtitle2">
                                {formatMoney(ToDineroObj(reconcileOwnSum, currency.current as Currency))}
                              </Typography>
                            </Grid>
                            <Grid item>
                              <Typography variant="subtitle1" color="textSecondary">
                                +
                              </Typography>
                            </Grid>
                            <Grid item>
                              <Typography variant="caption" color="primary">
                                Partner Sum
                              </Typography>
                              <Typography variant="subtitle2">
                                {formatMoney(ToDineroObj(reconcileBpSum, currency.current as Currency))}
                              </Typography>
                            </Grid>
                            <Grid item>
                              <Typography variant="subtitle1" color="textSecondary">
                                =
                              </Typography>
                            </Grid>
                            <Grid item>
                              <Typography variant="caption" color="textSecondary">
                                Gap
                              </Typography>
                              <Typography variant="subtitle2">
                                {formatMoney(ToDineroObj(reconcileTotalSum, currency.current as Currency))}{" "}
                                {(selectedRowOwn?.length > 0 && selectedRowBp?.length > 0) ||
                                selectedSuggestedEntry?.length > 0
                                  ? `(${reconcileTotalPercentage} %)`
                                  : ""}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      )}

                      {activeTable === ActiveTableInterface.ManualReconcileHistoryTable && (
                        <>
                          <Button
                            disabled={
                              getSuggestedHistoryLoading ||
                              selectedSuggestedHistoryPresent?.length === 0 ||
                              statusDataFetching
                            }
                            startIcon={<LoadingIcon loading={getSuggestedHistoryLoading} />}
                            onClick={async () => {
                              await applySuggestedHistoryMatches();
                              await createAndFetchManualReconSuggestions("");
                            }}
                            className="green_btn"
                          >
                            Apply Match
                          </Button>
                        </>
                      )}
                      {selectedAppliedHistory?.length > 0 && (
                        <>
                          <Button
                            disabled={loadingRevertAppliedHistory || selectedAppliedHistory?.length === 0}
                            startIcon={<LoadingIcon loading={loadingRevertAppliedHistory} />}
                            onClick={() => {
                              revertAppliedHistory();
                            }}
                            className="green_btn"
                          >
                            Revert
                          </Button>
                        </>
                      )}
                    </Grid>
                  </Box>
                )}
                {/* End of Fixed Footer */}
              </Box>
            </Grid>
          </div>

          <UnreconcileConfirmWarningModal />
          <ReconcileConfirmWarningModal />
          <ManualReconReconcileOwnFilter />
          <ManualReconReconcileBpFilter />
          <DeleteAllMatches />
        </Grid>
      </ManualReconContext.Provider>
    </>
  );
};

export default ManualRecon;
