import { Clear } from "@mui/icons-material";
import { Checkbox, IconButton, InputAdornment, Stack, Typography } from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import React from "react";

export default function InvoicingTaskComponent({
  title,
  isChecked,
  setIsChecked,
  selectedDate,
  setSelectedDate,
  selectedDateErrorText,
  setSelectedDateErrorText,
}) {
  return (
    <Stack direction={"row"} gap={2} justifyContent={"space-between"}>
      <Stack direction={"row"} alignItems={"center"}>
        <Checkbox checked={isChecked} onChange={(e) => setIsChecked(e.target.checked)} />
        <Typography>{title}</Typography>
      </Stack>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DesktopDatePicker
          sx={{ "& fieldset": { borderRadius: "4px" } }}
          label="Invoice Date"
          onError={(error) => {
            if (error) setSelectedDateErrorText(error);
            else setSelectedDateErrorText("");
          }}
          onChange={(date: Date) => {
            setSelectedDate(date);
          }}
          disabled={!isChecked}
          value={selectedDate}
          format="dd/MM/yyyy"
          slotProps={{
            textField: {
              helperText: selectedDateErrorText && "Invalid Date",
              sx: { width: "200px" },
              size: "small",
              InputProps: {
                style: { borderRadius: "4px" },
                startAdornment: (
                  <InputAdornment position="start" sx={{ margin: 0 }}>
                    {selectedDate && (
                      <IconButton
                        disabled={!isChecked}
                        size="small"
                        sx={{ position: "absolute", right: "40px" }}
                        onClick={() => {
                          setSelectedDate(null);
                        }}
                      >
                        <Clear />
                      </IconButton>
                    )}
                  </InputAdornment>
                ),
              },
            },
          }}
        />
      </LocalizationProvider>
    </Stack>
  );
}
