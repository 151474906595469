import { Box, Button, Skeleton, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import useFetch from "src/Components/Common/useFetch";
import { TICKET_CATEGORY } from "src/entity/recon-entity/ReconInterfaces";
import StatusSection from "./StatusSection";

export interface StatusItem {
  id: number;
  status: string;
  actionOwner: string;
  isActive: boolean;
  isDefault: boolean;
  defaultStatus: string;
}
export interface CategoryWiseStatuses {
  [key: string]: StatusItem[];
}

const allCategories = [
  TICKET_CATEGORY.Ledger,
  TICKET_CATEGORY.Maker,
  TICKET_CATEGORY.Checker,
  TICKET_CATEGORY.Approver,
  TICKET_CATEGORY.DisputeResolution,
  TICKET_CATEGORY.Miscellaneous,
];

export default function WorkflowSettings() {
  const [isLoadingStatuses, setIsLoadingStatuses] = useState<boolean>(false);
  const [isAddingNewStatus, setIsAddingNewStatus] = useState<boolean>(false);
  const [statuses, setStatuses] = useState<CategoryWiseStatuses>({});
  const [actionOwnerOptions, setActionOwnerOptions] = useState<string[]>([]);

  useEffect(() => {
    getAllStatuses();
    getCustomisedData();
  }, []);

  const getCustomisedData = () => {
    useFetch("/api/Workflow/CustomizationData", "GET", {
      thenCallBack: (response) => {
        setActionOwnerOptions(response.data.actionOwner || []);
      },
    });
  };
  const getAllStatuses = () => {
    setIsLoadingStatuses(true);
    useFetch<{ categoryWiseStatuses: CategoryWiseStatuses }>("/api/Workflow/Status", "GET", {
      thenCallBack: (response) => {
        setStatuses(response.data.categoryWiseStatuses || {});
        setIsLoadingStatuses(false);
      },
      catchCallBack: () => {
        setIsLoadingStatuses(false);
      },
    });
  };

  const handleAddStatus = (category: string) => {
    const newStatus: StatusItem = {
      id: null,
      status: "",
      actionOwner: "-",
      isActive: true,
      isDefault: false,
      defaultStatus: "",
    };
    setStatuses((prev) => ({
      ...prev,
      [category]: [...prev[category], newStatus],
    }));
  };

  return (
    <Box
      sx={{
        background: "#ffffff",
      }}
    >
      <Box
        sx={{
          padding: "16px",
          borderBottom: "1px solid #dadada",
        }}
      >
        <Typography variant="h6">Task Status Customisation</Typography>
        <Typography variant="body2">Create, rename and hide your workflow statuses</Typography>
      </Box>
      <Box
        sx={{
          overflow: "auto",
          paddingX: "16px",
          maxHeight: "74vh",
        }}
      >
        {isLoadingStatuses ? (
          <Stack gap={2} my={2}>
            <Skeleton variant="rectangular" height={40} width={150} />
            <Skeleton variant="rectangular" height={40} />
            <Skeleton variant="rectangular" height={40} />
            <Skeleton variant="rectangular" height={40} />
            <Skeleton variant="rectangular" height={40} width={300} />
            <Skeleton variant="rectangular" height={40} />
            <Skeleton variant="rectangular" height={40} />
            <Skeleton variant="rectangular" height={40} width={250} />
            <Skeleton variant="rectangular" height={40} />
            <Skeleton variant="rectangular" height={40} />
            <Skeleton variant="rectangular" height={40} />
          </Stack>
        ) : (
          allCategories.map((category) => {
            return (
              <Box
                key={category}
                sx={{
                  borderBottom: "1px solid #dadada",
                  marginTop: "16px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography variant="overline" className="fw_700">
                    {category}
                  </Typography>
                  <Button
                    onClick={() => handleAddStatus(category)}
                    disabled={isAddingNewStatus}
                    variant="outlined"
                    sx={{
                      fontSize: "14px",
                      fontWeight: "500",
                      borderRadius: "4px !important",
                      color: "rgba(84, 28, 76, 1)",
                      borderColor: "rgba(84, 28, 76, 0.50)",
                      "&:hover, &.Mui-focusVisible": { borderColor: "rgba(84, 28, 76, 1)" },
                    }}
                  >
                    Add Status
                  </Button>
                </Box>
                {statuses[category]?.map((status) => (
                  <StatusSection
                    key={status.id}
                    setStatuses={setStatuses}
                    statuses={statuses}
                    status={status}
                    category={category}
                    setIsAddingNewStatus={setIsAddingNewStatus}
                    actionOwnerOptions={actionOwnerOptions}
                    getAllStatuses={getAllStatuses}
                  />
                ))}
              </Box>
            );
          })
        )}
      </Box>
    </Box>
  );
}
