import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import React, { useState } from "react";
import LoadingIcon from "src/Components/Common/LoadingIcon";
import { NdButton } from "../../PartnerCommunication/MsmePartnerPortal/CommonComponents";
import { TicketMetaStatuses } from "../../Tickets/ViewTickets";

export default function SaveTaskModal({ open, setOpen, changeStatus, taskId }) {
  const [isSavingTask, setIsSavingTask] = useState(false);
  return (
    <Dialog open={open} sx={{ borderRadius: "4px" }}>
      <DialogTitle>Are you sure you want to save the current recon</DialogTitle>
      <DialogContent>
        <Alert severity="info">
          This action will close this reconciliation and save the result for future reconciliations
        </Alert>
      </DialogContent>
      <DialogActions sx={{ padding: 2 }}>
        <Button
          variant="text"
          onClick={() => {
            setOpen(false);
          }}
        >
          Cancel
        </Button>
        <NdButton
          disabled={isSavingTask}
          variant="contained"
          onClick={async () => {
            setIsSavingTask(true);
            await changeStatus(TicketMetaStatuses.Saved, taskId);
            setIsSavingTask(false);
          }}
        >
          {isSavingTask && <LoadingIcon loading={isSavingTask} />}
          Save
        </NdButton>
      </DialogActions>
    </Dialog>
  );
}
