import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import { Box, Button, Stack, Step, StepLabel, Stepper } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { uiLoggerNamesPartnerCommunication } from "src/Utils/Recon/UiLogger/Constants";
import uiLogger from "src/Utils/UiLogger";
import { BcBetaContext } from "../../BalanceConfirmationBeta/BalanceConfirmationBeta";
import { DialogSection } from "../../BalanceConfirmationBeta/CommunicationTypes/CommsTypesCommon";
import { PartnerCommsContext } from "../../Context/PartnerCommunicationContext";
import { NdAsyncButton, NdButton } from "../../MsmePartnerPortal/CommonComponents";
import { StateDispatch } from "../CommonComponents";
import { ChooseCommsType } from "./ChooseCommsType";
import SetupMail from "./SetupMail";

export const steps = ["Communication Type", "Setup Email"];

interface BulkMailProps {
  setOpenBcBetaBulkMailing: (open: boolean) => void;
  activeStep: number;
  setActiveStep: StateDispatch<number>;
  companyId: string;
  branchCode: string;
  // selectedRow: object[] | any[] | Record<string, any>[];
}

export default function BulkMail({
  setOpenBcBetaBulkMailing,
  activeStep,
  setActiveStep,
  companyId,
  branchCode,
}: BulkMailProps) {
  // const [selectedCommsTypeId, setSelectedCommsTypeId] = useState<number>(undefined);
  const [passedFns, setPassedFns] = useState<{ sendEmail: () => Promise<void> }>();
  const { selectedRow } = useContext(BcBetaContext);
  // const dispatch = useDispatch();

  const firstFoundCommType = selectedRow.find((row) => row.communicationTypeId);
  const allRowsMatched = selectedRow.every(
    (row) => row?.communicationTypeId !== firstFoundCommType?.communicationTypeId
  );
  const { communicationTypeId, setCommunicationTypeId, setTaskLabel, setEscalationStarted } =
    useContext(PartnerCommsContext);

  const handlePageBack = () => {
    setOpenBcBetaBulkMailing(false);
  };

  const handleBack = () => {
    if (firstFoundCommType && !allRowsMatched) {
      handlePageBack();
      return;
    }

    if (activeStep < 1) handlePageBack();
    else setActiveStep(activeStep - 1);
  };

  const handleNext = () => {
    if (activeStep === 1) return;
    setActiveStep(activeStep + 1);
  };

  useEffect(() => {
    // Clean up Function: clear communication type id and task label
    return () => {
      setCommunicationTypeId(null);
      setTaskLabel({ title: "" });
      setEscalationStarted(false);
    };
  }, []);

  return (
    <Box sx={{ width: "100%" }}>
      {/* Top Stepper Bar */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          p: 2,
          borderBottom: 1,
          borderColor: "divider",
          bgcolor: "background.paper",
          borderRadius: 4,
        }}
      >
        <Stepper nonLinear activeStep={activeStep} sx={{ flex: 1, maxWidth: 900 }}>
          {steps.map((label, index) => (
            <Step key={label} completed={activeStep > index}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>

      {/* Main Section */}
      <DialogSection
        sx={{
          p: 2,
          "& > div": {
            animation: "fade-in 0.25s ease-out",
          },
        }}
      >
        {activeStep === 0 && <ChooseCommsType setSelectedCommsTypeId={setCommunicationTypeId} />}
        {activeStep === 1 && (
          <SetupMail handleBack={handleBack} handlePageBack={handlePageBack} setPassedFns={setPassedFns} />
        )}
      </DialogSection>

      {/* Bottom Navigation Bar */}
      <Box
        sx={{
          position: "fixed",
          bottom: 0,
          left: -2,
          width: "100%",
          boxShadow: "0px -2px 4px 0px rgba(0, 0, 0, 0.08)",
          background: "#fff",
        }}
      >
        <Stack
          direction={"row"}
          sx={{
            justifyContent: "space-between",
            maxWidth: "900px",
            marginX: "auto",
            padding: "24px",
          }}
        >
          <Stack sx={{ display: "flex", flexDirection: "row", gap: "16px" }}>
            <Button color="inherit" onClick={handleBack} startIcon={<ChevronLeft />}>
              Back
            </Button>
            <Box sx={{ flex: "1 1 auto" }} />
            {activeStep === 0 && (
              <NdButton
                variant="contained"
                disabled={!communicationTypeId}
                onClick={handleNext}
                endIcon={<ChevronRight />}
              >
                Next
              </NdButton>
            )}
            {activeStep === 1 && (
              <NdAsyncButton
                className="theme_btn"
                buttonComponent={Button}
                onClick={async () => {
                  uiLogger(
                    uiLoggerNamesPartnerCommunication.UI_PC_BCB_BULK_MAIL_SEND_CLICK.functionName,
                    companyId,
                    branchCode,
                    {
                      message: uiLoggerNamesPartnerCommunication.UI_PC_BCB_BULK_MAIL_SEND_CLICK.message,
                    }
                  );

                  if (passedFns?.sendEmail) await passedFns?.sendEmail();
                  // done sending emails
                }}
              >
                Send Email
              </NdAsyncButton>
            )}
          </Stack>
        </Stack>
      </Box>
    </Box>
  );
}
