import ApartmentIcon from "@mui/icons-material/Apartment";
import PersonIcon from "@mui/icons-material/Person";
import WorkIcon from "@mui/icons-material/Work";
import { Autocomplete, Box, CircularProgress, TextField, Tooltip } from "@mui/material";
import { createFilterOptions } from "@mui/material/Autocomplete";
import React, { useContext, useEffect, useRef, useState } from "react";
import useFetch from "src/Components/Common/useFetch";
import { userContext } from "src/Components/Contexts/userContext";
import { ListOwnTallyCompanies, ListOwnTallyCompaniesRes } from "src/entity/recon-entity/ReconInterfaces";
import { API_ENDPOINTS } from "src/Utils/ApiConstants/ApiUrlConstants";
import { uiLoggerNamesTaskBoard } from "src/Utils/Recon/UiLogger/Constants";
import uiLogger from "src/Utils/UiLogger";

export const DEFAULT_COMPANY_ID = "default-company-id";

export const DEFAULT_BRANCH_CODE = "default-branch-code";

export type TallyOrSapCompanyId = string & NonNullable<unknown>;

export type StateDispatch<T> = React.Dispatch<React.SetStateAction<T>>;

export interface ListCompaniesRes {
  companies: CompanyInfo[];
  lastSelectedCompanyId: string;
}

export interface CompanyInfo {
  companyId: string;
  companyName: string;
}

export interface ListBranchesRes {
  lastSelectedbranch: BranchInfo;
  branches: BranchInfo[];
}

export interface BranchInfo {
  branchCode: string;
  branchName: string;
}

interface IntegratedDropDownIconsProps {
  tallyCompanyNameSelect?: (e: any, option: ListOwnTallyCompanies) => void;
  AfterListOwnTallyCompanies?: (res: ListOwnTallyCompaniesRes) => void;
  companyNameSelect: (e: any, option: CompanyInfo) => void;
  AfterListCompanies?: (res: ListCompaniesRes) => void;
  branchNameSelect: (e: any, option: BranchInfo) => void;
  AfterListBranches?: (res: ListBranchesRes) => void;
  isDisabled?: boolean;
  companyId?: string;
  branchCode?: string;
}

export const IntegratedDropDownIcons = ({
  tallyCompanyNameSelect,
  companyNameSelect,
  branchNameSelect,
  companyId,
  branchCode,
  ...props
}: IntegratedDropDownIconsProps) => {
  const { actor } = useContext(userContext);

  const [companiesLoaded, setCompaniesLoaded] = useState<boolean>(false);
  const [listCompanies, setListCompanies] = useState<CompanyInfo[]>([]);
  const [lastCompany, setLastCompany] = useState<CompanyInfo>(null);
  const selectedCompanyId = useRef<string>(DEFAULT_COMPANY_ID);

  const [branchesLoaded, setBranchesLoaded] = useState<boolean>(true);
  const [listBranches, setListBranches] = useState<BranchInfo[]>([]);
  const [lastBranch, setLastBranch] = useState<BranchInfo>(null);
  const selectedBranchCode = useRef<string>(DEFAULT_BRANCH_CODE);

  const [ownBusinessPartnersLoaded, setOwnBusinessPartnersLoaded] = useState<boolean>(true);
  const [ownBusinessPartners, setOwnBusinessPartners] = useState([]);
  const [selectedBusinessPartner, setSelectedBusinessPartner] = useState<any>(null);

  const optionsListCompanies = GetOptionsList(listCompanies, "companyName");
  const optionsListBranches = GetOptionsList(listBranches, "branchName");
  const optionsOwnBusinessPartners = GetOptionsList(ownBusinessPartners, "businessPartnerName");

  const _internalCompanyNameSelect = (e: any, option: CompanyInfo) => {
    if (option !== null) {
      if (e?.persist) e.persist();
      companyNameSelect && companyNameSelect(e, option);
      setLastCompany(option);
      selectedCompanyId.current = option.companyId;
      setSelectedBusinessPartner(null);
      setOwnBusinessPartners([]);
      if (actor.integration && actor.branchLevelReconcilation) {
        ListBranches(option.companyId);
      } else if (actor.integration) {
        listAllOwnBusinessPartners();
      }
    }
  };

  const _internalBranchNameSelect = (e: any, option: BranchInfo) => {
    if (option !== null) {
      if (e?.persist) e.persist();
      branchNameSelect && branchNameSelect(e, option);
      setLastBranch(option);
      selectedBranchCode.current = option.branchCode;
      setSelectedBusinessPartner(null);
      setOwnBusinessPartners([]);
      if (actor.integration && actor.branchLevelReconcilation) {
        listAllOwnBusinessPartners();
      }
    }
  };

  const _internalCompanySave = (e: any, option: CompanyInfo) => {
    // Reset Branch before selecting new company
    setLastBranch(null);
    branchNameSelect(e, null);

    _internalCompanyNameSelect(e, option);
    useFetch(API_ENDPOINTS.SAVE_SELECTED_COMPANY.url, "POST", {
      failureMessage: API_ENDPOINTS.SAVE_SELECTED_COMPANY.failureMessage,
      showSuccessToast: true,
      data: {
        companyId: option.companyId,
      },
      thenCallBack: (_res) => {},
    });
  };

  const _internalBranchSave = (e: any, option: BranchInfo) => {
    _internalBranchNameSelect(e, option);
    useFetch(API_ENDPOINTS.SAVE_SELECTED_BRANCH.url, "POST", {
      failureMessage: API_ENDPOINTS.SAVE_SELECTED_BRANCH.failureMessage,
      showSuccessToast: true,
      data: {
        companyId: lastCompany.companyId,
        branchCode: option.branchCode,
        branchName: option.branchName,
      },
      thenCallBack: (_res) => {},
    });
  };

  const ListCompanies = async () => {
    setCompaniesLoaded(false);
    useFetch<ListCompaniesRes>(API_ENDPOINTS.LIST_COMPANIES.url, "GET", {
      failureMessage: API_ENDPOINTS.LIST_COMPANIES.failureMessage,
      thenCallBack: (res) => {
        setListCompanies(res?.data?.companies);
        setCompaniesLoaded(true);
        const lastCompID = res?.data?.lastSelectedCompanyId;
        if (lastCompID) {
          const foundCompany = res.data.companies.find((c) => c.companyId === lastCompID);
          const foundLastCompany = {
            firstLetter: foundCompany?.companyName[0].toUpperCase(),
            ...foundCompany,
          };
          if (foundCompany) {
            setLastCompany(foundLastCompany);
            _internalCompanyNameSelect({}, foundLastCompany);
          }
        }
        if (props.AfterListCompanies) props.AfterListCompanies(res.data);
      },
    });
  };

  const ListBranches = async (company: string) => {
    setBranchesLoaded(false);
    await useFetch<ListBranchesRes>(API_ENDPOINTS.LIST_BRANCHES.url, "GET", {
      failureMessage: API_ENDPOINTS.LIST_BRANCHES.failureMessage,
      config: {
        params: {
          companyId: company,
        },
      },
      thenCallBack: (res) => {
        setListBranches(res?.data?.branches);
        setBranchesLoaded(true);
        const lastBranch = res?.data?.lastSelectedbranch;
        if (lastBranch) {
          const foundBranch = res.data.branches.find((c) => c.branchCode === lastBranch.branchCode);
          const foundLastBranch = {
            firstLetter: foundBranch?.branchName[0]?.toUpperCase(),
            ...foundBranch,
          };
          if (foundBranch) {
            setLastBranch(foundLastBranch);
            _internalBranchNameSelect({}, foundLastBranch);
          } else {
            setLastBranch(null);
          }
        }
        if (props.AfterListBranches) props.AfterListBranches(res.data);
      },
    });
  };

  const listAllOwnBusinessPartners = async () => {
    setOwnBusinessPartnersLoaded(false);
    await useFetch("/api/ListAllBusinessPartners", "GET", {
      config: {
        params: {
          ownId: actor.id,
          companyId: selectedCompanyId.current,
          branchCode: selectedBranchCode.current,
        },
      },
      thenCallBack: (response) => {
        setOwnBusinessPartners(response.data.partnerList);
        setOwnBusinessPartnersLoaded(true);
        // listUserEmailTemplates();
      },
    });
  };

  const filterOptions = createFilterOptions({
    stringify: (option: any) => `${option.businessPartnerVendorCode} ${option.businessPartnerName}`,
  });
  useEffect(() => {
    if (actor.integration) {
      ListCompanies();
    } else {
      listAllOwnBusinessPartners();
    }
  }, [actor]);

  return (
    <>
      {/* Company Select Drop Down */}
      {actor.integration === true && (
        <div className="width_30_per d_block">
          <IconCommonAutoComplete<CompanyInfo>
            lastSelected={lastCompany}
            onChangeSelectFn={_internalCompanySave}
            optionsList={optionsListCompanies}
            optionLabelKey={"companyName"}
            loaded={companiesLoaded}
            label="Company Name"
            placeholder={"Select Company"}
            isDisabled={props.isDisabled}
          />
        </div>
      )}

      {/* Any Acc Software Branch Drop Down */}
      {actor.integration === true && actor.branchLevelReconcilation === true && (
        <div className="width_30_per d_block">
          <IconCommonAutoComplete<BranchInfo>
            lastSelected={lastBranch}
            onChangeSelectFn={_internalBranchSave}
            optionsList={optionsListBranches}
            optionLabelKey={"branchName"}
            loaded={branchesLoaded}
            label="Branch Name"
            placeholder="Select Branch"
          />
        </div>
      )}
      <Autocomplete
        size="small"
        sx={{
          width: "300px",
          "& fieldset": { borderRadius: "4px" },
          flex: 1,
        }}
        value={selectedBusinessPartner}
        onChange={(_evt, value) => {
          setSelectedBusinessPartner(value);
          if (value) {
            uiLogger(
              uiLoggerNamesTaskBoard.UI_WF_TB_PARTNERSELECTFROMDROPDOWN_CLICK.functionName,
              companyId,
              branchCode,
              {
                message: uiLoggerNamesTaskBoard.UI_WF_TB_PARTNERSELECTFROMDROPDOWN_CLICK.message,
                businessPartnerId: value.businessPartnerId,
              }
            );
            const params = `bpId=${value.businessPartnerId}&bpName=${btoa(value.businessPartnerName)}`;
            const uri = `/${actor.name}/recon360/Summary/Ledger?${params}`;
            window.open(uri, "_blank")?.focus();
          }
        }}
        id="businessPartner"
        options={optionsOwnBusinessPartners.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))}
        loading={!ownBusinessPartnersLoaded}
        groupBy={(option) => option.firstLetter}
        getOptionLabel={(option: any) =>
          option.vendorCode
            ? `${option.businessPartnerName} (Vendor Code: ${option.businessPartnerVendorCode})`
            : option.businessPartnerName
        }
        filterOptions={filterOptions}
        renderOption={(props, option) => (
          <Box
            key={`${option.id}-${option.businessPartnerVendorCode}`}
            component="li"
            sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
            {...props}
            style={{ display: "block" }}
          >
            <div>{option.businessPartnerName}</div>
            <div>
              <span style={{ color: "#666666", fontSize: "12px" }}>
                Vendor Code: {option.businessPartnerVendorCode}
              </span>
            </div>
          </Box>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder="Partner Search"
            InputProps={{
              ...params.InputProps,
              startAdornment: <PersonIcon color="primary" />,
              endAdornment: (
                <React.Fragment>
                  {!ownBusinessPartnersLoaded ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
    </>
  );
};

function GetOptionsList<T>(optionsList: T[], keyName: keyof T) {
  return optionsList.map((option) => {
    const firstLetter = (option[keyName][0] as string)?.toUpperCase();
    return {
      firstLetter,
      ...option,
    };
  });
}

export type RequireAtLeastOne<T, Keys extends keyof T = keyof T> = Pick<T, Exclude<keyof T, Keys>> &
  {
    [K in Keys]-?: Required<Pick<T, K>> & Partial<Pick<T, Exclude<Keys, K>>>;
  }[Keys];

interface CommonAutoCompletePropsBase<T> {
  lastSelected: Partial<T>;
  onChangeSelectFn: (e: any, value: T) => void;
  optionsList: (T & { firstLetter: string })[];
  optionLabelKey: keyof T;
  loaded: boolean;
  label?: string;
  placeholder?: string;
  id?: string;
  isDisabled?: boolean;
}

type CommonAutoCompleteProps<T> = RequireAtLeastOne<CommonAutoCompletePropsBase<T>, "label" | "placeholder">;

type TypeWithFirstLetter = { firstLetter?: string; [k: string]: any };

export function IconCommonAutoComplete<T extends TypeWithFirstLetter>({
  lastSelected,
  onChangeSelectFn,
  optionsList,
  optionLabelKey,
  loaded,
  label,
  id,
  isDisabled = false,
}: CommonAutoCompleteProps<T>) {
  return (
    <Autocomplete
      slotProps={{
        paper: {
          sx: {
            width: "250px",
          },
        },
      }}
      sx={{
        width: "70px",
        "& fieldset": { borderRadius: "4px" },
      }}
      disabled={isDisabled}
      value={lastSelected}
      size="medium"
      onChange={(e, value) => onChangeSelectFn(e, value as T)}
      id={id || label}
      options={optionsList.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter)) || []}
      loading={!loaded}
      disableClearable={true}
      groupBy={(option) => option?.firstLetter}
      getOptionLabel={(option) => (option as T)[optionLabelKey] || ""}
      renderInput={(params) => (
        <TextField
          {...params}
          sx={{
            "& input": {
              display: "none",
            },
          }}
          placeholder="Type Name"
          InputProps={{
            ...params.InputProps,
            startAdornment:
              optionLabelKey === "companyName" ? (
                <Tooltip title="Select Company">
                  <ApartmentIcon color="primary" />
                </Tooltip>
              ) : (
                <Tooltip title="Select Branch/Plant/Unit">
                  <WorkIcon color="primary" />
                </Tooltip>
              ),
          }}
        />
      )}
    />
  );
}
