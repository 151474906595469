import AddIcon from "@mui/icons-material/Add";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import MailIcon from "@mui/icons-material/Mail";
import { Box, Button, Divider } from "@mui/material";
import { Buffer } from "buffer";
import { saveAs } from "file-saver";
import React, { useContext, useState } from "react";
import LoadingIcon from "src/Components/Common/LoadingIcon";
import useFetch from "src/Components/Common/useFetch";
import { userContext } from "src/Components/Contexts/userContext";
import { API_ENDPOINTS } from "src/Utils/ApiConstants/ApiUrlConstants";
import { uiLoggerNamesPartnerRiskManagement } from "src/Utils/Recon/UiLogger/Constants";
import uiLogger from "src/Utils/UiLogger";
import { DEFAULT_BRANCH_CODE, DEFAULT_COMPANY_ID } from "../../PartnerCommunication/CommonLegacy/CommonComponents";
import { NdButton } from "../../PartnerCommunication/MsmePartnerPortal/CommonComponents";

export default function Header({ setShowMISFilterModal, setShowAddNewReportModal }) {
  const [showLoaderDownloadTaskRepository, setShowLoaderDownloadTaskRepository] = useState(false);
  const { actor } = useContext(userContext);

  const downloadTaskRepositoryReport = () => {
    setShowLoaderDownloadTaskRepository(true);
    useFetch(API_ENDPOINTS.GET_DOWNLOAD_TASK_REPOSITORY_REPORT.url, "POST", {
      failureMessage: API_ENDPOINTS.GET_DOWNLOAD_TASK_REPOSITORY_REPORT.failureMessage,
      showSuccessToast: true,
      thenCallBack: (response) => {
        const { workBookBase64, filename } = response.data;
        if (workBookBase64) {
          const excelData = Buffer.from(workBookBase64, "base64");
          const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
          const blob = new Blob([excelData], { type: fileType });
          saveAs(blob, `${filename}`);
        }
        setShowLoaderDownloadTaskRepository(false);
      },
      catchCallBack: () => {
        setShowLoaderDownloadTaskRepository(false);
      },
    });
  };
  return (
    <Box sx={{ display: "flex", justifyContent: "space-between", gap: "32px", marginTop: 2 }}>
      <Box sx={{ display: "flex", gap: "8px" }}>
        <Button
          variant="contained"
          startIcon={<FilterAltIcon />}
          sx={{
            backgroundColor: "#1976d2",
            borderRadius: "4px !important",
            color: "#fff",
            textTransform: "uppercase",
            fontWeight: 500,
            fontSize: "14px",
            lineHeight: "24px",
            letterSpacing: "0.4px",
          }}
          onClick={() => {
            uiLogger(
              uiLoggerNamesPartnerRiskManagement.UI_MIS_PRM_MAINFILTER_CLICK.functionName,
              DEFAULT_COMPANY_ID,
              DEFAULT_BRANCH_CODE,
              { message: uiLoggerNamesPartnerRiskManagement.UI_MIS_PRM_MAINFILTER_CLICK.message }
            );
            setShowMISFilterModal(true);
          }}
        >
          Filter
        </Button>
      </Box>
      <Box sx={{ display: "flex", gap: "8px" }}>
        <NdButton
          variant="contained"
          startIcon={<AddIcon />}
          onClick={() => {
            uiLogger(
              uiLoggerNamesPartnerRiskManagement.UI_MIS_PRM_ADDNEWREPORT_CLICK.functionName,
              DEFAULT_COMPANY_ID,
              DEFAULT_BRANCH_CODE,
              { message: uiLoggerNamesPartnerRiskManagement.UI_MIS_PRM_ADDNEWREPORT_CLICK.message }
            );
            setShowAddNewReportModal(true);
          }}
        >
          Add New Report
        </NdButton>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            borderRadius: "4px",
            border: "1px solid #541c4c80",
          }}
        >
          <Button
            onClick={() => {
              uiLogger(
                uiLoggerNamesPartnerRiskManagement.UI_MIS_PRM_MAILREPORTS_CLICK.functionName,
                DEFAULT_COMPANY_ID,
                DEFAULT_BRANCH_CODE,
                {
                  message: uiLoggerNamesPartnerRiskManagement.UI_MIS_PRM_MAILREPORTS_CLICK.message,
                }
              );
              const uri = `/${actor.name}/ReconMIS/reportsMailAutomation`;
              window.open(uri, "_blank")?.focus();
            }}
            startIcon={<MailIcon />}
            sx={{
              flexGrow: 1,
              color: "#541c4c",
              fontWeight: 500,
              fontSize: "14px",
              lineHeight: "24px",
              letterSpacing: "0.4px",
            }}
          >
            Mail Reports
          </Button>
          <Divider orientation="vertical" flexItem sx={{ borderColor: "#541c4c80" }} />
          <Button
            startIcon={
              showLoaderDownloadTaskRepository ? (
                <LoadingIcon loading={showLoaderDownloadTaskRepository} />
              ) : (
                <CheckCircleIcon />
              )
            }
            sx={{
              flexGrow: 1,
              color: "#541c4c",
              fontWeight: 500,
              fontSize: "14px",
              lineHeight: "24px",
              letterSpacing: "0.4px",
            }}
            disabled={showLoaderDownloadTaskRepository}
            onClick={() => {
              uiLogger(
                uiLoggerNamesPartnerRiskManagement.UI_MIS_PRM_TASKREPOSITORY_CLICK.functionName,
                DEFAULT_COMPANY_ID,
                DEFAULT_BRANCH_CODE,
                {
                  message: uiLoggerNamesPartnerRiskManagement.UI_MIS_PRM_TASKREPOSITORY_CLICK.message,
                }
              );
              downloadTaskRepositoryReport();
            }}
          >
            Task Repository
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
